import React from 'react'
import { Grid, TextField, Box, Card } from '@mui/material';
import { headerstockjob } from '../Services/Apivariables';
import axios from 'axios';
import { useState, useEffect } from 'react';


function QRStockJobOrdernLayoutHeader({ data }) {
  const access = JSON.parse(localStorage.getItem('access'));
  const { role } = access;
  const [isloading, setIsloading] = useState(false);
  const [emptydata, setemptydata] = useState(false);
  const [header, setHeader]= useState([]);
  const order = data.workOrderNum;

  useEffect(() => {
    const worknum = new FormData()
    worknum.append('workOrderNum',order)
   
     axios({
       method:'POST',
       url:headerstockjob,
       data:worknum
     }).then(res => {
       if (res.data.data !== undefined) {
           setHeader(res.data.data);
           setIsloading(true);
       } else {
           setIsloading(true)
           setemptydata(true)
       }
   }).catch(err => {
       console.log(err)
   });
   
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      {
        [header].map((item) =>
          <Grid key={item.id} sx={{ p: 3, borderRadius: 4 }} container component={Card} spacing={2}>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}  >
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.workOrderNum}
                  size="small"
                  fullWidth
                  label='Work Order Num'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.customerName}
                  size="small"
                  fullWidth
                  label='Customer Name'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.fanModel}
                  size="small"
                  fullWidth
                  label='Fan Model'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.workType1}
                  size="small"
                  fullWidth
                  label='Work Type 1'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.workType2}
                  size="small"
                  fullWidth
                  label='Work Type 2'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.stockNumber}
                  size="small"
                  fullWidth
                  label='Stock Number'
                  variant="outlined"

                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.partCode}
                  size="small"
                  fullWidth
                  label='PartCode'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.ERPJobNum}
                  size="small"
                  fullWidth
                  label='ERP Job Num'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.weight}
                  size="small"
                  fullWidth
                  label='Weight'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.Qty}
                  size="small"
                  fullWidth
                  label='Qty'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
          </Grid>
)
      }
    </Box>
  )
}

export default QRStockJobOrdernLayoutHeader