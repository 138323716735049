import React, { useContext, useState } from 'react'
import { Box, Card, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, TablePagination, TextField, Button, IconButton, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Heading from './../../Heading';
import SnackBar from './../../SnackBar';
import DialogBox from './../../DialogBox';
import { appendData } from '../../Variables/ProcessVariables';
import submitForm from './../../../Services/ProcessScreenPostData';
import {  insertStoreBom, methodGet, methodPost } from '../../../Services/Apivariables';
import { bomExcelHeading, bomTableHeading, exporttoexcel } from '../../Variables/Variables';
import { bomdataContext } from './../../../Pages/OtherScreenlayoutSingle';
import Nodata from './../../Nodata';
import StoreBomExcel from './StoreBomExcel';
import { useEffect } from 'react';
import axios from 'axios';
import useFetch from '../../../Services/useFetch';
import Statusloader from '../../Statusloader';




function Storebom({ setrefreshpage, storebomscreen , isloading }) {
  // const { storebomscreen } = useContext(bomdataContext)
  const [availableComponents, setAvailableComponents] = useState(0);
  const [count, setCount] = useState(0);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const additionComponents = (e, qty) => {
    const addvalue = qty - e
    setCount(addvalue)
  }
  const submit = (id) => {
    const sendData = {
      bom_id: id,
      available_qty: availableComponents,
      short_qty: count
    }
    const serverData = appendData(sendData)
    submitForm(insertStoreBom, methodPost, serverData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage)
    setCount(0)
    setEdit(false);
    setId(null);
  }
  const qty = storebomscreen && storebomscreen.every((element) => {
    if (element.qty === '0') {
      return true
    } else {
      return false
    }
  })


  // useEffect(() => {
  //   axios({
  //     method: methodGet,
  //     url: boughtout,
  //   })
  // }, [])

  if (!isloading) {
    return (
      <Statusloader />
    )
  } else { 

  return (
    <>
      <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <DialogBox message={message} open={openDialog} setOpenDialog={setOpenDialog} />
      <Grid container sx={{ py: 3 }}>
        <Grid item lg={10} >
      <Box>
        <Heading title={'Store Bom'} />
      </Box>
      </Grid>
      {/* <Grid item lg={2}>
        <Box>
        <Button type="submit" onClick={HandleClick} variant="contained">
                          Bom Data
                        </Button>
        </Box>
        </Grid> */}
      <Grid item lg={2}>
        <Box>
          <StoreBomExcel  tableid={'storebom_report'} filename={"Store_Bom_report"} />
        </Box>
        </Grid>
        </Grid>
        {storebomscreen.length === 0 || qty ? <Nodata /> : <>
          <TableContainer className='d-none'>
            <Table id="storebom_report">
              <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                <TableRow>
                  {bomExcelHeading.map((labels) =>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 600, color: 'white' }} key={labels.id}>
                      {labels.name}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {storebomscreen.map((bomdata) =>
                  <TableRow key={bomdata.id}>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {bomdata.job_num}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {bomdata.part_code}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {bomdata.descriptions}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {bomdata.qty}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {bomdata.available_qty}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {bomdata.short_qty}
                    </TableCell>
              
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
       
        </>}
      <Box sx={{ p: 2 }} component={Card}>
        {storebomscreen.length === 0 || qty ? <Nodata /> : <>
          <TableContainer>
            <Table>
              <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                <TableRow>
                  {bomTableHeading.map((labels) =>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 600, color: 'white' }} key={labels.id}>
                      {labels.name}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {storebomscreen.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((bomdata) =>
                  <TableRow key={bomdata.id}>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {bomdata.job_num}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {bomdata.part_code}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {bomdata.descriptions}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {bomdata.qty}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <IconButton aria-label="edit" onClick={() => {
                        setEdit(true);
                        setId(bomdata.id);
                      }}>
                        <EditIcon color='primary' />
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Box>
                        <TextField
                          disabled={edit && id === bomdata.id ? false : true}
                          fullWidth
                          // defaultValue={bomdata.available_qty}
                          label="Available Components"
                          variant="outlined"
                          size='small'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            setAvailableComponents(e.target.value)
                            additionComponents(e.target.value, bomdata.qty)
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {bomdata.available_qty}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {(edit && id === bomdata.id) && (count !== 0 && count) || (bomdata.short_qty && bomdata.short_qty)}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Box>
                        <Button disabled={edit && id === bomdata.id ? false : true} onClick={() => submit(bomdata.id)} variant="contained" startIcon={<CheckCircleOutlineIcon />}>
                          Update
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={storebomscreen.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>}
      </Box>
    </>
  )
}
}

export default Storebom