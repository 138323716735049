import React, { useState , useEffect } from 'react'
import {
  Card, Grid, TextField, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  TablePagination,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tab,
  Typography,
  Checkbox,
  Chip,
  ListItem
} from '@mui/material'
import useFetch from '../Services/useFetch';
import Heading from './../Comps/Heading';
import { subcontractorheading, mapSubJobValues, mapSubBroughtOutValues, subcontractorOutwardsheading } from '../Comps/Variables/Variables';
import { fetchsubcontractorjobs, insertsubcontractorcompany, insertsubcontractoutwardjobs, methodGet, methodPost, subcontractordetails, subcontractorfetch } from '../Services/Apivariables';
import { appendData } from '../Comps/Variables/ProcessVariables';
import submitForm from './../Services/ProcessScreenPostData';
import SnackBar from './../Comps/SnackBar';
import DialogBox from './../Comps/DialogBox';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Filter from './../Comps/Filter';
import { filterData } from '../Comps/FilterData';
import SubcontractorBoughtoutTable from '../Comps/SubcontractorBoughtoutTable';
import date from 'date-and-time';
import Pdfdialogcomp from '../Comps/PdfModule/Pdfdialogcomp';
import { MuiChipsInput } from 'mui-chips-input'
import ChipInputTable from '../Comps/ChipInputTableOutwards';

function SubContractorScreen() {
  const { register, formState: { errors }, reset, handleSubmit } = useForm();
  const { data: subcontractordata, setrefreshpage: refreshpage } = useFetch(subcontractordetails, methodGet);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [fetchData, setFetchData] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [boughtOutAllOrders, setBoughtOutAllOrders] = useState([]);
  const [worktype, setWorktype] = useState('');
  const [weight, setWeight] = useState(null);
  const [value, setValue] = useState('1');
  const [search, setSearch] = useState('');
  const [broughtOutSelected, setBroughtOutSelected] = useState([]);
  const [number, setNumber] = useState([]);
  const [pdfdialogopen, setpdfdialogopen] = useState(false);
  const [dialogdata, setdialogdata] = useState({});
  const[subSelected, setsubSelected] = useState([]);
  const [chips, setChips] = React.useState([])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const submitContractor = (data) => {
    const serverData = appendData(data)
    submitForm(insertsubcontractorcompany, methodPost, serverData, setMessage, setOpen, setStatus, setColor, setOpenDialog, refreshpage);
    reset({
      subcontract_name: '',
      address: '',
      gst_no: ''
    })
  }
  const fetchdetails = (url, method, id) => {
    const subid = new FormData()
    subid.append("id", id)
    axios({
      method: method,
      url: url,
      data: subid
    }).then(res => {
      setFetchData(res.data.data);
    }).catch(err => {
      console.log(err)
    });
  }

const sendTag =(e)=>{setNumber(e);}

const fetchAllOrders = () => {
    if (worktype !== "") {
      const selectedjobs = number.map((tags, i) => {
        const obj = {
            i: i,
            ordernum: tags
           
        }
        return obj
    }) 
    const serverData = {
        "order_num": JSON.stringify(selectedjobs),
        "work_type": worktype
    }
   const deptData = appendData(serverData);
      axios({
        method: 'POST',
        url: fetchsubcontractorjobs,
        data: deptData
      }).then(res => {
        if (!res.data.error) {
          if (res.data.data !== undefined || res.data.boughtout !== undefined) {
            setAllOrders(res.data.data);
            setBoughtOutAllOrders(res.data.boughtout)
          }
        } else {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
        }
      }).catch(err => {
        console.log(err)
      });
    } else {
      setMessage("Please select atleast one work type")
      setOpen(true)
      setStatus(false)
      setColor(false)
    }
   
  }
  
  const submit = () => {
    const now = new Date();
    const currentDateandTime = date.format(now, 'DDMMYYYYHHmmss');
    if (broughtOutSelected.length !== 0 && subSelected.length !== 0 && weight !== null) {
      const subcontractorjobData = mapSubJobValues(subSelected, {
        subcontract_name: fetchData.subcontract_name,
        address: fetchData.address,
        gst_no: fetchData.gst_no,
        subcontractor_worktype: worktype,
        dcnumber: number + currentDateandTime,
        actual_fw:weight
      });
      const subcontractorbrogData = mapSubBroughtOutValues(broughtOutSelected, {
        subcontract_name: fetchData.subcontract_name,
        address: fetchData.address,
        gst_no: fetchData.gst_no,
        subcontractor_worktype: worktype,
        dcnumber: number + currentDateandTime
      });
      const sendData = {
        'subcontract': JSON.stringify(subcontractorjobData),
        'boughtout': JSON.stringify(subcontractorbrogData),
        'actual_fw': weight
      }
      const submitData = appendData(sendData)
      submitForm(insertsubcontractoutwardjobs, methodPost, submitData, setMessage, setOpen, setStatus, setColor, setOpenDialog, refreshpage)
      setdialogdata({
        subcontract_name: fetchData.subcontract_name,
        address: fetchData.address,
        gst_no: fetchData.gst_no,
        subcontractor_worktype: worktype,
        dcnumber: number + currentDateandTime,
        subcontractorjobData: subcontractorjobData,
        subcontractorbrogData: broughtOutSelected,
      });
      setpdfdialogopen(!pdfdialogopen)
      fetchAllOrders();
      setBroughtOutSelected([]);
      setsubSelected([]);  
    } else {
      setMessage("Please select atleast one job")
      setOpen(true)
      setStatus(false)
      setColor(false)
    }

  }



  // Checkbox selection for individual boxes
  const handleClick = (event, name) => {
    const selectedIndex = subSelected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(subSelected, name);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(subSelected.slice(1));
    } else if (selectedIndex === subSelected.length - 1) {
        newSelected = newSelected.concat(subSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          subSelected.slice(0, selectedIndex),
          subSelected.slice(selectedIndex + 1),
        );
    }

    setsubSelected(newSelected);
};

// this function is for select all checkbox
const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const suborder = allOrders.flatMap((order)=>order);
        const newSelecteds = suborder;
        setsubSelected(newSelecteds);
        return;
    }
    setsubSelected([]);
};

const flatAllOrder = allOrders.flatMap((orders) => orders);


const isSelected = (name) => subSelected.indexOf(name) !== -1;

  return (
    <Box variant="div" sx={{ p: 3 }}>
      <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
      <DialogBox message={message} open={openDialog} setOpenDialog={setOpenDialog} />
      <Pdfdialogcomp data={dialogdata} setpdfdialogopen={setpdfdialogopen} pdfdialogopen={pdfdialogopen} />
      <Box sx={{ py: 3 }}>
        <Heading title={'sub contract outwards'} />
      </Box>
      <Box sx={{ p: 2, borderRadius: 4 }} component={Card}>
        <TabContext value={value} >
          <Box sx={{ py: 3 }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              centered
            >
              <Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Subcontarctor Details" value="1" />
              <Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Add Subcontarctor Details" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Box>
              <Grid container justifyContent='center'>
                <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                  <Grid container sx={{ p: 2 }} spacing={2}>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                      <Box>
                        <Autocomplete
                          disablePortal
                          size='small'
                          fullWidth
                          disableClearable
                          onChange={(e, obj) => {
                            fetchdetails(subcontractorfetch, methodPost, obj.id)
                          }}
                          options={subcontractordata}
                          renderInput={(params) => <TextField {...params} label="Subcontractor Company Name" InputLabelProps={{
                            shrink: true,
                          }} />}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                      <TextField
                        disabled
                        multiline
                        defaultValue={fetchData.address}
                        fullWidth
                        size='small'
                        label="Address"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                      <TextField
                        disabled
                        multiline
                        defaultValue={fetchData.gst_no}
                        fullWidth
                        size='small'
                        label="GST Number"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                      <FormControl size='small' fullWidth>
                        <InputLabel>Work Type</InputLabel>
                        <Select defaultValue="" label="Work Type" onChange={(e) => {
                          setWorktype(e.target.value)
                          // fetchAllOrders(fetchsubcontractorjobs, methodPost, number)
                        }}>
                          <MenuItem value={'Casing'}>Casing</MenuItem>
                          <MenuItem value={'Impeller'}>Impeller</MenuItem>
                          <MenuItem value={'Hub'}>Hub</MenuItem>
                          <MenuItem value={'Machining'}>Machining</MenuItem>
                          <MenuItem value={'First Coat Paint'}>Frist Coat Paint</MenuItem>
                          <MenuItem value={'Final Painting'}>Final Painting</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Form onSubmit={handleSubmit(submitContractor)}>
              <Grid container justifyContent='center'>
                <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                  <Grid container sx={{ p: 2 }} spacing={2}>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                      <Box>
                        <TextField
                          error={errors.subcontract_name ? true : false}
                          helperText={(errors.subcontract_name && errors.subcontract_name.type === "required") ? " Subcontarctor company name is required" : "Please provide inital also"}
                          multiline
                          fullWidth
                          size='small'
                          label="Subcontractor Company Name"
                          variant="outlined"
                          {...register("subcontract_name", { required: true })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                      <TextField
                        error={errors.address ? true : false}
                        helperText={(errors.address && errors.address.type === "required") && "Address is required"}
                        multiline
                        fullWidth
                        size='small'
                        label="Address"
                        variant="outlined"
                        {...register("address", { required: true })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                      <TextField
                        error={errors.gst_no ? true : false}
                        helperText={((errors.gst_no && errors.gst_no.type === "required") && "GST No required") || ((errors.gst_no && errors.gst_no.type === "minLength") && "Minium 15 characters is required") || ((errors.gst_no && errors.gst_no.type === "maxLength") && "Maximum 15 characters is required")}
                        multiline
                        fullWidth
                        size='small'
                        label="GST Number"
                        variant="outlined"
                        {...register("gst_no", { required: true, maxLength: 15, minLength: 15 })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                      <Box>
                        <Button variant="contained" type='submit'>
                          Submit
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </TabPanel>
        </TabContext>
      </Box>
      <Box sx={{ py: 5 }} >
        <Box sx={{ p: 2, borderRadius: 4 }} component={Card}>
          <Box sx={{ p: 3, borderRadius: 4 }} component={Card}>
            <Heading title={'subcontractor details'} />
            <Box sx={{ py: 3 }}>
              <Grid container justifyContent='space-between'>
                <Grid item sm={2} md={2} lg={2}>
                  <Box sx={{py:1}}>
                  <ChipInputTable sendTag={sendTag} />
                  <Button
                        sx={{ bgcolor: "#09628e" , mt:2}}
                        variant="contained"
                        onClick={fetchAllOrders}
                       >
                        Submit
                      </Button>
                  </Box>
                </Grid>
                <Grid item sm={2} md={2} lg={2}>
                  <Typography sx={{ fontWeight: '500' }}>Name :</Typography>
                  <Box>
                    {fetchData.subcontract_name}
                  </Box>
                </Grid>
                {/* <Grid item sm={2} md={2} lg={2}>
                  <Typography sx={{ fontWeight: '500' }}>Address :</Typography>
                  <Box>
                    {fetchData.address}
                  </Box>
                </Grid> */}
                <Grid item sm={2} md={2} lg={2}>
                  <Typography sx={{ fontWeight: '500' }}>GST Number :</Typography>
                  <Box>
                    {fetchData.gst_no}
                  </Box>
                </Grid>
                <Grid item sm={2} md={2} lg={2}>
                  <Typography sx={{ fontWeight: '500' }}>Work Type :</Typography>
                  <Box>
                    {worktype}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ py: 3 }}>
            {allOrders.length !== 0 && <>
              <Box>
                <Box>
                  <TableContainer sx={{ py: 4 }}>
                    <Table aria-label="simple table">
                      <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                        <TableRow>
                          <TableCell sx={{
                            color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                          }} >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Box>
                                <Checkbox className='text-white' onChange={handleSelectAllClick} />
                              </Box>
                              <Box>
                                Select
                              </Box>
                            </Box>
                          </TableCell>
                          {subcontractorOutwardsheading.map((labels) =>
                            <TableCell key={labels.id} sx={{
                              color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                            }} >
                              <Box sx={{ display: 'flex' }}>
                                <Box sx={{ my: 'auto' }}>
                                  {labels.title}
                                </Box>
                                <Box>
                                  {labels.filter && <Filter label={labels.title} setSearch={setSearch} />}
                                </Box>
                              </Box>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {flatAllOrder.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((orders , index) => {
                           const isItemSelected = isSelected(orders);
                           return (
                            <TableRow hover key={index} onClick={(event) => handleClick(event, orders)}>
                            <TableCell align='center'>
                              <Box>
                                <Checkbox checked={isItemSelected} />
                              </Box>
                            </TableCell>
                            {/* <TableCell align='center'>
                              {orders.id}
                            </TableCell> */}
                            <TableCell align='center'>
                              {orders.job_num}
                            </TableCell>
                            <TableCell align="center">{orders.sfg_part_name}</TableCell>
                            <TableCell align="center">{orders.sfg_code}</TableCell>
                            <TableCell align="center">{orders.part_code}</TableCell>
                            <TableCell align="center">{orders.descriptions}</TableCell>
                            {/* <TableCell align="center">{orders.part_no}</TableCell> */}
                            <TableCell align="center">{orders.qty}</TableCell>
                            <TableCell align="center">{orders.prod_code}</TableCell>
                            <TableCell align="center">{orders.finished_weight}</TableCell>
                          </TableRow>
                       )})}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={allOrders.flat().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>
                <Box>
               <Box sx={{ py: 5 }} >
               <Box sx={{ p: 2, borderRadius: 4 }} component={Card}>
                 <Heading title={'Add Actual Weight'} />
                 <Box sx={{ py: 2 }}>
                  <TextField
                        size='small'
                        label="Enter Weight"
                        variant="outlined"
                        onChange={(e) => {
                          setWeight(e.target.value);                   
                        }} 
                        InputLabelProps={{
                          shrink: true,
                        }}   
                      />
                  </Box>
                  </Box>
                </Box>
                </Box>
                <Box sx={{ py: 3 }}>
                  <SubcontractorBoughtoutTable data={boughtOutAllOrders} setBroughtOutSelected={setBroughtOutSelected} broughtOutSelected={broughtOutSelected} />
                </Box>
                <Box sx={{ py: 1, textAlign: 'right' }}>
                  <Button variant="contained" onClick={submit} startIcon={<CheckCircleIcon />}>Submit</Button>
                </Box>
              </Box>
            </>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SubContractorScreen
