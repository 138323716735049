import React, { useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Card, Button, Checkbox } from '@mui/material';
import { singlejobScreenTableHeading } from '../Variables/Variables';
import Filter from '../Filter';
import { filterData } from '../FilterData';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function JobscreenSingle({ data,selected,setSelected}) {
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
   


    // this function is for selecting row checkbox
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    // this function is for select all checkbox
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data;
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    return (
        <>
            {
                data.length !== 0 && (
                    <Box sx={{ py: 4 }}>
                        {/* <Box sx={{ py: 2, textAlign: 'right' }}>
                            <Button variant="contained" onClick={submit} startIcon={<CheckCircleIcon />}>
                                Submit
                            </Button>
                        </Box> */}
                        <Box sx={{ p: 2, borderRadius: 4 }} component={Card}>
                            <TableContainer sx={{ borderRadius: 2 }} >
                                <Table>
                                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                                        <TableRow >
                                            <TableCell sx={{
                                                color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                                            }} >
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Box>
                                                        <Checkbox className='text-white' onChange={handleSelectAllClick} />
                                                    </Box>
                                                    <Box>
                                                        Select
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            {
                                                singlejobScreenTableHeading.map((headings, index) =>

                                                    <TableCell sx={{
                                                        color: 'white', fontWeight: 600
                                                    }} key={index}>
                                                        <Box sx={{ display: 'flex',justifyContent:'center' }}>
                                                            <Box sx={{ my: 'auto' }}>
                                                                {headings.heading}
                                                            </Box>
                                                            <Box>
                                                                {headings.filter ? <Filter label={headings.heading} setSearch={setSearch} /> : ''}
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            data.filter((data) => filterData(data, search, {
                                                searchFeildOne: data.job_num_1,
                                                searchFeildTwo: data.prod_code,
                                            })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index) => {
                                                const isItemSelected = isSelected(order);
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={index}
                                                        role="checkbox"
                                                        onClick={(event) => handleClick(event, order)}
                                                    >
                                                        <TableCell align='center'>
                                                            <Box>
                                                                <Checkbox checked={isItemSelected} />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{order.id_no}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{order.serial_no}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{order.job_num_1}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{order.prod_code}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{order.short_char_01}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>1</TableCell>
                                                        {/* <TableCell sx={{ textAlign: 'center' }}>{order.bill_planned}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{order.year_planne}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{order.motor_scope}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{order.payment_status_on_date}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{order.payment_terms}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{order.remarks}</TableCell> */}
                                                    </TableRow>
                                                )
                                            }
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Box>
                    </Box>
                )
            }
        </>

    )
}

export default JobscreenSingle