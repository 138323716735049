import React, { useEffect } from 'react'
import { useState } from 'react';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, IconButton, Tooltip } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SnackBar from '../SnackBar';
import submitForm from '../../Services/ProcessScreenSubmitForm';
import { filterJobScreen } from '../FilterData';
import Filter from '../Filter';
import Heading from '../Heading';
import { methodPost, otherScreenSingleOrders, insertsingleprocessjob, fetchsingleprod, addremarks } from '../../Services/Apivariables';
import { productionDrawingTableHeading } from '../Variables/ProcessVariables';
import DialogBox from '../DialogBox';
import Statusbar from '../Statusbar';
import { nullFinderandDateswapper } from '../Variables/Variables';
import Productiondrawingsinglejob from './Processsinglejob/Productiondrawingsinglejob'
import { appendData } from '../Variables/ProcessVariables'
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styled from '@emotion/styled';
import sampleBom from '../../Assets/sampleexcel/sampleBom.xls'
import Statusloader from '../Statusloader';

const Input = styled('input')({
  display: 'none',
});


function ProductionDrawing({ data, title, setrefreshpage, isloading}) {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [multipleOrders, setMultipleOrders] = useState([])
  const [id, setid] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const [bomExcelFile, setBomExcelFile] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // this state hold all th production inputs value as an obj
  const [productionDrawingInputs, setProductionDrawingInputs] = useState({})
  const [selected, setSelected] = useState([]);
  const jobnum = data.map((i)=>i.job_num);
  const orderid = data.map((i)=>i.id);


  const handleSubmit = () => {
    if (selected.length > 0) {
     if(productionDrawingInputs.production_dg_committed_date !== '' || productionDrawingInputs.bom_committed_date !== ''){
      const selectedjobs = selected.map((element, i) => {
        const obj = {
          i: i,
          orderid: element.orderid,
          production_dg_committed_date: productionDrawingInputs.production_dg_committed_date,
          bom_committed_date: productionDrawingInputs.bom_committed_date,
          production_remarks: productionDrawingInputs.production_remarks ? productionDrawingInputs.production_remarks : ''
        }
        return obj
      })
      const sendData = {
        "job_no":jobnum,
        "production": JSON.stringify(selectedjobs),
        // "bom_excel_file": bomExcelFile
      }
      const lData = appendData(sendData)
       submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchsinglejob, jobnum)
           setProductionDrawingInputs({
             production_dg_committed_date: '',
             bom_committed_date: '',
             production_remarks: ''
           });
    }
    else
    {
      const selectedjobs = selected.map((element, i) => {
        const obj = {
          i: i,
          orderid: element.orderid,
          production_remarks: productionDrawingInputs.production_remarks
        }
        return obj
      })
      const sendData = {
        "production": JSON.stringify(selectedjobs),
        // "bom_excel_file": bomExcelFile
      }
      const lData = appendData(sendData)
       submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchsinglejob, jobnum)
        setProductionDrawingInputs({
        production_dg_committed_date: '',
        bom_committed_date: '',
        production_remarks: ''
      });
    }}
    else {
      setMessage("Please select atleast one job")
      setOpen(true)
      setStatus(false)
      setColor(false)
    }
  }


// const onUpdate = () => {
//     if(productionDrawingInputs.production_dg_committed_date !== '' || productionDrawingInputs.bom_committed_date !== ''){
//       const selectedjobs = data.map((element, i) => {
//         const obj = {
//           i: i,
//           orderid: orderid.toString(),
//           production_dg_committed_date: productionDrawingInputs.production_dg_committed_date,
//           bom_committed_date: productionDrawingInputs.bom_committed_date,
//           production_remarks: productionDrawingInputs.production_remarks ? productionDrawingInputs.production_remarks : ''
//         }
//         return obj
//       })
//      const sendData = {
//        "job_no":jobnum,
//        "production": JSON.stringify(selectedjobs),
//        // "bom_excel_file": bomExcelFile
//      }
//      const lData = appendData(sendData)
//      submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage)
//      setProductionDrawingInputs({
//        production_dg_committed_date: '',
//        bom_committed_date: '',
//        production_remarks: ''
//      });
     
//      setIsEdit(false);
//      setid(null)
   
//    }
//    else if(productionDrawingInputs.production_remarks !==''){
//     const selectedjobs = data.map((element, i) => {
//       const obj = {
//         i: i,
//         orderid: orderid.toString(),
//         production_remarks: productionDrawingInputs.production_remarks
//       }
//       return obj
//     })
//      const sendData = {
//       "job_no":jobnum,
//        "production": JSON.stringify(selectedjobs),
//        // "bom_excel_file": bomExcelFile
//      }
//      const lData = appendData(sendData)
//      submitForm(addremarks, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage)
//      setProductionDrawingInputs({
//        production_dg_committed_date: '',
//        bom_committed_date: '',
//        production_remarks: ''
//      });
//      setSelected([]);
//      setIsEdit(false);
//      setid(null)
//    }
//    else {
//     setMessage("Please Fill atleast One Input Field")
//     setOpen(true)
//     setStatus(false)
//     setColor(false)
// }
// }


  const fetchsinglejob = (id) => {
    const sendid = new FormData();
    sendid.append("job_num", id);
    axios({
      method: methodPost,
      url: fetchsingleprod,
      data: sendid
    }).then(res => {
      setMultipleOrders(res.data.data)
    }).catch(err => {
      console.log(err)
    });
  }

  // const edd = data.map((i)=> i.production_drawing_edd.toString());
  // const date = new Date(edd);
  // const day = date.getDate()- 6;
  // const minDay =  date.setDate(day);
  // let d , m ;
  // d = date.getDate();
  // m = date.getMonth()+1;
  // if (d < 10) {
  //     d = '0' + d;
  //   }
    
  //   if (m < 10) {
  //     m = '0' + m;
  //   }
  // const convert = [date.getFullYear(),(m), (d)].join('-');
  if (!isloading) {
    return (
      <Statusloader />
    )
  } else { 
  return (
    <Box>
      <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
      <Box sx={{ py: 3 }}>
        <Heading title={title} />
      </Box>
      <Box sx={{ py: 3 }}>
        <Box sx={{ p: 2 }} component={Card}>
          <Box sx={{ py: 1, textAlign: 'end' }}>
            <Box>
              <Button
                sx={{ whiteSpace: 'nowrap' }}
                variant="contained"
                href={sampleBom}
                target='_blank'
                startIcon={<FileDownloadIcon fontSize='small'
                />}>
                Sample Bom File
              </Button>
            </Box>
          </Box>
          <TableContainer >
            <Table>
              <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                <TableRow >
                  {
                    productionDrawingTableHeading.map((headings) =>
                      <TableCell key={headings.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box sx={{ my: 'auto' }}>
                            {headings.label}
                          </Box>
                          <Box>
                            {headings.filter ? <Filter label={headings.label} setSearch={setSearch} /> : ''}
                          </Box>
                        </Box>
                      </TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  data.filter((data) => filterJobScreen(data, search, {
                    searchFeildOne: data.job_num,
                    searchFeildTwo: data.prod_code,
                    searchFeildThree: data.short_char_01
                  })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) =>
                    <TableRow key={items.id} hover >
                      <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.serial_no}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.job_num}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        <Box>
                          <Statusbar label={items.production_status === 'true' ? 'Completed' : 'Pending'} color={items.production_status === 'true' ? 'success' : 'error'} />
                        </Box>
                      </TableCell> */}
                      <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                      <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                      <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.production_drawing_edd)}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <IconButton
                          aria-label="edit" onClick={() => {
                            setIsEdit(!isEdit)
                            setid(items.id)
                            fetchsinglejob(items.job_num)
                            setProductionDrawingInputs({
                              production_dg_committed_date: '',
                              bom_committed_date: '',
                              production_remarks: ''
                            });
                          }}>
                          {isEdit && id === items.id ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
                        </IconButton>
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        <Box>
                          <Box >
                            <label htmlFor="contained-button-file">
                              <Input
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={(e) => {
                                  setBomExcelFile(e.target.files[0]);
                                  setFileUploaded(!fileUploaded)
                                }} id="contained-button-file" type="file" />
                              <Button
                                disabled={isEdit && id === items.id ? false : true}
                                variant="contained"
                                color={fileUploaded ? 'secondary' : 'primary'}
                                sx={{ whiteSpace: 'nowrap' }}
                                component="span"
                                startIcon={fileUploaded ? <DownloadDoneIcon /> : <UploadFileIcon />}>
                                {fileUploaded ? 'Bom Uploaded' : 'Bom Upload'}
                              </Button>
                            </label>
                          </Box>
                        </Box>
                      </TableCell> */}
                      <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          disabled={isEdit && id === items.id ? false : true}
                          value={isEdit && id === items.id ? productionDrawingInputs.production_dg_committed_date : ''}
                          type='date'
                          onChange={(e) => setProductionDrawingInputs({ ...productionDrawingInputs, "production_dg_committed_date": e.target.value })}
                          fullWidth
                          size='small'
                          label='Production Drawing DG Release Date'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // inputProps={{min: [convert] }}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          disabled={isEdit && id === items.id ? false : true}
                          type='date'
                          value={isEdit && id === items.id ? productionDrawingInputs.bom_committed_date : ''}
                          onChange={(e) => setProductionDrawingInputs({ ...productionDrawingInputs, "bom_committed_date": e.target.value })}
                          fullWidth
                          size='small'
                          label='BOM Release Date'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ min: productionDrawingInputs.bom_committed_date }}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          sx={{ width: 140 }}
                          multiline
                          value={isEdit && id === items.id ? productionDrawingInputs.production_remarks : ''}
                          disabled={isEdit && id === items.id ? false : true}
                          onChange={(e) => setProductionDrawingInputs({ ...productionDrawingInputs, "production_remarks": e.target.value })}
                          fullWidth
                          size='small'
                          label='Remarks'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell>

                         <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box> 

                        {/* {
                          multipleOrders.length !== 0 ? 
                          <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box> : 
                         <Box>
                         <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                           onUpdate()
                          
                         }} startIcon={<CheckCircleOutlineIcon />}>
                           Update
                         </Button>
                       </Box>
                        } */}
                        

                       
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          type='date'
                          onChange={(e) => setPdRevisedDate(e.target.value)}
                          fullWidth
                          size='small'
                          label='Revised Date'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          sx={{ width: 140 }}
                          multiline
                          onChange={(e) => setPdRRemarks(e.target.value)}
                          fullWidth
                          size='small'
                          label='Remarks'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Box>
                          <Button variant="contained" onClick={() => {
                            handleSubmitRevised(items.id)
                            fetchsinglejob(items.job_num)
                          }} startIcon={<TelegramIcon />}>
                            Request
                          </Button>
                        </Box>
                      </TableCell> */}
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <Box sx={{ py: 3 }}>
        <Box>
          <Productiondrawingsinglejob isEdit={isEdit} data={multipleOrders} selected={selected} setSelected={setSelected} />
        </Box>
      </Box>
    </Box >
  )
}
}

export default ProductionDrawing