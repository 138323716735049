import { AppBar, Box, IconButton, Toolbar, Typography} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react'
import ResponsiveBd from './ResponsiveBd';

function NavBar({ mobileOpen, setMobileOpen }) {
 const responsive = ResponsiveBd()
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <AppBar position="sticky" sx={{ py: 1, width: '100%', bgcolor: 'white',color:'black' }}>
        <Toolbar>
          <IconButton aria-label="menu"
            sx={{
              display: responsive ? 'block': 'none'
            }} 
            onClick={handleDrawerToggle} >
            <MenuIcon />
          </IconButton>
          <Typography sx={{ px: 2,textTransform: 'uppercase' }} variant="h6">
            Nadi
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default NavBar