import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, TablePagination, Card } from '@mui/material';


export default function CustomizedDialogs({open, setOpen, report , dept}) {
  const center = 'center';
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <React.Fragment>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box display='flex' justifyContent='space-between'>
            <Box>
              <Typography variant='subtitle1' fontWeight={600}>{`${dept} : Total Jobs - ${report && report.length}`}</Typography>
            </Box>
            <Box onClick={handleClose}>
              <CloseIcon />
            </Box>
          </Box></DialogTitle>
        <DialogContent>
    {report && report.length !== 0 ? (
        <Box m={3}>
                  <TableContainer sx={{ borderRadius: 2 }} >
                  <Table sx={{ borderRadius: 2 }}>
                      <TableHead sx={{ whiteSpace: 'nowrap' }}>
                          <TableRow>
                          <TableCell sx={{fontWeight:600,textAlign: center }}>Job Number</TableCell>
                          <TableCell sx={{fontWeight:600,textAlign: center }}>Customer Name</TableCell>
                          <TableCell sx={{fontWeight:600,textAlign: center }}>Fan Model</TableCell>
                          <TableCell sx={{fontWeight:600,textAlign: center }}>Qty</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                   {
                       report.map((item) => 
                        <TableRow>
                          <TableCell sx={{ textAlign:'center'}}>{item.job_num}</TableCell>
                          <TableCell sx={{ textAlign:'center'}}>{item.CustomerName}</TableCell>
                          <TableCell sx={{ textAlign:'center'}}>{item.FanModel}</TableCell>
                          <TableCell sx={{ textAlign:'center'}}>{item.OrderQty}</TableCell>
                        </TableRow>
                      )}
                      </TableBody>
                  </Table>
              </TableContainer>
              </Box>
              )
              :
            (
            <Box>
              <Typography variant='subtitle1' fontWeight={600}>There is No Job. Total Qty is 'ZERO'</Typography>
            </Box>
                    )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
    </div>
  );
}