import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Button, Grid, TextField, Typography, IconButton, InputAdornment } from '@mui/material';
// import Loginimg from '../Assets/login.jpg';
import nadi from '../Assets/nadiloader.gif';
import Image from 'react-bootstrap/Image';
import { useForm } from 'react-hook-form';
import SnackBar from './../Comps/SnackBar';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Form } from 'react-bootstrap';
import { login, methodGet, methodPost } from '../Services/Apivariables';
import axios from 'axios';
import { startUrl } from './../Routes';



function Login({ setsuccessOpen, setsuccessMessage, setsuccessStatus, setsuccessColor }) {
  const navigate = useNavigate();
  // useEffect(() => {
  //   axios({
  //     method: methodGet,
  //     url: orderdb,
  //   })
  // }, [])
  // useEffect(() => {
  //   axios({
  //     method: methodGet,
  //     url: serialno,
  //   })
  // }, [])
  // useEffect(() => {
  //   axios({
  //     method: methodGet,
  //     url: jobnoloop,
  //   })
  // }, [])


  const { register, formState: { errors }, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [dept , setDept]= useState('');
  const onSubmit = (data) => {
    const serverData = new FormData()
    for (var key in data) {
      serverData.append(key, data[key]);
    }
    if (!navigator.onLine) {
      setMessage('Your internet is in Offline')
      setOpen(true)
      setStatus(false)
      setColor(false)
    } else {
      axios({
        method: methodPost,
        url: login,
        data: serverData,
      }).then(res => {
        if (res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
        } else {
          localStorage.setItem('auth', true);
          localStorage.setItem('department', JSON.stringify(res.data.data.department));
          localStorage.setItem('roles', JSON.stringify(res.data.data.roles));
          localStorage.setItem('access', JSON.stringify({
            role: res.data.data.roles,
            jobscreenedit: res.data.data.jobscreenedit,
            jobscreenview: res.data.data.jobscreenview,
            partcode_edit: res.data.data.partcode_edit,
            fgpartcode_edit: res.data.data.fgpartcode_edit,
            sfgpartcode_edit: res.data.data.sfgpartcode_edit,
            subcomppartcode_edit: res.data.data.subcomppartcode_edit,
            partcode_view: res.data.data.partcode_view,
            store_stock: res.data.data.store_stock,
            stock_job: res.data.data.stock_job,
            stock_qr: res.data.data.stock_qr,
            stock_pending: res.data.data.stock_pending,
            stock_issued: res.data.data.stock_issued,
            momscreenedit: res.data.data.momscreenedit,
            momscreenview: res.data.data.momscreenview,
            view_all: res.data.data.view_all,
            process_view: res.data.data.process_view,
            process_edit: res.data.data.process_edit,
            pms: res.data.data.pms,
            production: res.data.data.production,
            store: res.data.data.store,
            mom: res.data.data.mom,
            purchase: res.data.data.purchase,
            cnc: res.data.data.cnc,
            hub: res.data.data.hub,
            machining: res.data.data.machining,
            impeller: res.data.data.impeller,
            accessories:res.data.data.accessories,
            casing: res.data.data.casing,
            first_paint: res.data.data.first_paint,
            assembly: res.data.data.assembly,
            testing: res.data.data.testing,
            final_paint: res.data.data.final_paint,
            packing: res.data.data.packing,
            production_view: res.data.data.production_view,
            store_view: res.data.data.store_view,
            mom_view: res.data.data.mom_view,
            purchase_view: res.data.data.purchase_view,
            cnc_view: res.data.data.cnc_view,
            hub_view: res.data.data.hub_view,
            machining_view: res.data.data.machining_view,
            impeller_view: res.data.data.impeller_view,
            casing_view: res.data.data.casing_view,
            first_paint_view: res.data.data.first_paint_view,
            assembly_view: res.data.data.assembly_view,
            testing_view: res.data.data.testing_view,
            final_paint_view: res.data.data.final_paint_view,
            packing_view: res.data.data.packing_view,
            accessories_view:res.data.data.accessories_view,
            dashboard_view:res.data.data.dashboard_view,
            bom_file_edit:res.data.data.bom_file_edit,
            sales_target_edit:res.data.data.sales_target_edit,
            inventory:res.data.data.inventory,
            outwards:res.data.data.outwards,
            inwards:res.data.data.inwards,
            dc_numbers:res.data.data.dc_numbers,
            edit_register:res.data.data.edit_register,
            add_register:res.data.data.add_register,
            sale_order:res.data.data.sale_order,
            management:res.data.data.management,
            monthly_job:res.data.data.monthly_job

          }))
          navigate(`${startUrl}app`)
          setsuccessOpen(true)
          setsuccessMessage(res.data.message)
          setsuccessStatus(true)
          setsuccessColor(true)

        }
      }).catch(err => {
        alert('Oops something went wrong ' + err)
      });
    }

  }
  return (
    <Box sx={{ bgcolor: '#eaf4fc', height: '100vh' }}>
      <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <>
        <Box component='div' >
          <Grid container sx={{ height: '100vh' }} alignItems={'center'}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container rowSpacing={2} justifyContent='center' alignItems='center'>
                <Grid item md={6} lg={7}>
                  <Box textAlign='center' sx={{
                    p: {
                      sm: 2,
                      xs: 2
                    },
                    height: {
                      lg: '70%',
                      xl: '100%'
                    },
                    width: {
                      lg: '70%',
                      xl: '100%'
                    }
                  }}>
                    <Image src={nadi} className='curve img-fluid' />
                  </Box>
                </Grid>
                <Grid item md={6} lg={4}>
                  <Grid container justifyContent='center'>
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                      <Box>
                        <Box sx={{ py: 2 }}>
                          <Typography fontSize={{
                            lg: 30,
                            md: 26,
                            sm: 23,
                            xs: 20
                          }} color='primary' variant='h5' >Welcome</Typography>
                        </Box>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <Box sx={{ py: 2 }}>
                            <TextField
                              error={errors.username ? true : false}
                              helperText={(errors.username && errors.username.type === "required") ? "User name is required" : ""}
                              fullWidth
                              label="User Name"
                              variant="filled"
                              {...register("username", { required: true })}
                            />

                          </Box>
                          <Box sx={{ py: 2 }}>
                            <TextField
                              fullWidth
                              variant="filled"
                              type={showPassword ? 'password' : 'text'}
                              {...register("pass", { required: true })}
                              label="Password"
                              InputProps={{
                                endAdornment:
                                  (<InputAdornment position="end">
                                    <IconButton
                                      onClick={() => setShowPassword(!showPassword)}
                                      edge="end"
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>),
                              }}
                              helperText={(errors.pass && errors.pass.type === "required") ? "Password is required" : ""}
                              error={errors.pass ? true : false}
                            />
                          </Box>
                          <Box sx={{ py: 2 }}>
                            <Button
                              type='submit'
                              sx={{ p: 2 }}
                              fullWidth
                              variant="contained"
                              color='primary'
                            >Submit</Button>
                          </Box>
                        </Form>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Outlet />
        </Box>
      </>
    </Box>

  )
}

export default Login