import React from 'react'
import { useState , useEffect } from 'react';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, IconButton, Tooltip } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { addremarks, fetchsingleaccessories, fetchsingleimpeller, insertsingleprocessjob, methodPost, otherScreenSingleOrders } from '../../Services/Apivariables';
import SnackBar from './../SnackBar';
import DialogBox from './../DialogBox';
import Heading from './../Heading';
import { AccessoriesTableHeading, impellerTableHeading } from '../Variables/ProcessVariables';
import Filter from './../Filter';
import { filterJobScreen } from '../FilterData';
import { nullFinderandDateswapper } from '../Variables/Variables';
import AccessoriesBarCodeTable from './../BarCodeModule/AccessoriesBarCodeTable';
import submitForm from '../../Services/ProcessScreenSubmitForm';
import Statusbar from './../Statusbar';
import Accessoriessinglejob from './Processsinglejob/Accessoriessinglejob'
import { appendData } from '../Variables/ProcessVariables'
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Accessories({ data, title, setrefreshpage, fetchCommonFirstTable }) {
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [id, setid] = useState('');
    const [multipleOrders, setMultipleOrders] = useState([])
    const [accessoriesBarcodeData, setAccessoriesBarcodeData] = useState([]);
    const [accessoriesscreen, setAccessoriesscreen] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [receivedDate, setReceivedDate] = useState(null);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // useEffect(() => {
    //     if(data.length !== 0){
    //     const sendData = new FormData();
    //     sendData.append('job_num', jobnum)
    //     sendData.append('department', title)
    //     axios({
    //         method: 'POST',
    //         url: otherScreenSingleOrders,
    //         data: sendData,
    //     }).then(res => {
    //         if (res.data.error) {
    //             setMessage(res.data.message)
    //             setrefreshpage(false)
    //         } else {
    //             setAccessoriesBarcodeData(res.data.accessories_sfg);
    //             setAccessoriesscreen(res.data.accessoriesbom);
    //             setOpen(true)
    //             setMessage(res.data.message)
    //             setStatus(true)
    //             setColor(true)
    //             setrefreshpage(false)
    //             setIsloading(true);
    //         }
    //     }).catch(err => {
    //         console.log(err)
    //     });
    // }
    // }, [])  

    // this state holds all the input values as an obj
    const [accessoriesInput, setaccessoriesInput] = useState({})
    const [selected, setSelected] = useState([]);
    const jobnum = data.map((i)=>i.job_num);
    const orderid = data.map((i)=>i.id);

    const handleSubmit = () => {
        if (receivedDate === null) {
            if (accessoriesInput.issued_date !== '' && accessoriesInput.issued_date !== null) {
                const sendData = {
                    "job_no": jobnum,
                    "accessories": JSON.stringify([{ issued_date: accessoriesInput.issued_date }])
                }
                const lData = appendData(sendData)
                submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchCommonFirstTable, fetchsinglejob, jobnum) 
                setaccessoriesInput({
                    issued_date: '',
                    accessories_remarks: '',
                    completed_date: ''
                });
            }
            else {
                setMessage("Please Fill Received Date")
                setOpen(true)
                setStatus(false)
                setColor(false)
            }
        }
         else if (selected.length > 0) {
        if (accessoriesInput.completed_date !== null && accessoriesInput.completed_date!==''){
            const selectedjobs = selected.map((element, i) => {
                const obj = {
                    i: i,
                    orderid: element.orderid,
                    accessories_remarks: accessoriesInput.accessories_remarks ? accessoriesInput.accessories_remarks : '',
                    completed_date: accessoriesInput.completed_date
                }
                return obj
            })
            const sendData = {
                "job_no":jobnum,
                "accessories": JSON.stringify(selectedjobs)
            }
            const lData = appendData(sendData)
                submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchCommonFirstTable, fetchsinglejob, jobnum) 
                setaccessoriesInput({
                issued_date: '',
                accessories_remarks: '',
                completed_date: ''
            });
        }
        }
        else {
            setMessage("Please select atleast one job")
            setOpen(true)
            setStatus(false)
            setColor(false)
        } 
    }


    // const onUpdate = () => {
    //     if(accessoriesInput.issued_date!==''||accessoriesInput.completed_date!==''){
    //         const selectedjobs = data.map((element, i) => {
    //             const obj = {
    //                 i: i,
    //                 orderid:orderid.toString(),
    //                 issued_date: accessoriesInput.issued_date,
    //                 accessories_remarks: accessoriesInput.accessories_remarks ? accessoriesInput.accessories_remarks : '',
    //                 completed_date: accessoriesInput.completed_date
    //             }
    //             return obj
    //         })
    //         const sendData = {
    //             "job_no":jobnum,
    //             "accessories": JSON.stringify(selectedjobs)
    //         }
    //         const lData = appendData(sendData)
    //         submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
    //         setaccessoriesInput({
    //             issued_date: '',
    //             accessories_remarks: '',
    //             completed_date: ''
    //         });
    //         setIsEdit(false);
    //         setid(null);
    //      }
    //      else if(accessoriesInput.accessories_remarks!==''){
    //         const selectedjobs = data.map((element, i) => {
    //             const obj = {
    //                 i: i,
    //                 orderid: orderid.toString(),
    //                 accessories_remarks: accessoriesInput.accessories_remarks 
    //             }
    //             return obj
    //         })
    //         const sendData = {
    //             "job_no":jobnum,
    //             "accessories": JSON.stringify(selectedjobs)
    //         }
    //         const lData = appendData(sendData)
    //         submitForm(addremarks, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
    //         setaccessoriesInput({
    //             issued_date: '',
    //             accessories_remarks: '',
    //             completed_date: ''
    //         });
    //         setIsEdit(false);
    //         setid(null);
    //         setSelected([]);
    //      }
    //      else {
    //         setMessage("Please Fill atleast One Input Field")
    //         setOpen(true)
    //         setStatus(false)
    //         setColor(false)
    //     }
    // }

    const fetchsinglejob = (id) => {
        const oid = { job_num: id }
        const sendid = appendData(oid)
        axios({
            method: methodPost,
            url: fetchsingleaccessories,
            data: sendid
        }).then(res => {
            setMultipleOrders(res.data.data)
        }).catch(err => {
            console.log(err)
        });
    }

    return (
        <Box>
            <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
            <Box sx={{ py: 3 }}>
                <Heading title={title} />
            </Box>
            <Box sx={{ py: 3 }}>
                <Box sx={{ p: 2 }} component={Card}>
                    <TableContainer >
                        <Table>
                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                <TableRow >
                                    {
                                        AccessoriesTableHeading.map((headings) =>
                                            <TableCell key={headings.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Box sx={{ my: 'auto' }}>
                                                        {headings.label}
                                                    </Box>
                                                    <Box>
                                                        {headings.filter ? <Filter label={headings.label} setSearch={setSearch} /> : ''}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.filter((data) => filterJobScreen(data, search, {
                                        searchFeildOne: data.job_num,
                                        searchFeildTwo: data.prod_code,
                                        searchFeildThree: data.short_char_01
                                    })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) =>
                                        <TableRow key={items.id}>
                                            <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.serial_no}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.job_num}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                                            <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                                                <IconButton
                                                    aria-label="edit" onClick={() => {
                                                        setIsEdit(!isEdit)
                                                        setid(items.id)
                                                        setReceivedDate(items.accessories_date)
                                                        fetchsinglejob(items.job_num)
                                                        setaccessoriesInput({
                                                            issued_date: '',
                                                            accessories_remarks: '',
                                                            completed_date: ''
                                                        })
                                                    }}>
                                                    {isEdit && id === items.id ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
                                                </IconButton>
                                            </TableCell>

                                            {
                                                items.accessories_date === null || items.accessories_date === '' ?     
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    disabled={isEdit && id === items.id ? false : true}
                                                    type='date'
                                                    value={isEdit && id === items.id ? accessoriesInput.issued_date : ''}
                                                    onChange={(e) => setaccessoriesInput({ ...accessoriesInput, 'issued_date': e.target.value })}
                                                    fullWidth
                                                    size='small'
                                                    label='Received Date'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // inputProps={{ min: items.need_by_date }}
                                                />
                                            </TableCell>
                                                    :
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.accessories_date)}</TableCell>
                                            }

                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    disabled={isEdit && id === items.id && items.accessories_date !== null ? false : true}
                                                    type='date'
                                                    value={isEdit && id === items.id ? accessoriesInput.completed_date : ''}
                                                    onChange={(e) => setaccessoriesInput({ ...accessoriesInput, 'completed_date': e.target.value })}
                                                    fullWidth
                                                    size='small'
                                                    label='Completed Date'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                            inputProps={{ min: items.accessories_date }}
                                                />
                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    sx={{ width: 140 }}
                                                    multiline
                                                    disabled={isEdit && id === items.id ? false : true}
                                                    value={isEdit && id === items.id ? accessoriesInput.accessories_remarks : ''}
                                                    onChange={(e) => setaccessoriesInput({ ...accessoriesInput, 'accessories_remarks': e.target.value })}
                                                    fullWidth
                                                    size='small'
                                                    label='Remarks'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>

                           <Box>
                            <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box>

                                            {/* {
                          multipleOrders.length !== 0 ? 
                          <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box> : 
                         <Box>
                         <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                           onUpdate()
                          
                         }} startIcon={<CheckCircleOutlineIcon />}>
                           Update
                         </Button>
                       </Box>
                        } */}
                        
                                            </TableCell>
                                            {/* <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    type='date'
                                                    onChange={(e) => setImpellerRevisedDate(e.target.value)}
                                                    fullWidth
                                                    size='small'
                                                    label='Revised Date'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    sx={{ width: 140 }}
                                                    multiline
                                                    onChange={(e) => setImpellerRevisedRemarks(e.target.value)}
                                                    fullWidth
                                                    size='small'
                                                    label='Remarks'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <Button variant="contained" onClick={() => {handleSubmitRevised(items.id)
                                                    fetchsinglejob(items.job_num)
                                                    }} startIcon={<TelegramIcon />}>
                                                        Request
                                                    </Button>
                                                </Box>
                                            </TableCell> */}
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
            {
                receivedDate !== null && 
                <Box>
                    <Accessoriessinglejob data={multipleOrders} selected={selected} setSelected={setSelected} />
                </Box>
            }
            {/* <AccessoriesBarCodeTable accessoriesBarcodeData={accessoriesBarcodeData} isloading={isloading} accessoriesscreen={accessoriesscreen} /> */}
        </Box>
    )
}

export default Accessories