import React, { useState } from 'react'
import { Box, Card, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, TablePagination } from '@mui/material';
import Heading from './../Comps/Heading';
import Nodata from './../Comps/Nodata';
import { displayMomandPlanning, displaysingleMomandPlanning, nullFinderandDateswapper } from '../Comps/Variables/Variables';
import Filter from './../Comps/Filter';
import { filterData } from '../Comps/FilterData';
import { useLocation } from 'react-router-dom';
import { displayMomFetch, methodPost } from '../Services/Apivariables';
import useFetch from './../Services/useFetch';
import Statusloader from './../Comps/Statusloader';

function Displaymomandplanningsingleorder() {
    const location = useLocation();
    const orderid = location.state.id
    const jobNumber = new FormData();
    jobNumber.append("order_num", orderid);
    const { data, isloading } = useFetch(displayMomFetch, methodPost, jobNumber);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box variant="div" sx={{ p: 3 }}>
                <Box sx={{ py: 3 }}>
                    <Heading title={'mom and planning single job status'} />
                </Box>
                <Box sx={{ py: 3 }}>
                    <Box>
                        {data.length === 0 ? <Nodata /> : <Box sx={{ p: 1, borderRadius: 4 }} component={Card}>
                            <TableContainer sx={{ borderRadius: 2 }} >
                                <Table id='display-mom_and_planning'>
                                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                                        <TableRow >
                                            {
                                                displaysingleMomandPlanning.map((hd) =>
                                                    <TableCell key={hd.id} sx={{
                                                        color: 'white', fontWeight: 600, textAlign: 'center'
                                                    }}>
                                                        <Box sx={{ display: 'flex' }}>
                                                            <Box sx={{ my: 'auto' }}>
                                                                {hd.label}
                                                            </Box>
                                                            <Box>
                                                                {hd.filter ? <Filter search={search} label={hd.label} setSearch={setSearch} /> : ''}
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            data.sort((a, b) => (a.planning_status > b.status) ? 1 : -1).filter((data) => filterData(data, search, {
                                                searchFeildOne: data.order_num,
                                                searchFeildTwo: data.job_num_1
                                            })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                                                <TableRow
                                                    hover
                                                    key={item.orderid}
                                                >
                                                    <TableCell sx={{ textAlign: 'center' }} >{item.order_num}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }} >{item.job_num_1}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.production_drawing_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.bom_released_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.mom_planning_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.stores_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.motor_delivery_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.cnc_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.hub_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.machining_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.impeller_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.casing_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.first_coat_paint_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.assembly_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.testing_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.final_paint_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(item.packing_dispatch_edd)}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center' }}>{item.remarks}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Box>}
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default Displaymomandplanningsingleorder