import React from 'react'
import { useState } from 'react';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { addremarks, fetchsinglestore, methodPost, otherScreenSingleOrders } from '../../Services/Apivariables';
import SnackBar from './../SnackBar';
import DialogBox from './../DialogBox';
import Heading from './../Heading';
import { appendData, storeTableHead } from '../Variables/ProcessVariables';
import Filter from './../Filter';
import { filterJobScreen } from '../FilterData';
import { nullFinderandDateswapper } from '../Variables/Variables';
import submitForm from '../../Services/ProcessScreenSubmitForm';
import Storebom from './Bom/Storebom';
import Storesinglejob from './Processsinglejob/Storesinglejob'
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useEffect } from 'react';

function Store({ data, title, setrefreshpage }) {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [multipleOrders, setMultipleOrders] = useState([])
  const [id, setid] = useState('');
  const [storebomscreen, setStorebomscreen] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // this inputs stores all the values in obj
  const [storeInputs, setStoreInputs] = useState({});
  const [selected, setSelected] = useState([]);
  const jobnum = data.map((i)=>i.job_num);
  const orderid = data.map((i)=>i.id);


      useEffect(() => {
        if (data.length !== 0) {
        const sendData = new FormData();
        sendData.append('job_num', jobnum)
        sendData.append('department', title)
        axios({
            method: 'POST',
            url: otherScreenSingleOrders,
            data: sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setrefreshpage(false)
            } else {
              setStorebomscreen(res.data.storebom);
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setrefreshpage(false)
                setIsloading(true);
            }
        }).catch(err => {
            console.log(err)
        });
      }
    }, [])  



  const handleSubmit = () => {
    if (selected.length > 0) {
      if(storeInputs.report_submitted !== ''){
      const selectedjobs = selected.map((element, i) => {
        const obj = {
          i: i,
          orderid: element.orderid,
          report_submitted: storeInputs.report_submitted,
          stores_remarks: storeInputs.store_remarks ? storeInputs.store_remarks : ''
        }
        return obj
      })
      const sendData = {
        "job_no":jobnum,
        "stores": JSON.stringify(selectedjobs)
      }
      const lData = appendData(sendData)
        submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchsinglejob, jobnum)
        setStoreInputs({
        bom_received_date: '',
        report_submitted:'',
        store_remarks: ''
      });   
    }else{
      const selectedjobs = selected.map((element, i) => {
        const obj = {
          i: i,
          orderid: element.orderid,
          store_remarks: storeInputs.store_remarks
        }
        return obj
      })
      const sendData = {
        "job_no":jobnum,
        "store": JSON.stringify(selectedjobs)
      }
      const lData = appendData(sendData)
        submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchsinglejob, jobnum)
        setStoreInputs({
        bom_received_date: '',
        store_remarks: ''
      });
    }} else {
      setMessage("Please select atleast one job")
      setOpen(true)
      setStatus(false)
      setColor(false)
    }
  }

  // const onUpdate =() =>{
  //   if(storeInputs.report_submitted !== ''){
  //     const selectedjobs = data.map((element, i) => {
  //       const obj = {
  //         i: i,
  //         orderid: orderid.toString(),
  //         report_submitted: storeInputs.report_submitted,
  //         stores_remarks: storeInputs.store_remarks ? storeInputs.store_remarks : ''
  //       }
  //       return obj
  //     })
  //     const sendData = {
  //       "job_no":jobnum,
  //       "stores": JSON.stringify(selectedjobs)
  //     }
  //     const lData = appendData(sendData)
  //     submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
  //     setStoreInputs({
  //       bom_received_date: '',
  //       report_submitted:'',
  //       store_remarks: ''
  //     });
  //     setIsEdit(false);
  //     setid(null);
  //     setSelected([])
      
  //   }else if (storeInputs.store_remarks  !==""){
  //     const selectedjobs = data.map((element, i) => {
  //       const obj = {
  //         i: i,
  //         orderid: orderid.toString(),
  //         store_remarks: storeInputs.store_remarks
  //       }
  //       return obj
  //     })
  //     const sendData = {
  //       "job_no":jobnum,
  //       "store": JSON.stringify(selectedjobs)
  //     }
  //     const lData = appendData(sendData)
  //     submitForm(addremarks, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
  //     setStoreInputs({
  //       bom_received_date: '',
  //       store_remarks: ''
  //     });
  //     setIsEdit(false);
  //     setid(null);
  //     setSelected([])
  //   } else {
  //     setMessage("Please Fill atleast One Input Field")
  //     setOpen(true)
  //     setStatus(false)
  //     setColor(false)
  //   }
  // }

  const fetchsinglejob = (id) => {
    const oid = { job_num: id }
    const sendid = appendData(oid)
    axios({
      method: methodPost,
      url: fetchsinglestore,
      data: sendid
    }).then(res => {
      setMultipleOrders(res.data.data)
    }).catch(err => {
      console.log(err)
    });
  }

  // const edd = data.map((i)=> i.stores_edd.toString());
  // const date = new Date(edd);
  // const day = date.getDate()- 6;
  // const minDay =  date.setDate(day);
  // let d , m ;
  // d = date.getDate();
  // m = date.getMonth()+1;
  // if (d < 10) {
  //     d = '0' + d;
  //   }
    
  //   if (m < 10) {
  //     m = '0' + m;
  //   }
  // const convert = [date.getFullYear(),(m), (d)].join('-');

  return (
    <Box>
      <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
      <Box sx={{ py: 3 }}>
        <Heading title={title} />
      </Box>
      <Box sx={{ py: 3 }}>
        <Box sx={{ p: 2 }} component={Card}>
          <TableContainer >
            <Table>
              <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                <TableRow >
                  {
                    storeTableHead.map((headings) =>
                      <TableCell key={headings.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box sx={{ my: 'auto' }}>
                            {headings.label}
                          </Box>
                          <Box>
                            {headings.filter ? <Filter label={headings.label} setSearch={setSearch} /> : ''}
                          </Box>
                        </Box>
                      </TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  data.filter((data) => filterJobScreen(data, search, {
                    searchFeildOne: data.job_num,
                    searchFeildTwo: data.prod_code,
                    searchFeildThree: data.short_char_01
                  }, data.stores_status)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) =>
                    <TableRow key={items.id}>
                      <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.serial_no}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.job_num}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                      <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                      <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.stores_edd)}</TableCell>
                      <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.bom_released_date)}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <IconButton
                          aria-label="edit" onClick={() => {
                            setIsEdit(!isEdit)
                            setid(items.id)
                            fetchsinglejob(items.job_num)
                            setStoreInputs({
                              bom_received_date: '',
                              report_submitted: '',
                              no_of_items_available: '',
                              no_of_items_not_available: '',
                              ack: '',
                              part_dec: '',
                              store_remarks: ''
                            })
                          }}>
                          {isEdit && id === items.id ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
                        </IconButton>
                      </TableCell>
                    
                      <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          disabled={isEdit && id === items.id ? false : true}
                          type='date'
                          value={isEdit && id === items.id ? storeInputs.report_submitted : ''}
                          onChange={(e) => setStoreInputs({ ...storeInputs, 'report_submitted': e.target.value })}
                          fullWidth
                          size='small'
                          label='Report Submitted Date'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // inputProps={{min: [convert]}}
                        />
                      </TableCell>
                  
                      <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          sx={{ width: 140 }}
                          multiline
                          disabled={isEdit && id === items.id ? false : true}
                          value={isEdit && id === items.id ? storeInputs.store_remarks : ''}
                          onChange={(e) => setStoreInputs({ ...storeInputs, 'store_remarks': e.target.value })}
                          fullWidth
                          size='small'
                          label='Remarks'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell>

                          <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box> 

                      {/* {
                          multipleOrders.length !== 0 ? 
                          <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box> : 
                         <Box>
                         <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                           onUpdate()
                          
                         }} startIcon={<CheckCircleOutlineIcon />}>
                           Update
                         </Button>
                       </Box>
                        } */}
                        
                      </TableCell>
                     
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <Box sx={{ py: 3 }}>
        <Storesinglejob data={multipleOrders} selected={selected} setSelected={setSelected} />
      </Box>
      <Storebom setrefreshpage={setrefreshpage} storebomscreen={storebomscreen} isloading={isloading} />
    </Box>
  )
}

export default Store