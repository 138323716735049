import React from 'react'
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import {qrcodeStockJobTableHeadings} from '../Comps/Variables/Variables';
import Heading from './../Comps/Heading';
import {allstockforqrcode } from '../Services/Apivariables';
import useFetch from './../Services/useFetch';
import Statusloader from './../Comps/Statusloader';
import Nodata from './../Comps/Nodata';
import QRStockJobTable from '../Comps/QRStockJobTable';



function QRCodeGenerationStockJobs() {
  
    
    const { data, isloading, emptydata } = useFetch(allstockforqrcode , 'GET');
    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box variant="div" sx={{ p: 3 }}>
                <Box sx={{ py: 3 }}>
                    <Heading title={'QR code Generation for Stock Jobs'} />
                </Box>
                {emptydata && data.length === 0 ? <Nodata /> : <Box sx={{ py: 3 }}>
                    <QRStockJobTable data={data} headingLabels={qrcodeStockJobTableHeadings} subpage={`generateQRforstockjobs2`} />
                </Box>}
                <Outlet />
            </Box>
        )
    }
}

export default QRCodeGenerationStockJobs;