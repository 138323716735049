import React from 'react'
import { Box } from '@mui/material';
import { getstock, methodGet } from '../../Services/Apivariables';
import Nodata from '../Nodata';
import Stocktable from './Stocktable';
import useFetch from '../../Services/useFetch';
import Statusloader from '../Statusloader';


function Stock() {
    const { data, isloading } = useFetch(getstock, methodGet);
    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
     
        return (
            <Box variant="div" sx={{ p: 3 }}>
         
                {data.length === 0 ? <Nodata /> : 
                <Box>
                 
                    <Box sx={{ py: 3 }}>
                        <Stocktable data={data} subpage={`singlestock`} />
                    </Box>
                </Box>
                }
           
            </Box>)
    }

}

export default Stock