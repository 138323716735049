import React from 'react'
import { useState } from 'react';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, IconButton, Tooltip } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { addremarks, fetchsinglehub, insertsingleprocessjob, methodPost, otherScreenSingleOrders } from '../../Services/Apivariables';
import SnackBar from './../SnackBar';
import DialogBox from './../DialogBox';
import Heading from './../Heading';
import { appendData, hubTableHeading } from '../Variables/ProcessVariables';
import Filter from './../Filter';
import { filterJobScreen } from '../FilterData';
import { nullFinderandDateswapper } from '../Variables/Variables';
import HubBarCodeTable from './../BarCodeModule/HubBarCodeTable';
import submitForm from '../../Services/ProcessScreenSubmitForm';
import Statusbar from './../Statusbar';
import axios from 'axios'
import Hubsinglejob from './Processsinglejob/Hubsinglejob'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useEffect } from 'react';

function Hub({ data, title, setrefreshpage, fetchCommonFirstTable }) {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setid] = useState('');
  const [multipleOrders, setMultipleOrders] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isloading, setIsloading] = useState(false);
  // this state hold all the value in obj
  const [hubInputs, setHubInputs] = useState({});
  const jobnum = data.map((i)=>i.job_num);
  const orderid = data.map((i)=>i.id);
  const [hubscreen, setHubscreen] = useState([]);
  const [hubBarcodeData, setHubBarcodeData] = useState([]);
  const [receivedDate, setReceivedDate] = useState(null);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // useEffect(() => {
  //   if (data.length !== 0) {
  //   const sendData = new FormData();
  //   sendData.append('job_num', jobnum)
  //   sendData.append('department', title)
  //   axios({
  //     method: 'POST',
  //     url: otherScreenSingleOrders,
  //     data: sendData,
  //   }).then(res => {
  //     if (res.data.error) {
  //       setMessage(res.data.message)
  //       setrefreshpage(false)
  //     } else {
  //       setHubscreen(res.data.hub);
  //       setHubBarcodeData(res.data.hub_sfg);
  //       setOpen(true)
  //       setMessage(res.data.message)
  //       setStatus(true)
  //       setColor(true)
  //       setrefreshpage(false)
  //       setIsloading(true);
  //     }
  //   }).catch(err => {
  //     console.log(err)
  //   });
  // }
  // }, [])  


  const handleSubmit = () => {
    if (receivedDate === null) {
      if (hubInputs.issued_date != null && hubInputs.issued_date !== '') {
        const sendData = {
          "job_no": jobnum,
          "hub": JSON.stringify([{ issued_date: hubInputs.issued_date }])
        }
        const lData = appendData(sendData)
 submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchCommonFirstTable, fetchsinglejob, jobnum)
         setHubInputs({
          issued_date: '',
          completed_date: '',
          hub_remarks: ''
        });
      }
      else {
        setMessage("Please Fill Received Date")
        setOpen(true)
        setStatus(false)
        setColor(false)
      }
    }
    else if (selected.length > 0) {
      if (hubInputs.completed_date !== '' && hubInputs.completed_date !== null){
        const selectedjobs = selected.map((element, i) => {
          const obj = {
            i: i,
            orderid: element.orderid,
            hub_remarks: hubInputs.hub_remarks ? hubInputs.hub_remarks : '',
            completed_date: hubInputs.completed_date
          }
          return obj
        })
        const sendData = {
          "job_no":jobnum,
          "hub": JSON.stringify(selectedjobs)
        }
        const lData = appendData(sendData)
        submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchCommonFirstTable, fetchsinglejob, jobnum) 
        setHubInputs({
          issued_date: '',
          completed_date: '',
          hub_remarks: ''
        });
      }
      fetchsinglejob(jobnum);
    } 
    else
    {
      setMessage("Please select atleast one job")
      setOpen(true)
      setStatus(false)
      setColor(false)
    }

  }

  // const onUpdate =()=>{
  //   if(hubInputs.issued_date!='' || hubInputs.completed_date!==''){
  //     const selectedjobs = data.map((element, i) => {
  //       const obj = {
  //         i: i,
  //         orderid: orderid.toString(),
  //         issued_date: hubInputs.issued_date,
  //         hub_remarks: hubInputs.hub_remarks ? hubInputs.hub_remarks : '',
  //         completed_date: hubInputs.completed_date
  //       }
  //       return obj
  //     })
  //     const sendData = {
  //       "job_no":jobnum,
  //       "hub": JSON.stringify(selectedjobs)
  //     }
  //     const lData = appendData(sendData)
  //     submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
  //     setHubInputs({
  //       issued_date: '',
  //       completed_date: '',
  //       hub_remarks: ''
  //     });
  //     setSelected([]);
  //     setIsEdit(false);
  //     setid(null);
  //   }else if(hubInputs.hub_remarks!==''){
  //     const selectedjobs = data.map((element, i) => {
  //       const obj = {
  //         i: i,
  //         orderid:orderid.toString(),
  //         hub_remarks: hubInputs.hub_remarks
  //       }
  //       return obj
  //     })
  //     const sendData = {
  //       "job_no":jobnum,
  //       "hub": JSON.stringify(selectedjobs)
  //     }
  //     const lData = appendData(sendData)
  //     submitForm(addremarks, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
  //     setHubInputs({
  //       issued_date: '',
  //       completed_date: '',
  //       hub_remarks: ''
  //     });
  //     setSelected([]);
  //     setIsEdit(false);
  //     setid(null);
      
  //   }
  //  else {
  //   setMessage("Please Fill atleast One Input Field")
  //   setOpen(true)
  //   setStatus(false)
  //   setColor(false)
  // }
  // }

  const fetchsinglejob = (id) => {
    const oid = { job_num: id }
    const sendid = appendData(oid)
    axios({
      method: methodPost,
      url: fetchsinglehub,
      data: sendid
    }).then(res => {
      setMultipleOrders(res.data.data)
      setMessage(res.data.message)
    }).catch(err => {
      console.log(err)
    });
  }

  // const edd = data.map((i)=> i.hub_edd.toString());
  // const date = new Date(edd);
  //   const day = date.getDate()- 6;
  //   const minDay =  date.setDate(day);
  //   let d , m ;
  //   d = date.getDate();
  //   m = date.getMonth()+1;
  //   if (d < 10) {
  //       d = '0' + d;
  //     }
      
  //     if (m < 10) {
  //       m = '0' + m;
  //     }
  //   const convert = [date.getFullYear(),(m), (d)].join('-');
  
  return (
    <Box>
      <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
      <Box sx={{ py: 3 }}>
        <Heading title={title} />
      </Box>
      <Box sx={{ py: 3 }}>
        <Box sx={{ p: 2 }} component={Card}>
          <TableContainer >
            <Table>
              <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                <TableRow >
                  {
                    hubTableHeading.map((headings) =>
                      <TableCell key={headings.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box sx={{ my: 'auto' }}>
                            {headings.label}
                          </Box>
                          <Box>
                            {headings.filter ? <Filter label={headings.label} setSearch={setSearch} /> : ''}
                          </Box>
                        </Box>
                      </TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  data.filter((data) => filterJobScreen(data, search, {
                    searchFeildOne: data.job_num,
                    searchFeildTwo: data.prod_code,
                    searchFeildThree: data.short_char_01
                  })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) =>
                    <TableRow key={items.id}>
                      <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.serial_no}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.job_num}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        <Box>
                          <Statusbar label={items.hubstatus === 'true' ? 'Completed' : 'Pending'} color={items.hubstatus === 'true' ? 'success' : 'error'} />
                        </Box>
                      </TableCell> */}
                      <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                      <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.hub_edd)}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <IconButton
                          aria-label="edit" onClick={() => {
                            setIsEdit(!isEdit)
                            setid(items.id);
                            setReceivedDate(items.hub_date)
                            fetchsinglejob(items.job_num);
                            setHubInputs({
                              issued_date: '',
                              completed_date: '',
                              hub_remarks: ''
                            });

                          }}>
                          {isEdit && id === items.id ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
                        </IconButton>
                      </TableCell>
                      {
                        items.hub_date === null || items.hub_date === '' ?
                      <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          disabled={isEdit && id === items.id ? false : true}
                          type='date'
                          value={isEdit && id === items.id ? hubInputs.issued_date : ''}
                          onChange={(e) => setHubInputs({ ...hubInputs, 'issued_date': e.target.value })}
                          fullWidth
                          size='small'
                          label='Received Date'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // inputProps={{min: [convert] }}
                        />
                      </TableCell>
                          :
                          <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.hub_date)}</TableCell>
                      }

                      <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                              disabled={isEdit && id === items.id && items.hub_date !== null ? false : true}
                          type='date'
                          value={isEdit && id === items.id ? hubInputs.completed_date : ''}
                          onChange={(e) => setHubInputs({ ...hubInputs, 'completed_date': e.target.value })}
                          fullWidth
                          size='small'
                          label='Completed Date'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                              inputProps={{ min: items.hub_date }}
                        />
                      </TableCell>

                      <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          sx={{ width: 140 }}
                          multiline
                          disabled={isEdit && id === items.id ? false : true}
                          value={isEdit && id === items.id ? hubInputs.hub_remarks : ''}
                          onChange={(e) => setHubInputs({ ...hubInputs, 'hub_remarks': e.target.value })}
                          fullWidth
                          size='small'
                          label='Remarks'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          
                        />
                      </TableCell>
                      <TableCell>

                             <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box> 

                      {/* {
                          multipleOrders.length !== 0 ? 
                          <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box> : 
                         <Box>
                         <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                           onUpdate()
                          
                         }} startIcon={<CheckCircleOutlineIcon />}>
                           Update
                         </Button>
                       </Box>
                        } */}
                        
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          type='date'
                          onChange={(e) => setHubRevisedDate(e.target.value)}
                          fullWidth
                          size='small'
                          label='Revised Date'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          sx={{ width: 140 }}
                          multiline
                          onChange={(e) => setHubRevisedRemarks(e.target.value)}
                          fullWidth
                          size='small'
                          label='Remarks'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Box>
                          <Button variant="contained" onClick={() => {handleSubmitRevised(items.id)
                            fetchsinglejob(items.job_num)
                          }} startIcon={<TelegramIcon />}>
                            Request
                          </Button>
                        </Box>
                      </TableCell> */}
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      {
        receivedDate !== null && 
        <Box>
          <Hubsinglejob data={multipleOrders} selected={selected} setSelected={setSelected} />
        </Box>
      }

      {/* <HubBarCodeTable hubscreen={hubscreen} hubBarcodeData={hubBarcodeData} isloading={isloading} /> */}
    </Box>
  )
}

export default Hub