import React, { useContext, useState } from 'react'
import { Box, Card, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, TablePagination, TextField, Button, IconButton, FormControl, RadioGroup, Stack, FormControlLabel, Radio } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Heading from './../../Heading';
import SnackBar from './../../SnackBar';
import DialogBox from './../../DialogBox';
import { appendData } from '../../Variables/ProcessVariables';
import submitForm from './../../../Services/ProcessScreenPostData';
import { insertpurchaseBom, methodPost } from '../../../Services/Apivariables';
import { currentDate, purchasebomTableHeading } from '../../Variables/Variables';
import { bomdataContext } from './../../../Pages/OtherScreenlayoutSingle';
import Nodata from './../../Nodata';
import Statusloader from '../../Statusloader';


function Purchasebom({ setrefreshpage , purchasebomscreen ,isloading }) {
    // const { purchasebomscreen } = useContext(bomdataContext)
    const [edit, setEdit] = useState(false);
    const [id, setId] = useState(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [purchasebomInputs, setPurchaseBomInputs] = useState({});
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const submit = (id, orderid) => {
        const sendData = {
            bom_id: id,
            orderid: orderid,
            // reciept_date: purchasebomInputs.reciept_date,
            boughtout_expected_date: purchasebomInputs.boughtout_expected_date,
            bo_poreleased: purchasebomInputs.bo_poreleased,
            boughtout_received_date: purchasebomInputs.boughtout_received_date,
        }
        const serverData = appendData(sendData)
        submitForm(insertpurchaseBom, methodPost, serverData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
        setPurchaseBomInputs({
            reciept_date: '',
            boughtout_expected_date: '',
            bo_poreleased: 'No',
            boughtout_received_date: '',
        });
        setEdit(false);
        setId(null);
    }
    const qty = purchasebomscreen && purchasebomscreen.every((element) => {
        if (element.qty === '0') {
            return true
        } else {
            return false
        }
    })

    if (!isloading) {
        return (
            <Statusloader />
        )
    } else { 
    return (
        <>
            <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
            <DialogBox message={message} open={openDialog} setOpenDialog={setOpenDialog} />
            <Box sx={{ py: 3 }}>
                <Heading title={'Purchase Bom'} />
            </Box>
            <Box sx={{ p: 2 }} component={Card}>
                {purchasebomscreen.length === 0 || qty ? <Nodata /> : <>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                                <TableRow>
                                    {purchasebomTableHeading.map((labels) =>
                                        <TableCell sx={{ textAlign: 'center', fontWeight: 600, color: 'white' }} key={labels.id}>
                                            {labels.name}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {purchasebomscreen.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((bomdata) =>
                                    <TableRow key={bomdata.id}>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            {bomdata.job_num}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            {bomdata.part_code}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            {bomdata.descriptions}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            {bomdata.qty}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            <IconButton aria-label="edit" onClick={() => {
                                                setEdit(true);
                                                setId(bomdata.id);
                                                setPurchaseBomInputs({
                                                    boughtout_expected_date: '',
                                                    bo_poreleased: 'No',
                                                    boughtout_received_date: '',
                                                });
                                            }}>
                                                <EditIcon color='primary' />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            {bomdata.short_qty}
                                        </TableCell>
                                        {/* <TableCell sx={{ textAlign: 'center' }}>
                                            <TextField
                                                disabled={edit && id === bomdata.id ? false : true}
                                                type='date'
                                                value={edit && id === bomdata.id ? false : true ? purchasebomInputs.reciept_date : ''}
                                                onChange={(e) => setPurchaseBomInputs({ ...purchasebomInputs, 'reciept_date': e.target.value })}
                                                fullWidth
                                                size='small'
                                                label='Motor Received Date'
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ min: bomdata.need_by_date }}
                                            />
                                        </TableCell> */}
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            <FormControl>
                                                <RadioGroup value={edit && id === bomdata.id ? purchasebomInputs.bo_poreleased : ''} onChange={(e) => setPurchaseBomInputs({ ...purchasebomInputs, "bo_poreleased": e.target.value })}>
                                                    <Stack direction="row" >
                                                        <FormControlLabel
                                                            disabled={edit && id === bomdata.id ? false : true}
                                                            value="Yes"
                                                            control={<Radio />}
                                                            label="Yes"
                                                        />
                                                        <FormControlLabel
                                                            disabled={edit && id === bomdata.id ? false : true}
                                                            value="No"
                                                            control={<Radio />}
                                                            label="No"
                                                        />
                                                    </Stack>
                                                </RadioGroup>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            <TextField
                                                type='date'
                                                value={edit && id === bomdata.id ? purchasebomInputs.boughtout_expected_date : ''}
                                                onChange={(e) => setPurchaseBomInputs({ ...purchasebomInputs, "boughtout_expected_date": e.target.value })}
                                                disabled={edit && id === bomdata.id ? false : true}
                                                fullWidth
                                                size='small'
                                                label='Boughtout Execpted Date'
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ min: currentDate }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            <TextField
                                                type='date'
                                                value={edit && id === bomdata.id ? purchasebomInputs.boughtout_received_date : ''}
                                                onChange={(e) => setPurchaseBomInputs({ ...purchasebomInputs, "boughtout_received_date": e.target.value })}
                                                disabled={edit && id === bomdata.id ? false : true}
                                                fullWidth
                                                size='small'
                                                label='Boughtout Received Date'
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ min: currentDate }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                            <Box>
                                                <Button disabled={edit && id === bomdata.id ? false : true} onClick={() => submit(bomdata.id, bomdata.orderid)} variant="contained" startIcon={<CheckCircleOutlineIcon />}>
                                                    Update
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={purchasebomscreen.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
            </Box>
        </>
    )
}
}
export default Purchasebom