import React, {useState, useEffect } from 'react'
import { Box } from '@mui/system';
import { Card, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, TablePagination} from '@mui/material';
import { StocksScreenbarCodeTableHeadings } from '../Variables/ProcessVariables';
import Heading from '../Heading';
import {fetchstockno, allstockforqrcode} from "../../Services/Apivariables";
import useFetch from "../../Services/useFetch";
import { ExportCSV } from '../../Comps/ExportCSV';
import { exportStockJobScreenDatatoexcel } from '../Variables/Variables';
import { filterQRStockJobScreen  } from '../FilterData';
import Filter from '../Filter';

function StockGenerateTable() {
    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState('');

 

    const {data} = useFetch(fetchstockno, 'GET');
    const {dataOne} = useFetch(allstockforqrcode , 'GET')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const exporttoExcel = exportStockJobScreenDatatoexcel(dataOne); 

    return (
        <Box sx={{ py: 3 }}>
                    <Box sx={{ py: 3 }}>
                        <Heading title={'Stock Number Generation'} />
                    </Box>
                    <Box sx={{ py: 1 }}>
                        <ExportCSV csvData={exporttoExcel} fileName={'nadi_stockJobOrder_report'} />
                    </Box>
                    <Box sx={{ p: 2 }} component={Card}>
                        <TableContainer>
                            <Table>
                                <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                    <TableRow >
                                        {
                                            StocksScreenbarCodeTableHeadings.map((hd) =>
                                                <TableCell key={hd.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                      <Box sx={{ display: 'flex',justifyContent:'center' }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    {hd.label}
                                                </Box>
                                                <Box>
                                                {hd.filter ? <Filter search={search} label={hd.label} setSearch={setSearch} /> : ''}
                                                </Box>
                                            </Box>
                                                </TableCell >
                                                
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>{
                                    data.filter((data) => filterQRStockJobScreen(data, search, {
                                        searchFeildOne: data.workOrderNum,
                                        searchFeildTwo: data.stockNumber,
                                        searchFeildThree: data.partCode
                                    })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item)=>{
                                        return(
                                           <TableRow key={item.id}>
                                                <TableCell sx={{ textAlign: 'center' }}>{item.workOrderNum}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{item.stockNumber}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{item.partCode}</TableCell>
                                            </TableRow>
                                        )
                                    })       
                                    }                       
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
        </Box>
    )
}

export default StockGenerateTable;