import React, { useEffect } from 'react'
import { useState } from 'react';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, FormControl, RadioGroup, FormControlLabel, Radio, IconButton, Stack } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { addremarks, fetchsinglepurchase, methodPost, otherScreenSingleOrders } from '../../Services/Apivariables';
import SnackBar from './../SnackBar';
import DialogBox from './../DialogBox';
import Heading from './../Heading';
import { appendData, purchaseScreenTableHeading } from '../Variables/ProcessVariables';
import Filter from './../Filter';
import { filterJobScreen } from '../FilterData';
import { nullFinderandDateswapper } from '../Variables/Variables';
import submitForm from '../../Services/ProcessScreenSubmitForm';
import Statusbar from './../Statusbar';
import Purchasebom from './Bom/Purchasebom';
import axios from 'axios';
import Purchasesinglejob from './Processsinglejob/Purchasesinglejob'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Purchase({ data, title, setrefreshpage }) {
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [id, setid] = useState('');
    const [multipleOrders, setMultipleOrders] = useState([])
    const [selected, setSelected] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const jobnum = data.map((i)=>i.job_num);
    const orderid = data.map((i)=>i.id);
    const [purchasebomscreen, setPurchasebomscreen] = useState([]);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if(data.length !== 0){
        const sendData = new FormData();
        sendData.append('job_num', jobnum)
        sendData.append('department', title)
        axios({
            method: 'POST',
            url: otherScreenSingleOrders,
            data: sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setrefreshpage(false)
            } else {
                setPurchasebomscreen(res.data.purchasebom);
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setrefreshpage(false)
                setIsloading(true);
            }
        }).catch(err => {
            console.log(err)
        });
        }
    }, [])  


    // this inputs stores all the values in obj
    const [processInputs, setProcessInputs] = useState({});
    const handleSubmit = () => {
        if (selected.length > 0) {
          if(processInputs.reciept_date !== '' || processInputs.woodencrate_expected_date!=='' || processInputs.woodencrate_received_date!==''){
            const selectedjobs = selected.map((element, i) => {
                const obj = {
                    i: i,
                    orderid: element.orderid,
                    reciept_date: processInputs.reciept_date,
                    m_poreleased: processInputs.m_poreleased,
                    wc_poreleased: processInputs.wc_poreleased,
                    woodencrate_expected_date: processInputs.woodencrate_expected_date,
                    woodencrate_received_date: processInputs.woodencrate_received_date,
                    purchase_remarks: processInputs.purchase_remarks ? processInputs.purchase_remarks : ''
                }
                return obj
            })
            const sendData = {
                "job_no":jobnum,
                "purchase": JSON.stringify(selectedjobs)
            }
            const lData = appendData(sendData)
              submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchsinglejob, jobnum)
               setProcessInputs({
                reciept_date: '',
                m_poreleased: 'No',
                wc_poreleased: 'No',
                woodencrate_expected_date: '',
                woodencrate_received_date: '',
                pending_breakup: '',
                purchase_remarks: ''
            });
          }else if(processInputs.purchase_remarks !==''){
            const selectedjobs = selected.map((element, i) => {
                const obj = {
                    i: i,
                    orderid: element.orderid,
                    purchase_remarks: processInputs.purchase_remarks
                }
                return obj
            })
            const sendData = {
                "job_no":jobnum,
                "purchase": JSON.stringify(selectedjobs)
            }
            const lData = appendData(sendData)
              submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchsinglejob, jobnum)
              setProcessInputs({
                reciept_date: '',
                m_poreleased: 'No',
                wc_poreleased: 'No',
                woodencrate_expected_date: '',
                woodencrate_received_date: '',
                pending_breakup: '',
                purchase_remarks: ''
            });
          }
        } else {
            setMessage("Please select atleast one job")
            setOpen(true)
            setStatus(false)
            setColor(false)
        }
    }

    // const onUpdate =() =>{
    //     if(processInputs.reciept_date !== '' || processInputs.woodencrate_expected_date!=='' || processInputs.woodencrate_received_date!==''){
    //         const selectedjobs = data.map((element, i) => {
    //             const obj = {
    //                 i: i,
    //                 orderid: orderid.toString(),
    //                 reciept_date: processInputs.reciept_date,
    //                 m_poreleased: processInputs.m_poreleased,
    //                 wc_poreleased: processInputs.wc_poreleased,
    //                 woodencrate_expected_date: processInputs.woodencrate_expected_date,
    //                 woodencrate_received_date: processInputs.woodencrate_received_date,
    //                 purchase_remarks: processInputs.purchase_remarks ? processInputs.purchase_remarks : ''
    //             }
    //             return obj
    //         })
    //         const sendData = {
    //             "job_no":jobnum,
    //             "purchase": JSON.stringify(selectedjobs)
    //         }
    //         const lData = appendData(sendData)
    //         submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
    //         setProcessInputs({
    //             reciept_date: '',
    //             m_poreleased: 'No',
    //             wc_poreleased: 'No',
    //             woodencrate_expected_date: '',
    //             woodencrate_received_date: '',
    //             pending_breakup: '',
    //             purchase_remarks: ''
    //         });
    //         setSelected([]);
    //         setIsEdit(false);
    //         setid(null);
    //       }else if(processInputs.purchase_remarks !==''){
    //         const selectedjobs = data.map((element, i) => {
    //             const obj = {
    //                 i: i,
    //                 orderid:orderid.toString(),
    //                 purchase_remarks: processInputs.purchase_remarks
    //             }
    //             return obj
    //         })
    //         const sendData = {
    //             "job_no":jobnum,
    //             "purchase": JSON.stringify(selectedjobs)
    //         }
    //         const lData = appendData(sendData)
    //         submitForm(addremarks, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
    //         setProcessInputs({
    //             reciept_date: '',
    //             m_poreleased: 'No',
    //             wc_poreleased: 'No',
    //             woodencrate_expected_date: '',
    //             woodencrate_received_date: '',
    //             pending_breakup: '',
    //             purchase_remarks: ''
    //         });
    //         setSelected([]);
    //         setIsEdit(false);
    //         setid(null);
    //       }
    //      else {
    //         setMessage("Please Fill atleast One Input Field")
    //         setOpen(true)
    //         setStatus(false)
    //         setColor(false)
    //     }
    // }

    const fetchsinglejob = (id) => {
        const oid = { job_num: id }
        const sendid = appendData(oid)
        axios({
            method: methodPost,
            url: fetchsinglepurchase,
            data: sendid
        }).then(res => {
            setMultipleOrders(res.data.data)
        }).catch(err => {
            console.log(err)
        });
    }

    // const edd = data.map((i)=> i.motor_delivery_edd.toString());
    // const date = new Date(edd);
    // const day = date.getDate()- 6;
    // const minDay =  date.setDate(day);
    // let d , m ;
    // d = date.getDate();
    // m = date.getMonth()+1;
    // if (d < 10) {
    //     d = '0' + d;
    //   }
      
    //   if (m < 10) {
    //     m = '0' + m;
    //   }
    // const convert = [date.getFullYear(),(m), (d)].join('-');

    return (
        <Box>
            <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
            <Box sx={{ py: 3 }}>
                <Heading title={title} />
            </Box>
            <Box sx={{ py: 3 }}>
                <Box sx={{ p: 2 }} component={Card}>
                    <TableContainer >
                        <Table>
                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                <TableRow >
                                    {
                                        purchaseScreenTableHeading.map((headings) =>
                                            <TableCell key={headings.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box sx={{ my: 'auto' }}>
                                                        {headings.label}
                                                    </Box>
                                                    <Box>
                                                        {headings.filter ? <Filter label={headings.label} setSearch={setSearch} /> : ''}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.filter((data) => filterJobScreen(data, search, {
                                        searchFeildOne: data.job_num,
                                        searchFeildTwo: data.prod_code,
                                        searchFeildThree: data.short_char_01
                                    })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) =>
                                        <TableRow key={items.id}>
                                            <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.serial_no}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.job_num}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                                            {/* <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <Statusbar label={items.purchase_status === 'true' ? 'Completed' : 'Pending'} color={items.purchase_status === 'true' ? 'success' : 'error'} />
                                                </Box>
                                            </TableCell> */}
                                            <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                                            <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.motor_delivery_edd)}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <IconButton
                                                    aria-label="edit" onClick={() => {
                                                        setIsEdit(!isEdit)
                                                        setid(items.id)
                                                        fetchsinglejob(items.job_num)
                                                        setProcessInputs({
                                                            reciept_date: '',
                                                            m_poreleased: 'No',
                                                            wc_poreleased: 'No',
                                                            woodencrate_expected_date: '',
                                                            woodencrate_received_date: '',
                                                            pending_breakup: '',
                                                            purchase_remarks: ''
                                                        })
                                                    }}>
                                                    {isEdit && id === items.id ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
                                                </IconButton>
                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <FormControl>
                                                    <RadioGroup value={isEdit && id === items.id ? processInputs.m_poreleased : ''} onChange={(e) => setProcessInputs({ ...processInputs, 'm_poreleased': e.target.value })}>
                                                        <Stack direction="row" >
                                                            <FormControlLabel
                                                                disabled={isEdit && id === items.id ? false : true}
                                                                value="Yes"
                                                                control={<Radio />}
                                                                label="Yes"
                                                            />
                                                            <FormControlLabel
                                                                disabled={isEdit && id === items.id ? false : true}
                                                                value="No"
                                                                control={<Radio />}
                                                                label="No"
                                                            />
                                                        </Stack>
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    disabled={isEdit && id === items.id ? false : true}
                                                    type='date'
                                                    value={isEdit && id === items.id ? processInputs.reciept_date : ''}
                                                    onChange={(e) => setProcessInputs({ ...processInputs, 'reciept_date': e.target.value })}
                                                    fullWidth
                                                    size='small'
                                                    label='Motor Received Date'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // inputProps={{min: [convert]}}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <FormControl>
                                                    <RadioGroup value={isEdit && id === items.id ? processInputs.wc_poreleased : ''} onChange={(e) => setProcessInputs({ ...processInputs, 'wc_poreleased': e.target.value })}>
                                                        <Stack direction="row" >
                                                            <FormControlLabel
                                                                disabled={isEdit && id === items.id ? false : true}
                                                                value="Yes"
                                                                control={<Radio />}
                                                                label="Yes"
                                                            />
                                                            <FormControlLabel
                                                                disabled={isEdit && id === items.id ? false : true}
                                                                value="No"
                                                                control={<Radio />}
                                                                label="No"
                                                            />
                                                        </Stack>
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    type='date'
                                                    disabled={isEdit && id === items.id ? false : true}
                                                    value={isEdit && id === items.id ? processInputs.woodencrate_expected_date : ''}
                                                    onChange={(e) => setProcessInputs({ ...processInputs, 'woodencrate_expected_date': e.target.value })}
                                                    fullWidth
                                                    size='small'
                                                    label='Wooden Crate Expected Date'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // inputProps={{ min: items.motor_delivery_edd }}
                                                />
                                            </TableCell>
                                        

                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    type='date'
                                                    disabled={isEdit && id === items.id ? false : true}
                                                    value={isEdit && id === items.id ? processInputs.woodencrate_received_date : ''} onChange={(e) => setProcessInputs({ ...processInputs, 'woodencrate_received_date': e.target.value })}
                                                    fullWidth
                                                    size='small'
                                                    label='Wooden Crate Received Date'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // inputProps={{ max: items.motor_delivery_edd }}
                                                />
                                            </TableCell>

                                            {/* <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    value={isEdit && id === items.id ? processInputs.pending_breakup : ''}
                                                    disabled={isEdit && id === items.id ? false : true}
                                                    onChange={(e) => setProcessInputs({ ...processInputs, 'pending_breakup': e.target.value })}
                                                    fullWidth
                                                    size='small'
                                                    label='Pending Break Up'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell> */}
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    sx={{ width: 140 }}
                                                    multiline
                                                    disabled={isEdit && id === items.id ? false : true}
                                                    value={isEdit && id === items.id ? processInputs.purchase_remarks : ''}
                                                    onChange={(e) => setProcessInputs({ ...processInputs, 'purchase_remarks': e.target.value })}
                                                    fullWidth
                                                    size='small'
                                                    label='Remarks'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>

                                                  <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box>

                                            {/* {
                          multipleOrders.length !== 0 ? 
                          <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box> : 
                         <Box>
                         <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                           onUpdate()
                          
                         }} startIcon={<CheckCircleOutlineIcon />}>
                           Update
                         </Button>
                       </Box>
                        } */}
                        
                                            </TableCell>
                                            {/* <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    type='date'
                                                    onChange={(e) => setPurchaseRemarksRevisedDate(e.target.value)}
                                                    fullWidth
                                                    size='small'
                                                    label='Revised Date'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    sx={{ width: 140 }}
                                                    multiline
                                                    onChange={(e) => setPurchaseRevisedRemarks(e.target.value)}
                                                    fullWidth
                                                    size='small'
                                                    label='Remarks'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <Button variant="contained" onClick={() => {
                                                        handleSubmitRevised(items.id)
                                                        fetchsinglejob(items.job_num)
                                                    }} startIcon={<TelegramIcon />}>
                                                        Request
                                                    </Button>
                                                </Box>
                                            </TableCell> */}
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
            <Box>
                <Purchasesinglejob data={multipleOrders} selected={selected} setSelected={setSelected} />
            </Box>
            <Purchasebom isloading={isloading} purchasebomscreen={purchasebomscreen} setrefreshpage={setrefreshpage} />
        </Box>
    )
}

export default Purchase