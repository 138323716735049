import React, { useRef } from 'react'
import {
    Button,
    Box,
    Container,
    Dialog,
    DialogContentText,
    DialogActions,
    DialogContent
} from '@mui/material'
import ReactToPrint from 'react-to-print';
import { subcontractorboughtoutheading, subcontractorheading } from '../Variables/Variables';
import Heading from '../Heading';
import PrintIcon from '@mui/icons-material/Print';
import logo from '../../Assets/nadi_logo.png'

function Pdfdialogcomp({ pdfdialogopen, setpdfdialogopen, data  }) {
    const componentRef = useRef();
    const { subcontractorjobData, subcontractorbrogData } = data;
    
    
    return (
        <>
            {subcontractorjobData !== undefined && (
                <>
                    <Dialog
                        fullWidth={true}
                        maxWidth='lg'
                        onClose={() => setpdfdialogopen(!pdfdialogopen)}
                        open={pdfdialogopen}
                    >
                        <DialogContent>
                            <DialogContentText>
                                <Box ref={componentRef} >
                                    <Container maxWidth='xl'>
                                        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <Box>
                                                <img src={logo} width="60" />
                                            </Box>
                                            <Box sx={{ py: 3 }}>
                                                <Heading title={'sub contract dc number'} />
                                            </Box>
                                        </Box>
                                        <Box>
                                        <Box sx={{ py: 3 }}>
                                                <Heading title={'sub contract details'} />
                                            </Box>
                                            <table class="table table-bordered border-dark">
                                                <thead className='bg-dark text-white'>
                                                    <tr>
                                                        <th className='text-nowrap'>No</th>
                                                        <th className='text-nowrap'>Subcontractor Name</th>
                                                        <th className='text-nowrap'>Subcontractor Address</th>
                                                        <th className='text-nowrap'>Subcontractor Worktype</th>
                                                        <th className='text-nowrap'>Subcontractor Gstnumber</th>
                                                        <th className='text-nowrap'>Subcontractor Dc number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[data].map((subcontractor, i) =>
                                                        <tr>
                                                            <td className='text-center'>{i + 1}</td>
                                                            <td className='text-center'>{subcontractor.subcontract_name}</td>
                                                            <td className='text-center'>{subcontractor.address}</td>
                                                            <td className='text-center'>{subcontractor.subcontractor_worktype}</td>
                                                            <td className='text-center'>{subcontractor.gst_no}</td>
                                                            <td className='text-center'>{subcontractor.dcnumber}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Box>
                                        <Box >
                                            <Box sx={{ py: 3 }}>
                                                <Heading title={'sub contract jobs'} />
                                            </Box>
                                            <table class="table table-bordered border-dark">
                                                <thead className='bg-dark text-white'>
                                                    <tr>
                                                        {subcontractorheading.map((labels) =>
                                                            <th className='text-nowrap'>{labels.title}</th>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {subcontractorjobData.map((job, i) =>
                                                        <tr>
                                                            <td className='text-center'>{job.job_num}</td>
                                                            <td className='text-center'>{job.sfg_part_name}</td>
                                                            <td className='text-center'>{job.sfg_code}</td>
                                                            <td className='text-center'>{job.prod_code}</td>
                                                            <td className='text-center'>{job.descriptions}</td>
                                                            <td className='text-center'>{job.qty}</td>
                                                            <td className='text-center'>{job.work_type}</td>
                                                            <td className='text-center'>{job.finished_weight}</td>
                                                            <td className='text-center'>{job.actual_fw}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Box>
                                        <Box>
                                            <Box sx={{ py: 3 }}>
                                                <Heading title={'sub contract boughouts'} />
                                            </Box>
                                            <table class="table table-bordered border-dark">
                                                <thead className='bg-dark text-white'>
                                                    <tr>
                                                        {subcontractorboughtoutheading.map((labels) =>
                                                            <th className='text-nowrap'>{labels.title}</th>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {subcontractorbrogData.map((job, i) =>
                                                        <tr>
                                                            <td className='text-center'>{job.job_num}</td>
                                                            <td className='text-center'>{job.sfg_part_name}</td>
                                                            <td className='text-center'>{job.sfg_code}</td>
                                                            <td className='text-center'>{job.prod_code}</td>
                                                            <td className='text-center'>{job.descriptions}</td>
                                                            <td className='text-center'>{job.qty}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </Box>
                                    </Container>
                                </Box>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <ReactToPrint
                                trigger={() => <Button variant='contained' startIcon={<PrintIcon />}>Print</Button>}
                                content={() => componentRef.current}
                            />
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    )
}

export default Pdfdialogcomp