import React, { useState } from 'react'
import { Card, Grid, TextField, InputLabel, FormControl, Select, MenuItem, Button, FormHelperText, Checkbox, InputAdornment, IconButton, Typography, Box, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Form } from 'react-bootstrap';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import SnackBar from './SnackBar';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { signup, methodPost } from '../Services/Apivariables';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useFetch from '../Services/useFetch';
import { departmentlist } from '../Services/Apivariables';

function RegisterForm() {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [showPassword, setShowPassword] = useState(true);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [prod, setProd] = useState(false);
    const [mom, setMom] = useState(false);
    const [store, setStore] = useState(false);
    const [purchase, setPurchase] = useState(false);
    const [cnc, setCnc] = useState(false);
    const [hub, setHub] = useState(false);
    const [machining, setMachining] = useState(false);
    const [impeller, setImpeller] = useState(false);
    const [casing, setCasing] = useState(false);
    const [firstCoatPaint, setFirstCoatPaint] = useState(false);
    const [assembly, setAssembly] = useState(false);
    const [testing, setTesting] = useState(false);
    const [finalPaint, setFinalPaint] = useState(false);
    const [packingAndDispatching, setPackingAndDispatching] = useState(false);
    const [accessories, setAccessories] = useState(false);
    const [prodView, setProdView] = useState(false);
    const [momView, setMomView] = useState(false);
    const [storeView, setStoreView] = useState(false);
    const [purchaseView, setPurchaseView] = useState(false);
    const [cncView, setCncView] = useState(false);
    const [hubView, setHubView] = useState(false);
    const [machiningView, setMachiningView] = useState(false);
    const [impellerView, setImpellerView] = useState(false);
    const [casingView, setCasingView] = useState(false);
    const [firstCoatPaintView, setFirstCoatPaintView] = useState(false);
    const [assemblyView, setAssemblyView] = useState(false);
    const [testingView, setTestingView] = useState(false);
    const [finalPaintView, setFinalPaintView] = useState(false);
    const [packingAndDispatchingView, setPackingAndDispatchingView] = useState(false);
    const [accessoriesView, setAccessoriesView] = useState(false);
    const [momEditOrder, setMomEditOrder] = useState(false);
    const [momViewOrder, setMomViewOrder] = useState(false);
    const [jobEditOrder, setJobEditOrder] = useState(false);
    const [jobViewOrder, setJobViewOrder] = useState(false);
    const [partcodeEditOrder, setPartcodeEditOrder] = useState(false);
    const [partcodeViewOrder, setPartcodeViewOrder] = useState(false);
    const [pms,setPms] = useState(false);
    const [fgpartcode_edit,setfgpartcode_edit] = useState(false);
    const [sfgpartcode_edit,setsfgpartcode_edit] = useState(false);
    const [subcomppartcode_edit,setsubcomppartcode_edit] = useState(false);
    const [dashboard_view,setDashboard] = useState(false);
    const [mngmtdashboard_view,setmngmtDashboard] = useState(false);
    const [bom_file_edit,setBom_file_edit] = useState(false);
    const [sales_target_edit,setSales_target_edit] = useState(false);
    const [inventory,setInventory] = useState(false);
    const [outwards,setOutwards] = useState(false);
    const [inwards,setInwards] = useState(false);
    const [dc_numbers,setDcnumbers] = useState(false);
    const [allView, setAllView] = useState(false);
    const [edit_register, setEditusers] = useState(false);
    const [add_register, setAddusers] = useState(false);
    const [sale_order, setsale_order] = useState(false);
    // const [stock, setStock] = useState(false);
    const [stockJobOrder, setStockJobOrder] = useState(false);
    const [stockQR, setStockQR] = useState(false);
    const [jobOrder, setJobOrder] = useState(false);
    const [pendingStock, setPendingStock] = useState(false);
    const [stockIssued, setStockIssued] = useState(false);
    const [storeScreen, setStoreScreen] = useState(false);
    const handleChange = (event) => {
        setProd(event.target.checked);
        setMom(event.target.checked);
        setStore(event.target.checked);
        setPurchase(event.target.checked);
        setCnc(event.target.checked);
        setHub(event.target.checked);
        setMachining(event.target.checked);
        setImpeller(event.target.checked);
        setCasing(event.target.checked);
        setFirstCoatPaint(event.target.checked);
        setAssembly(event.target.checked);
        setTesting(event.target.checked);
        setFinalPaint(event.target.checked);
        setPackingAndDispatching(event.target.checked);
        setAccessories(event.target.checked);
        // setStock(event.target.checked);
        // setStockJobOrder(event.target.checked);
        // setStockQR(event.target.checked);
        // setStockIssued(event.target.checked);
        // pendingStock(event.target.checked);
        // setStoreScreen(event.target.checked);
 
     
    };
    const handleChangeView = (event) => {
        setProdView(event.target.checked);
        setMomView(event.target.checked);
        setStoreView(event.target.checked);
        setPurchaseView(event.target.checked);
        setCncView(event.target.checked);
        setHubView(event.target.checked);
        setMachiningView(event.target.checked);
        setImpellerView(event.target.checked);
        setCasingView(event.target.checked);
        setFirstCoatPaintView(event.target.checked);
        setAssemblyView(event.target.checked);
        setTestingView(event.target.checked);
        setFinalPaintView(event.target.checked);
        setPackingAndDispatchingView(event.target.checked);
        setAccessoriesView(event.target.checked);
      
    };
    const onSubmit = (data) => {
        const checkBoxs = {
            view_all: allView,
            jobscreenedit: jobEditOrder,
            jobscreenview: jobViewOrder,
            momscreenedit: momEditOrder,
            momscreenview: momViewOrder,
            partcode_edit: partcodeEditOrder,
            partcode_view: partcodeViewOrder,

            pms: pms,
            production: prod,
            mom: mom,
            store: store,
            purchase: purchase,
            cnc: cnc,
            hub: hub,
            machining: machining,
            impeller: impeller,
            casing: casing,
            first_paint: firstCoatPaint,
            assembly: assembly,
            testing: testing,
            final_paint: finalPaint,
            packing: packingAndDispatching,
            accessories: accessories,
            production_view: prodView,
            mom_view: momView,  
            store_view: storeView,
            purchase_view: purchaseView,
            cnc_view: cncView,
            hub_view: hubView,
            machining_view: machiningView,
            impeller_view: impellerView,
            casing_view: casingView,
            first_paint_view: firstCoatPaintView,
            assembly_view: assemblyView,
            testing_view: testingView,
            final_paint_view: finalPaintView,
            packing_view: packingAndDispatchingView,
            accessories_view: accessoriesView,
            dashboard_view: dashboard_view,
            fgpartcode_edit:fgpartcode_edit,
            sfgpartcode_edit:sfgpartcode_edit,
            subcomppartcode_edit:subcomppartcode_edit,
            bom_file_edit:bom_file_edit,
            sales_target_edit:sales_target_edit,
            inventory:inventory,
            outwards: outwards,
            inwards:inwards,
            dc_numbers:dc_numbers,
            add_register:add_register,
            edit_register:edit_register,
            sale_order:sale_order,
            // stock:stock,
            stock_job:stockJobOrder,
            stock_qr:stockQR,
            stock_pending:stockIssued,
            stock_issued:pendingStock,
            store_stock:storeScreen,
            management:mngmtdashboard_view,
             monthly_job:jobOrder
        }
        const contcatData = { ...data, ...checkBoxs };
        const serverData = new FormData()
        for (var key in contcatData) {
            serverData.append(key, contcatData[key]);
        }

        if (!navigator.onLine) {
            setMessage('Your internet is in Offline')
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            axios({
                method: methodPost,
                url: signup,
                data: serverData,
            }).then(res => {
                console.log(res)
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(true)
                    setColor(true)
                }
            }).catch(err => {
                alert('Oops something went wrong ' + err)
            });
        }
    };
  
    const {dept} = useFetch(departmentlist,'GET');
   
    return (
        <Box sx={{ py: 2 }}>
            <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
            <Box>
                <Grid container justifyContent={'center'} spacing={2}>
                    <Grid item textAlign={'center'} xs={12} lg={8}>
                        <Box component={Card} sx={{ p: 2, boxShadow: 3, borderRadius: 5 }}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container justifyContent='start' spacing={1}>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="Employee Id"
                                                variant="filled"
                                                {...register("employee_id", { required: true })}
                                                error={errors.employee_id ? true : false}
                                                helperText={(errors.employee_id && errors.employee_id.type === "required") ? "Employee id is required" : ""}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                variant="filled"
                                                {...register("email", { required: true })}
                                                error={errors.email ? true : false}
                                                helperText={(errors.email && errors.email.type === "required") ? "Email id is required" : ""}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="User Name"
                                                variant="filled"
                                                {...register("username", { required: true, maxLength: 15 })}
                                                error={errors.username ? true : false}
                                                helperText={(errors.username && errors.username.type === "required") ? "User name is required" : "" || (errors.username && errors.username.type === "maxLength") ? "User name can be max 15 characters long." : ""}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type={showPassword ? 'password' : 'text'}
                                                {...register("pass", { required: true, maxLength: 15 })}
                                                label="Password"
                                                InputProps={{
                                                    endAdornment:
                                                        (<InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>),
                                                }}
                                                helperText={(errors.pass && errors.pass.type === "required") ? "Password is required" : "" || (errors.pass && errors.pass.type === "maxLength") ? "Password can be max 15 characters long." : ""}
                                                error={errors.pass ? true : false}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="Contact Number"
                                                variant="filled"
                                                {...register("mobile_no", { required: true, maxLength: 15, pattern: /^\d{10}$/ })}
                                                error={errors.mobile_no ? true : false}
                                                helperText={(errors.mobile_no && errors.mobile_no.type === "required") ? "Contact number is required" : "" || (errors.mobile_no && errors.mobile_no.type === "maxLength") ? "Contact number can be max 15 characters long." : "" || (errors.mobile_no && errors.mobile_no.type === 'pattern') ? "Mobile number contains only numbers" : ""}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Box sx={{ py: 1 }}>
                                            <FormControl fullWidth error={errors.roles ? true : false}>
                                                <InputLabel>Role</InputLabel>
                                                <Select
                                                    defaultValue=""
                                                    sx={{ textAlign: 'start' }}
                                                    label="Role"
                                                    {...register('roles', { required: true })}
                                                >
                                                    <MenuItem value={"Admin"}>Admin</MenuItem>
                                                    <MenuItem value={"Manager"}>Manager</MenuItem>
                                                    <MenuItem value={"Assistant Manager"}>Assistant Manager</MenuItem>
                                                    <MenuItem value={"Head Of Department"}>Head Of Department</MenuItem>
                                                    <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                                                    <MenuItem value={"Senior Executive"}>Senior Executive</MenuItem>
                                                    <MenuItem value={"Executive"}>Executive</MenuItem>
                                                </Select>
                                                <FormHelperText>{errors.roles ? "Role is required" : ""}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                       <Box sx={{ py: 1 }}>
                                            <FormControl fullWidth error={errors.department ? true : false}>
                                                <InputLabel>Department</InputLabel>
                                                <Select
                                         label="department" {...register("department")}>
                                        {
                                            dept.map((dept) =>
                                                <MenuItem key={dept.departmentId} value={dept.departmentName}>{dept.departmentName}</MenuItem>
                                            )
                                        }
                                    </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Management Dashboard</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                View
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Management Dashboard
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setmngmtDashboard(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>



                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Production Dashboard</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                View
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Production Dashboard
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setDashboard(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                               
                               
                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Sale Order</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                          
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                               Edit
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Sale Order
                                                            </TableCell>
                                                            
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setsale_order(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Job Screen Access</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                View
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Job Screen
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setJobEditOrder(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setJobViewOrder(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Mom And Planning Screen Access</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                View
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Mom and Planning Screen
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setMomEditOrder(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setMomViewOrder(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Bom File</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                          
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                               Edit
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Bom File Upload
                                                            </TableCell>
                                                            
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setBom_file_edit(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                {/* JOb order Screen */}

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Job Order</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                          
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                               Edit
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                           Job Order
                                                            </TableCell>
                                                            
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setJobOrder(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>


                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Process Screen Access</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer >
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                View
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {/* <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Processing screen show
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={allView} onChange={(e) =>  setAllView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow> */}
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                All
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={handleChange} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={handleChangeView} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Production Drawing And Bom
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={prod} onChange={(e) => setProd(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={prodView} onChange={(e) => setProdView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Mom
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={mom} onChange={(e) => setMom(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={momView} onChange={(e) => setMomView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Store
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={store} onChange={(e) => setStore(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={storeView} onChange={(e) => setStoreView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Purchase
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={purchase} onChange={(e) => setPurchase(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={purchaseView} onChange={(e) => setPurchaseView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Cnc
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={cnc} onChange={(e) => setCnc(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={cncView} onChange={(e) => setCncView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Hub
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={hub} onChange={(e) => setHub(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={hubView} onChange={(e) => setHubView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Machining
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={machining} onChange={(e) => setMachining(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={machiningView} onChange={(e) => setMachiningView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Impeller
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={impeller} onChange={(e) => setImpeller(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={impellerView} onChange={(e) => setImpellerView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Casing
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={casing} onChange={(e) => setCasing(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={casingView} onChange={(e) => setCasingView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                First Coat Paint
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={firstCoatPaint} onChange={(e) => setFirstCoatPaint(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={firstCoatPaintView} onChange={(e) => setFirstCoatPaintView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Assembly
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={assembly} onChange={(e) => setAssembly(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={assemblyView} onChange={(e) => setAssemblyView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Testing
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={testing} onChange={(e) => setTesting(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={testingView} onChange={(e) => setTestingView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Final Paint
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={finalPaint} onChange={(e) => setFinalPaint(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={finalPaintView} onChange={(e) => setFinalPaintView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Packing &amp; Dispatch
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={packingAndDispatching} onChange={(e) => setPackingAndDispatching(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={packingAndDispatchingView} onChange={(e) => setPackingAndDispatchingView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                Accessories
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={accessories} onChange={(e) => setAccessories(e.target.checked)} />
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={accessoriesView} onChange={(e) => setAccessoriesView(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                PMS
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox checked={pms} onChange={(e) => setPms(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Sub Contract</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                          
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                               Edit
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Inventory
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setInventory(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Outwards
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setOutwards(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Inwards
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setInwards(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Dc Numbers
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setDcnumbers(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box> 

                                 {/* Stock Screen Registration */}
                                 {/* <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Stock</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                          
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                               Edit
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Stock
                                                            </TableCell>
                                                            
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setStock(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box> */}

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Target</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                          
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                               Edit
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Target
                                                            </TableCell>
                                                            
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setSales_target_edit(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>

                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Partcode Screen Access</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Edit & View
                                                            </TableCell>
                                                           
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                               Material Part Code Screen
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setPartcodeEditOrder(e.target.checked)} />
                                                            </TableCell>
                                                          
                                                        </TableRow>
                                                    </TableBody>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                               Boughtout Part Code Screen
                                                            </TableCell>
                                                        
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setPartcodeViewOrder(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                               FG Part Code Screen
                                                            </TableCell>
                                                        
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setfgpartcode_edit(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                               SFG Part Code Screen
                                                            </TableCell>
                                                        
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setsfgpartcode_edit(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                               Sub Component Part Code Screen
                                                            </TableCell>
                                                        
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setsubcomppartcode_edit(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                               
                               {/* Stock Job Screen Access */}

                               <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Stock Job Screen </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                          
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                               Edit
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Stock Job Order
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setStockJobOrder(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Stock QR Codes
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setStockQR(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Pending Stocks
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setPendingStock(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Issued Stocks
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setStockIssued(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box> 

                                 {/* Store Screen Registration */}
                                 <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Store Screen</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                          
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                               Edit
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Store
                                                            </TableCell>
                                                            
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setStoreScreen(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>


                               
                                <Box sx={{ py: 2 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Register</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer>
                                                <Table size='small'>
                                                    <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                Screens
                                                            </TableCell>
                                                          
                                                            <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                               Edit
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Add Users
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setAddusers(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                            Edit Users
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center' }}>
                                                                <Checkbox onChange={(e) => setEditusers(e.target.checked)} />
                                                            </TableCell>
                                                        </TableRow>
                                                 
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                                <Box sx={{ py: 2, textAlign: 'center' }}>
                                    <Button variant="contained" type='submit' endIcon={<SendIcon />}>Submit</Button>
                                </Box>
                            </Form>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default RegisterForm