import React from 'react'
import { Box, Typography , Button , TextField } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { useState } from 'react';
import { useEffect } from 'react';
import useFetch from '../../Services/useFetch';
import { delayedprocessreport } from '../../Services/Apivariables';
import { otherqtyexport, spareqtyexport, stdqtyexport ,DelayedProcessChartReport} from '../Variables/Variables';
import { useForm } from "react-hook-form";
import { appendData } from '../Variables/ProcessVariables';
import axios from "axios";
import { ExportCategoryCsv } from '../ExportCategoryCsv';
import { ExportProcessScreenCSV } from '../ExportProcessScreenCSV';



function DelayedProcessChart({ url, title, labels }) {
  

  const {register, formState: { errors }, handleSubmit, reset} = useForm();
  const[dateData , setDateData] = useState([]);
  const [message, setMessage] = useState("");
  const [productions, setproduction]= useState([]);
  const [moms, setmom]= useState([]);
  const [casings, setcasing]= useState([]);
  const [purchases, setpurchase]= useState([]);
  const [firstcoatpaints, setfirstcoatpaint]= useState([]);
  const [stores, setstore]= useState([]);
  const [finalpaints, setfinalpaint]= useState([]);
  const [assemblys, setassembly]= useState([]);
  const [hubs, sethub]= useState([]);
  const [cncs, setcnc]= useState([]);
  const [packings, setpacking]= useState([]);
  const [accessoriess, setaccessories]= useState([]);
  const [impellers, setimpeller]= useState([]);
  const [testings, settesting]= useState([]);
  const [machinings, setmachining]= useState([]);



  const backgroundColorOne = [
    'rgb(153, 102, 255)'
  ]

  const backgroundColorTwo = [
    'rgb(75, 192, 192)'
  ]
  const backgroundColorThree = [
    'rgb(54, 162, 235)'
  ]
  const backgroundColorFour = [
    'rgb(201, 203, 207)'
  ]
  const backgroundColorFive = [
    'rgb(255, 99, 132)'
    
  ]
  const backgroundColorSix = [
    'rgb(255, 159, 64)'
  
  ]
  const backgroundColorSeven = [
    'rgb(75, 192, 192)'
   
  ]

  const controller = new AbortController();
  const signal = controller.signal;
  const [delayedData, setDelayedData] = useState([]);

  useEffect(() => {
    if (!navigator.onLine) {
      alert("Your internet is in Offline")
    } else {
      fetch(url, {
        signal: signal
      })
        .then(response => response.json())
        .then(data => {
        setDelayedData(data.data)
        
        })
        .catch(err => {
          console.log(err)
        });
    }
    return () => controller.abort();
  }, []);

  
  const linedata = {
    labels: labels,
    datasets: [{
      label: ['Delayed Process'],
      backgroundColor: backgroundColorOne,
      borderWidth: 1,
      data: delayedData.map((graphdata) => graphdata.Quantity),
    },
    ]
  };

 useEffect(() => {
  axios({
    method:'GET',
    url:delayedprocessreport,
  }).then(res => {
    if (res.data.error) {
      setMessage(res.data.message)
  } else {
      setproduction(res.data.production);
      setmachining(res.data.machining)
      setmom(res.data.mom)
      setaccessories(res.data.accessories)
      setassembly(res.data.assembly)
      setcnc(res.data.cnc)
      sethub(res.data.hub)
      setfinalpaint(res.data.finalpaint)
      setfirstcoatpaint(res.data.firstcoatpaint)
      setimpeller(res.data.impeller)
      setpacking(res.data.packing)
      setpurchase(res.data.purchase)
      setcasing(res.data.casing)
      setstore(res.data.store)
      settesting(res.data.testing)
  }
  }).catch(err => {
    console.log(err)
  });

 }, [])
 

 
  

  const production = DelayedProcessChartReport(productions);
  const mom = DelayedProcessChartReport(moms);
  const casing = DelayedProcessChartReport(casings);
  const store = DelayedProcessChartReport(stores);
  const purchase = DelayedProcessChartReport(purchases);
  const impeller = DelayedProcessChartReport(impellers);
  const assembly = DelayedProcessChartReport(assemblys);
  const firstcoatpaint = DelayedProcessChartReport(firstcoatpaints);
   const accessories = DelayedProcessChartReport(accessoriess);
   const cnc = DelayedProcessChartReport(cncs);
   const hub = DelayedProcessChartReport(hubs);
   const machining = DelayedProcessChartReport(machinings);
   const finalpaint = DelayedProcessChartReport(finalpaints);
   const testing = DelayedProcessChartReport(testings);
   const packing = DelayedProcessChartReport(packings);

  return (
    <Box>
    
      <Box sx={{ py: 2, display:'flex',justifyContent:'space-evenly' }}>
        <Box>
        <Typography
          variant="h3"
          sx={{ textAlign: 'start', fontWeight: 600, fontSize: 25, textTransform: 'uppercase', color: 'primary.main' }}>
          {title}
        </Typography>
        </Box>
        <Box>
        <ExportProcessScreenCSV
        production={production}  mom={mom} casing={casing}  impeller={impeller} store={store}  
        finalpaint={finalpaint} firstcoatpaint={firstcoatpaint} packing={packing} assembly={assembly} 
         cnc={cnc} hub={hub} machining={machining} accessories={accessories} purchase={purchase}
         testing={testing}  fileName={'Delayed Process chart data'}/>
        </Box>
      </Box>
      <Box>
        <Bar data={linedata} options={{
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: 'black',
                font: {
                  size: 12,
                  weight: '500',
                  family: "'Jost', sans-serif"
                }
              }
            },
            x: {
              ticks: {
                color: 'black',
                font: {
                  size: 12,
                  weight: '500',
                  family: "'Jost', sans-serif"
                }
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                font: {
                  size: 12,
                  weight: '500',
                  family: "'Jost', sans-serif"
                }
              },
            }
          },
        }} />
      </Box>
    </Box>
  )
}

export default DelayedProcessChart