import React, {useState } from 'react'
import { Box } from '@mui/system';
import { Card, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, TablePagination, Tab, Checkbox, Button } from '@mui/material';
import { StockIssueTabeleHeading } from './Variables/Variables';
import Filter from './Filter';
import { footerpendingstock , stockissue } from '../Services/Apivariables';
import axios from "axios";
import { appendData } from './Variables/ProcessVariables';
import SnackBar from './SnackBar';
import useFetch from '../Services/useFetch';
import { filterQRStockJobScreen  } from './FilterData';

function StockIssueScreenLayoutTable({data}) {

    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [message, setMessage] = useState("");
    const [dummystate, setdummystate] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState("");
    const [labels] = useState([]);
    const [selected, setSelected] = useState([]);

        const worknumber = new FormData()
        worknumber.append('workOrderNum', data.workOrderNum);
        const { dataOne , setrefreshpage} = useFetch(footerpendingstock , 'POST', worknumber)
   
    // this function is for selecting row checkbox
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    // this function is for select all checkbox
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = dataOne;
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

     console.log('Selected', selected);

     const handleSubmit = () => {
        if (selected.length > 0) {
          const selectedjobs = selected.map((element, i) => {
            const obj = {
              i: i,
              stockCompletionId: element.stockCompletionId,
              stockNumber: element.stockNumber,
              partCode : element.partCode,
            }
            return obj
          })
          const sendData = {
            "stockIssue": JSON.stringify(selectedjobs)
          }
          const lData = appendData(sendData)
          axios({
            method:'POST',
            url: stockissue,
            data:lData,
          }).then(res => {
            if (res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(false)
              setColor(false)
              setrefreshpage(false)
          } else {
              setOpen(true)
              setMessage(res.data.message)
              setStatus(true)
              setColor(true)
              setrefreshpage(true);
              setSelected([]);
          }
          }).catch(err => {
            console.log(err)
          });
        }
    
        else {
            setMessage("Please select atleast one work")
            setOpen(true)
            setStatus(false)
            setColor(false)
            setrefreshpage(false);
          }
          
      }



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

return ( 
         <Box sx={{ py: 3 }}>
                        <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
                        <Box sx={{ py: 3 }}>{
                         dataOne.length > 0 && 
                         <Button variant='contained' onClick={handleSubmit}>Generate Issue No</Button>
                        }
                         </Box>
        <Box sx={{ p: 2 }} component={Card}>
                        <TableContainer sx={{ borderRadius: 2 }}>
                            <Table>
                                <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                            <TableRow >
                               <TableCell sx={{
                              color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                            }} >
                              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Box>
                                  <Checkbox className='text-white' onChange={handleSelectAllClick}  />
                                </Box>
                                <Box>
                                  Select
                                </Box>
                              </Box>
                            </TableCell>
                        {
                            StockIssueTabeleHeading.map((hd) =>
                                <TableCell key={hd.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    {hd.label}
                                                </Box>
                                                <Box>
                                                {hd.filter ? <Filter search={search} label={hd.label} setSearch={setSearch} /> : ''}
                                                </Box>
                                            </Box>
                                            </TableCell>
                            )
                        }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {dataOne.filter((data) => filterQRStockJobScreen(data, search, {
                                    searchFeildOne: data.workOrderNum,
                                    searchFeildTwo: data.stockNumber,
                                    searchFeildThree: data.partCode
                                })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item , index) =>{
                                const isItemSelected = isSelected(item);
                                return(
                                <TableRow 
                                key={index}
                                hover
                                tabIndex={-1}
                                role="checkbox"
                                onClick={(event) => handleClick(event, item)}>
                                <TableCell align='center'>
                                <Box>
                                <Checkbox checked={isItemSelected}/>
                                </Box>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.workOrderNum}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.stockNumber}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.partCode}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.Qty}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.stockCompleted}</TableCell>
                                </TableRow>)})}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dataOne.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
         </Box>
        </Box>
    )
}

export default StockIssueScreenLayoutTable;