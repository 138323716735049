import React, { useState } from 'react'
import { Box } from '@mui/system';
import { Card, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, TablePagination, Tab } from '@mui/material';
import { storeScreenbarCodeTableHeadings} from '../Variables/ProcessVariables';
import Heading from '../Heading';
import { loopStoreBarcode, loopStoreData } from '../Variables/Variables';
import PrintMultiQR from './StoreNStockBarCode/PrintMultiQR';


function StoreScreenQRCodeTable({data}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
  const storedata = loopStoreData([data]);

  const storeBarcodeLoop = loopStoreBarcode([data]);



    return (
        <Box sx={{ py: 3 }}>
                    <Box sx={{ py: 3 }}>
                        <Heading title={'Store Qrcode Generation'} />
                    </Box>
                    <Box sx={{ p: 1 }}>
                        <PrintMultiQR barcodedata={storeBarcodeLoop} />
                    </Box>
                    <Box sx={{ p: 2 }} component={Card}>
                        <TableContainer>
                            <Table>
                                <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                    <TableRow >
                                        {
                                            storeScreenbarCodeTableHeadings.map((headingLabels) =>
                                                <TableCell key={headingLabels.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                    {headingLabels.name}
                                                </TableCell >
                                            )
                                        }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                    storedata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((barCodeData) =>
                                            <TableRow key={barCodeData.i}>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.partCode}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.partDescriptions}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.class}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.subclass}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.uom}</TableCell>
                                            </TableRow>
                                        )
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={storedata.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
        </Box>
    )
}

export default StoreScreenQRCodeTable