import {
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Alert,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@mui/material";
// import { QRCode } from "react-qrcode-logo";
// import logo from "../Assets/nadi_logo.jpg";

function DialogBox({
  open,
  setOpenDialog,
  message,
  imageUrl,
  customerDetails,
}) {
  const handleClose = () => {
    setOpenDialog(false);
  };

  const onPrintBarcode = () => {
    const container = document.getElementById("div-svg");
    const width = "500px";
    const height = "500px";

    const printWindow = window.open(
      "",
      "PrintMap",
      "width=" + width + ",height=" + height
    );
    printWindow.document.writeln(container.innerHTML);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent id="div-svg" sx={{ fontSize: "10px" }}>
        <DialogContentText id="alert-dialog-description">
          <TableContainer>
            <Table>
              <TableBody>
                <TableCell>
                  {imageUrl ? (
                    <a href={imageUrl} download>
                     
                      <img src={imageUrl} alt="img" height="150" width="150" />
                    </a>
                  ) : null}
                  {message}
                </TableCell>
                <TableCell>
                  <Box> {" FAN MODEL - " + customerDetails?.short_char_01}</Box>

                  <Box> {"JOB NO - " + customerDetails?.job_num}</Box>

                  <Box> {"SERIAL NO - " + customerDetails?.serial_no}</Box>

                  <Box> {" PART DESC - " + customerDetails?.descriptions}</Box>

                  <Box>
                    {" "}
                    {customerDetails?.stage + customerDetails?.matl_part_code}
                  </Box>
                </TableCell>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onPrintBarcode}
          startIcon={<PrintIcon />}
        >
          Print
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogBox;
