import React from 'react'
import { useState, useContext , useEffect } from 'react';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { addremarks, fetchsinglefirstcoatpaint, methodPost, otherScreenSingleOrders } from '../../Services/Apivariables';
import SnackBar from './../SnackBar';
import DialogBox from './../DialogBox';
import Heading from './../Heading';
import { barCodeTableHeadings, firtsCoatPaintTableHeading } from '../Variables/ProcessVariables';
import Filter from './../Filter';
import { filterJobScreen } from '../FilterData';
import { nullFinderandDateswapper } from '../Variables/Variables';
import submitForm from '../../Services/ProcessScreenSubmitForm';
import Firstcoatpaintsinglejob from './Processsinglejob/Firstcoatpaintsinglejob'
import { appendData } from '../Variables/ProcessVariables'
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FirstCoatPaintSFGtable from '../BarCodeModule/SFGQrCodetable/FirstCoatPaintSFGtable';
import { bomdataContext } from './../../Pages/OtherScreenlayoutSingle';
import Nodata from '../Nodata';





function FirstCoatPaint({ data, title, setrefreshpage }) {
    const [firstcoatpaintBarcodeData, setFirstCoatPaintBarcodeData] = useState([]);
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [id, setid] = useState('');
    const [multipleOrders, setMultipleOrders] = useState([])
    const [selected, setSelected] = useState([]);
    const [isloading, setIsloading] = useState(false);
    
    // this state hold all the value in obj
    const [fristcoatpaint, setFristCoatPaint] = useState({});
    const jobnum = data.map((i)=>i.job_num);
    const orderid = data.map((i)=>i.id);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(() => {
        if(data.length !== 0){
        const sendData = new FormData();
        sendData.append('job_num', jobnum)
        sendData.append('department', title)
        axios({
            method: 'POST',
            url: otherScreenSingleOrders,
            data: sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setrefreshpage(false)
            } else {
                setFirstCoatPaintBarcodeData(res.data.fcpsfg);
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setrefreshpage(false)
                setIsloading(true);
            }
        }).catch(err => {
            console.log(err)
        });
    }
    }, [])  


    const handleSubmit = () => {
        if (selected.length > 0) {
       if(fristcoatpaint.actual_date!==''){
        const selectedjobs = selected.map((element, i) => {
            const obj = {
                i: i,
                orderid: element.orderid,
                actual_date: fristcoatpaint.actual_date,
                first_remarks: fristcoatpaint.first_remarks ? fristcoatpaint.first_remarks : ''
            }
            return obj
        })
        const sendData = {
            "job_no":jobnum,
            "fc_paint": JSON.stringify(selectedjobs)
        }
        const lData = appendData(sendData)
           submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchsinglejob, jobnum)        
           setFristCoatPaint({
            actual_date: '',
            first_remarks: ''
        });
       }else if(fristcoatpaint.first_remarks!==''){
        const selectedjobs = selected.map((element, i) => {
            const obj = {
                i: i,
                orderid: element.orderid,
                first_remarks: fristcoatpaint.first_remarks
            }
            return obj
        })
        const sendData = {
            "job_no":jobnum,
            "fc_paint": JSON.stringify(selectedjobs)
        }
        const lData = appendData(sendData)
           submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchsinglejob, jobnum)        
           setFristCoatPaint({
            actual_date: '',
            first_remarks: ''
        });
       }
        } else {
            setMessage("Please select atleast one job")
            setOpen(true)
            setStatus(false)
            setColor(false)
        }
    }

    // const onUpdate= ()=>{
    //     if(fristcoatpaint.actual_date!==''){
    //         const selectedjobs = data.map((element, i) => {
    //             const obj = {
    //                 i: i,
    //                 orderid: orderid.toString(),
    //                 actual_date: fristcoatpaint.actual_date,
    //                 first_remarks: fristcoatpaint.first_remarks ? fristcoatpaint.first_remarks : ''
    //             }
    //             return obj
    //         })
    //         const sendData = {
    //             "job_no":jobnum,
    //             "fc_paint": JSON.stringify(selectedjobs)
    //         }
    //         const lData = appendData(sendData)
    //         submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
    //         setFristCoatPaint({
    //             actual_date: '',
    //             first_remarks: ''
    //         });
    //         setIsEdit(false);
    //         setid(null);
    //         setSelected([]);
    //        }else if(fristcoatpaint.first_remarks!==''){
    //         const selectedjobs = data.map((element, i) => {
    //             const obj = {
    //                 i: i,
    //                 orderid: orderid.toString(),
    //                 first_remarks: fristcoatpaint.first_remarks
    //             }
    //             return obj
    //         })
    //         const sendData = {
    //             "job_no":jobnum,
    //             "fc_paint": JSON.stringify(selectedjobs)
    //         }
    //         const lData = appendData(sendData)
    //         submitForm(addremarks, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
    //         setFristCoatPaint({
    //             actual_date: '',
    //             first_remarks: ''
    //         });
    //         setIsEdit(false);
    //         setid(null);
    //         setSelected([]);
    //         fetchsinglejob(jobnum)
    //        } else {
    //             setMessage("Please Fill atleast One Input Field")
    //             setOpen(true)
    //             setStatus(false)
    //             setColor(false)
    //         }
    // }

    const fetchsinglejob = (id) => {
        const oid = { job_num: id }
        const sendid = appendData(oid)
        axios({
            method: methodPost,
            url: fetchsinglefirstcoatpaint,
            data: sendid
        }).then(res => {
            setMultipleOrders(res.data.data)
        }).catch(err => {
            console.log(err)
        });
    }

    // const edd = data.map((i)=> i.first_coat_paint_edd.toString());
    // const date = new Date(edd);
    // const day = date.getDate()- 6;
    // const minDay =  date.setDate(day);
    // let d , m ;
    // d = date.getDate();
    // m = date.getMonth()+1;
    // if (d < 10) {
    //     d = '0' + d;
    //   }
      
    //   if (m < 10) {
    //     m = '0' + m;
    //   }
    // const convert = [date.getFullYear(),(m), (d)].join('-');

    return (
        <Box>
            <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
            <Box sx={{ py: 3 }}>
                <Heading title={title} />
            </Box>
            <Box sx={{ py: 3 }}>
                <Box sx={{ p: 2 }} component={Card}>
                    <TableContainer >
                        <Table>
                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                <TableRow >
                                    {
                                        firtsCoatPaintTableHeading.map((headings) =>
                                            <TableCell key={headings.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Box sx={{ my: 'auto' }}>
                                                        {headings.label}
                                                    </Box>
                                                    <Box>
                                                        {headings.filter ? <Filter label={headings.label} setSearch={setSearch} /> : ''}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.filter((data) => filterJobScreen(data, search, {
                                        searchFeildOne: data.job_num,
                                        searchFeildTwo: data.prod_code,
                                        searchFeildThree: data.short_char_01
                                    })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) =>
                                        <TableRow key={items.id}>
                                            <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.serial_no}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.job_num}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                                            {/* <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <Statusbar label={items.first_status === 'true' ? 'Completed' : 'Pending'} color={items.first_status === 'true' ? 'success' : 'error'} />
                                                </Box>
                                            </TableCell> */}
                                            <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                                            <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.first_coat_paint_edd)}</TableCell>
                                            {/* <TableCell sx={{ textAlign: 'center' }}>
                                                <Tooltip title="Display jobs">
                                                    <IconButton
                                                        aria-label="edit" onClick={() => {
                                                            loopmultipledata({
                                                                orderid: items.id,
                                                                qty: items.order_qty_1
                                                            })
                                                        }}>
                                                        <EditIcon color='primary' />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell> */}
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <IconButton
                                                    disabled={items.first_status === 'true' ? true : false}
                                                    aria-label="edit" onClick={() => {
                                                        setIsEdit(!isEdit)
                                                        setid(items.id)
                                                        fetchsinglejob(items.job_num)
                                                    }}>
                                                    {isEdit && id === items.id ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
                                                </IconButton>
                                            </TableCell>
                                            {/* <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                disabled={items.first_status  === 'true' ? true : false}
                                                    type='date'
                                                    onChange={(e) => setPlannedDate(e.target.value)}
                                                    fullWidth
                                                    size='small'
                                                    label='Planned Date'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell> */}
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                   disabled={isEdit && id === items.id ? false : true}
                                                    type='date'
                                                    value={isEdit && id === items.id ? fristcoatpaint.actual_date : ''}
                                                    onChange={(e) => setFristCoatPaint({ ...fristcoatpaint, 'actual_date': e.target.value })}
                                                    fullWidth
                                                    size='small'
                                                    label='Completed Date'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    // inputProps={{min: [convert]}}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    sx={{ width: 140 }}
                                                    multiline
                                                    disabled={isEdit && id === items.id ? false : true}
                                                    value={isEdit && id === items.id ? fristcoatpaint.first_remarks : ''}
                                                    onChange={(e) => setFristCoatPaint({ ...fristcoatpaint, 'first_remarks': e.target.value })}
                                                    fullWidth
                                                    size='small'
                                                    label='Remarks'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>

                                                  <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box>

                                            {/* {
                          multipleOrders.length !== 0 ? 
                          <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box> : 
                         <Box>
                         <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                           onUpdate()
                          
                         }} startIcon={<CheckCircleOutlineIcon />}>
                           Update
                         </Button>
                       </Box>
                        } */}
                        
                                            </TableCell>
                                            {/* <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    type='date'
                                                    onChange={(e) => setFirstCoatPaintRemarksRevisedDate(e.target.value)}
                                                    fullWidth
                                                    size='small'
                                                    label='Revised Date'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    multiline
                                                    sx={{ width: 140 }}
                                                    onChange={(e) => setFirstCoatPaintRevisedRemarks(e.target.value)}
                                                    fullWidth
                                                    size='small'
                                                    label='Remarks'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <Button variant="contained" onClick={() => {handleSubmitRevised(items.id)
                                                    fetchsinglejob(items.job_num)
                                                    }} startIcon={<TelegramIcon />}>
                                                        Request
                                                    </Button>
                                                </Box>
                                            </TableCell> */}
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
            <Box sx={{ py: 2 }}>
                <Firstcoatpaintsinglejob data={multipleOrders} selected={selected} setSelected={setSelected} />
            </Box>


                <Box sx={{ py: 3 }}>
               {/* <PrintMultipleSFGCode barcodedata={FirstCoatPaintBarcodeData} /> */}

               <FirstCoatPaintSFGtable isloading={isloading} data={firstcoatpaintBarcodeData} title={"First Coat Paint SFG Qrcode Generation"} tableHeadings={barCodeTableHeadings} />
                </Box>
        </Box>
    )
}

export default FirstCoatPaint