import React from 'react'
import { Box, Card, Typography } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import useFetch from './../../Services/useFetch';
import { doughnutOne, methodGet } from '../../Services/Apivariables';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
// import { backgroundColor, borderColor, domesticlabels } from '../Variables/Variables';
import Heading from './../Heading';



function DoughnutChart({ url, title, labels, position,backgroundColor,borderColor }) {
  const controller = new AbortController();
  const signal = controller.signal;
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!navigator.onLine) {
      alert("Your internet is in Offline")
    } else {
      fetch(url, {
        signal: signal
      })
        .then(response => response.json())
        .then(data => setData(data.data))
        .catch(err => {
          console.log(err)
        });
    }
    return () => controller.abort();
  }, []);

  const chartdata = {
    labels: labels,
    datasets: [{
      label: 'My First dataset',
      backgroundColor: backgroundColor,
      borderColor: borderColor,
      borderWidth: 1,
      data: data.map((graphdata) => graphdata.Quantity),
    }
    ]
  };
  return (
    <Box >
      <Box sx={{ p: 2 }}>
        <Typography
          variant="h3"
          sx={{ textAlign: 'center', fontWeight: 600, fontSize: 25, textTransform: 'uppercase', color: 'primary.main' }}
        >
          {title}
        </Typography>
      </Box>
      <Box>
        <Doughnut data={chartdata} options={{
          plugins: {
            legend: {
              labels: {
                color: 'black',
                font: {
                  size: 15,
                  weight: '500',
                  family: "'Jost', sans-serif"
                }
              },
              display: true,
              position: position
            }
          },
          layout: {
            padding: 10
          }
        }} />
      </Box>
    </Box>
  )
}

export default DoughnutChart