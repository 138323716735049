import React, { useState } from 'react'
import { Box, Button } from '@mui/material';
import OverallQR from './OverallQR';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';


function PrintMultiQR({barcodedata}) {
    const [open, setOpen] = useState(false);
    return (
        <Box sx={{ py: 1 }}>
            <Button variant='contained' startIcon={<LocalPrintshopIcon />} onClick={() => setOpen(!open)}>QRCode</Button>
            <OverallQR setOpen={setOpen} open={open} data={barcodedata} />
        </Box>
    )
}

export default PrintMultiQR