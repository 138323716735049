import React, { useState } from "react";
import {
  Card,
  Grid,
  TextField,
  Button,
  Box,
 Autocomplete,
} from "@mui/material";
import Heading from "../Comps/Heading";
import {
 customername, fanmodel, workTypeOne, workTypeTwo , stockTable
} from "../Services/Apivariables";
import SnackBar from "../Comps/SnackBar";
import { useForm } from "react-hook-form";
import axios from "axios";
import StockGenerateTable from "../Comps/BarCodeModule/StockGenerateTable";
import useFetch from "../Services/useFetch";
import Statusloader from '../Comps/Statusloader';


function StockScreen() {

  const {register, formState: { errors }, handleSubmit, reset} = useForm();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [message, setMessage] = useState("");
  const [dummystate, setdummystate] = useState(false);
  const [data , setData] = useState("");
  const [workType1 , setWorkType1] = useState("");
  const {dataOne , isloading} = useFetch(customername);
  const {workType2} = useFetch(workTypeTwo);
  const [input , setInput]= useState(false);
  const [refreshpage, setrefreshpage] = useState(false);

  const handleChange = (event, value) => {
    const custname = new FormData()
    custname.append('customerName', value);

  const methPost = 'POST';
  axios.all([
    axios({
      method: methPost,
      url: fanmodel,
      data: custname,
  }).then(res => {
        if (res.data.error) {
            // setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
            setrefreshpage(false)
        } else {
            setOpen(true)
            // setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setdummystate(true)
            setData(res.data.data)
            setrefreshpage(false)
        }
    }).catch(err => {
        console.log(err)
    }),
    axios({
      method: methPost,
      url: workTypeOne,
      data: custname,
  }).then(res => {
        if (res.data.error) {
            setMessage(res.data.message)
            setOpen(true)
            setStatus(false)
            setColor(false)
            setrefreshpage(false)
        } else {
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setdummystate(true)
            setWorkType1(res.data.data)
            setrefreshpage(false)
        }
    }).catch(err => {
        console.log(err)
    })
  ]);
    
  }
 

  const autocompletedata =  dataOne.map((element)=>element.customerName);
  const optionFan = data && data.map((fan)=> fan.fanModel);
  const optionWork1 = workType1 && workType1.map((type)=> type.workType1);
  const optionWork2 =  workType2.map((type)=> type.workType2);


const onSubmit = (data) => {
  const serverData = new FormData()
  for (var key in data) {
      serverData.append(key, data[key]);
  }
  axios({
    method: "POST",
    url: stockTable,
    data: serverData,
}).then(res => {
      if (res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
          setrefreshpage(false)
      } else {
          setOpen(true)
          setMessage(res.data.message)
          setStatus(true)
          setColor(true)
          setdummystate(true)
          setrefreshpage(false)
          reset({
              customerName: '',
              workOrderNum: '',
              fanModel:'',
              workType1: '',
              workType2: '',
              batchQty: '',
              weight:'',
              ERPJobNum:'',
          })
      }
  }).catch(err => {
      console.log(err)
  });
  setInput(true);

}

if (!isloading) {
  return (
      <Statusloader />
  )
}
else
{
  return (
    <Box variant="div" sx={{ p: 2 }}>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        color={color}
        status={status}
      />
  
      <Box sx={{ py: 3 }}>
        <Heading title={"Stocks"} />
      </Box>
      <Box sx={{ p: 2, borderRadius: 4 }} component={Card}>
      
    <Box component='form' >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
          <Grid container sx={{ p: 2 }} rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={4}>
                      <Autocomplete
                        freeSolo
                        size='small'
                        onChange={handleChange}
                        defaultValue=""
                        key={input}
                        disablePortal
                        options={[...autocompletedata]}
                        renderInput={(params) => <TextField  
                          error={errors.customerName ? true : false}
                          helperText={errors.customerName ? "Customer Name is required" : ""}
                          {...params} label="Customer Name"
                          {...register("customerName", { required: true })}
                            />}
                    />
                      </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={4}>
                      <TextField
                                    error={errors.workOrderNum ? true : false}
                                    helperText={errors.workOrderNum ? "Work Order Number is required" : ""}
                                    {...register("workOrderNum", { required: true })}
                                    required
                                    fullWidth
                                    size='small'
                                    label="Work Order No"
                                    variant="outlined"
                                     />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <Autocomplete
                      freeSolo
                      size='small'
                      disablePortal
                      defaultValue=""
                      key={input}
                      options={[...optionFan]}
                      renderInput={(params) => <TextField  
                        {...params} label="Fan Model" 
                        error={errors.fanModel ? true : false}
                        helperText={errors.fanModel ? "Fan Model is required" : ""}
                        {...register("fanModel", { required: true })}  />}
                                    />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <Autocomplete
                        freeSolo
                        size='small'
                        disablePortal
                        defaultValue=""
                        key={input}
                        options={[...optionWork1]}
                        renderInput={(params) => <TextField 
                           {...params} label="Work Type1" 
                           error={errors.workType1 ? true : false}
                           helperText={errors.workType2 ? "Work Type 1 is required" : ""}
                           {...register("workType1", { required: true })}  />}
                    />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <Autocomplete
                        freeSolo
                        size='small'
                        disablePortal
                        defaultValue=""
                        key={input}
                        options={[...optionWork2]}
                        renderInput={(params) => <TextField  
                          {...params} label="Work Type 2" 
                          error={errors.workType2 ? true : false}
                          helperText={errors.workType2 ? "Work Type 2 is required" : ""}
                           {...register("workType2", { required: true })} />}
                    />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <TextField
                      required
                      error={errors.batchQty ? true : false}
                      helperText={errors.batchQty ? "Batch Qty is required" : ""}
                      {...register("batchQty", { required: true })} 
                      fullWidth
                      size='small'
                      type='number'
                      label="Batch Qty"
                      variant="outlined" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <TextField
                      required
                      error={errors.weight ? true : false}
                      helperText={errors.weight ? "Weight is required" : ""}
                      {...register("weight", { required: true })} 
                      fullWidth
                      size='small'
                      type='number'
                      label="Weight"
                      variant="outlined" />
                    </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                      <TextField
                      required
                      error={errors.ERPJobNum ? true : false}
                      helperText={errors.ERPJobNum ? "ERP Job No is required" : ""}
                      {...register("ERPJobNum", { required: true })} 
                      fullWidth
                      size='small'
                      label="ERP Job No"
                      variant="outlined" />
                    </Grid>

                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Box sx={{ pt: 3 }}>
                      <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
      </Box>
      <Box>
        <StockGenerateTable dummystate={dummystate} setdummystate={setdummystate}  />
      </Box>
    </Box>
  )

}
}

export default StockScreen;