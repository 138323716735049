import { v1 as uuidv1 } from 'uuid';

// this variable for production drawing table heading and inputs labels
const productionDrawingTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Production Drawing EDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Display view'
    // },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    // {
    //     id: uuidv1(),
    //     label: 'BOM Excel Upload'
    // },
    {
        id: uuidv1(),
        label: 'Production DG Release Date'
    },
    {
        id: uuidv1(),
        label: 'BOM Release Date'
    },
    // {
    //     id: 11,
    //     label: 'Upload Excel File'
    // },
    // {
    //     id: 12,
    //     label: 'Not Available Components'
    // },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }

];

const singleproductionDrawingTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Production Drawing EDD'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Production DG Committed Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'BOM Committed Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }
];

const showProductionDrawingTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Production Drawing EDD'
    },
    {
        id: uuidv1(),
        label: 'Production DG Release Date'
    },
    {
        id: uuidv1(),
        label: 'BOM Release Date'
    },
    // {
    //     id: 10,
    //     label: 'File Name'
    // },
    // {
    //     id: 11,
    //     label: 'Not Available Components'
    // },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }

];

// this variable is for mom table
const momTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Mom EDD'
    },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Mom Executed Date'
    // },
    {
        id: uuidv1(),
        label: 'Mom Released Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: 12,
    //     label: 'Revised Date'
    // },
    // {
    //     id: 13,
    //     label: 'Remarks'
    // },
    // {
    //     id: 14,
    //     label: 'Change Request Button'
    // }

];
const singleMomTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    }

];

const showMomTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: 7,
    //     label: 'Mom EDD'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Mom Executed Date'
    // },
    {
        id: uuidv1(),
        label: 'Mom Released Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: 11,
    //     label: 'Revised Date'
    // },
    // {
    //     id: 12,
    //     label: 'Remarks'
    // }

];

// this variable for store table heading and inputs labels  
const storeTableHead = [
    {
        id: uuidv1(),
        label: 'ID.No',
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    // {
    //     id: uuidv1(),
    //     label: 'status'
    // },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Stores EDD'
    },
    {
        id: uuidv1(),
        label: 'Bom Released Date'
    },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    {
        id: uuidv1(),
        label: 'DWG Received Date'
    },
    // {
    //     id: uuidv1(),
    //     label: 'No of Items Available'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'No of Items Not Available'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Acknowledgement'
    // },
    // {
    //     id: 12,
    //     label: 'Part Code'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Part Description'
    // },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }

];
const singleStoreTableHead = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Stores EDD'
    // }

];

const showStoreTableHead = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Stores EDD'
    },
    {
        id: uuidv1(),
        label: 'Bom Released Date'
    },
    {
        id: uuidv1(),
        label: 'Report Submitted Date'
    },
    // {
    //     id: uuidv1(),
    //     label: 'No of Items Available'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'No of Items Not Available'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Acknowledgement'
    // },
    // {
    //     id: 12,
    //     label: 'Part Code'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Part Description'
    // },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }

];

// this variable for cnc screen table heading
const cncScreenTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'CNC EDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Display view'
    // },
    {
        id: uuidv1(),
        label: 'Edit'
    },

    {
        id: uuidv1(),
        label: 'Drawing Received Date'
    },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    // {
    //     id: 11,
    //     label: 'Released Order'
    // },
    // {
    //     id: 12,
    //     label: 'Completed Date'
    // },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }
];

const singleCncScreenTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'CNC EDD'
    // }
];

const showingCncScreenTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CNC EDD'
    },
    // {
    //     id: 8,
    //     label: 'Planned Date'
    // },
    {
        id: uuidv1(),
        label: 'Drawing Received Date'
    },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    // {
    //     id: 11,
    //     label: 'Released Order'
    // },
    // {
    //     id: 12,
    //     label: 'Completed Date'
    // },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }
];


// this variable for Purchase Screen table heading and inputs labels
const purchaseScreenTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Motor Delivery EDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Display view'
    // },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    {
        id: uuidv1(),
        label: 'Po Released Date'
    },
    {
        id: uuidv1(),
        label: 'Motor Received Date'
    },
    {
        id: uuidv1(),
        label: 'Po Released'
    },
    {
        id: uuidv1(),
        label: 'Wooden Crate Expected Date'
    },
  
    {
        id: uuidv1(),
        label: 'Wooden Crate Received Date'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Bought Out Expected Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Po Released'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Bought Out Received Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Pending Break Up'
    // },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }

];

const singlePurchaseScreenTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Motor Delivery EDD'
    // }

];

const showPurchaseScreenTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Motor Delivery EDD'
    },
    {
        id: uuidv1(),
        label: 'Po Released Date'
    },
    {
        id: uuidv1(),
        label: 'Motor Received Date'
    },
    {
        id: uuidv1(),
        label: 'Wooden Crate Expected Date'
    },
    {
        id: uuidv1(),
        label: 'Po Released'
    },
    {
        id: uuidv1(),
        label: 'Wooden Crate Received Date'
    },
    {
        id: uuidv1(),
        label: 'Bought Out Expected Date'
    },
    {
        id: uuidv1(),
        label: 'Po Released'
    },
    {
        id: uuidv1(),
        label: 'Bought Out Received Date'
    },
    {
        id: uuidv1(),
        label: 'Pending Break Up'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },

];

// this variable for Hub table heading and inputs labels
const hubTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Hub EDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Display view'
    // },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    {
        id: uuidv1(),
        label: 'Received Date'
    },
    // {
    //     id: 10,
    //     label: 'Required Date'
    // },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }

];
const singleHubTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Hub EDD'
    // }

];
const showHubTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Hub EDD'
    },
    // {
    //     id: 8,
    //     label: 'Planned Date'
    // },
    {
        id: uuidv1(),
        label: 'Received Date'
    },
    // {
    //     id: 10,
    //     label: 'Required Date'
    // },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }

];

// this variable for Machining table heading and inputs labels
const machiningTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Machining EDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Display view'
    // },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    {
        id: uuidv1(),
        label: 'Received Date'
    },
    // {
    //     id: 10,
    //     label: 'Required Date'
    // },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }

];
const singleMachiningTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Machining EDD'
    // }

];

const showMachiningTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Machining EDD'
    },
    // {
    //     id: 8,
    //     label: 'Planned Date'
    // },
    {
        id: uuidv1(),
        label: 'Received Date'
    },
    // {
    //     id: 10,
    //     label: 'Required Date'
    // },
    {
        id: uuidv1(),
        label: 'Compeleted Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }

];

// this variable for impeller table heading and inputs labels
const impellerTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Impeller EDD'
    },
    {
        id: uuidv1(),
        label: 'Hub Status',
        status: Boolean(true)
    },
    // {
    //     id: uuidv1(),
    //     label: 'Display view'
    // },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    {
        id: uuidv1(),
        label: 'Received Date'
    },
    // {
    //     id: 11,
    //     label: 'Required Date'
    // },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }

];
const singleImpellerTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Order No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Part Code',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Descriptions',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },


];
const showImpellerTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Impeller EDD'
    },
    {
        id: uuidv1(),
        label: 'Hub Status',
        status: Boolean(true)
    },
    // {
    //     id: 9,
    //     label: 'Planned Date'
    // },
    {
        id: uuidv1(),
        label: 'Received Date'
    },
    // {
    //     id: 11,
    //     label: 'Required Date'
    // },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }

];

const showAccessoriesTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Accessories EDD'
    },
    {
        id: uuidv1(),
        label: 'Hub Status',
        status: Boolean(true)
    },
    // {
    //     id: 9,
    //     label: 'Planned Date'
    // },
    {
        id: uuidv1(),
        label: 'Received Date'
    },
    // {
    //     id: 11,
    //     label: 'Required Date'
    // },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }

];

// this variable for casing table heading and inputs labels
const casingTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Casing EDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Display view'
    // },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    {
        id: uuidv1(),
        label: 'Received Date'
    },
    // {
    //     id: 10,
    //     label: 'Required Date'
    // },
    {
        id: uuidv1(),
        label: 'Compeleted Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }

];
const singlecasingtableheading =[
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Casing EDD'
    // }

]



const showCasingTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Status'
    },
    {
        id: uuidv1(),
        label: 'Casing EDD'
    },
    // {
    //     id: 9,
    //     label: 'Planned Date'
    // },
    {
        id: uuidv1(),
        label: 'Received Date'
    },
    // {
    //     id: 11,
    //     label: 'Required Date'
    // },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }

];

// this variable for wooden crate table heading and inputs labels
const woodenCrateTableHeading = [
    {
        id: 1,
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: 2,
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: 3,
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: 4,
        label: 'Qty'
    },
    {
        id: 5,
        label: 'Status'
    },
    {
        id: 6,
        label: 'CDD'
    },
    {
        id: 7,
        label: 'Wooden crate EDD'
    },
    {
        id: 8,
        label: 'Drawing Received Date'
    },
    // {
    //     id: 9,
    //     label: 'Required Date'
    // },
    {
        id: 10,
        label: 'Wooden Crate Revised Date'
    },
    {
        id: 11,
        label: 'Remarks'
    },
    {
        id: 12,
        label: 'Update'
    },
    {
        id: 13,
        label: 'Revised Date'
    },
    {
        id: 14,
        label: 'Remarks'
    },
    {
        id: 15,
        label: 'Change Request Button'
    }

];

const showWoodenCrateTableHeading = [
    {
        id: 1,
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: 2,
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: 3,
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: 4,
        label: 'Qty'
    },
    {
        id: 5,
        label: 'Status'
    },
    {
        id: 6,
        label: 'CDD'
    },
    {
        id: 7,
        label: 'Wooden crate EDD'
    },
    {
        id: 8,
        label: 'Drawing Received Date'
    },
    // {
    //     id: 9,
    //     label: 'Required Date'
    // },
    {
        id: 10,
        label: 'Wooden Crate Revised Date'
    },
    {
        id: 11,
        label: 'Remarks'
    },
    {
        id: 13,
        label: 'Revised Date'
    },
    {
        id: 14,
        label: 'Remarks'
    }

];

// this variable for Bought Out table heading and inputs labels
const boughtOutTableHeading = [
    {
        id: 1,
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: 2,
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: 3,
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: 4,
        label: 'Qty'
    },
    {
        id: 5,
        label: 'Status'
    },
    {
        id: 6,
        label: 'CDD'
    },
    {
        id: 7,
        label: 'Bought Out EDD'
    },
    {
        id: 8,
        label: 'Bom Received Date'
    },
    {
        id: 9,
        label: 'Component'
    },
    // {
    //     id: 10,
    //     label: 'Required Date'
    // },
    {
        id: 11,
        label: 'Received Date'
    },
    {
        id: 12,
        label: 'Remarks'
    },
    {
        id: 13,
        label: 'Update'
    },
    {
        id: 14,
        label: 'Revised Date'
    },
    {
        id: 15,
        label: 'Remarks'
    },
    {
        id: 16,
        label: 'Change Request Button'
    }

];
const showBoughtOutTableHeading = [
    {
        id: 1,
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: 2,
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: 3,
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: 4,
        label: 'Qty'
    },
    {
        id: 5,
        label: 'CDD'
    },
    {
        id: 6,
        label: 'Status'
    },
    {
        id: 7,
        label: 'Bought Out EDD'
    },
    {
        id: 8,
        label: 'Bom Received Date'
    },
    {
        id: 9,
        label: 'Component'
    },
    // {
    //     id: 10,
    //     label: 'Required Date'
    // },
    {
        id: 11,
        label: 'Received Date'
    },
    {
        id: 12,
        label: 'Remarks'
    },
    {
        id: 13,
        label: 'Revised Date'
    },
    {
        id: 14,
        label: 'Remarks'
    }

];

// this variable for Firts Coat Paint table heading and inputs labels
const firtsCoatPaintTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'First Coat Paint EDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Display view'
    // },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }

];
const showFirtsCoatPaintTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'First Coat Paint EDD'
    },
    {
        id:uuidv1(),
        label: 'Completed'
    },
    //   {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    {
        id: uuidv1(),
        label: 'Remarks'
    }
];

const singlefirstcoatpainttabeheading =[
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'First Coat Paint EDD'
    // },
]

// this variable for Assembly table heading and inputs labels
const assemblyTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model'
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Assembly EDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Display view'
    // },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }

];
const showAssemblyTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model'
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Assembly EDD'
    },
    // {
    //     id: 8,
    //     label: 'Plan Date'
    // },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }

];

const singleassemblytableheading =[
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model'
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Assembly EDD'
    // }
]

// this variable for testing table heading and inputs labels
const testingTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Testing EDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Display view'
    // },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }

];
const showTestingTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Testing EDD'
    },
    // {
    //     id: 8,
    //     label: 'Plan Date'
    // },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }

];

const singletestingtableheading =[
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Testing EDD'
    // }
]

// this variable for final paint table heading and inputs labels
const finalPaintTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Final Paint EDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Display view'
    // },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }

];
const showFinalPaintTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Final Paint EDD'
    },
    // {
    //     id: 8,
    //     label: 'Plan Date'
    // },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }

];

const singlefinalpainttableheadeing =[
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Final Paint EDD'
    // }
]

// this variable for Packing table heading and inputs labels
const packingTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Packing and Dispatching EDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Display view'
    // },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    {
        id: uuidv1(),
        label: 'Packing Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Dispatch Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Change Request Button'
    // }
];
const AccessoriesTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'Edit'
    },
    {
        id: uuidv1(),
        label: 'Received Date'
    },
    // {
    //     id: 11,
    //     label: 'Required Date'
    // },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    {
        id: uuidv1(),
        label: 'Update'
    },
]

const showDisplayAccessoriesTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'DisplayAccessories EDD'
    },
    {
        id: uuidv1(),
        label: 'DisplayAccessories DG Release Date'
    },
    {
        id: uuidv1(),
        label: 'BOM Release Date'
    },
    // {
    //     id: 10,
    //     label: 'File Name'
    // },
    // {
    //     id: 11,
    //     label: 'Not Available Components'
    // },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }

];

const showPackingTableHeading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    {
        id: uuidv1(),
        label: 'Packing and Dispatching EDD'
    },
    // {
    //     id: 8,
    //     label: 'Plan Date'
    // },
    {
        id: uuidv1(),
        label: 'Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Packing Completed Date'
    },
    {
        id: uuidv1(),
        label: 'Remarks'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Revised Date'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Remarks'
    // }
];
const singlepackingtableheading =[
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Type',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Fan Model',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Qty'
    },
    {
        id: uuidv1(),
        label: 'CDD'
    },
    {
        id: uuidv1(),
        label: 'Packing Completed Date'
    },
    // {
    //     id: uuidv1(),
    //     label: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     label: 'Packing and Dispatching EDD'
    // }
]

const simpleaccessoriestableheading = [
    {
        id: uuidv1(),
        label: 'ID.No'
    },
    {
        id: uuidv1(),
        label: 'Serial.No'
    },
    {
        id: uuidv1(),
        label: 'Job Number',
        filter: Boolean(true)
    },
    
]

const barCodeTableHeadings = [
    {
        id: uuidv1(),
        name: 'Order number'
    },
    {
        id: uuidv1(),
        name: 'Job number',
    },
    {
        id: uuidv1(),
        name: 'Serial number',
    },
    {
        id: uuidv1(),
        name: 'Part code',
    },
    {
        id: uuidv1(),
        name: 'Description'
    },
    {
        id: uuidv1(),
        name: 'Generate QRCode'
    }
]


const StocksScreenbarCodeTableHeadings = [
    {
        id: uuidv1(),
        label: 'Work Order number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Stock Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: 'Part Code',
        filter: Boolean(true)
    }
]

const storeScreenbarCodeTableHeadings = [
    {
        id: uuidv1(),
        name: 'Part Code'
    },
    {
        id: uuidv1(),
        name: 'Part Description',
    },
    {
        id: uuidv1(),
        name: 'Class',
    },
    {
        id: uuidv1(),
        name: 'Sub Class',
    },
    {
        id: uuidv1(),
        name: 'UOM'
    },
]

const appendData = (data) => {
    const serverData = new FormData()
    for (var key in data) {
        serverData.append(key, data[key]);
    }
    return serverData
}

export { productionDrawingTableHeading, storeTableHead, storeScreenbarCodeTableHeadings,cncScreenTableHeading, purchaseScreenTableHeading, machiningTableHeading, hubTableHeading, impellerTableHeading, casingTableHeading, woodenCrateTableHeading, boughtOutTableHeading, firtsCoatPaintTableHeading, assemblyTableHeading, testingTableHeading, finalPaintTableHeading, packingTableHeading, showProductionDrawingTableHeading, showStoreTableHead, showingCncScreenTableHeading, showPurchaseScreenTableHeading, showHubTableHeading, showMachiningTableHeading, showImpellerTableHeading, showAccessoriesTableHeading, showCasingTableHeading, showWoodenCrateTableHeading, showBoughtOutTableHeading, showFirtsCoatPaintTableHeading, showAssemblyTableHeading, showTestingTableHeading, showFinalPaintTableHeading, showPackingTableHeading, momTableHeading, showMomTableHeading, simpleaccessoriestableheading,StocksScreenbarCodeTableHeadings, barCodeTableHeadings, appendData, singleproductionDrawingTableHeading, singleMomTableHeading, singleStoreTableHead, singleCncScreenTableHeading, singlePurchaseScreenTableHeading, singleHubTableHeading, singleMachiningTableHeading, singleImpellerTableHeading,singleassemblytableheading,singletestingtableheading,singlefinalpainttableheadeing,singlepackingtableheading,singlecasingtableheading,singlefirstcoatpainttabeheading,AccessoriesTableHeading }