import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { displaytargetscreen, methodGet } from '../Services/Apivariables';
import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import Nodata from './Nodata';
import axios from 'axios';
import { targettableheadings } from './Variables/Variables';
import { filterData } from './FilterData';
import Statusloader from './Statusloader';
import Filter from './Filter';

function Targettable({dummystate,setdummystate}) {
    const controller = new AbortController();
    const signal = controller.signal;
    const [targetdata, setTargetdata] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [emptydata, setemptydata] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    useEffect(() => {
        if (!navigator.onLine) {
            alert("Your internet is in Offline")
        } else {
            axios({
                method: methodGet,
                url: displaytargetscreen,
                signal: signal
            }).then(res => {
                if (res.data.data !== undefined) {
                    setTargetdata(res.data.data);
                    setIsloading(true);
                    setdummystate(false)
                } else {
                    setIsloading(true)
                    setemptydata(true)
                }
            }).catch(err => {
                console.log(err)
            });
        }
        return () => controller.abort();
    }, [dummystate]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box sx={{ my: 3, borderRadius: 4 }} component={Card}>
                {emptydata && targetdata.length === 0 ? <Nodata /> : (<Box sx={{ p: 2 }} >
                    <TableContainer >
                        <Table>
                            <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                                <TableRow >
                                    {
                                        targettableheadings.map((hd) =>
                                            <TableCell key={hd.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                <Box sx={{ display: 'flex',justifyContent:'center',alignItems:'center' }}>
                                                    <Box>
                                                        {hd.label}
                                                    </Box>
                                                    <Box>
                                                        {hd.filter ? <Filter label={hd.label} setSearch={setSearch} /> : ''}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    targetdata.filter((data) => filterData(data, search, {
                                        searchFeildOne: data.sales_person_name,
                                        searchFeildTwo: data.locations
                                    })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                                        <TableRow key={item.id} sx={{ cursor: 'pointer' }}>
                                            <TableCell  key={item.id}>{item.sales_person_type}</TableCell>
                                            <TableCell >{item.locations}</TableCell>
                                            <TableCell >{item.team}</TableCell>
                                            <TableCell >{item.sales_person_name}</TableCell>
                                            <TableCell >{item.monthly_target}</TableCell>
                                            <TableCell >{item.annual_target}</TableCell>
                                            <TableCell >{item.financial_year}</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={targetdata.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>)}
            </Box>
        )
    }
}

export default Targettable