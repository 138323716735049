import React, { useState } from 'react'
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useLocation } from 'react-router-dom';
import OrderDetails from '../../Comps/OrderDetails';
import { displayProcessFetch, methodPost } from '../../Services/Apivariables';
import Heading from '../../Comps/Heading';
import Statusloader from '../../Comps/Statusloader';
import useFetch from '../../Services/useFetch';

// this imports are comps used in process screen 
import Displayproductiondrawing from '../ManagementDshbrdTabs/Displayproductiondrawing'
import Displaymom from '../ManagementDshbrdTabs/Displaymom';
import Displaystore from '../ManagementDshbrdTabs/DisplayDispatch';
import Displaypurchase from '../ManagementDshbrdTabs/Displaypurchase';
import Displaycnc from '../ManagementDshbrdTabs/Displaycnc';
import Displayhub from '../ManagementDshbrdTabs/Displayhub';
import Displaymachining from '../ManagementDshbrdTabs/Displaymachining';
import Displayimpeller from '../ManagementDshbrdTabs/Displayimpeller';
import Displaycasing from '../ManagementDshbrdTabs/Displaycasing';
import Displayfirstcoat from '../ManagementDshbrdTabs/Displayfirstcoat';
import Displayassembly from '../ManagementDshbrdTabs/Displayassembly';
import Displaytesting from '../ManagementDshbrdTabs/Displaytesting';
import Displayfinalpaint from '../ManagementDshbrdTabs/Displayfinalpaint';
import Displaypacking from '../ManagementDshbrdTabs/Displaypacking';
import Displayaccessories from '../ManagementDshbrdTabs/Displayaccessories';
import DisplayDispatch from '../ManagementDshbrdTabs/DisplayDispatch';

function TabListPanel() {
    const location = useLocation();
    const [isloading , setIsLoading] = useState(false)
    const access = JSON.parse(localStorage.getItem('access'));
    const { production_view, mom_view, store_view, purchase_view, cnc_view, hub_view, machining_view, impeller_view, casing_view, first_paint_view, assembly_view, testing_view, final_paint_view, packing_view, accessories_view } = access;
    // this condition is for inital tab index where user based view
    const i = (production_view === 'true' && ('1')) || (mom_view === 'true' && ('2')) || (store_view === 'true' && ('3')) || (purchase_view === 'true' && ('4')) || (cnc_view === 'true' && ('5')) || (hub_view === 'true' && ('6')) || (machining_view === 'true' && ('7')) || (impeller_view === 'true' && ('8')) || (casing_view === 'true' && ('9')) || (first_paint_view === 'true' && ('10')) || (assembly_view === 'true' && ('11')) || (testing_view === 'true' && ('12')) || (final_paint_view === 'true' && ('13')) || (packing_view === 'true' && ('14')) || (accessories_view === 'true' && ('15'))
    const [value, setValue] = useState(i);
    // Tab functionality
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [data , setData] = useState([]);

        return (
            <Box variant='div' sx={{ p: 3 }}>
                <Box>
                    <Box sx={{ py: 3 }}>
                        <TabContext value={value}>
                            <Box>
                                <TabList
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    onChange={handleChange} aria-label="tabs">
                                    {production_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Production Drawing " value="1" />)}
                                    {mom_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Mom" value="2" />)}
                                    {cnc_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="CNC" value="3" />)}
                                    {hub_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="HUB" value="4" />)}
                                    {machining_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Machining" value="5" />)}
                                    {impeller_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Impeller" value="6" />)}
                                    {casing_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Casing" value="7" />)}
                                    {first_paint_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="First Coat Paint" value="8" />)}
                                    {assembly_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Assembly" value="9" />)}
                                    {testing_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Testing" value="10" />)}
                                    {final_paint_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Final Paint" value="11" />)}
                                    {packing_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Packing" value="12" />)}
                                    {packing_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Dispatch" value="13" />)}
                                    {accessories_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Accessories" value="14" />)}
                                </TabList>
                            </Box>
                            {production_view === 'true' && (<TabPanel value="1">
                                <Box>
                                    <Displayproductiondrawing data={data} title={'Production and Drawing'} />
                                </Box>
                            </TabPanel>)}
                            {mom_view === 'true' && (<TabPanel value="2">
                                <Box>
                                    <Displaymom data={data} title={'MOM'} />
                                </Box>
                            </TabPanel>)}
                            {cnc_view === 'true' && (<TabPanel value="3">
                                <Box>
                                    <Displaycnc data={data} title={'CNC'} />
                                </Box>
                            </TabPanel>)}
                            {hub_view === 'true' && (<TabPanel value="4">
                                <Box>
                                    <Displayhub data={data} title={'Hub'} />
                                </Box>
                            </TabPanel>)}
                            {machining_view === 'true' && (<TabPanel value="5">
                                <Box>
                                    <Displaymachining data={data} title={'Machining'} />
                                </Box>
                            </TabPanel>)}
                            {impeller_view === 'true' && (<TabPanel value="6">
                                <Box>
                                    <Displayimpeller data={data} title={'Impeller'} />
                                </Box>
                            </TabPanel>)}
                            {casing_view === 'true' && (<TabPanel value="7">
                                <Box>
                                    <Displaycasing data={data} title={'Casing'} />
                                </Box>
                            </TabPanel>)}
                            {first_paint_view === 'true' && (<TabPanel value="8">
                                <Box>
                                    <Displayfirstcoat data={data} title={'First Coat Paint'} />
                                </Box>
                            </TabPanel>)}
                            {assembly_view === 'true' && (<TabPanel value="9">
                                <Box>
                                    <Displayassembly data={data} title={'Assembly'} />
                                </Box>
                            </TabPanel>)}
                            {testing_view === 'true' && (<TabPanel value="10">
                                <Box>
                                    <Displaytesting data={data} title={'Testing'} />
                                </Box>
                            </TabPanel>)}
                            {final_paint_view === 'true' && (<TabPanel value="11">
                                <Box>
                                    <Displayfinalpaint data={data} title={'Final Paint'} />
                                </Box>
                            </TabPanel>)}
                            {packing_view === 'true' && (<TabPanel value="12">
                                <Box>
                                    <Displaypacking data={data} title={'Packing'} />
                                </Box>
                            </TabPanel>)}
                            {packing_view === 'true' && (<TabPanel value="13">
                            <Box>
                            <DisplayDispatch data={data} title={'Dispatch'} />
                            </Box>
                            </TabPanel>)}
                            {accessories_view === 'true' && (<TabPanel value="14">
                                <Box>
                                    <Displayaccessories data={data} title={'Accessories'} />
                                </Box>
                            </TabPanel>)}
                        </TabContext>
                    </Box>
                </Box>
            </Box>
        )
    }

export default TabListPanel