import React from 'react'
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Heading from './../Comps/Heading';
import Statusloader from './../Comps/Statusloader';
import StoreScreenLayoutHeader from '../Comps/StoreScreenLayoutHeader';
import StoreScreenQRCodeTable from '../Comps/BarCodeModule/StoreScreenQRCodeTable';

function StoreScreenlayoutSingle() {
    const location = useLocation();
    const StoreScreenLayoutData = location.state; 
  

        return (
                <Box variant='div' sx={{ p: 3 }}>
                    <Box sx={{ py: 3 }}>
                        <Heading title={'STORE QR CODES'} />
                    </Box>
                    <Box sx={{ py: 3 }}>
                        <StoreScreenLayoutHeader data={StoreScreenLayoutData} />
                    </Box>
                    <Box>
                        <StoreScreenQRCodeTable  data={StoreScreenLayoutData} />
                    </Box>                  
                </Box>

        )
    }


export default StoreScreenlayoutSingle
