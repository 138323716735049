import TextField from '@mui/material/TextField';
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { Box, Button } from '@mui/material';
import {allMomFetch, getmonthlyjob, monthlyjobreport}  from '../Services/Apivariables';
import useFetch from '../Services/useFetch';
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, TablePagination, Card } from '@mui/material';
import Filter from '../Comps/Filter';
import {exportjonbreporttoexcel, jobreportscreenheading, jobreportscreenheadingnonadmin} from '../Comps/Variables/Variables';
import { nullFinderandDateswapper } from '../Comps/Variables/Variables';
import Heading from '../Comps/Heading';
import axios from 'axios';
import { ExportCSV } from './../Comps/ExportCSV';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import SnackBar from "../Comps/SnackBar";
import { filterJobReportScreen, filterJobScreen } from '../Comps/FilterData';
import Statusloader from '../Comps/Statusloader';

export default function JobReportScreen() {
    const roles = JSON.parse(localStorage.getItem('roles'));
    const role = "Super Admin" || "Admin" ;
    const center = 'center';
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [page, setPage] = useState(0);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const {data , isloading} = useFetch(getmonthlyjob,'GET');


    const [jobReport , setJobReport]=useState([]);
    const[filterColor , setFilterColor] = useState(false);

    // From Date Conversion
    const date = new Date(from);
    let m ;
    m = date.getMonth()+1;
      if (m < 10) {
        m = '0' + m;
      }
    const fromDate = [date.getFullYear(),(m)].join('-');

    // To Date Conversion
    const dates = new Date(to);
    let mo ;
    mo = dates.getMonth()+1;
      if (mo < 10) {
        mo = '0' + mo;
      }
    const toDate = [dates.getFullYear(),(mo)].join('-');


//    page per row in Table
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onSubmit = () => {
        if(fromDate !== null && toDate !== null){
        setFilterColor(true);
        const subid = new FormData()
        subid.append("fromDate", fromDate)
        subid.append("toDate", toDate)
        axios({
          method: "POST",
          url: monthlyjobreport,
          data: subid,
      }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setJobReport(res.data.data)
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
            }
        }).catch(err => {
            console.log(err)
        });    
    }
    else {
        setMessage("Please Select From and To Dates");
    } 
      }
    
  const resetClick = () =>{
    setFrom(null);
    setTo(null);
    setFilterColor(false)
    setJobReport([]);
  }

    const headingLabel = roles === role ? jobreportscreenheading : jobreportscreenheadingnonadmin ;

  const exporttoExcel = exportjonbreporttoexcel(jobReport); 

 const filtered = data.filter((data) => filterJobReportScreen(data, search, {
    searchFeild1: data.OrderNumber,
    searchFeild2: data.JobNumber,
    searchFeild3: data.FanModel,                                 
 }));

 if (!isloading) {
    return (
        <Statusloader />
    )
} else {

  return (
    <Box variant="div" sx={{ p: 3 }}>
         <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        color={color}
        status={status}
      />
    <Box sx={{ py: 3 }} display='flex' justifyContent='space-between'>
        <Heading title={'Job Report'} />
        {
            jobReport.length !== 0 && <ExportCSV csvData={exporttoExcel} fileName={'nadi_store_report'} />
        }
    </Box>
    <Box variant="div" sx={{ py: 3 }}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
            views={['year', 'month']}
            openTo='year'
            label="FROM"
            value={from}
            onChange={(newValue) => {
            setFrom(new Date(newValue));
            }}
            renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps, readOnly: true}} className="textfield" size='small' sx={{width:200, marginRight:2}} helperText={null}/>}
        /> <DatePicker
        views={['year', 'month']}
        label="TO"
        openTo='year'
        value={to}
        onChange={(newValue) => {
            setTo(new Date(newValue));
        }}
        renderInput={(params) => <TextField {...params}  size='small' inputProps={{...params.inputProps, readOnly: true}} sx={{width:200, marginRight:2}} helperText={null} />}
        />
  </LocalizationProvider>
  <Button onClick={onSubmit}> {from !== null && to !== null ?<FilterAltIcon size='small' sx={{ color : filterColor ? '#259D69' : 'primary'}} /> : <FilterAltOffIcon size='small' />}</Button>
  <Button onClick={resetClick}><RestartAltIcon size='small' sx={{marginRight:3}} /></Button>
  </Box>

 {/* Table */}

 <Box sx={{ p: 1, borderRadius: 4 }} component={Card}>
                <TableContainer sx={{ borderRadius: 2 }} >
                    <Table>
                        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                            <TableRow >
                                {
                                  headingLabel.map((hd) =>
                                        <TableCell key={hd.id} sx={{
                                            color: 'white', fontWeight: 600, textAlign: 'center'
                                        }}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    {hd.label}
                                                </Box>
                                                <Box>
                                                {hd.filter ? <Filter  label={hd.label}  setSearch={setSearch} /> : ''}
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                
                                filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                                    <TableRow hover key={item.storeId} sx={{ cursor: 'pointer' }}>
                                       <TableCell sx={{ textAlign: center }}>{item.OrderNumber}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.JobNumber}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.BillPlanned)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.YearPlanne)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.TotalWeight}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.OrderDate)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.JobReleaseDate)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.OrderQuantity}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.SalesRepName}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.CustomerName}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.CustomerCity}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.CustomerState}</TableCell>
                                        {/* {
                                            roles ===  role ?
                                            <>
                                        <TableCell sx={{ textAlign: center }}>{item.CustomerContactPhone}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.CustomerContactCellPhone}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.CustomerMail}</TableCell>
                                            </>
                                            :<></>
                                        } */}

                                        <TableCell sx={{ textAlign: center }}>{item.CustomerType}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.BillingGroup}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.PODate)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.PONumber}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.PartNumber}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.PartDescription}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.NeedBy)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.ShipBy)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.POCleared)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.MotorScope}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.PaymentTerms}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.MotorDriveType}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.FanModel}</TableCell>
                                        {
                                            roles === role ?
                                                <>
                                                    <TableCell sx={{ textAlign: center }}>{item.UnitPrice}</TableCell>
                                                    <TableCell sx={{ textAlign: center }}>{item.Discount}</TableCell>
                                                    <TableCell sx={{ textAlign: center }}>{item.TotalValue}</TableCell>
                                                </> :
                                                <></>
                                        }
                                        
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={search ==="" ? data.length : filtered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>

  </Box>
  )
}
}