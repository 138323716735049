import React from 'react'
import { Box } from '@mui/system';
import Heading from '../Comps/Heading';
import { getRegister, methodGet } from '../Services/Apivariables';
import useFetch from '../Services/useFetch';
import Registertable from '../Comps/Registertable';
import Statusloader from '../Comps/Statusloader';
import Nodata from '../Comps/Nodata';

function Allregisteredusers() {
    const {data, isloading, emptydata} = useFetch(getRegister, methodGet);
    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box sx={{ p: 3 }}>
                <Box sx={{ py: 3 }}>
                    <Heading title={'All Registered Users'} />
                </Box>
               {emptydata && data.length === 0 ? <Nodata /> : ( <Box sx={{ py: 3 }}>
                    <Registertable data={data} subpage={`editregistereduser`} />
                </Box>)}
            </Box>
        )
    }
}

export default Allregisteredusers