import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Card } from '@mui/material';
import { loopQrcode, singlestockhead } from '../Variables/Variables';
import Heading from '../Heading';
import Filter from '../Filter';
import { filterJobScreen } from '../FilterData';
import { useState } from 'react';
import { getsinglestock, methodPost } from '../../Services/Apivariables';
import useFetch from '../../Services/useFetch';
import Statusloader from '../Statusloader';
import Nodata from '../Nodata';
// import { ExportCSV } from './../Comps/ExportCSV';
import PrintmultiQr from '../StockQrCode/PrintmultiQr';




function SingleStock() {

    const location = useLocation();
    const stockid = location.state.id;
    const stock_id = new FormData();
    stock_id.append("id", stockid);
    const { data, isloading, emptydata } = useFetch(getsinglestock, methodPost,stock_id );
    // const navigate = useNavigate();
    const center = 'center';
    const justify = 'justify';
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const stockdata = loopQrcode(data);
    // this variable is for export execl label custome structure
    // const exportdata = exportdataExeclJob(data);
    const navigate = useNavigate();

    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box variant="div" sx={{ p: 3 }}>
                <Box sx={{ py: 3 }}>
                    <Heading title={'Single Stock'} />
                </Box>
                <Box sx={{ py: 3 }}>
                    <Box>
                        {emptydata && data.length === 0 ? <Nodata /> : <>
                            <Box sx={{ py: 2 }}>
                            <PrintmultiQr QrcodeData={stockdata} />
                            </Box>
                            <Box sx={{ p: 1, borderRadius: 4 }} component={Card}>
                                <TableContainer sx={{ borderRadius: 2 }} >
                                    <Table>
                                        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                                            <TableRow >
                                                {
                                                    singlestockhead.map((hd) =>
                                                        <TableCell key={hd.id} sx={{
                                                            px: 3,
                                                            color: 'white', fontWeight: 600
                                                        }}>
                                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Box sx={{ my: 'auto' }}>
                                                                    {hd.heading}
                                                                </Box>
                                                                <Box>
                                                                    {hd.filter ? <Filter search={search} label={hd.heading} setSearch={setSearch} /> : ''}
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                stockdata.filter((data) => filterJobScreen(data, search, {
                                                    searchFeildOne: data.part_no,
                                                    searchFeildTwo: data.partdescription,
                                                    searchFeildThree: data.fanmodel
                                                })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((stock) =>
                                                    <TableRow hover key={stock.id}>
                                                        <TableCell sx={{ textAlign: center }}>{stock.part_no}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.partdescription}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.fanmodel_no}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.qty}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.material}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.weight}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.location}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.rackwise}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.location_rackno}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.dia}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.stockno}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.customer}</TableCell>
                                                        {/* <TableCell sx={{ textAlign: center }}>
                                                <GenerateQrcode QrCodeValue={{partno:stock.part_no,partdescription:stock.partdescription,fanmodel:stock.fanmodel,qty:stock.qty,material:stock.material,weight:stock.weight,stockno:stock.stockno}}  QrCode={'Location - ' + stock.location+ '\nRack Wise - ' + stock.rackwise +'\nLocation (Rack No) - '+ stock.location_rackno +'\nDIA - '+ stock.dia}/>
                                                </TableCell> */}
                                                </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={stockdata.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box></>}
                    </Box>
                </Box>
                <Outlet />
            </Box>
        )
    }
}

export default SingleStock