import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Heading from '../Comps/Heading';
import { Button, Card, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { trackstatus } from '../Services/Apivariables';
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody,} from '@mui/material';
import VerticalStepper from '../Comps/VerticalStepper/VerticalStepper';


export default function TrackStatus() {
    
  const {register, formState: { errors }, handleSubmit, reset} = useForm();

    const [dataOne, setDataOne] = useState([]);
    const [jobData, setJobData] = useState([]);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [message, setMessage] = useState("");
    const [errorData , setErrorData]  = useState(true);
    const [job, setJob]= useState(null);
    const center = 'center';

    const onSubmit = (data)=>{
      setJob(data.JobNumber);
      const sendData = new FormData();
      sendData.append('jobNum', data.JobNumber);

      axios({
        method:'POST',
        url:trackstatus,
        data:sendData,
      }).then(res => {
        if (res.data.error) {
          setMessage(res.data.message);
          setDataOne([]);
          setJobData(res.data.jobDetails);
      } else {
        setDataOne(res.data.data);
        setJobData(res.data.jobDetails);
        setOpen(true)
        setMessage(res.data.message)
        setErrorData(res.data.error)
        setStatus(true)
        setColor(true)
      }
      }).catch(err => {
        console.log(err)
      });
    }


    // const filteredArray = dataOne.length !==0 ? dataOne.filter(function(obj) {
    //   return obj.isCompleted === "true";
    //   }) : [];
    
    
    // const totalCompletion = filteredArray.length;
      
    // const sortedArray = dataOne.length !==0  ? dataOne.sort(function(a, b) {
    //     if (a.isCompleted === "true" && b.isCompleted === "false") {
    //         return -1;
    //     } else if (a.isCompleted === "false" && b.isCompleted === "true") {
    //         return 1;
    //     } else {
    //         return 0;
    //     }
    //   }) : [];
    
 console.log(job);
console.log(jobData);
  return (
    <Box variant="div" sx={{ p: 3 }}>
    <Box sx={{ py: 3 }}>
    <Heading title={'Track Work Status'} />
    </Box>
    <Box py={2} component='form'>
    <TextField
    required
    error={errors.JobNumber ? true : false}
    helperText={errors.JobNumber ? "Job Number is required" : ""}
    {...register("JobNumber", { required: true })} 
    size='small'
    placeholder='Track Job Number'
    label="Job Number"
    variant="outlined" />
    <Button onClick={handleSubmit(onSubmit)} variant='contained' sx={{marginLeft:2}} >Submit</Button>
    </Box>
    <Box component={Card} p={2} boxShadow={5}>
      {
        job !== null ?
         <Box py={3}>
          <TableContainer sx={{ borderRadius: 2 }} >
            <Table sx={{ borderRadius: 2 }}>
            <TableHead sx={{ whiteSpace: 'nowrap' }}>
            <TableRow>
                <TableCell sx={{fontWeight:600,textAlign: center }}>Job Number</TableCell>
                <TableCell sx={{fontWeight:600,textAlign: center }}>Order Date</TableCell>
                <TableCell sx={{fontWeight:600,textAlign: center }}>Customer Name</TableCell>
                <TableCell sx={{fontWeight:600,textAlign: center }}>Fan Model</TableCell>
                <TableCell sx={{fontWeight:600,textAlign: center }}>Qty</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
            {
              [jobData].map((item, index)=>{
                return(
              <TableRow key={index}>
                  <TableCell sx={{ textAlign: center }}>{item.jobNum}</TableCell>
                   <TableCell sx={{ textAlign: center }}>{item.OrderDate}</TableCell>
                  <TableCell sx={{ textAlign: center }}>{item.CustomerName}</TableCell>
                  <TableCell sx={{ textAlign: center }}>{item.FanModel}</TableCell>
                  <TableCell sx={{ textAlign: center }}>{item.OrderQty}</TableCell>
              </TableRow>
                )
              })
            }
             </TableBody>
                  </Table>
              </TableContainer>
          </Box>
            :
            <></>
      }


      {
        dataOne.length === 0 ?
        <Box p={2}>
         <Typography fontWeight={600} variant='h5'>{message}</Typography>
        </Box> :
        <Box p={2}>
            <Box py={2}>
            <Typography variant='h6'
             sx={{ textAlign: 'left', fontWeight: 600, fontSize: 20, textTransform: 'uppercase', color: 'primary.main' }}>Status</Typography>
            </Box>   
          <Box>
          <VerticalStepper data={dataOne} />
           </Box>
          </Box>
      }

      </Box>
    </Box>
  );
}