import React from 'react'
import { Box } from '@mui/material';


function Statusloader() {
  return (
    // <Backdrop
    //   sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //   open={true}>
    //   <Box sx={{ textAlign: 'center' }}>
    //     <img src={require('../Assets/nadiloader.gif')} className='w-25 h-25' alt="loading..." />
    //   </Box>
    // </Backdrop>
    <Box sx={{ display:'flex',justifyContent:'center',alignItems:'center',height:'80vh',zIndex: (theme) => theme.zIndex.drawer + 1  }}>
         <img src={require('../Assets/nadiloader.gif')} width='150' height='150' alt="loading..." />
      </Box>
  )
}

export default Statusloader