import { Box, Button } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { fetchsubcontractorjobs } from '../Services/Apivariables';
import { appendData } from "./Variables/ProcessVariables";
import SnackBar from './SnackBar';

function ChipInputTable(props){
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");

    const {handleSubmit,} = useForm();

    const [tags, setTags] = useState([])

    function handleKeyDown(e){
        if(e.key !== 'Enter') return
        const value = e.target.value
        if(!value.trim()) return
        setTags([...tags, value])
        e.target.value = ''      
    }

    function removeTag(index){
        setTags(tags.filter((el, i) => i !== index))
    }

    props.sendTag(tags);

//   const selectedjobs = tags.map((tags, i) => {
//     const obj = {
//         i: i,
//         order_num: tags
       
//     }
//     return obj
// }) 


// const serverData = {
//     "order_num": JSON.stringify(selectedjobs),
//     "work_type": work
// }
//   const deptData = appendData(serverData);
//   const onSubmit = () => {
//     // const serverData = new FormData();
//     // for (var key in data) {
//     //   serverData.append(key, data[key]);
//     // }
//     if (!navigator.onLine) {
//       setMessage("Your internet is in Offline");
//       setOpen(true);
//       setStatus(false);
//       setColor(false);
//     } else {
//       axios({
//         method: 'POST',
//         url: fetchsubcontractorjobs,
//         data: deptData,
//       })
//         .then((res) => {
//           console.log(res);
//           if (res.data.error) {
//             setMessage(res.data.message);
//             setOpen(true);
//             setStatus(false);
//             setColor(false);
//           } else {
//             setMessage(res.data.message);
//             setOpen(true);
//             setStatus(true);
//             setColor(true);
//             // setrefreshpage(true);
//           }
//         })
//         .catch((err) => {
//           alert("Oops something went wrong " + err);
//         });
//     }
//   };




    return (
        
        <Box>
        <div className="tags-input-container">
            { tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                    <span className="text">{tag}</span>
                    <span className="close" onClick={() => removeTag(index)}>&times;</span>
                </div>
            )) }
            <input onKeyDown={handleKeyDown} type="text" className="tags-input" placeholder="Enter Order Num" />
        </div> 
 
        </Box>
    )
}

export default ChipInputTable