import React from 'react'
import { useState } from 'react';
import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { filterJobScreen } from './../FilterData';
import Filter from './../Filter';
import { showProductionDrawingTableHeading } from '../Variables/ProcessVariables';
import Heading from './../Heading';
import Statusbar from './../Statusbar';
import { nullFinderandDateswapper } from '../Variables/Variables';
import Nodata from '../Nodata';




function Displayproductiondrawing({ data, title }) {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <Box>
      <Box sx={{ py: 3 }}>
        <Heading title={title} />
      </Box>
      <Box sx={{ py: 3 }}>
        {data.length === 0 ? <Nodata /> : (
          <Box sx={{ p: 2 }} component={Card}>
            <TableContainer >
              <Table>
                <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                  <TableRow >
                    {
                      showProductionDrawingTableHeading.map((headings) =>
                        <TableCell key={headings.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                          <Box sx={{ display: 'flex' }}>
                            <Box sx={{ my: 'auto' }}>
                              {headings.label}
                            </Box>
                            <Box>
                              {headings.filter ? <Filter label={headings.label} setSearch={setSearch} /> : ''}
                            </Box>
                          </Box>
                        </TableCell>
                      )
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    data.sort((a, b) => (a.production_status > b.production_status) ? 1 : -1).filter((data) => filterJobScreen(data, search, {
                      searchFeildOne: data.job_num_1,
                      searchFeildTwo: data.prod_code,
                      searchFeildThree: data.short_char_01
                    })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) =>
                      <TableRow key={items.productionid}>
                        <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{items.serial_no}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{items.job_num_1}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                        {/* <TableCell sx={{ textAlign: 'center' }}>
                        <Box>
                          <Statusbar label={items.production_status === 'true' ? 'Completed' : 'Pending'} color={items.production_status === 'true' ? 'success' : 'error'} />
                        </Box>
                      </TableCell> */}
                        <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.production_drawing_edd)}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.production_dg_committed_date)}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {nullFinderandDateswapper(items.bom_committed_date)}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {items.production_remarks}
                        </TableCell>
                        {/* <TableCell sx={{ textAlign: 'center' }}>
                        {nullFinderandDateswapper(items.production_revised_date)}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {items.production_revised_remarks}
                      </TableCell> */}
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Displayproductiondrawing