import React from 'react'
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Heading from './../Comps/Heading';
import StockIssueScreenLayoutHeader from '../Comps/StockIssueScreenLayoutHeader';
import StockIssueScreenLayoutTable from '../Comps/StockIssueScreenLayoutTable';

function StoreScreenlayoutSingle() {
    const location = useLocation();
    const stockScreenLayoutData = location.state; 
  

        return (
                <Box variant='div' sx={{ p: 3 }}>
                    <Box sx={{ py: 3 }}>
                        <Heading title={'Generate Issue Number For Stocks'}/>
                    </Box>
                    <Box sx={{ py: 3 }}>
                        <StockIssueScreenLayoutHeader data={stockScreenLayoutData}/>
                    </Box>
                    <Box>
                        <StockIssueScreenLayoutTable data={stockScreenLayoutData}/>
                    </Box>                  
                </Box>

        )
    }


export default StoreScreenlayoutSingle
