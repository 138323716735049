import React from 'react'
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import {exportPendingStocksScreenDatatoexcel, pendingStocksTableHeadings } from '../Comps/Variables/Variables';
import Heading from './../Comps/Heading';
import {pendingstocks, pendingstockexcel} from '../Services/Apivariables';
import useFetch from './../Services/useFetch';
import Statusloader from './../Comps/Statusloader';
import Nodata from './../Comps/Nodata';
import StocksissueTable from '../Comps/StocksIssueTable';
import { ExportCSV } from '../Comps/ExportCSV';


function PendingStockScreen() {
  
    
    const { data, isloading, emptydata } = useFetch(pendingstocks , 'GET');
    const {dataOne}= useFetch(pendingstockexcel, 'GET')

    const exporttoExcel = exportPendingStocksScreenDatatoexcel(dataOne); 

    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box variant="div" sx={{ p: 3 }}>
                <Box sx={{ py: 3 }}>
                    <Heading title={'Stocks Pending To be issued'} />
                </Box>
                <Box sx={{ py: 1 }}>
                        <ExportCSV csvData={exporttoExcel} fileName={'nadi_pendingstocks_report'} />
                    </Box>
                {emptydata && data.length === 0 ? <Nodata /> : <Box sx={{ py: 3 }}>
                    <StocksissueTable data={data} headingLabels={pendingStocksTableHeadings} subpage={`pendingstocks2`} />
                </Box>}
                <Outlet />
            </Box>
        )
    }
}

export default PendingStockScreen;