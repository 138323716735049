import { Box, Grid, Card ,Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper } from '@mui/material';
import { LocalizationProvider, DateAdapter, MonthPicker  } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker } from '@mui/lab';
import { TextField } from "@mui/material";
import React, { useEffect, useState } from 'react'
import Heading from '../Heading';
import enLocale from "date-fns/locale/en-US";
import { sales_report, sales_report_excel, so_annual_report, so_monthly_excel, so_monthly_report } from '../../Services/Apivariables';
import axios from 'axios';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import {DshbrdTableExportExcel} from '../DshbrdTableExportExcel';
import { DshbrdTableReportExport, DshbrdTableReportSalesExport } from '../Variables/Variables';
import { appendData } from '../Variables/ProcessVariables';
import { DshbrdSaleTableExportExcel } from '../DshbrdSaleTableExportExcel';

export default function MonthlyReportTable() {

  const [selectedDate, setSelectedDate] = useState(null);
  const [financialYear, setFinancialYear] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [data , setData] = useState([]);
  const [trunOnFilter , setTurnOnFilter] =useState(false);
  const [DomasticReport, setDomasticReport] = useState([]);
  const [AlstomReport, setAlstomReport] = useState([]);
  const [WabtecIndiaReport, setWabtecIndiaReport] = useState([]);
  const [WabtecUSAReport, setWabtecUSAReport] = useState([]);
  const [ExportReport, setExportReport] = useState([]);
  const [DomasticSalesReport, setDomasticSalesReport] = useState([]);
  const [AlstomSalesReport, setAlstomSalesReport] = useState([]);
  const [WabtecIndiaSalesReport, setWabtecIndiaSalesReport] = useState([]);
  const [WabtecUSASalesReport, setWabtecUSASalesReport] = useState([]);
  const [ExportSalesReport, setExportSalesReport] = useState([]);

  const currentDate = new Date();

  const year = currentDate.getFullYear(); // Get the current year (e.g., 2023)
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Get the current month (0-based, so add 1) and pad with '0' if needed
  const day = String(currentDate.getDate()).padStart(2, '0'); // Get the current day of the month and pad with '0' if needed

  const formattedDate = `${year}-${month}-${day}`;

  const [dateData, setDateData] = useState({
    'fromDate': "",
    'toDate': "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDateData({ ...dateData, [name]: value })
  }

  useEffect(() => {
    const sendData = {
      "fromDate": dateData.fromDate,
      'toDate': dateData.toDate
    }
    const lData = appendData(sendData)

     axios({
      method: 'POST',
       url: sales_report,
      data: lData,
  }).then(res => {
      if (res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
      } else {
          setOpen(true)
          setMessage(res.data.message)
          setStatus(true)
          setColor(true)
          setData(res.data.data)
      }
  }).catch(err => {
      console.log(err)
  });
  }, [])
  


  const handleFilter = () =>{
     const sendData = {
       "fromDate": dateData.fromDate,
       'toDate': dateData.toDate
     }
     const lData = appendData(sendData)

     axios({
       method: 'POST',
       url: sales_report,
       data: lData,
  }).then(res => {
      if (res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
      } else {
          setOpen(true)
          setMessage(res.data.message)
          setStatus(true)
          setColor(true)
          setData(res.data.data)
          getReport();
          setTurnOnFilter(!trunOnFilter)
      }
  }).catch(err => {
      console.log(err)
  });
  }
  
  const handleReset = () =>{
    const sendData = {
      "fromDate": dateData.fromDate,
      'toDate': dateData.toDate
    }
    const lData = appendData(sendData)

    axios({
      method: 'POST',
      url: sales_report,
      data: lData,
  }).then(res => {
      if (res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
      } else {
          setOpen(true)
          setMessage(res.data.message)
          setStatus(true)
          setColor(true)
          setData(res.data.data)
          setTurnOnFilter(!trunOnFilter)
          getReport();
          setDateData({
            'fromDate': "",
            'toDate': "",
        })
      }
  }).catch(err => {
      console.log(err)
  });
  }
  
 const getReport = () =>{
   const sendData = {
     "fromDate": dateData.fromDate,
     'toDate': dateData.toDate
   }
   const lData = appendData(sendData)

   axios({
     method: 'POST',
     url: sales_report_excel,
     data: lData,
    }).then(res => {
      if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
      } else {
        setOpen(true)
        setMessage(res.data.message)
        setStatus(true)
        setColor(true)
        setDomasticReport(res.data.data.Domestic)
        setAlstomReport(res.data.data.Alstom)
        setWabtecIndiaReport(res.data.data.WabtecIndia)
        setWabtecUSAReport(res.data.data.WabtecUSA)
        setExportReport(res.data.data.Export)
        setDomasticSalesReport(res.data.data.Miscellaneous)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  useEffect(()=>{
   getReport();
  },[])

  const ExportDomestic = DshbrdTableReportSalesExport(DomasticReport)
  const ExportAlstom = DshbrdTableReportSalesExport(AlstomReport)
  const ExportWabtecIndia = DshbrdTableReportSalesExport(WabtecIndiaReport)
  const ExportWabtecUSA = DshbrdTableReportSalesExport(WabtecUSAReport)
  const Exportexport = DshbrdTableReportSalesExport(ExportReport)
  const ExportDomestic_Sale = DshbrdTableReportSalesExport(DomasticSalesReport)


  return (
    <Box>
     <Box sx={{ py: 3 }}>
    <Heading title={'Sale Order Report'} />
     </Box>
      <Box display='flex' justifyContent='space-between'>

    <Box py={2}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box display='flex' gap={3}>
              <TextField
                onChange={handleChange}
                sx={{ width: 180, marginRight: 1 }}
                required
                value={dateData.fromDate}
                type='date'
                name='fromDate'
                size="small"
                label="From Date"
                id="outlined-size-small"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                required
                onChange={handleChange}
                sx={{ width: 180 }}
                type='date'
                value={dateData.toDate}
                name='toDate'
                size="small"
                label="To Date"
                id="outlined-size-small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box>
                {
                 !trunOnFilter ?  
                 <FilterAltOffIcon fontSize='large' onClick={handleFilter} sx={{verticalAlign:'middle',color:'#616e80'}} />
                 :
                 <Box display='flex' gap={3}>
                  <FilterAltIcon fontSize='large' sx={{verticalAlign:'middle', color:'success.main'}} />
                  <RefreshIcon fontSize='large'  onClick={handleReset} sx={{verticalAlign:'middle',color:'primary.main'}} />
                </Box>
                 

                }
              </Box>
      </Box>
    </LocalizationProvider>

    </Box>

        <Box display='flex' justifyContent='end' sx={{ py: 2 }}>
          <DshbrdSaleTableExportExcel
            Domestic={ExportDomestic}
            Alstom={ExportAlstom}
            WabtecIndia={ExportWabtecIndia}
            WabtecUSA={ExportWabtecUSA}
            Export={Exportexport}
            Domestic_Sale={ExportDomestic_Sale}
            fileName={'Sale Order Report'} />
        </Box>
      </Box>
   <TableContainer component={Paper} sx={{boxShadow:4}}>
      <Table id='monthTable'>
         <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
          <TableRow sx={{ '& > *': { width: '2%' } }}> 
            <TableCell align='center' sx={{ fontWeight: 600, color: 'white' , fontSize:13 }}>Order Category</TableCell>
            <TableCell align='center' sx={{ fontWeight: 600, color: 'white', fontSize:13 }}>Order Type</TableCell>
              <TableCell align='center' sx={{ fontWeight: 600, color: 'white', fontSize: 13 }}>Total Qty (Nos)</TableCell>
              <TableCell align='center' sx={{ fontWeight: 600, color: 'white', fontSize: 13 }}>Order Released (INR)</TableCell>
         </TableRow>
        </TableHead>

          <TableBody>
            {Object.keys(data).map((orderCategory) => {
              let rowSpanTotal;
              if (orderCategory === 'Grand Total' || orderCategory === 'Miscellaneous') {
                rowSpanTotal = 2;
              } else {
                rowSpanTotal = 5;
              }
              return(
              <>
                <TableRow>
                  <TableCell
                      rowSpan={rowSpanTotal} align='center' sx={{ fontWeight: 700, fontSize: 17 }}>{orderCategory}</TableCell>
                </TableRow>
                {Object.keys(data[orderCategory]).map((orderType) => (
                  <TableRow>
                    <TableCell align='left' sx={{ fontWeight: orderType === 'Domestic Total' || orderType === 'Miscellaneous Total' || orderType === 'Alstom Total' || orderType === 'Wabtec USA Total' || orderType === 'Wabtec India Total' || orderType === 'Export Total' || orderType === 'Total' ? 600 : 200 }}>{orderType}</TableCell>
                    <TableCell align='center' sx={{ fontWeight: orderType === 'Domestic Total' || orderType === 'Miscellaneous Total' || orderType === 'Alstom Total' || orderType === 'Wabtec USA Total' || orderType === 'Wabtec India Total' || orderType === 'Export Total' || orderType === 'Total' ? 600 : 200 }}>{data[orderCategory][orderType].SaleOrderQty}</TableCell>
                    <TableCell align='center' sx={{ fontWeight: orderType === 'Domestic Total' || orderType === 'Miscellaneous Total' || orderType === 'Alstom Total' || orderType === 'Wabtec USA Total' || orderType === 'Wabtec India Total' || orderType === 'Export Total' || orderType === 'Total' ? 600 : 200 }}>{Number(data[orderCategory][orderType].SaleOrderValue).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}</TableCell>
                  </TableRow>
                ))}
              </>
            )})}
          </TableBody>
      </Table>
    </TableContainer>
    </Box>
  )
}
