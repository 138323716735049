import React, { createContext, useState } from 'react'
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useLocation } from 'react-router-dom';
import OrderDetails from '../Comps/OrderDetails';
import useFetch from '../Services/useFetch';
import { methodPost, otherScreenSingleOrders } from '../Services/Apivariables';
import Heading from './../Comps/Heading';

// this imports are comps used in process screen 
import Statusloader from './../Comps/Statusloader';
import Nodata from './../Comps/Nodata';
import ProductionDrawing from '../Comps/ProcessScreenModule/ProductionDrawing';
import Mom from '../Comps/ProcessScreenModule/Mom';
import Store from './../Comps/ProcessScreenModule/Store';
import Purchase from './../Comps/ProcessScreenModule/Purchase';
import Cnc from './../Comps/ProcessScreenModule/Cnc';
import Hub from './../Comps/ProcessScreenModule/Hub';
import Machining from './../Comps/ProcessScreenModule/Machining';
import Impeller from './../Comps/ProcessScreenModule/Impeller';
import Casing from './../Comps/ProcessScreenModule/Casing';
import FirstCoatPaint from './../Comps/ProcessScreenModule/FirstCoatPaint';
import Assembly from './../Comps/ProcessScreenModule/Assembly';
import Testing from './../Comps/ProcessScreenModule/Testing';
import FinalPaint from './../Comps/ProcessScreenModule/FinalPaint';
import Packinganddispatching from './../Comps/ProcessScreenModule/Packinganddispatching';
import Accessories from './../Comps/ProcessScreenModule/Accessories';
import axios from 'axios';
import { useEffect } from 'react';

export const bomdataContext = createContext()

function OtherScreenlayoutSingle() {
    const location = useLocation();
    const orderData = location.state; 
    const job_num = location.state.job_num
    const order_num = new FormData();
    order_num.append("job_num", job_num);
    const[data , setData] = useState([]);
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [refreshpage, setrefreshpage] = useState(false);
    const [emptydata, setemptydata] = useState(false);
    const [isloading, setIsloading] = useState(false);
    
    // const { data, isloading, emptydata, cncscreen, hubscreen, machiningscreen, impellerscreen, accessoriesscreen, storebomscreen, purchasebomscreen, barCodeData, setrefreshpage } = useFetch(otherScreenSingleOrders, methodPost, order_num);
    const access = JSON.parse(localStorage.getItem('access'));
    const { production, mom, store, purchase, cnc, hub, machining, impeller, casing, first_paint, assembly, testing, final_paint, packing, accessories } = access;

    // this condition is for inital tab index where user based view
    const i = (production === 'true' && ('1')) || (mom === 'true' && ('2')) || (store === 'true' && ('3')) || (purchase === 'true' && ('4')) || (cnc === 'true' && ('5')) || (hub === 'true' && ('6')) || (machining === 'true' && ('7')) || (impeller === 'true' && ('8')) || (casing === 'true' && ('9')) || (first_paint === 'true' && ('10')) || (assembly === 'true' && ('11')) || (testing === 'true' && ('12')) || (final_paint === 'true' && ('13')) || (packing === 'true' && ('14')) || (accessories === 'true' && ('15'))

    const [value, setValue] = useState(i);

    // Tab functionality
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
     

    const fetchCommonFirstTable = () =>{
        const sendData = new FormData();
        sendData.append('job_num', job_num)
        sendData.append('department', '')
        axios({
            method: 'POST',
            url: otherScreenSingleOrders,
            data: sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setrefreshpage(false)
                setemptydata(true);
            } else {
                setData(res.data.data);
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setrefreshpage(false)
                setIsloading(true);
            }
        }).catch(err => {
            console.log(err)
        });
    }

    useEffect(() => {
        fetchCommonFirstTable() 
    }, [])  
    


        return (
            <>
                <Box variant='div' sx={{ p: 3 }}>
                    <Box sx={{ py: 3 }}>
                        <Heading title={'process screen'} />
                    </Box>
                    <Box sx={{ py: 3 }}>
                        <OrderDetails data={orderData} />
                    </Box>
                    {emptydata && data.length === 0 ? <Nodata /> : <Box>
                        <Box sx={{ py: 3 }}>
                            <TabContext value={value}>
                                <Box>
                                    <TabList
                                        variant="scrollable"
                                        scrollButtons='auto'
                                        onChange={handleChange} aria-label="tabs">
                                        {production === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Production Drawing " value="1" />)}
                                        {mom === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Mom" value="2" />)}
                                        {store === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Store" value="3" />)}
                                        {purchase === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Purchase" value="4" />)}
                                        {cnc === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="CNC" value="5" />)}
                                        {hub === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="HUB" value="6" />)}
                                        {machining === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Machining" value="7" />)}
                                        {impeller === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Impeller" value="8" />)}
                                        {casing === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Casing" value="9" />)}
                                        {accessories === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Accessories" value="15" />)}
                                        {first_paint === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="First Coat Paint" value="10" />)}
                                        {assembly === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Assembly" value="11" />)}
                                        {testing === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Testing" value="12" />)}
                                        {final_paint === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Final Paint" value="13" />)}
                                        {packing === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Packing Dispatch" value="14" />)}
                                       
                                    </TabList>
                                </Box>
                                {production === 'true' && (<TabPanel value="1">
                                    <Box>
                                        <ProductionDrawing isloading={isloading} data={data} title={'Production Drawing And BOM'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {mom === 'true' && (<TabPanel value="2">
                                    <Box>
                                        <Mom data={data} title={'MOM'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {store === 'true' && (<TabPanel value="3">
                                    <Box>
                                        <Store data={data} title={'Store'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {purchase === 'true' && (<TabPanel value="4">
                                    <Box>
                                        <Purchase data={data} title={'Purchase'} setrefreshpage={setrefreshpage} />

                                    </Box>
                                </TabPanel>)}
                                {cnc === 'true' && (<TabPanel value="5">
                                    <Box>
                                        <Cnc fetchCommonFirstTable={fetchCommonFirstTable} data={data} title={'CNC'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {hub === 'true' && (<TabPanel value="6">
                                    <Box>
                                        <Hub fetchCommonFirstTable={fetchCommonFirstTable} data={data} title={'Hub'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {machining === 'true' && (<TabPanel value="7">
                                    <Box>
                                        <Machining fetchCommonFirstTable={fetchCommonFirstTable} data={data} title={'Machining'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {impeller === 'true' && (<TabPanel value="8">
                                    <Box>
                                        <Impeller fetchCommonFirstTable={fetchCommonFirstTable} data={data} title={'Impeller'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {casing === 'true' && (<TabPanel value="9">
                                    <Box>
                                        <Casing fetchCommonFirstTable={fetchCommonFirstTable} data={data} title={'Casing'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {first_paint === 'true' && (<TabPanel value="10">
                                    <Box>
                                        <FirstCoatPaint data={data} title={'First Coat Paint'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {assembly === 'true' && (<TabPanel value="11">
                                    <Box>
                                        <Assembly data={data} title={'Assembly'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {testing === 'true' && (<TabPanel value="12">
                                    <Box>
                                        <Testing data={data} title={'Testing'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {final_paint === 'true' && (<TabPanel value="13">
                                    <Box>
                                        <FinalPaint data={data} title={'Final Paint'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {packing === 'true' && (<TabPanel value="14">
                                    <Box>
                                        <Packinganddispatching fetchCommonFirstTable={fetchCommonFirstTable} data={data} title={'Packing & Dispatch'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {accessories === 'true' && (<TabPanel value="15">
                                    <Box>
                                        <Accessories fetchCommonFirstTable={fetchCommonFirstTable} data={data} title={'Accessories'} setrefreshpage={setrefreshpage} />
                                    </Box>
                                </TabPanel>)}
                                {/* <TabPanel value='15'>
                                    <Box>
                                        <Accessories />
                                    </Box>
                                </TabPanel> */}
                            </TabContext>

                        </Box>
                    </Box>}
                </Box>
            </>

        )

}

export default OtherScreenlayoutSingle