import React from 'react'
import { Box } from '@mui/system';
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, TablePagination, Card } from '@mui/material';
import Filter from '../Comps/Filter';
import { useState } from 'react';
import Heading from './../Comps/Heading';
import {exportIssuedStocksScreenDatatoexcel, IssuedStocksTabeleHeading} from '../Comps/Variables/Variables';
import { getissuedstock } from '../Services/Apivariables';
import useFetch from "../Services/useFetch";
import Statusloader from './../Comps/Statusloader';
import { ExportCSV } from '../Comps/ExportCSV';
import {filterIssuedStockJobScreen} from '../Comps/FilterData';

function IssuedStockScreen() {
    const center = 'center';
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
     
    const{data, isloading} = useFetch(getissuedstock, 'GET')
    const exporttoExcel = exportIssuedStocksScreenDatatoexcel(data); 
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {   
    return (
      <Box variant="div" sx={{ p: 3 }}>
         <Box sx={{ py: 2 }}>
                    <Heading title={'Issued Stocks'} />
                </Box>
                <Box sx={{ py: 1 }}>
                        <ExportCSV csvData={exporttoExcel} fileName={'nadi_IssuedStocks_report'} />
                    </Box>
            <Box sx={{ p: 1, borderRadius: 4 }} component={Card}>
                <TableContainer sx={{ borderRadius: 2 }} >
                    <Table>
                        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                            <TableRow >
                                {
                                    IssuedStocksTabeleHeading.map((hd) =>
                                        <TableCell key={hd.id} sx={{
                                            color: 'white', fontWeight: 600, textAlign: 'center'
                                        }}>
                                             <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    {hd.label}
                                                </Box>
                                                <Box>
                                                {hd.filter ? <Filter search={search} label={hd.label} setSearch={setSearch} /> : ''}
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {data.filter((data) =>filterIssuedStockJobScreen(data, search, {
                                    searchFeildOne: data.workOrderNum,
                                    searchFeildTwo: data.stockNumber,
                                    searchFeildThree: data.partCode,
                                    searchFeildFour: data.stockIssueNo
                                })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                                <TableRow key={item.id}>
                                <TableCell sx={{ textAlign: 'center' }}>{item.workOrderNum}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.stockNumber}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.partCode}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.stockIssueNo}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.stockCompleted}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.customerName}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.fanModel}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.workType1}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.workType2}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.Qty}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.ERPJobNum}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.weight}</TableCell>
                                </TableRow>)}
                               
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    )
}}

export default IssuedStockScreen;
