import React, { useState } from 'react'
import {
  Card, Grid, TextField, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  TablePagination,
  Typography,
  Checkbox
} from '@mui/material'
import useFetch from '../Services/useFetch';
import Heading from './../Comps/Heading';
import { subcontractorheading, mapSubJobinwardValues, mapSubBroughtOutinwardValues, subcontractorInwardsheading } from '../Comps/Variables/Variables';
import { getsubcontractinventrayinwards, insertsubcontractinwardjobs, methodGet, methodPost, subcontractordetails, subcontractorfetch } from '../Services/Apivariables';
import { appendData } from '../Comps/Variables/ProcessVariables';
import submitForm from './../Services/ProcessScreenPostData';
import SnackBar from './../Comps/SnackBar';
import DialogBox from './../Comps/DialogBox';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SubcontractorBoughtoutTable from './../Comps/SubcontractorBoughtoutTable';
import Filter from './../Comps/Filter';
import { filterData } from '../Comps/FilterData';
import SubcontractInwardsBoughtoutTable from '../Comps/SubcontractInwardsBoughtoutTable';


function Subcontractorinwardscreen() {
  const { data: subcontractordata, setrefreshpage: refreshpage } = useFetch(subcontractordetails, methodGet);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [fetchData, setFetchData] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [boughtOutAllOrders, setBoughtOutAllOrders] = useState([]);
  const [selected, setSelected] = useState([]);
  const [broughtOutSelected, setBroughtOutSelected] = useState([]);
  const [worktype, setWorktype] = useState('');
  const [search, setSearch] = useState('');
  const [subcontractorDetails, setSubcontractorDetails] = useState([])
  const [pdfdialogopen, setpdfdialogopen] = useState(false);
  const [dialogdata, setdialogdata] = useState({});
  const [jobWeight, setJobWeight] = useState(null);

  // this function is for selecting row checkbox
  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // // this function is for select all checkbox
  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = allOrders;
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const fetchdetails = (url, method, id) => {

    const subid = new FormData()
    subid.append("id", id)
    axios({
      method: method,
      url: url,
      data: subid
    }).then(res => {
      setFetchData(res.data.data);
    }).catch(err => {
      console.log(err)
    });

  }

  const fetchAllOrders = (url, method, data) => {
    const subid = new FormData()
    subid.append("order_num", data)
    axios({
      method: method,
      url: url,
      data: subid
    }).then(res => {
      if (!res.data.error) {
        if (res.data.sub_contract !== undefined || res.data.boughtout !== undefined) {
          setAllOrders(res.data.sub_contract);
          setBoughtOutAllOrders(res.data.boughtout);
          setSubcontractorDetails(res.data.contractor_details);
        }
      } else {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  // this submit holds all values in an obj
  const overAllSubmit = () => {
    if(broughtOutSelected !==0 && jobWeight !== null){
    const selecteditems = allOrders.filter((element) => element.is_checked)
    const subcontractorjobData = mapSubJobinwardValues(selecteditems, {
      subcontract_name: allOrders.subcontract_name,
      address: allOrders.address,
      gst_no: allOrders.gst_no,
      subcontractor_worktype: worktype,
      subcon_job_weight:jobWeight
    });
    const subcontractorbrogData = mapSubBroughtOutinwardValues(broughtOutSelected);
    const sendData = {
      'subcontract': JSON.stringify(subcontractorjobData),
      'boughtout': JSON.stringify(subcontractorbrogData)
    }
    const submitData = appendData(sendData)
    submitForm(insertsubcontractinwardjobs, methodPost, submitData, setMessage, setOpen, setStatus, setColor, setOpenDialog, refreshpage)
    
    fetchAllOrders(getsubcontractinventrayinwards , 'POST' , allOrders.order_num);
  }
  else{
    setMessage("Please Enter Jo Weight")
    setOpen(true)
    setStatus(false)
    setColor(false)
  }
}


const onselectAll = (e) => {
  const allitems = [...allOrders]
  allitems.forEach(function (a) {
    a.is_checked = e.target.checked;
  })
  setAllOrders(allitems)
}

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  
  return (
    <Box variant="div" sx={{ p: 3 }}>
      <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
      <Box sx={{ py: 3 }}>
        <Heading title={'sub contract inwards'} />
      </Box>
      <Box sx={{ py: 5 }} >
        <Box sx={{ p: 2, borderRadius: 4 }} component={Card}>
          <Box sx={{ p: 3, borderRadius: 4 }} component={Card}>
            <Heading title={'subcontractor details'} />
            <Box sx={{ py: 3 }}>
              <Grid container justifyContent='space-between'>
                <Grid item sm={2} md={2} lg={2}>
                  <Box>
                    <Box sx={{ py: 1 }}>
                      <TextField
                        size='small'
                        label="Enter Dc number"
                        variant="outlined"
                        onChange={(e) => fetchAllOrders(getsubcontractinventrayinwards, methodPost, e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                {subcontractorDetails.map((details) =>
                  <>
                    <Grid item sm={2} md={2} lg={2}>
                      <Typography sx={{ fontWeight: '500' }}>Name :</Typography>
                      <Box>
                        {details.subcontract_name}
                      </Box>
                    </Grid>
                    <Grid item sm={2} md={2} lg={2}>
                      <Typography sx={{ fontWeight: '500' }}>Address :</Typography>
                      <Box>
                        {details.address}
                      </Box>
                    </Grid>
                    <Grid item sm={2} md={2} lg={2}>
                      <Typography sx={{ fontWeight: '500' }}>GST Number :</Typography>
                      <Box>
                        {details.gst_no}
                      </Box>
                    </Grid>
                    <Grid item sm={2} md={2} lg={2}>
                      <Typography sx={{ fontWeight: '500' }}>Work Type :</Typography>
                      <Box>
                        {details.work_type}
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Box>
          <Box sx={{ py: 3 }}>
            {allOrders.length !== 0 && <>
              <Box>
                <Box>
                  <TableContainer sx={{ py: 4 }}>
                    <Table aria-label="simple table">
                      <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                        <TableRow>
                          <TableCell sx={{
                            color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                          }} >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Box>
                              <Checkbox className='text-white' onChange={onselectAll} />
                              </Box>
                              <Box>
                                Select
                              </Box>
                            </Box>
                          </TableCell>
                          {subcontractorInwardsheading.map((labels) =>
                            <TableCell key={labels.id} sx={{
                              color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                            }} >
                              <Box sx={{ display: 'flex' }}>
                                <Box sx={{ my: 'auto' }}>
                                  {labels.title}
                                </Box>
                                <Box>
                                  {labels.filter && <Filter label={labels.title} setSearch={setSearch} />}
                                </Box>
                              </Box>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allOrders.filter((data) => filterData(data, search, {
                          searchFeildOne: data.job_num,
                          searchFeildTwo: data.sfg_part_name
                        })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((orders, index) => {
                          // const isItemSelected = isSelected(orders);
                          return (
                            <TableRow
                              hover
                              key={index}
                              // tabIndex={-1}
                              // role="checkbox"
                              // onClick={(event) => handleClick(event, orders)}
                            >
                              <TableCell align='center'>
                                <Box>
                                <Checkbox checked={!!orders.is_checked} onChange={(e) => {
                                  orders.is_checked = e.target.checked;
                                  const allitems = [...allOrders]
                                  allitems.find((element) => element.id === orders.id).is_checked = orders.is_checked
                                  setAllOrders(allitems)
                                }} />
                                </Box>
                              </TableCell>
                              {/* <TableCell align='center'>
                                {index + 1}
                              </TableCell> */}
                              <TableCell align='center'>
                                {orders.job_num}
                              </TableCell>
                              <TableCell align="center">{orders.sfg_part_name}</TableCell>
                              <TableCell align="center">{orders.sfg_code}</TableCell>
                              <TableCell align="center">{orders.part_code}</TableCell>
                              <TableCell align="center">{orders.descriptions}</TableCell>
                              {/* <TableCell align="center">{orders.part_no}</TableCell> */}
                              <TableCell align="center">{orders.qty}</TableCell>
                              <TableCell align="center">{orders.prod_code}</TableCell>
                              <TableCell align="center">{orders.finished_weight}</TableCell>
                              <TableCell align="center">{orders.actual_fw}</TableCell>
                              <TableCell align="center">
                              <TextField
                                size='small'
                                label="Enter Job Weight"
                                variant="outlined"
                                onChange={(e) => {
                                  setJobWeight(e.target.value);                   
                                }} 
                                InputLabelProps={{
                                  shrink: true,
                                }}   
                              />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={allOrders.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>

                <Box sx={{ py: 3 }}>
                  <SubcontractInwardsBoughtoutTable data={boughtOutAllOrders} setBroughtOutSelected={setBroughtOutSelected} broughtOutSelected={broughtOutSelected} />
                </Box>
                <Box sx={{ py: 1, textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    startIcon={<CheckCircleIcon />}
                    onClick={overAllSubmit}
                  >Submit</Button>
                </Box>
              </Box>
            </>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Subcontractorinwardscreen
