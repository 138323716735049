import React, { useState } from 'react'
import {
  Card, Grid, TextField, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  TablePagination,
  FormControl, MenuItem, Select,InputLabel
} from '@mui/material'
import Heading from './../Comps/Heading';
import {pendingtaskheading } from '../Comps/Variables/Variables';
import {delayedtasks, departmentlist } from '../Services/Apivariables';
import SnackBar from './../Comps/SnackBar';
import axios from 'axios';
import Filter from './../Comps/Filter';
import { filterData, filterJobScreen } from '../Comps/FilterData';
import useFetch from '../Services/useFetch';
import { useEffect } from 'react';
import Nodata from '../Comps/Nodata';

function DelayedandPendingTasks() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState('');
  const [fetchData, setFetchData]= useState([]);
  const [ currentDept , setCurrentDept] = useState('');
  const {dept} = useFetch(departmentlist,'GET'); 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const department = JSON.parse(localStorage.getItem('department'));

  const fetchdetails = () => {

    const subid = new FormData()
    subid.append("department", department)
    axios({
      method: 'POST',
      url: delayedtasks,
      data: subid
    }).then(res => {
      setFetchData(res.data.data);
    }).catch(err => {
      console.log(err)
    });

  }
  useEffect(() => {fetchdetails()}, []);

  return (
    <Box variant="div" sx={{ p: 3 }}>
      <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
      <Box sx={{ py: 3 }}>
        <Heading title={'Delayed and Pending Tasks'} />
      </Box>
      <Box sx={{ py: 5 }} >
        <Box sx={{ p: 2, borderRadius: 4 }} component={Card}>
          <Box sx={{ py: 3 }}>
            {fetchData.length !== 0 ? 
              <Box>
                <Box>
                  <TableContainer sx={{ py: 4 }}>
                    <Table aria-label="simple table">
                      <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                        <TableRow>
                          {pendingtaskheading.map((labels) =>
                            <TableCell key={labels.id} sx={{
                              color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                            }} >
                              <Box sx={{ display: 'flex' }}>
                                <Box sx={{ my: 'auto' }}>
                                  {labels.title}
                                </Box>
                                <Box>
                                  {labels.filter && <Filter label={labels.title} setSearch={setSearch} />}
                                </Box>
                              </Box>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fetchData.filter((data) => filterJobScreen(data, search, {
                          searchFeildOne: data.JobNumber,
                          searchFeildTwo: data.OrderNumber,
                          searchFeildThree: data.CustomerName
                        })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((orders, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align='center'> {orders.OrderNumber}</TableCell>
                              <TableCell align="center">{orders.JobNumber}</TableCell>
                              <TableCell align="center">{orders.SerialNumber}</TableCell>
                              <TableCell align="center">{orders.OrderDate}</TableCell>
                              <TableCell align="center">{orders.EDD}</TableCell>
                              <TableCell align="center">{orders.ReleaseDate}</TableCell>
                              <TableCell align="center">{orders.SalesRepName}</TableCell>
                              <TableCell align="center">{orders.CustomerName}</TableCell>
                              <TableCell align="center">{orders.OrderQty}</TableCell>
                              <TableCell align='center'> {orders.CustomerType}</TableCell>
                              <TableCell align="center">{orders.BillingGroup}</TableCell>
                              <TableCell align="center">{orders.Project}</TableCell>
                              <TableCell align="center">{orders.PODate}</TableCell>
                              <TableCell align="center">{orders.PONum}</TableCell>
                              <TableCell align="center">{orders.NeedByDate}</TableCell>
                              <TableCell align="center">{orders.RequestDate}</TableCell>
                              <TableCell align="center">{orders.MotorScope}</TableCell>
                              <TableCell align="center">{orders.MotorDriveType}</TableCell>
                              <TableCell align="center">{orders.FanType}</TableCell>
                              <TableCell align="center">{orders.FanModel}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={fetchData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>

              </Box>
             : <Nodata />
              }
          </Box>
        </Box>
      </Box>
      </Box>
  )
}

export default DelayedandPendingTasks
