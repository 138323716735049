import React from 'react'
import { Grid, TextField, Box, Card } from '@mui/material';
import { nullFinderandDateswapper } from './Variables/Variables';


function OrderDetails({ data }) {
  const access = JSON.parse(localStorage.getItem('access'));
  const { role } = access;
  return (
    <Box sx={{ p: 2 }}>
      {
        [data].map((order) =>
          <Grid sx={{ p: 3, borderRadius: 4 }} key={order.id} container component={Card} spacing={2}>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}  >
              <Box variant="div">
                <TextField
                  disabled={true}
                  //  className={classes.root}
                  value={order.order_num}
                  size="small"
                  fullWidth
                  label='Order Num'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            {/* <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  value={order.order_date}
                  size="small"
                  fullWidth
                  label='Order Date'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid> */}
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={order.job_num}
                  size="small"
                  fullWidth
                  label='Job Num'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={nullFinderandDateswapper(order.release_date)}
                  size="small"
                  fullWidth
                  label='Release Date'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            {/* <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  value={order.territory_id}
                  size="small"
                  fullWidth
                  label='Territory'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid> */}
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={order.sales_rep_code}
                  size="small"
                  fullWidth
                  label='Sales Rep'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={order.customer_name}
                  size="small"
                  fullWidth
                  label='Customer Name'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            {/* <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  value={order.customer_contact_name}
                  size="small"
                  fullWidth
                  label='Customer Contact Name'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid> */}
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={order.city}
                  size="small"
                  fullWidth
                  label='Customer City'
                  variant="outlined"

                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            {/* <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  value={order.state}
                  size="small"
                  fullWidth
                  label='Customer State'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            {/* <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  value={order.phone_num}
                  size="small"
                  fullWidth
                  label='Customer Contact Phone'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            {/* <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  value={order.mobile_num}
                  size="small"
                  fullWidth
                  label='Customer Contact Cell Phone'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            {/* <Grid item justifyContent="center" xs={6} md={6} lg={3} >
              <Box variant="div">
                <TextField
                  value={order.email_address}
                  size="small"
                  fullWidth
                  label='Customer Contact E-Mail'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            {/* <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  value={order.customer_type}
                  size="small"
                  fullWidth
                  label='Customer Type'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={order.billing_group}
                  size="small"
                  fullWidth
                  label='Billing Group'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            {role === 'Admin' && (<Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={order.doc_order_amt}
                  size="small"
                  fullWidth
                  label='Total Value'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>)}
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={nullFinderandDateswapper(order.need_by_date)}
                  size="small"
                  fullWidth
                  label='Need By'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={nullFinderandDateswapper(order.request_date)}
                  size="small"
                  fullWidth
                  label='Ship By'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            {/* <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  value={order.reference}
                  size="small"
                  fullWidth
                  label='Inspection Type'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            {/* <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  value={order.short_char_05}
                  size="small"
                  fullWidth
                  label='Motor Scope'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            {/* <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  value={order.terms_code}
                  size="small"
                  fullWidth
                  label='Payment Terms'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={order.character_08}
                  size="small"
                  fullWidth
                  label='Motor Drive Type'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            {/* <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  value={order.project}
                  size="small"
                  fullWidth
                  label='Project'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={order.prc_con_num}
                  size="small"
                  fullWidth
                  label='PO Num'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={nullFinderandDateswapper(order.date_06)}
                  size="small"
                  fullWidth
                  label='PO Cleared'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={nullFinderandDateswapper(order.date_12)}
                  size="small"
                  fullWidth
                  label='PO Date'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            {/* <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  value={order.line_desc}
                  size="small"
                  fullWidth
                  label='Part Number'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            {/* <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  value={order.order_qty}
                  size="small"
                  fullWidth
                  label='Part Description'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            {/* <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  value={order.doc_unit_price}
                  size="small"
                  fullWidth
                  label='Selling Req Qty'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            {/* <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  value={order.doc_unit_price}
                  size="small"
                  fullWidth
                  label='Unit Price'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
            {/* <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  value={order.doc_total_discount}
                  size="small"
                  fullWidth
                  label='Discount'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid> */}
          </Grid>
        )
      }
    </Box>
  )
}

export default OrderDetails