import React from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';

export const ExportJobUpdateReportCSV = ({plannedproduction , plannedmom, plannedcasing , plannedimpeller ,
    plannedfinalpaint , plannedfirstcoatpaint, plannedpacking,planneddispatch, plannedassembly , plannedcnc , 
    plannedhub, plannedmachining , plannedaccessories , plannedtesting ,Actualproduction , Actualmom, Actualcasing , Actualimpeller ,
    Actualfinalpaint , Actualfirstcoatpaint, Actualpacking,Actualdispatch, Actualassembly , Actualcnc , 
    Actualhub, Actualmachining , Actualaccessories , Actualtesting ,Delayedproduction , Delayedmom, Delayedcasing , Delayedimpeller ,
    Delayedfinalpaint , Delayedfirstcoatpaint, Delayedpacking,Delayeddispatch, Delayedassembly , Delayedcnc , 
    Delayedhub, Delayedmachining , Delayedaccessories , Delayedtesting , fileName }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';


    const exportToCSV = (plannedproduction , plannedmom, plannedcasing , plannedimpeller ,
        plannedfinalpaint , plannedfirstcoatpaint, plannedpacking,planneddispatch, plannedassembly , plannedcnc , 
        plannedhub, plannedmachining , plannedaccessories , plannedtesting ,Actualproduction , Actualmom, Actualcasing , Actualimpeller ,
        Actualfinalpaint , Actualfirstcoatpaint, Actualpacking,Actualdispatch, Actualassembly , Actualcnc , 
        Actualhub, Actualmachining , Actualaccessories , Actualtesting ,Delayedproduction , Delayedmom, Delayedcasing , Delayedimpeller ,
    Delayedfinalpaint , Delayedfirstcoatpaint, Delayedpacking,Delayeddispatch, Delayedassembly , Delayedcnc , 
    Delayedhub, Delayedmachining , Delayedaccessories , Delayedtesting , fileName) => {

            const w1 = XLSX.utils.json_to_sheet(plannedproduction);
            const w2 = XLSX.utils.json_to_sheet(plannedmom);
            const w3 = XLSX.utils.json_to_sheet(plannedcasing);
            const w4 = XLSX.utils.json_to_sheet(plannedimpeller);
            const w5 = XLSX.utils.json_to_sheet(plannedfinalpaint);
            const w6 = XLSX.utils.json_to_sheet(plannedfirstcoatpaint);
            const w7 = XLSX.utils.json_to_sheet(plannedpacking);
            const w8 = XLSX.utils.json_to_sheet(plannedtesting);
            const w9 = XLSX.utils.json_to_sheet(plannedaccessories);
            const w10 = XLSX.utils.json_to_sheet(plannedassembly);
            const w11 = XLSX.utils.json_to_sheet(plannedcnc);
            const w12 = XLSX.utils.json_to_sheet(plannedhub);
            const w13 = XLSX.utils.json_to_sheet(plannedmachining);
            const w14 = XLSX.utils.json_to_sheet(planneddispatch);
            const w15 = XLSX.utils.json_to_sheet(Actualproduction);
            const w16 = XLSX.utils.json_to_sheet(Actualmom);
            const w17 = XLSX.utils.json_to_sheet(Actualcasing);
            const w18 = XLSX.utils.json_to_sheet(Actualimpeller);
            const w19 = XLSX.utils.json_to_sheet(Actualfinalpaint);
            const w20 = XLSX.utils.json_to_sheet(Actualfirstcoatpaint);
            const w21 = XLSX.utils.json_to_sheet(Actualpacking);
            const w22 = XLSX.utils.json_to_sheet(Actualtesting);
            const w23 = XLSX.utils.json_to_sheet(Actualaccessories);
            const w24 = XLSX.utils.json_to_sheet(Actualassembly);
            const w25 = XLSX.utils.json_to_sheet(Actualcnc);
            const w26 = XLSX.utils.json_to_sheet(Actualhub);
            const w27 = XLSX.utils.json_to_sheet(Actualmachining);
            const w28 = XLSX.utils.json_to_sheet(Actualdispatch);
            const w29 = XLSX.utils.json_to_sheet(Delayedproduction);
            const w30 = XLSX.utils.json_to_sheet(Delayedmom);
            const w31 = XLSX.utils.json_to_sheet(Delayedcasing);
            const w32 = XLSX.utils.json_to_sheet(Delayedimpeller);
            const w33 = XLSX.utils.json_to_sheet(Delayedfinalpaint);
            const w34 = XLSX.utils.json_to_sheet(Delayedfirstcoatpaint);
            const w35 = XLSX.utils.json_to_sheet(Delayedpacking);
            const w36 = XLSX.utils.json_to_sheet(Delayedtesting);
            const w37 = XLSX.utils.json_to_sheet(Delayedaccessories);
            const w38 = XLSX.utils.json_to_sheet(Delayedassembly);
            const w39 = XLSX.utils.json_to_sheet(Delayedcnc);
            const w40 = XLSX.utils.json_to_sheet(Delayedhub);
            const w41 = XLSX.utils.json_to_sheet(Delayedmachining);
            const w42 = XLSX.utils.json_to_sheet(Delayeddispatch);

            const ws = { Sheets: {'Planned Production and Drawings': w1, 'Planned Mom': w2, 'Planned Casing' : w3 ,
            'Planned Impeller':w4 ,'Planned FinalPaint':w5,'Planned FirstCoatPaint':w6 , 'Planned Packing':w7 ,'Planned Testing':w8,
             'Planned Accessories':w9,'Planned Assembly':w10 , 'Planned CNC':w11 , 'Planned Hub':w12, 'Planned Machining':w13,'Planned Dispatch':w14, 
            'Actual Production and Drawings': w15, 'Actual Mom': w16, 'Actual Casing' : w17 ,'Actual Impeller':w18 ,'Actual FinalPaint':w19,'Actual FirstCoatPaint':w20 ,
             'Actual Packing':w21 ,'Actual Testing':w22, 'Actual Accessories':w23,'Actual Assembly':w24 , 'Actual CNC':w25 , 
             'Actual Hub':w26, 'Actual Machining':w27,'Actual Dispatch':w28, 'InQueue Production and Drawings': w29, 'InQueue Mom': w30, 'InQueue Casing' : w31 ,'InQueue Impeller':w32 ,
             'InQueue FinalPaint':w33,'InQueue FirstCoatPaint':w34 ,'InQueue Packing':w35 , 'InQueue Testing':w36,
            'InQueue Accessories':w37,'InQueue Assembly':w38, 'InQueue CNC':w39 , 'InQueue Hub':w40, 'InQueue Machining':w41,'InQueue Dispatch':w42,},


            SheetNames:  ['Planned Production and Drawings','Actual Production and Drawings','InQueue Production and Drawings','Planned Mom','Actual Mom','InQueue Mom',
            'Planned CNC','Actual CNC','InQueue CNC','Planned Hub','Actual Hub','InQueue Hub',
            'Planned Machining','Actual Machining','InQueue Machining','Planned Impeller','Actual Impeller','InQueue Impeller',
            'Planned Casing' ,'Actual Casing' ,'InQueue Casing' ,'Planned Accessories','Actual Accessories','InQueue Accessories',
            'Planned FirstCoatPaint','Actual FirstCoatPaint','InQueue FirstCoatPaint', 'Planned Assembly','Actual Assembly','InQueue Assembly',
            'Planned Testing','Actual Testing','InQueue Testing','Planned FinalPaint','Actual FinalPaint','InQueue FinalPaint',
            'Planned Packing','Actual Packing','InQueue Packing','Planned Dispatch','Actual Dispatch', 'InQueue Dispatch', ] };
            
        const excelBuffer = XLSX.write(ws, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button variant="contained" 
        onClick={(e) => exportToCSV(plannedproduction , plannedmom, plannedcasing , plannedimpeller ,
    plannedfinalpaint , plannedfirstcoatpaint, plannedpacking,planneddispatch, plannedassembly , plannedcnc , 
    plannedhub, plannedmachining , plannedaccessories , plannedtesting ,Actualproduction , Actualmom, Actualcasing , Actualimpeller ,
    Actualfinalpaint , Actualfirstcoatpaint, Actualpacking,Actualdispatch, Actualassembly , Actualcnc , 
    Actualhub, Actualmachining , Actualaccessories , Actualtesting ,Delayedproduction , Delayedmom, Delayedcasing , Delayedimpeller ,
    Delayedfinalpaint , Delayedfirstcoatpaint, Delayedpacking,Delayeddispatch, Delayedassembly , Delayedcnc , 
    Delayedhub, Delayedmachining , Delayedaccessories , Delayedtesting , fileName)}
        startIcon={<ExitToAppIcon />}
        >
        Export
        </Button>
    )
}