import React, { useState } from 'react'
import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import Filter from './Filter';
import { useNavigate } from 'react-router-dom';
import { filterQRStockJobScreen  } from './FilterData';



function QRStockJobTable({data , headingLabels, subpage}) {
  const navigate = useNavigate();
  const center = 'center';
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
   
        return (
            <Box sx={{ py: 3 }}>
            <Box sx={{ p: 2 }} component={Card}>
                    <TableContainer component={Card} sx={{ borderRadius: 2 }} >
                      <Table >
                        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                          <TableRow >
                            {
                             headingLabels.map(items =>
                                <TableCell key={items.id} sx={{ fontWeight: 600, color: 'white' }}>
                                  <Box sx={{ display: 'flex',justifyContent:'center' }}>
                                    <Box sx={{ my: 'auto' }}>
                                      {items.label}
                                    </Box>
                                    <Box>
                                      {items.filter ? <Filter label={items.label} setSearch={setSearch} /> : ''}
                                    </Box>
                                  </Box>
                                </TableCell>
                              )
                            }
        
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                                data.filter((data) => filterQRStockJobScreen(data, search, {
                                    searchFeildOne: data.workOrderNum,
                                    searchFeildTwo: data.stockNumber,
                                    searchFeildThree: data.partCode
                                })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                                    <TableRow hover key={item.storeId} sx={{ cursor: 'pointer' }} onClick={() => {
                                        navigate(subpage, {
                                            state: {
                                                 workOrderNum:item.workOrderNum,
                                                 stockNumber:item.stockNumber,
                                                 partCode:item.partCode,
                                                 fanModel:item.fanModel,
                                                 workType1:item.workType1,
                                                 workType2:item.workType2,
                                                 ERPJobNum:item.ERPJobNum,
                                                 weight:item.weight,
                                                 customerName:item.customerName,
                                                 Qty:item.Qty
                                            }
                                        });
                                    }}>
                                        <TableCell sx={{ textAlign: center }}>{item.workOrderNum}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.stockNumber}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.partCode}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.customerName}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.fanModel}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.workType1}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.workType2}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.Qty}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.ERPJobNum}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.weight}</TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      rowsPerPage={rowsPerPage}
                      page={page}
                      count={data.length}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Box>
                    </Box>
        
        )
        }
export default QRStockJobTable;