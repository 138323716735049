import React from 'react'
import { Box } from '@mui/material';
import {exportStoreDatatoexcel, storescreendatatableheadings } from '../Comps/Variables/Variables';
import Heading from './../Comps/Heading';
import {storescreendata} from '../Services/Apivariables';
import useFetch from './../Services/useFetch';
import Statusloader from './../Comps/Statusloader';
import Nodata from './../Comps/Nodata';
import StoreScreenQRTotalTable from '../Comps/StoreScreenQRTotalTable';
import { ExportCSV } from './../Comps/ExportCSV';
function StoreScreenQRTotal() {

    
    const { data, isloading, emptydata } = useFetch(storescreendata, 'GET');
    const exporttoExcel = exportStoreDatatoexcel(data); 
    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box variant="div" sx={{ p: 3 }}>          
                <Box sx={{ py: 3 }}>
                    <Heading title={'Store - Total QR Code Generation'} />
                </Box>
                <Box sx={{ py: 2 }}>
                        <ExportCSV csvData={exporttoExcel} fileName={'nadi_store_report'} />
                    </Box>
                {emptydata && data.length === 0 ? <Nodata /> : <Box sx={{ py: 3 }}>
                    <StoreScreenQRTotalTable data={data} headingLabels={storescreendatatableheadings} />
                </Box>}
            </Box>
        )
    }
}

export default StoreScreenQRTotal;