import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import DialogBox from './../DialogBox';
import QRCode from 'qrcode';



function GenerateBarcode({barCodeValue, QrCodeData}) {

    const [imageUrl, setImageUrl] = useState('');
    const [openDialog,setOpenDialog
    
    ] = useState(false);

  
    const generateQrCode = async () => {
      try {
            const response = await QRCode.toDataURL(QrCodeData);
     
            setImageUrl(response);
         
           setOpenDialog(true);
          
      }catch (error) {
        console.log(error);
      }
    }

    return (
        <Box>
            <Box>
         
            <Button variant="contained" 
            color="primary" onClick={() => generateQrCode()}>Generate</Button>
     
            </Box>
           
            <Box>
            <DialogBox imageUrl={imageUrl} customerDetails={barCodeValue} open={openDialog} setOpenDialog={setOpenDialog}  />
            </Box>
        </Box>
    )
}

export default GenerateBarcode