import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import QRCode from 'qrcode';
import { TableContainer, TableHead, TableRow, TableCell, Table, TableBody } from '@mui/material';




function Allstockbarcode({ generateBarcode }) {
    const [imageUrl, setImageUrl] = useState('');
    useEffect(() => {
        const imageData = 'Manufacturer Name - NADI STORE'+ '\nPart Code - '+ generateBarcode.customerName +'\nFan Model - '+generateBarcode.fanModel +'\nPart Code - '+
         generateBarcode.partCode +'\nStock No - '+ generateBarcode.stockNumber
        +  '\nQty - '+ generateBarcode.Qty + '\nWeight- '+ generateBarcode.weight;
        
        const generateQrCode = async () => {
            try {
                const response = await QRCode.toDataURL(imageData);

                setImageUrl(response);

            } catch (error) {
                console.log(error);
            }
        }
        generateQrCode()
    }, [])

  
    return (
        <Box sx={{ py: 1 }}>
            {/* <svg ref={inputRef} /> */}
            <TableContainer>
                            <Table>
                                
                                <TableBody>
                                  <TableCell>
                    {imageUrl && (
                        <a href={imageUrl} download>
                            <img src={imageUrl} alt="img" height="150" width="150" />
                        </a>
                    )}
               </TableCell>
               <TableCell>
 <Box>{"CUSTOMER NAME - " + generateBarcode?.customerName}</Box>
 <Box>{"FAN MODEL - " + generateBarcode?.fanModel}</Box>
 <Box>{" PART CODE - " + generateBarcode?.partCode}</Box>
 <Box>{"STOCK NO - " + generateBarcode?.stockNumber}</Box>
  <Box>{" WEIGHT - " + generateBarcode?.weight}</Box>
  <Box>{"QTY - " + generateBarcode?.Qty}</Box>
  </TableCell> 
        </TableBody>
        </Table>
       </TableContainer>
                           
        </Box>
    )
}

export default Allstockbarcode