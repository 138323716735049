import React from 'react'
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import {storescreendatatableheadings } from '../Comps/Variables/Variables';
import Heading from './../Comps/Heading';
import {storescreendata} from '../Services/Apivariables';
import useFetch from './../Services/useFetch';
import Statusloader from './../Comps/Statusloader';
import Nodata from './../Comps/Nodata';
import StoreScreenTable from '../Comps/StoreScreenTable';

function StoreScreen() {
    // useEffect(() => {
    //     axios({
    //       method: methodGet,
    //       url: short_qty,
    //     })
    //   }, [])
    
    const { data, isloading, emptydata } = useFetch(storescreendata, 'GET');
    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box variant="div" sx={{ p: 3 }}>
                <Box sx={{ py: 3 }}>
                    <Heading title={'Store'} />
                </Box>
                {emptydata && data.length === 0 ? <Nodata /> : <Box sx={{ py: 3 }}>
                    <StoreScreenTable data={data} headingLabels={storescreendatatableheadings} subpage={`storeQRscreen`} />
                </Box>}
                <Outlet />
            </Box>
        )
    }
}

export default StoreScreen;