import React from 'react'
import { Outlet } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import useFetch from '../Services/useFetch';
import { allMomFetch, excelmom, methodGet, momjobs } from '../Services/Apivariables';
import AllOrdersTabel from './../Comps/AllOrdersTabel';
import Heading from './../Comps/Heading';
import Statusloader from './../Comps/Statusloader';
import Nodata from './../Comps/Nodata';
import { v1 as uuidv1 } from 'uuid';
import { ExportSampleBomReport } from '../Comps/ExportSampleBomReport';
import { useState } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { momandplanFilesampleReport } from '../Comps/Variables/Variables';
import { appendData } from '../Comps/Variables/ProcessVariables';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';

function MomandPlaningScreen() {
    const {data, isloading, setrefreshpage} = useFetch(allMomFetch, methodGet);
    const roles = JSON.parse(localStorage.getItem('roles'));
    const role = "Super Admin" || "Admin";
    const [bomfile, setbomfile] = useState(null);
    const {data:excelreport} = useFetch(momjobs, 'GET');
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
   

// this is for order table 

const headingLabels = [
    {
        id: uuidv1(),
        label: "Order Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Job Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Order Date"
    },
    {
        id: uuidv1(),
        label: "Job Release Date"
    },
    {
        id: uuidv1(),
        label: "Bill Planned"
    },
    {
        id: uuidv1(),
        label: "Year Planned"
    },
    {
        id: uuidv1(),
        label: "Selling Req Qty"
    },
    {
        id: uuidv1(),
        label: "Sales Rep"
    },
    {
        id: uuidv1(),
        label: "Customer Name"
    },
    {
        id: uuidv1(),
        label: "Customer Contact Name"
    },
    {
        id: uuidv1(),
        label: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Customer City"
    },
    {
        id: uuidv1(),
        label: "Customer State"
    },
    {
        id: uuidv1(),
        label: "Customer Type"
    },
    {
        id: uuidv1(),
        label: "Billing Group"
    },
    {
        id: uuidv1(),
        label: "Total Value"
    },
    {
        id: uuidv1(),
        label: "Need By"
    },
    {
        id: uuidv1(),
        label: "Ship By"
    },
    {
        id: uuidv1(),
        label: "PO Cleared"
    },
    {
        id: uuidv1(),
        label: "Inspection Type"
    },
    {
        id: uuidv1(),
        label: "Motor Scope"
    },
    {
        id: uuidv1(),
        label: "Payment Terms"
    },
    {
        id: uuidv1(),
        label: "Motor Drive Type"
    },
    {
        id: uuidv1(),
        label: "Project"
    },
    {
        id: uuidv1(),
        label: "PO Date"
    },
    {
        id: uuidv1(),
        label: "Part Number"
    },
    {
        id: uuidv1(),
        label: "Part Description"
    },
    {
        id: uuidv1(),
        label: "PO Number"
    },
    {
        id: uuidv1(),
        label: "Territory"
    },
    {
        id: uuidv1(),
        label: "Unit Price"
    },
    {
        id: uuidv1(),
        label: "Discount"
    }

];

const headingLabelsfornonadmin= [
    {
        id: uuidv1(),
        label: "Order Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Job Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Order Date"
    },
    {
        id: uuidv1(),
        label: "Job Release Date"
    },
    {
        id: uuidv1(),
        label: "Bill Planned"
    },
    {
        id: uuidv1(),
        label: "Year Planned"
    },
    {
        id: uuidv1(),
        label: "Selling Req Qty"
    },
    {
        id: uuidv1(),
        label: "Sales Rep"
    },
    {
        id: uuidv1(),
        label: "Customer Name"
    },
    {
        id: uuidv1(),
        label: "Customer Contact Name"
    },
    {
        id: uuidv1(),
        label: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Customer City"
    },
    {
        id: uuidv1(),
        label: "Customer State"
    },
    {
        id: uuidv1(),
        label: "Customer Type"
    },
    {
        id: uuidv1(),
        label: "Billing Group"
    },
    {
        id: uuidv1(),
        label: "Total Value"
    },
    {
        id: uuidv1(),
        label: "Need By"
    },
    {
        id: uuidv1(),
        label: "Ship By"
    },
    {
        id: uuidv1(),
        label: "PO Cleared"
    },
    {
        id: uuidv1(),
        label: "Inspection Type"
    },
    {
        id: uuidv1(),
        label: "Motor Scope"
    },
    {
        id: uuidv1(),
        label: "Payment Terms"
    },
    {
        id: uuidv1(),
        label: "Motor Drive Type"
    },
    {
        id: uuidv1(),
        label: "Project"
    },
    {
        id: uuidv1(),
        label: "PO Date"
    },
    {
        id: uuidv1(),
        label: "Part Number"
    },
    {
        id: uuidv1(),
        label: "Part Description"
    },
    {
        id: uuidv1(),
        label: "PO Number"
    },
    {
        id: uuidv1(),
        label: "Territory"
    },

];

  const exportData = momandplanFilesampleReport(excelreport);
const serverData = {
    mom_excel_file: bomfile
}
const lData = appendData(serverData);
const submit = () => {
    if (bomfile !== null) {
        axios({
            method: 'POST',
            url: excelmom,
            data: lData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setbomfile(null);
                setrefreshpage(true);
            }
        }).catch(err => {
            console.log(err)
        });

    }
    else{
        setMessage(bomfile === null ? 'file is required' : 'Job number is required')
        setOpen(true)
        setStatus(false)
        setColor(false)
    }

};
   


  const headingTables = roles === role ? headingLabels : headingLabelsfornonadmin ; 

 
    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box variant="div" sx={{ p: 3 }}>
                <Box sx={{ py: 3 }} display='flex' justifyContent='space-between'>
                    <Box>
                    <Heading title={'mom and planning'} />
                    </Box>
                    <Box display='flex' flexDirection='row'>
                        <Box mr={2}>
                        <ExportSampleBomReport csvData={exportData} fileName='Sample Report'/>
                        </Box>
                      <Box sx={{ textAlign: 'center' }}>
                    <label htmlFor="bomfile">
                     <input onChange={(e) => setbomfile(e.target.files[0])} className='d-none' id="bomfile" type="file" />
                    <Button
                        startIcon={<InsertDriveFileIcon />}
                        variant="contained"
                        color={bomfile !== null ? 'secondary' : 'primary'}
                        component="span">
                        {bomfile !== null ? 'File Uploaded' : 'Upload file'}
                    </Button>
                    </label>
                </Box>
                <Box ml={1} mr={1}>
                <Button
                    onClick={submit}
                    endIcon={<SendIcon />}
                    variant="contained"
                    sx={{height:35}}
                >
                </Button>
                </Box>
                    </Box>

                </Box>
                {data.length === 0 ? <Nodata /> : <> <Box sx={{ py: 3 }}>
                    <AllOrdersTabel data={data} headingLabels={headingTables} subpage={`singlemomandplanning`} planned={true} />
                </Box></>}

                <Outlet />
            </Box>
        )
    }

}

export default MomandPlaningScreen