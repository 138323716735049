import { v1 as uuidv1 } from 'uuid';
import date from 'date-and-time';
import { format } from 'date-fns';
import moment from 'moment';
const roles = JSON.parse(localStorage.getItem('roles'));
const role = ['Super Admin', 'Admin'];


// this variavble is for target screen 
const targettableheadings = [
    {
        id: 1,
        label: "Sales Person Type"
    },
    {
        id: 2,
        label: "Locations",
        filter: Boolean(true)
    },
    {
        id: 3,
        label: "Team"
    },
    {
        id: 4,
        label: "Sales Person Name",
        filter: Boolean(true)
    },
    {
        id: 5,
        label: "Monthly Target"
    },
    {
        id: 6,
        label: "Annual Target"
    },
    {
        id: 7,
        label: "Financial Year"
    }

]


// single job screen table heading
const jobScreenTableHeading = [
    {
        id: uuidv1(),
        heading: "Serial.No",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Job Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Fan Type",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Qty"
    },
    {
        id: uuidv1(),
        heading: "Edit"
    },
    {
        id: uuidv1(),
        heading: "Bill Planned"
    },
    {
        id: uuidv1(),
        heading: "Year Planned "
    },
    // {
    //     id: 6,
    //     heading: "Production Category"
    // },
    {
        id: uuidv1(),
        heading: "Motor Scope"
    },
    // {
    //     id: uuidv1(),
    //     heading: "Payment Status on Date"
    // },
    {
        id: uuidv1(),
        heading: "Payment Terms"
    },
    {
        id: uuidv1(),
        heading: "Remarks"
    },
    {
        id: uuidv1(),
        heading: "Submit"
    }
];


const partcodeTableHeading = [
    {
        id: 1,
        heading: "Material Partcode",
        filter: Boolean(true)
    },
    {
        id: 2,
        heading: "Created Date",
        filter: Boolean(true)
    }

];
// single job screen table heading
const singlejobScreenTableHeading = [
    {
        id: uuidv1(),
        heading: "id"
    },
    {
        id: uuidv1(),
        heading: "Serial.No"
    },
    {
        id: uuidv1(),
        heading: "Job Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Fan Type",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Fan Model"
    },
    {
        id: uuidv1(),
        heading: "Qty"
    },
    // {
    //     id: uuidv1(),
    //     heading: "Bill Planned"
    // },
    // {
    //     id: uuidv1(),
    //     heading: "Year Planned "
    // },
    // {
    //     id: uuidv1(),
    //     heading: "Motor Scope"
    // },
    // {
    //     id: uuidv1(),
    //     heading: "Payment Status on Date"
    // },
    // {
    //     id: uuidv1(),
    //     heading: "Payment Terms"
    // },
    // {
    //     id: uuidv1(),
    //     heading: "Remarks"
    // },
];
const showjobscreentableheading = [
    {
        id: uuidv1(),
        heading: "Job Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Order Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Fan Type",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Fan Model"
    },
    {
        id: uuidv1(),
        heading: "Qty"
    },
    {
        id: uuidv1(),
        heading: "Purchase Status"
    },
    {
        id: uuidv1(),
        heading: "Production Status"
    },
    {
        id: uuidv1(),
        heading: "Bill Planned"
    },
    {
        id: uuidv1(),
        heading: "Year Planned "
    },
    {
        id: uuidv1(),
        heading: "Motor Scope"
    },
    // {
    //     id: uuidv1(),
    //     heading: "Payment Status on Date"
    // },
    {
        id: uuidv1(),
        heading: "Payment Terms"
    },
    {
        id: uuidv1(),
        heading: "Remarks"
    }
];

//get stock heading
const stockhead = [
    {
        id: uuidv1(),
        heading: "Part No",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Part Descrition",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Qty"
    },
    {
        id: uuidv1(),
        heading: "Material"
    },
    {
        id: uuidv1(),
        heading: "Weight (Kg)"
    },
    {
        id: uuidv1(),
        heading: "Location"
    },
    {
        id: uuidv1(),
        heading: "Rackwise"
    },
    {
        id: uuidv1(),
        heading: "Location (Rack No)"
    },
    {
        id: uuidv1(),
        heading: "DIA"
    },
    {
        id: uuidv1(),
        heading: "Stock No"
    },
    {
        id: uuidv1(),
        heading: "QR Code"
    }
];

//get single stock heading
const singlestockhead = [
    {
        id: uuidv1(),
        heading: "Part No",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Part Descrition",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        heading: "Qty"
    },
    {
        id: uuidv1(),
        heading: "Material"
    },
    {
        id: uuidv1(),
        heading: "Weight (Kg)"
    },
    {
        id: uuidv1(),
        heading: "Location"
    },
    {
        id: uuidv1(),
        heading: "Rackwise"
    },
    {
        id: uuidv1(),
        heading: "Location/Status"
    },
    {
        id: uuidv1(),
        heading: "DIA"
    },
    {
        id: uuidv1(),
        heading: "Stock No"
    }
    ,
    {
        id: uuidv1(),
        heading: "Customer"
    }
];

// this is for order table 
const headingLabels = [
    {
        id: uuidv1(),
        label: "Order Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Job Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Order Date"
    },
    {
        id: uuidv1(),
        label: "Job Release Date"
    },
    {
        id: uuidv1(),
        label: "Selling Req Qty"
    },
    {
        id: uuidv1(),
        label: "Sales Rep"
    },
    {
        id: uuidv1(),
        label: "Customer Name"
    },
    {
        id: uuidv1(),
        label: "Customer Contact Name"
    },
    {
        id: uuidv1(),
        label: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Customer City"
    },
    {
        id: uuidv1(),
        label: "Customer State"
    },
    {
        id: uuidv1(),
        label: "Customer Type"
    },
    {
        id: uuidv1(),
        label: "Billing Group"
    },
    {
        id: uuidv1(),
        label: "Total Value"
    },
    {
        id: uuidv1(),
        label: "Need By"
    },
    {
        id: uuidv1(),
        label: "Ship By"
    },
    {
        id: uuidv1(),
        label: "PO Cleared"
    },
    {
        id: uuidv1(),
        label: "Inspection Type"
    },
    {
        id: uuidv1(),
        label: "Motor Scope"
    },
    {
        id: uuidv1(),
        label: "Payment Terms"
    },
    {
        id: uuidv1(),
        label: "Motor Drive Type"
    },
    {
        id: uuidv1(),
        label: "Project"
    },
    {
        id: uuidv1(),
        label: "PO Date"
    },
    {
        id: uuidv1(),
        label: "Part Number"
    },
    {
        id: uuidv1(),
        label: "Part Description"
    },
    {
        id: uuidv1(),
        label: "PO Number"
    },
    {
        id: uuidv1(),
        label: "Territory"
    },
    {
        id: uuidv1(),
        label: "Unit Price"
    },
    {
        id: uuidv1(),
        label: "Discount"
    }

];

const headingLabelsfornonadmin= [
    {
        id: uuidv1(),
        label: "Order Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Job Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Order Date"
    },
    {
        id: uuidv1(),
        label: "Job Release Date"
    },
    {
        id: uuidv1(),
        label: "Selling Req Qty"
    },
    {
        id: uuidv1(),
        label: "Sales Rep"
    },
    {
        id: uuidv1(),
        label: "Customer Name"
    },
    {
        id: uuidv1(),
        label: "Customer Contact Name"
    },
    {
        id: uuidv1(),
        label: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Customer City"
    },
    {
        id: uuidv1(),
        label: "Customer State"
    },
    {
        id: uuidv1(),
        label: "Customer Type"
    },
    {
        id: uuidv1(),
        label: "Billing Group"
    },
    {
        id: uuidv1(),
        label: "Total Value"
    },
    {
        id: uuidv1(),
        label: "Need By"
    },
    {
        id: uuidv1(),
        label: "Ship By"
    },
    {
        id: uuidv1(),
        label: "PO Cleared"
    },
    {
        id: uuidv1(),
        label: "Inspection Type"
    },
    {
        id: uuidv1(),
        label: "Motor Scope"
    },
    {
        id: uuidv1(),
        label: "Payment Terms"
    },
    {
        id: uuidv1(),
        label: "Motor Drive Type"
    },
    {
        id: uuidv1(),
        label: "Project"
    },
    {
        id: uuidv1(),
        label: "PO Date"
    },
    {
        id: uuidv1(),
        label: "Part Number"
    },
    {
        id: uuidv1(),
        label: "Part Description"
    },
    {
        id: uuidv1(),
        label: "PO Number"
    },
    {
        id: uuidv1(),
        label: "Territory"
    },

];
// Job Report screen heading

const jobreportscreenheading = [
    {
        id: uuidv1(),
        label: "Order Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Job Number",
        filter: Boolean(true)
    },   {
        id: uuidv1(),
        label: "Bill Planned"
    },
    {
        id: uuidv1(),
        label: "Year Planned"
    },
    {
        id: uuidv1(),
        label: "Total Weight"
    },
    {
        id: uuidv1(),
        label: "Order Date"
    },
    {
        id: uuidv1(),
        label: "Job Release Date"
    },
    {
        id: uuidv1(),
        label: "Order Qty"
    }, 
    {
        id: uuidv1(),
        label: "Sales Rep"
    }, 
    {
        id: uuidv1(),
        label: "Customer Name"
    },
    {
        id: uuidv1(),
        label: "Customer City"
    },
    {
        id: uuidv1(),
        label: "Customer State"
    },
    {
        id: uuidv1(),
        label: "Customer Contact Phone"
    },
    {
        id: uuidv1(),
        label: "Customer Contact Cell Phone"
    },
    {
        id: uuidv1(),
        label: "Customer Contact E-Mail"
    },
    {
        id: uuidv1(),
        label: "Customer Type"
    },
    {
        id: uuidv1(),
        label: "Billing Group"
    },
    {
        id: uuidv1(),
        label: "PO Date"
    },
    {
        id: uuidv1(),
        label: "PO Number"
    },
    {
        id: uuidv1(),
        label: "Part Number"
    },
    {
        id: uuidv1(),
        label: "Part Description"
    },
    {
        id: uuidv1(),
        label: "Need By"
    },
    {
        id: uuidv1(),
        label: "Ship By"
    },
    {
        id: uuidv1(),
        label: "PO Cleared"
    },
    {
        id: uuidv1(),
        label: "Motor Scope"
    },
    {
        id: uuidv1(),
        label: "Payment Terms"
    },
    {
        id: uuidv1(),
        label: "Motor Drive Type"
    },
    {
        id: uuidv1(),
        label: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Unit Price"
    },
    {
        id: uuidv1(),
        label: "Discount"
    },
    {
        id: uuidv1(),
        label: "Total Value"
    }
]

const jobreportscreenheadingnonadmin = [
    {
        id: uuidv1(),
        label: "Order Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Job Number",
        filter: Boolean(true)
    },   {
        id: uuidv1(),
        label: "Bill Planned"
    },
    {
        id: uuidv1(),
        label: "Year Planned"
    },
    {
        id: uuidv1(),
        label: "Total Weight"
    },
    {
        id: uuidv1(),
        label: "Order Date"
    },
    {
        id: uuidv1(),
        label: "Job Release Date"
    },
    {
        id: uuidv1(),
        label: "Order Qty"
    }, 
    {
        id: uuidv1(),
        label: "Sales Rep"
    }, 
    {
        id: uuidv1(),
        label: "Customer Name"
    },
    {
        id: uuidv1(),
        label: "Customer City"
    },
    {
        id: uuidv1(),
        label: "Customer State"
    },
    {
        id: uuidv1(),
        label: "Customer Type"
    },
    {
        id: uuidv1(),
        label: "Billing Group"
    },
    {
        id: uuidv1(),
        label: "PO Date"
    },
    {
        id: uuidv1(),
        label: "PO Number"
    },
    {
        id: uuidv1(),
        label: "Part Number"
    },
    {
        id: uuidv1(),
        label: "Part Description"
    },
    {
        id: uuidv1(),
        label: "Need By"
    },
    {
        id: uuidv1(),
        label: "Ship By"
    },
    {
        id: uuidv1(),
        label: "PO Cleared"
    },
    {
        id: uuidv1(),
        label: "Motor Scope"
    },
    {
        id: uuidv1(),
        label: "Payment Terms"
    },
    {
        id: uuidv1(),
        label: "Motor Drive Type"
    },
    {
        id: uuidv1(),
        label: "Fan Model",
        filter: Boolean(true)
    },
]



// this is for solo mom and planning table 
const soloMomandPlaningTabelHeading = [
    {
        id: uuidv1(),
        label: "ID.No",
    },
    {
        id: uuidv1(),
        label: "Serial.No",
    },
    {
        id: uuidv1(),
        label: "Job Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Fan Type",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Qty"
    },
    {
        id: uuidv1(),
        label: "CDD"
    },
    {
        id: uuidv1(),
        label: "Edit"
    },
    {
        id: uuidv1(),
        label: "Production Drawing EDD"
    },
    {
        id: uuidv1(),
        label: "BOM Released Edd"
    },
    // {
    //     id: uuidv1(),
    //     label: "BOM Excel Upload"
    // },
    {
        id: uuidv1(),
        label: "MOM Generation / Planning_EDD"
    },
    {
        id: uuidv1(),
        label: "Stores EDD"
    },
    {
        id: uuidv1(),
        label: "Motor Delivery EDD"
    },
    {
        id: uuidv1(),
        label: "CNC EDD"
    },
    {
        id: uuidv1(),
        label: "Hub EDD"
    },
    {
        id: uuidv1(),
        label: "Machining EDD"
    },
    {
        id: uuidv1(),
        label: "Impeller EDD"
    },
    {
        id: uuidv1(),
        label: "Casing EDD"
    },
    {
        id: uuidv1(),
        label: "Accessories EDD"
    },
    // {
    //     id: 16,
    //     label: "Wooden Crate EDD"
    // },
    // {
    //     id: 17,
    //     label: "Bought Out(Acessories) EDD"
    // },
    {
        id: uuidv1(),
        label: "First Coat Paint EDD"
    },
    {
        id: uuidv1(),
        label: "Assembly EDD"
    },
    {
        id: uuidv1(),
        label: "Testing EDD"
    },
    {
        id: uuidv1(),
        label: "Final Paint EDD"
    },
    {
        id: uuidv1(),
        label: "Packing & Dispatch EDD"
    },

    // {
    //     id: 22,
    //     label: "MOM Excecuted Date"
    // },
    // {
    //     id: 23,
    //     label: "MOM Release date"
    // },
    {
        id: uuidv1(),
        label: "Remarks"
    },
    {
        id: uuidv1(),
        label: "Submit"
    }
];
const singleMomandPlaningTabelHeading = [
    {
        id: uuidv1(),
        label: "ID.No",
    },
    {
        id: uuidv1(),
        label: "Serial.No"
    },
    {
        id: uuidv1(),
        label: "Job Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Fan Type",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Qty"
    },
    {
        id: uuidv1(),
        label: "CDD"
    }
];

const StockIssueTabeleHeading = [
    {
        id: uuidv1(),
        label: "Work Order Number",
        filter: Boolean(true)
    },
     {
        id: uuidv1(),
        label: "Stock No",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Part No",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Qty",
    }, {
        id: uuidv1(),
        label: "Completed Date",
    },

]
 
const IssuedStocksTabeleHeading = [
    {
        id: uuidv1(),
        label: "Work Order Number",
        filter: Boolean(true)
    },
     {
        id: uuidv1(),
        label: "Stock No",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Part No",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Issue No",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Completed Date",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Customer Name",
    },
    {
        id: uuidv1(),
        label: "Fan Model",
    },
    {
        id: uuidv1(),
        label: "Work Type 1",
    },
    {
        id: uuidv1(),
        label: "Work Type 2",
    },
    {
        id: uuidv1(),
        label: "Qty",
    },
    {
        id: uuidv1(),
        label: "ERP Job No",
    },
    {
        id: uuidv1(),
        label: "Weight",
    },
]
const qrcodeStockJobTableHeadings =[
    {
        id: uuidv1(),
        label: "Work Order Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Stock Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Part Code",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Customer Name",
    },
    {
        id: uuidv1(),
        label: "Fan Model",
    },
    {
        id: uuidv1(),
        label: "Work Type 1",
    },
    {
        id: uuidv1(),
        label: "Work Type 2",
    },
   
    {
        id: uuidv1(),
        label: "Qty",
    },
    {
        id: uuidv1(),
        label: "ERP Job No",
    },
    {
        id: uuidv1(),
        label: "Weight",
    },
]
const qrcodeStockJobsingleLayoutTableHeadings =[
    {
        id: uuidv1(),
        label: "Work Order Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Work Type 1",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Qty",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "ERP Job No",
        filter: Boolean(true)
    },
 
    {
        id: uuidv1(),
        label: "Edit",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Completed Date",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Submit",
        filter: Boolean(true)
    },
]
const pendingStocksTableHeadings =[
    {
        id: uuidv1(),
        label: "Work Order Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Customer Name",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Work Type 1",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Work Type 2",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Stock Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Part Code",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "ERP Job No",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Weight",
        filter: Boolean(true)
    },
]

//this is for storescreendata table 

const storescreendatatableheadings =[
    {
        id: uuidv1(),
        label: "Part Code",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Part Descriptions",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "UOM",

    },
    {
        id: uuidv1(),
        label: "onHandQty",

    },
       
    {
        id: uuidv1(),
        label: "Unit Cost",

    },
    {
        id: uuidv1(),
        label: "Total Cost",

    },
    {
        id: uuidv1(),
        label: "Class Description",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "subClassDescription",
        filter: Boolean(true)

    },
    
    {
        id: uuidv1(),
        label: "WareHouseCode",

    },
    {
        id: uuidv1(),
        label: "Bin",

    },
    {
        id: uuidv1(),
        label: "class",

    },
    {
        id: uuidv1(),
        label: "Sub class",

    },
    {
        id: uuidv1(),
        label: "Plant",

    },
]









// this is for active orders table 
const activeOrderTableHeading = [
    // {
    //     id: 1,
    //     label: "id"
    // },
    {
        id: 1,
        label: "Order Number"
    },
    // {
    //     id: 3,
    //     label: "Order Date"
    // },
    {
        id: 2,
        label: "Job Number",
        filter: Boolean(true)
    },
    {
        id: 3,
        label: "Release Date"
    },
    // {
    //     id: 6,
    //     label: "Territory"
    // },
    {
        id: 4,
        label: "Sales Rep"
    },
    {
        id: 5,
        label: "Customer Name",
        filter: Boolean(true)
    },
    // {
    //     id: 6,
    //     label: "Customer Contact Name"
    // },
    {
        id: 6,
        label: "Customer City"
    },
    // {
    //     id: 11,
    //     label: "Customer State"
    // },
    // {
    //     id: 12,
    //     label: "Customer Contact Phone"
    // },
    // {
    //     id: 13,
    //     label: "Customer Contact Cell Phone"
    // },
    // {
    //     id: 14,
    //     label: "Customer Contact E-Mail"
    // },
    // {
    //     id: 15,
    //     label: "Customer Type"
    // },
    {
        id: 7,
        label: "Billing Group"
    },
    {
        id: 8,
        label: "Total Value"
    },
    {
        id: 9,
        label: "Need By"
    },
    {
        id: 10,
        label: "Ship By"
    },
    {
        id: 11,
        label: "PO Cleared"
    },
    // {
    //     id: 21,
    //     label: "Inspection Type"
    // },
    // {
    //     id: 22,
    //     label: "Motor Scope"
    // },
    // {
    //     id: 23,
    //     label: "Payment Terms"
    // },
    {
        id: 12,
        label: "Motor Drive Type"
    },
    // {
    //     id: 25,
    //     label: "Project"
    // },
    {
        id: 13,
        label: "PO Date"
    }
    // {
    //     id: 14,
    //     label: "Part Number"
    // }
    // {
    //     id: 28,
    //     label: "Part Description"
    // },
    // {
    //     id: 29,
    //     label: "Selling Req Qty"
    // },
    // {
    //     id: 30,
    //     label: "Unit Price"
    // },
    // {
    //     id: 31,
    //     label: "Discount"
    // }
]
const displayMomandPlanning = [
    {
        id: uuidv1(),
        label: "Order Number",
        filter: Boolean(true)
    },
    // {
    //     id: uuidv1(),
    //     label: "Job Number",
    //     filter: Boolean(true)
    // },
    // {
    //     id: 3,
    //     label: "Status"
    // },
    {
        id: uuidv1(),
        label: "Production Drawing Edd"
    },
    {
        id: uuidv1(),
        label: "BOM Released Edd"
    },
    {
        id: uuidv1(),
        label: "Mom Generation / Panning Edd"
    },
    {
        id: uuidv1(),
        label: "Store Edd"
    },
    {
        id: uuidv1(),
        label: "Motor Delivery Edd"
    },
    {
        id: uuidv1(),
        label: "Cnc Edd"
    },
    {
        id: uuidv1(),
        label: "Hub Edd"
    },
    {
        id: uuidv1(),
        label: "Machining Edd"
    },
    {
        id: uuidv1(),
        label: "Impeller Edd"
    },
    {
        id: uuidv1(),
        label: "Casing Edd"
    },
    // {
    //     id: 13,
    //     label: "Wooden Crate Edd"
    // },
    // {
    //     id: 14,
    //     label: "Bought Out Edd"
    // },
    {
        id: uuidv1(),
        label: "First Coat Paint Edd"
    },
    {
        id: uuidv1(),
        label: "Assembly Edd"
    },
    {
        id: uuidv1(),
        label: "Testing Edd"
    },
    {
        id: uuidv1(),
        label: "Final Paint Edd"
    },
    {
        id: uuidv1(),
        label: "Packing & Dispatching Edd"
    },
    // {
    //     id: 18,
    //     label: "Mom Generation / Panning Edd"
    // },
    // {
    //     id: 19,
    //     label: "Mom Executed Date"
    // },
    // {
    //     id: 20,
    //     label: "Mom Release Date"
    // },
    {
        id: uuidv1(),
        label: "Remarks"
    }
];
const displaysingleMomandPlanning = [
    {
        id: uuidv1(),
        label: "Order Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Job Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Production Drawing Edd"
    },
    {
        id: uuidv1(),
        label: "BOM Released Edd"
    },
    {
        id: uuidv1(),
        label: "Mom Generation / Panning Edd"
    },
    {
        id: uuidv1(),
        label: "Store Edd"
    },
    {
        id: uuidv1(),
        label: "Motor Delivery Edd"
    },
    {
        id: uuidv1(),
        label: "Cnc Edd"
    },
    {
        id: uuidv1(),
        label: "Hub Edd"
    },
    {
        id: uuidv1(),
        label: "Machining Edd"
    },
    {
        id: uuidv1(),
        label: "Impeller Edd"
    },
    {
        id: uuidv1(),
        label: "Casing Edd"
    },
    // {
    //     id: 13,
    //     label: "Wooden Crate Edd"
    // },
    // {
    //     id: 14,
    //     label: "Bought Out Edd"
    // },
    {
        id: uuidv1(),
        label: "First Coat Paint Edd"
    },
    {
        id: uuidv1(),
        label: "Assembly Edd"
    },
    {
        id: uuidv1(),
        label: "Testing Edd"
    },
    {
        id: uuidv1(),
        label: "Final Paint Edd"
    },
    {
        id: uuidv1(),
        label: "Packing & Dispatching Edd"
    },
    // {
    //     id: 18,
    //     label: "Mom Generation / Panning Edd"
    // },
    // {
    //     id: 19,
    //     label: "Mom Executed Date"
    // },
    // {
    //     id: 20,
    //     label: "Mom Release Date"
    // },
    {
        id: uuidv1(),
        label: "Remarks"
    }
];


const pmsTabelHeadings = [
    {
        id: uuidv1(),
        label: "Order Number"
    },
    {
        id: uuidv1(),
        label: "Job Number",
        filter: Boolean(true)
    },
        {
        id: uuidv1(),
        label: "Customer Name",
    },
            {
        id: uuidv1(),
        label: "Fan Type",
    },
            {
        id: uuidv1(),
        label: "Fan Model",
    },
            {
        id: uuidv1(),
        label: "Order Qty",
    },
            {
        id: uuidv1(),
        label: "EDD",
    },
    {
        id: uuidv1(),
        label: "Planned Date"
    },
    {
        id: uuidv1(),
        label: "Drawing Release Date"
    },
        {
        id: uuidv1(),
        label: "Delay"
    },
    {
        id: uuidv1(),
        label: "Remarks"
    },
    {
        id: uuidv1(),
        label: "Planned Date"
    },
    {
        id: uuidv1(),
        label: "Released Date"
    },
            {
        id: uuidv1(),
        label: "Delay"
    },
    {
        id: uuidv1(),
        label: "Remarks"
    },
    {
        id: uuidv1(),
        label: "Planned Date "
    },
    {
        id: uuidv1(),
        label: "Issued Date"
    },
     {
        id: uuidv1(),
        label: "Delay"
    },
    {
        id: uuidv1(),
        label: "Remarks"
    },
    {
        id: uuidv1(),
        label: "EDD"
    },
    {
        id: uuidv1(),
        label: "Report Submitted"
    },

    {
        id: uuidv1(),
        label: "Planned Date"
    },
    {
        id: uuidv1(),
        label: "Issued Date"
    },
    {
        id: uuidv1(),
        label: "Completed Date"
    },
            {
        id: uuidv1(),
        label: "Delay"
    },
    {
        id: uuidv1(),
        label: "Remarks"
    },

    {
        id: uuidv1(),
        label: "Planned Date"
    },
    {
        id: uuidv1(),
        label: "Issued Date"
    },

    {
        id: uuidv1(),
        label: "Completed Date"
    },
            {
        id: uuidv1(),
        label: "Delay"
    },
    {
        id: uuidv1(),
        label: "Remarks"
    },

    {
        id: uuidv1(),
        label: "Planned Date"
    },
    {
        id: uuidv1(),
        label: "Issued Date"
    },
    {
        id: uuidv1(),
        label: "Completed Date"
    },
            {
        id: uuidv1(),
        label: "Delay"
    },
    {
        id: uuidv1(),
        label: "Remarks"
    },

        {
        id: uuidv1(),
        label: "Hub Staus"
    },
    {
        id: uuidv1(),
        label: "Planned Date"
    },
    {
        id: uuidv1(),
        label: "Issued Date"
    },
    {
        id: uuidv1(),
        label: "Completed Date"
    },
            {
        id: uuidv1(),
        label: "Delay"
    },
    {
        id: uuidv1(),
        label: "Remarks"
    },

    {
        id: uuidv1(),
        label: "CNC Status"
    },
    {
        id: uuidv1(),
        label: "Planned Date"
    },
    {
        id: uuidv1(),
        label: "Issued Date"
    },
    {
        id: uuidv1(),
        label: "Completed Date"
    },
                {
        id: uuidv1(),
        label: "Delay"
    },
    {
        id: uuidv1(),
        label: "Remarks"
    },

    {
        id: uuidv1(),
        label: "Plan Date"
    },
    {
        id: uuidv1(),
        label: "Actual Date"
    },
        {
        id: uuidv1(),
        label: "Remarks"
    },

    {
        id: uuidv1(),
        label: "Plan Date"
    },
    {
        id: uuidv1(),
        label: "Actual Date"
    },
        {
        id: uuidv1(),
        label: "Remarks"
    },

    {
        id: uuidv1(),
        label: "Plan Date"
    },
    {
        id: uuidv1(),
        label: "Actual Date"
    },
        {
        id: uuidv1(),
        label: "Remarks"
    },

    {
        id: uuidv1(),
        label: "Plan Date"
    },
    {
        id: uuidv1(),
        label: "Actual Date"
    },
        {
        id: uuidv1(),
        label: "Remarks"
    },

    {
        id: uuidv1(),
        label: "Plan Date"
    },
    {
        id: uuidv1(),
        label: "Packing Date"
    },
    {
        id: uuidv1(),
        label: "Dispatch Date"
    },
    {
        id: uuidv1(),
        label: "Remarks"
    },
]
const pmsTableHeader = [
    {
        id: uuidv1(),
        name: 'Order Details',
        colSize: 7
    },
    {
        id: uuidv1(),
        name: 'DRAWING',
        colSize: 4
    },
    {
        id: uuidv1(),
        name: 'MOM',
        colSize: 4
    },
    {
        id: uuidv1(),
        name: 'BOM',
        colSize: 4
    },
    {
        id: uuidv1(),
        name: 'STORES',
        colSize: 2
    },
    {
        id: uuidv1(),
        name: 'CNC',
        colSize: 5
    },
    {
        id: uuidv1(),
        name: 'HUB',
        colSize: 5
    },
    {
        id: uuidv1(),
        name: 'MACHINING ITEMS',
        colSize: 5
    },
    {
        id: uuidv1(),
        name: 'IMPELLER',
        colSize: 6
    },
    {
        id: uuidv1(),
        name: 'CASING',
        colSize: 6
    },
    {
        id: uuidv1(),
        name: 'FIRST COAT PAINT',
        colSize: 3
    },
    {
        id: uuidv1(),
        name: 'ASSEMBLY',
        colSize: 3
    },
    {
        id: uuidv1(),
        name: 'TESTING',
        colSize: 3
    },
    {
        id: uuidv1(),
        name: 'FINAL PAINT',
        colSize: 3
    },
    {
        id: uuidv1(),
        name: 'PACKING & DISPATCH',
        colSize: 4
    }

]
const subcontractorOutwardsheading = [
    {
        id: uuidv1(),
        title: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'SFG Part Name',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'SFG Code'
    },

    {
        id: uuidv1(),
        title: 'Part Code',
    },
    {
        id: uuidv1(),
        title: 'Part Description'
    },
    {
        id: uuidv1(),
        title: 'Part Quantity'
    },
    {
        id: uuidv1(),
        title: 'Fan Type'
    },
    {
        id: uuidv1(),
        title: 'Finished Weight'
    }
]

const subcontractorInwardsheading = [
    // {
    //     id: uuidv1(),
    //     title: 'No'
    // },
    {
        id: uuidv1(),
        title: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'SFG Part Name',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'SFG Code'
    },

    {
        id: uuidv1(),
        title: 'Part Code',
    },
    {
        id: uuidv1(),
        title: 'Part Description'
    },
    // {
    //     id: uuidv1(),
    //     title: 'Part Number'
    // },
    {
        id: uuidv1(),
        title: 'Part Quantity'
    },
    {
        id: uuidv1(),
        title: 'Fan Type'
    },
    {
        id: uuidv1(),
        title: 'Finished Weight'
    }, 
    {
        id: uuidv1(),
        title: 'Actual FW'
    },
    {
        id: uuidv1(),
        title: 'Job Weight'
    },
    // {
    //     id: uuidv1(),
    //     title: 'Edit'
    // },
    // {
    //     id: uuidv1(),
    //     title: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     title: 'Submit'
    // }
]
const department = JSON.parse(localStorage.getItem('department'));
const pendingtaskheading =[
    {
        id: uuidv1(),
        title: "OrderNumber",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: "SerialNumber",
    },
    {
        id: uuidv1(),
        title: "OrderDate",
    },
    {
        id: uuidv1(),
        title: `${department}EDD`,
    },
    {
        id: uuidv1(),
        title: "ReleaseDate",
    },
    {
        id: uuidv1(),
        title: "SalesRepName",
    },
    {
        id: uuidv1(),
        title: "CustomerName",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: "OrderQty",
    },
    {
        id: uuidv1(),
        title: "CustomerType",
    },
    {
        id: uuidv1(),
        title: "BillingGroup",
       
    },
    {
        id: uuidv1(),
        title: "Project",
       
    },
    {
        id: uuidv1(),
        title: "PODate",
        
    },
    {
        id: uuidv1(),
        title: "PONum",
    
    },
    {
        id: uuidv1(),
        title: "NeedByDate",
        
    },
    {
        id: uuidv1(),
        title: "RequestDate",
       
    },
    {
        id: uuidv1(),
        title: "MotorScope",
       
    },
    {
        id: uuidv1(),
        title: "MotorDriveType",
       
    },
    {
        id: uuidv1(),
        title: "FanType",
        
    },
    {
        id: uuidv1(),
        title: "FanModel",
    
    },
]


const subcontractorheading = [
    // {
    //     id: uuidv1(),
    //     title: 'No'
    // },
    {
        id: uuidv1(),
        title: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'SFG Part Name',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'SFG Code'
    },

    {
        id: uuidv1(),
        title: 'Part Code',
    },
    {
        id: uuidv1(),
        title: 'Part Description'
    },
    // {
    //     id: uuidv1(),
    //     title: 'Part Number'
    // },
    {
        id: uuidv1(),
        title: 'Part Quantity'
    },
    {
        id: uuidv1(),
        title: 'Fan Type'
    },
    {
        id: uuidv1(),
        title: 'Finished Weight'
    }, 
    {
        id: uuidv1(),
        title: 'Actual FW'
    },
    // {
    //     id: uuidv1(),
    //     title: 'Edit'
    // },
    // {
    //     id: uuidv1(),
    //     title: 'Status'
    // },
    // {
    //     id: uuidv1(),
    //     title: 'Submit'
    // }
]
const subcontractorboughtoutInwardsheading = [
    // {
    //     id: uuidv1(),
    //     title: 'No'
    // },
    {
        id: uuidv1(),
        title: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'SFG Part Name',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'SFG Code'
    },
    {
        id: uuidv1(),
        title: 'Part Code',
    },
    // {
    //     id: uuidv1(),
    //     title: 'Part Number'
    // },
    {
        id: uuidv1(),
        title: 'Part Description'
    },
    {
        id: uuidv1(),
        title: 'Part Quantity'
    },
    // {
    //     id: uuidv1(),
    //     title: 'Fan Type',
    // },
    // {
    //     id: uuidv1(),
    //     title: 'Finished Weight'
    // },
    // {
    //     id: uuidv1(),
    //     title: 'Submit'
    // }
]

const subcontractorboughtoutheading = [
    // {
    //     id: uuidv1(),
    //     title: 'No'
    // },
    {
        id: uuidv1(),
        title: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'SFG Part Name',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'SFG Code'
    },
    {
        id: uuidv1(),
        title: 'Part Code',
    },
    // {
    //     id: uuidv1(),
    //     title: 'Part Number'
    // },
    {
        id: uuidv1(),
        title: 'Part Description'
    },
    {
        id: uuidv1(),
        title: 'Part Quantity'
    },
    // {
    //     id: uuidv1(),
    //     title: 'Fan Type',
    // },
    // {
    //     id: uuidv1(),
    //     title: 'Finished Weight'
    // },
    // {
    //     id: uuidv1(),
    //     title: 'Available Quantity'
    // },
    // {
    //     id: uuidv1(),
    //     title: 'Short Quantity'
    // }
    // {
    //     id: uuidv1(),
    //     title: 'Submit'
    // }
]
const subcontractorinventryheadings = [
    {
        id: uuidv1(),
        title: 'No'
    },
    {
        id: uuidv1(),
        title: 'Serial.No'
    },
    {
        id: uuidv1(),
        title: 'Job Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'Order Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'Subcontractor Name'
    },
    {
        id: uuidv1(),
        title: 'Subcontractor Address'
    },
    {
        id: uuidv1(),
        title: 'Subcontractor Gst Number'
    },
    {
        id: uuidv1(),
        title: 'Subcontractor Worktype'
    },
    {
        id: uuidv1(),
        title: 'Subcontractor Status'
    },
    {
        id: uuidv1(),
        title: 'Part Description',
    },
    {
        id: uuidv1(),
        title: 'Part Number'
    },
    {
        id: uuidv1(),
        title: 'Fan Type'
    },
    {
        id: uuidv1(),
        title: 'Part Code'
    },
    {
        id: uuidv1(),
        title: 'Part Quantity'
    },
    {
        id: uuidv1(),
        title: 'Finished Weight'
    },
    {
        id: uuidv1(),
        title: 'Actual Finished Weight'
    }
]
const labels = [
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'March',
];
const JobUpdateslabels = [
'PDWG',
'MOM',
'CNC',
'HUB',
'MACHINING',
'IMPELLER',
'CASING',
'ACCESSORIES',
'FCP',
'ASSEMBLY',
'TESTING',
'FINAL PAINT',
'PACKING',
'DISPATCH',
];
const JobAmountUpdateslabels = [
    'JOB',
    'MOM & PLANNING',
    'PRODUCTION',
    'DISPATCH'
];

const backgroundColor = [
    'rgb(255, 99, 132)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)',
    'rgb(201, 203, 207)',
    'rgb(153, 102, 255)',
    'rgb(201, 203, 207)',
    'rgb(54, 162, 235)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
]
const processbackgroundColor = [
    'rgb(255, 99, 132)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)',
    'rgb(201, 203, 207)',
    'rgb(153, 102, 255)',
    'rgb(201, 203, 207)',
    'rgb(54, 162, 235)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    '#800000',
    '#A52A2A',
]
const borderColor = [
    'rgb(255, 99, 132)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)',
    'rgb(201, 203, 207)',
    'rgb(201, 203, 207)',
    'rgb(201, 203, 207)',
    'rgb(201, 203, 207)',
    'rgb(201, 203, 207)',
    'rgb(201, 203, 207)'
]
const procesborderColor = [
    'rgb(255, 99, 132)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)',
    'rgb(201, 203, 207)',
    'rgb(201, 203, 207)',
    'rgb(201, 203, 207)',
    'rgb(201, 203, 207)',
    'rgb(201, 203, 207)',
    'rgb(201, 203, 207)',
    '#800000',
    '#A52A2A',
]


const editRegisterTableHeading = [
    {
        id: uuidv1(),
        name: 'Edit'
    },
    {
        id: uuidv1(),
        name: 'Employee Number',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        name: 'User Name',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        name: 'Email',

    },
    {
        id: uuidv1(),
        name: 'Contact Number'
    },
    {
        id: uuidv1(),
        name: 'Role',
        filter: Boolean(true)
    }
]
const bomTableHeading = [
    {
        id: uuidv1(),
        name: 'Job Number'
    },
    {
        id: uuidv1(),
        name: 'Part Code'
    },
    {
        id: uuidv1(),
        name: 'Description',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        name: 'Required Quantity'
    },
    {
        id: uuidv1(),
        name: 'Edit'
    },
    {
        id: uuidv1(),
        name: 'Actual Available Quantity'
    },
    {
        id: uuidv1(),
        name: 'Available Quantity'
    },
    // {
    //     id: uuidv1(),
    //     name: 'Reserved'
    // },
    // {
    //     id: uuidv1(),
    //     name: 'Balance'
    // },
    {
        id: uuidv1(),
        name: 'Short Quantity'
    },
    {
        id: uuidv1(),
        name: 'Submit'
    }
]

const bomExcelHeading = [
    {
        id: uuidv1(),
        name: 'Job Number'
    },
    {
        id: uuidv1(),
        name: 'Part Code'
    },
    {
        id: uuidv1(),
        name: 'Description',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        name: 'Required Quantity'
    },
    
    {
        id: uuidv1(),
        name: 'Available Quantity'
    },
    {
        id: uuidv1(),
        name: 'Short Quantity'
    },
    // {
    //     id: uuidv1(),
    //     name: 'Submit'
    // }
]

const purchasebomTableHeading = [
    {
        id: uuidv1(),
        name: 'Job Number'
    },
    {
        id: uuidv1(),
        name: 'Part Code'
    },
    {
        id: uuidv1(),
        name: 'Description'
    },
    {
        id: uuidv1(),
        name: 'Required Quantity'
    },
    {
        id: uuidv1(),
        name: 'Edit'
    },
    {
        id: uuidv1(),
        name: 'Short Quantity'
    },
    // {
    //     id: uuidv1(),
    //     name: 'Motor Received Date'
    // },
    {
        id: uuidv1(),
        name: 'PO Released'
    },
    {
        id: uuidv1(),
        name: 'Boughtout Expected Date'
    },
    {
        id: uuidv1(),
        name: 'Boughtout Received Date'
    },
    {
        id: uuidv1(),
        name: 'Submit'
    }
]

const displaymomandplaningtableheading = [
    {
        id: uuidv1(),
        label: "ID.No"
    },
    {
        id: uuidv1(),
        label: "Serial.No"
    },
    {
        id: uuidv1(),
        label: "Job Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Fan Type",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Fan Model",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Qty"
    },
    {
        id: uuidv1(),
        label: "Bill Planned"
    },
    {
        id: uuidv1(),
        label: "Year Planned "
    },
    {
        id: uuidv1(),
        label: "Motor Scope"
    },
    {
        id: uuidv1(),
        label: "Payment Status on Date"
    },
    {
        id: uuidv1(),
        label: "Payment Terms"
    },
    {
        id: uuidv1(),
        label: "Remarks"
    },
]



const saleorderheading =[
    {
        id: uuidv1(),
        label: "SO Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "SO Category"
    },
    {
        id: uuidv1(),
        label: "SO Date"
    },
    {
        id: uuidv1(),
        label: "Customer PO Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Customer PO Date"
    },
    {
        id: uuidv1(),
        label: "PO Cleared Date"
    },
    {
        id: uuidv1(),
        label: "Need By Date"
    },
    {
        id: uuidv1(),
        label: "Request Date"
    },
    {
        id: uuidv1(),
        label: "Payment Terms"
    },
    {
        id: uuidv1(),
        label: "Customer Name"
    },
    {
        id: uuidv1(),
        label: "SO Released Status",
         filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "SO Released Date"
    },
    {
        id: uuidv1(),
        label: "Total Order Value"
    },
    {
        id: uuidv1(),
        label: "SalesRep Name"
    },
    {
        id: uuidv1(),
        label: "Fan Model"
    },
    {
        id: uuidv1(),
        label: "Class Of Construction"
    },
    {
        id: uuidv1(),
        label: "Drive Type"
    },
    {
        id: uuidv1(),
        label: "Inspection Type"
    },
    {
        id: uuidv1(),
        label: "Packing Type"
    },
    {
        id: uuidv1(),
        label: "Arrangement Type"
    },
    {
        id: uuidv1(),
        label: "OutletOrientation"
    },
    {
        id: uuidv1(),
        label: "Air flow"
    },
    {
        id: uuidv1(),
        label: "Casing MOC"
    },
    {
        id: uuidv1(),
        label: "Stand MOC"
    },
    {
        id: uuidv1(),
        label: "Impeller MOC"
    }, 
    {
        id: uuidv1(),
        label: "Shaft MOC"
    },
    {
        id: uuidv1(),
        label: "Inlet Cone MOC"
    },
    {
        id: uuidv1(),
        label: "Baseframe MOC"
    },
    {
        id: uuidv1(),
        label: "Motor Scope"
    },
    {
        id: uuidv1(),
        label: "Motor Power"
    },
    {
        id: uuidv1(),
        label: "Motor Poles"
    },
    {
        id: uuidv1(),
        label: "Motor Make"
    },
    {
        id: uuidv1(),
        label: "Motor Frame"
    },
    {
        id: uuidv1(),
        label: "SO Type"
    },
    {
        id: uuidv1(),
        label: "SO Quantity"
    },
    {
        id: uuidv1(),
        label: "SO Unit Price"
    },

]

const saleorderheadingnonadmin = [
    {
        id: uuidv1(),
        label: "SO Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "SO Category"
    },
    {
        id: uuidv1(),
        label: "SO Date"
    },
    {
        id: uuidv1(),
        label: "Customer PO Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "Customer PO Date"
    },
    {
        id: uuidv1(),
        label: "PO Cleared Date"
    },
    {
        id: uuidv1(),
        label: "Need By Date"
    },
    {
        id: uuidv1(),
        label: "Request Date"
    },
    {
        id: uuidv1(),
        label: "Payment Terms"
    },
    {
        id: uuidv1(),
        label: "Customer Name"
    },
    {
        id: uuidv1(),
        label: "SO Released Status",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "SO Released Date"
    },
    {
        id: uuidv1(),
        label: "SalesRep Name"
    },
    {
        id: uuidv1(),
        label: "Fan Model"
    },
    {
        id: uuidv1(),
        label: "Class Of Construction"
    },
    {
        id: uuidv1(),
        label: "Drive Type"
    },
    {
        id: uuidv1(),
        label: "Inspection Type"
    },
    {
        id: uuidv1(),
        label: "Packing Type"
    },
    {
        id: uuidv1(),
        label: "Arrangement Type"
    },
    {
        id: uuidv1(),
        label: "OutletOrientation"
    },
    {
        id: uuidv1(),
        label: "Air flow"
    },
    {
        id: uuidv1(),
        label: "Casing MOC"
    },
    {
        id: uuidv1(),
        label: "Stand MOC"
    },
    {
        id: uuidv1(),
        label: "Impeller MOC"
    },
    {
        id: uuidv1(),
        label: "Shaft MOC"
    },
    {
        id: uuidv1(),
        label: "Inlet Cone MOC"
    },
    {
        id: uuidv1(),
        label: "Baseframe MOC"
    },
    {
        id: uuidv1(),
        label: "Motor Scope"
    },
    {
        id: uuidv1(),
        label: "Motor Power"
    },
    {
        id: uuidv1(),
        label: "Motor Poles"
    },
    {
        id: uuidv1(),
        label: "Motor Make"
    },
    {
        id: uuidv1(),
        label: "Motor Frame"
    },
    {
        id: uuidv1(),
        label: "SO Type"
    },
    {
        id: uuidv1(),
        label: "SO Quantity"
    },

]

const displaySingleSalefooter = [

    {
        id: uuidv1(),
        label: "SO Number",
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        label: "SO Category"
    },
 
    {
        id: uuidv1(),
        label: "Fan Model"
    },
    {
        id: uuidv1(),
        label: "Class Of Construction"
    },
    {
        id: uuidv1(),
        label: "Drive Type"
    },
    {
        id: uuidv1(),
        label: "Inspection Type"
    },
    {
        id: uuidv1(),
        label: "Packing Type"
    },
    {
        id: uuidv1(),
        label: "Arrangement Type"
    },
    {
        id: uuidv1(),
        label: "OutletOrientation"
    },
    {
        id: uuidv1(),
        label: "Air flow"
    },
    {
        id: uuidv1(),
        label: "Casing MOC"
    },
    {
        id: uuidv1(),
        label: "Stand MOC"
    },
    {
        id: uuidv1(),
        label: "Impeller MOC"
    }, 
    {
        id: uuidv1(),
        label: "Shaft MOC"
    },
    {
        id: uuidv1(),
        label: "Inlet Cone MOC"
    },
    {
        id: uuidv1(),
        label: "Baseframe MOC"
    },
    {
        id: uuidv1(),
        label: "Motor Scope"
    },
    {
        id: uuidv1(),
        label: "Motor Power"
    },
    {
        id: uuidv1(),
        label: "Motor Poles"
    },
    {
        id: uuidv1(),
        label: "Motor Make"
    },
    {
        id: uuidv1(),
        label: "Motor Frame"
    },
    {
        id: uuidv1(),
        label: "SO Type"
    },
    {
        id: uuidv1(),
        label: "SO Quantity"
    },
    {
        id: uuidv1(),
        label: "SO Unit Price"
    },



]





const domesticlabels = [
    'Domestic Axial',
    'Domestic Centrifugal',
    'Railway',
    'Accessories & Spares'
];
const gelabels = [
    'GE Export',
    'GE India'
]
const alstormlabels = [
    'Punction Blower',
    'Machine Room Blower',
    'Traction Blower'
]
const barclabels = [
    'Barc spares',
    'Barc Centrifugal'
]
const processlabels = [
    'PDB',
    'Mom',
    'Store',
    'Purchase',
    'Cnc',
    'Hub',
    'Machining',
    'Impeller',
    'Casing',
    'FCP',
    'Assembly',
    'Testing',
    'Final Paint',
    'P & D',
    'Accessories'
];


const nullFinderandDateswapperSecond = (value) => {
    if (!value || value === "0-00-0000") {
        return null;
    }

    const dateParts = value.split("-");
    if (dateParts.length !== 3) {
        return null;
    }

    let day, month, year;
    if (dateParts[0].length === 4) {
        // YYYY-MM-DD format
        year = parseInt(dateParts[0]);
        month = parseInt(dateParts[1]) - 1;
        day = parseInt(dateParts[2]);
    } else {
        // DD-MM-YYYY format
        day = parseInt(dateParts[0]);
        month = parseInt(dateParts[1]) - 1;
        year = parseInt(dateParts[2]);
    }

    const date = new Date(year, month, day);
    return isNaN(date) ? null : date;
}


const nullFinderandDateswapper = (value) => {
    const result = (value === null || value === undefined) ? '' : value.split("-").reverse().join("-");
    return result
};



const exportdataExeclJob = (data) => {
    const exportObject = data.map((o) => {
        const structureobjectData = {
            "Order Num": o.order_num,
            "Job Number": o.job_num_1,
            "Fan Type": o.prod_code,
            "Fan Model": o.short_char_01,
            "Quantity": o.order_qty_1,
            "Purchase Status": o.purchase_status,
            "Production Status": o.job_pending_status,
            "Bill Planned": o.bill_planned,
            "Year Planned": o.year_planne,
            "Motor Scope": o.motor_scope,
            "Payment status on date": o.payment_status_on_date,
            "Payment terms": o.payment_terms,
            "Remarks": o.remarks
        }
        return structureobjectData
    })
    return exportObject
}

const exportchartdataexecl = (data) => {
    const allvalues = []
    const allkeys = ['Production Drawing & Bom', 'Mom', 'Store', 'Purchase', 'Cnc', 'Hub', 'Machining', 'Impeller', 'Casing', 'Firts Coat Paint', 'Assembly', 'Testing', 'Final Paint', 'Packing & Dispatching', 'Accessories']
    data.map((v) => {
        allvalues.push(v.Quantity);
    })
    const sendexecldata = {}
    for (var i = 0; i < allvalues.length; i++) {
        var allkey = allkeys[i];
        sendexecldata[allkey] = allvalues[i];
    }
    return [sendexecldata]
}



const mapSubJobValues = (data, details) => {
    const { subcontract_name, address, subcontractor_worktype, gst_no, dcnumber , actual_fw} = details
    let arr = []
    data.map((element, i) => {
        let inobj = {
            i: i,
            bom_id: element.id,
            orderid: element.orderid,
            order_num: element.order_num,
            prod_code: element.prod_code,
            part_code: element.part_code,
            part_number: element.part_number,
            sfg_part_name: element.sfg_part_name,
            sfg_code: element.sfg_code,
            job_num: element.job_num,
            serial_no: element.serial_no,
            part_no: element.part_no === null ? '' : element.part_no,
            descriptions: element.descriptions === null ? '' : element.descriptions,
            qty: element.qty === null ? '' : element.qty,
            finished_weight: element.finished_weight === null ? '' : element.finished_weight,
            actual_fw: actual_fw,
            subcontract_name: subcontract_name,
            address: address,
            gst_no: gst_no,
            work_type: subcontractor_worktype,
            dc_number: dcnumber
        }
        arr.push(inobj)
    })
    return arr
}

const mapSubJobinwardValues = (data, details) => {
    const {subcontractor_worktype, subcon_job_weight} = details
    let arr = []
    data.map((element, i) => {
        let inobj = {
            i: i,
            bom_id: element.bom_id,
            orderid: element.orderid,
            order_num: element.order_num,
            prod_code: element.prod_code,
            part_code: element.part_code,
            part_number: element.part_number,
            sfg_part_name: element.sfg_part_name,
            sfg_code:element.sfg_code,
            job_num: element.job_num,
            serial_no: element.serial_no,
            part_no: element.part_no === null ? '' : element.part_no,
            descriptions: element.descriptions === null ? '' : element.descriptions,
            qty: element.qty === null ? '' : element.qty,
            finished_weight: element.finished_weight === null ? '' : element.finished_weight,
            actual_fw: element.actual_fw === null ? '' : element.actual_fw,
            subcontract_name: element.subcontract_name,
            address: element.address,
            gst_no: element.gst_no,
            work_type: element.work_type,
            dc_number: element.dc_number,
            subcon_job_weight:subcon_job_weight,
            subcontractor_worktype:subcontractor_worktype

        }
        arr.push(inobj)
    })
    return arr
}

const mapmaterialpartcode = (data, details) => {
    const { material_type, material_standard, material_grade, material_thickness } = details
    let arr = []
    data.map((element, i) => {
        let inobj = {
            material_type: material_type,
            material_standard: material_standard,
            material_grade: material_grade,
            material_thickness: material_thickness,

        }
        arr.push(inobj)
    })
    return arr
}


const mapboughtoutpartcode = (data, details) => {
    const {  source, type_code, item_code, size, length } = details
    let arr = []
    data.map((element, i) => {
        let inobj = {
            source: source,
            type_code: type_code,
            item_code: item_code,
            size: size,
            length: length

        }
        arr.push(inobj)
    })
    return arr
}

const mapSubBroughtOutValues = (data, details) => {
    const { subcontract_name, address, subcontractor_worktype, gst_no, dcnumber } = details
    let arr = []
    data.map((element, i) => {
        let inobj = {
            i: i,
            bo_bom_id: element.id,
            bo_orderid: element.orderid,
            bo_order_num: element.order_num,
            bo_prod_code: element.prod_code,
            bo_job_num: element.job_num,
            bo_serial_no: element.serial_no,
            bo_part_no: element.part_no === null ? '' : element.part_no,
            bo_part_code: element.part_code === null ? '' : element.part_code,
            bo_sfg_code: element.sfg_code === null ? '' : element.sfg_code,
            bo_sfg_part_name: element.sfg_part_name === null ? '' : element.sfg_part_name,
            bo_descriptions: element.descriptions === null ? '' : element.descriptions,
            bo_qty: element.qty === null ? '' : element.qty,
            bo_available_qty: element.available_qty === null ? '' : element.available_qty,
            bo_short_qty: element.short_qty === null ? '' : element.short_qty,
            bo_subcontract_name: subcontract_name,
            bo_address: address,
            bo_gst_no: gst_no,
            bo_work_type: subcontractor_worktype,
            bo_dc_number: dcnumber
        }
        arr.push(inobj)
    })
    return arr
}

const mapSubBroughtOutinwardValues = (data, details) => {
    let arr = []
    data.map((element, i) => {
        let inobj = {
            i: i,
            bo_bom_id: element.bom_id,
            bo_orderid: element.orderid,
            bo_order_num: element.order_num,
            bo_prod_code: element.prod_code,
            bo_job_num: element.job_num,
            bo_serial_no: element.serial_no,
            bo_part_no: element.part_no === null ? '' : element.part_no,
            bo_part_code: element.part_code === null ? '' : element.part_code,
            bo_sfg_code: element.sfg_code === null ? '' : element.sfg_code,
            bo_sfg_part_name: element.sfg_part_name === null ? '' : element.sfg_part_name,
            bo_descriptions: element.descriptions === null ? '' : element.descriptions,
            bo_qty: element.qty === null ? '' : element.qty,
            bo_available_qty: element.available_qty === null ? '' : element.available_qty,
            bo_short_qty: element.short_qty === null ? '' : element.short_qty,
            bo_subcontract_name: element.subcontract_name,
            bo_address: element.address,
            bo_gst_no: element.gst_no,
            bo_work_type: element.work_type,
            bo_dc_number: element.dc_number
        }
        arr.push(inobj)
    })
    return arr
}


const exportdataExeclSubcontractorInventory = (data) => {
    const exportObject = data.map((o, i) => {
        const structureobjectData = {
            "ID.No": i + 1,
            "Serial.No": o.serial_no,
            "Job Number": o.job_num,
            "Order Number": o.order_num,
            "Subcontractor name": o.subcontract_name,
            "Address": o.address,
            "GST Number": o.gst_no,
            "Worktype": o.work_type,
            "Status": o.subcontract_job_status === 'true' ? 'Completed' : 'Pending',
            "Part Descriptions": o.descriptions,
            "Part Number": o.part_no,
            "Fantype": o.prod_code,
            "Part Code": o.part_code,
            "Part Quantity": o.qty,
            "Finished Weight": o.finished_weight,
            "Actual Finished Weight": o.actual_fw
        }
        return structureobjectData
    })
    return exportObject
}

const loopappend = (data, name) => {
    const formData = new FormData();
    formData.append(name, JSON.stringify(data))
    return formData
}

const loopBarcode = (data) => {
    const ar = []
    data.map((map) => {
        for (let i = 1; i <= map.qty; i++) {
            ar.push({
                i: i,
                order_num: map.order_num,
                job_num: map.job_num,
                part_code: map.part_code,
                descriptions: map.descriptions,
                qty: map.qty,
                sfg_code:map.sfg_code,
                customer_name:map.customer_name,
                prc_con_num:map.prc_con_num,
                need_by_date:map.need_by_date,
                request_date:map.request_date,
                short_char_01:map.short_char_01,
                serial_no:map.serial_no,
                finished_weight:map.finished_weight,
                matl_part_code:map.matl_part_code

            })
        }
    });
    return ar
}


const loopStoreBarcode = (data) => {
    const ar = []
    data.map((item) => {
        for (let i = 1; i <= item.onHandQty; i++) {
            ar.push({
                i: i,
                partCode: item.partCode,
                partDescriptions: item.partDescriptions,
                classDescription: item.classDescription,
                subClassDescription: item.subClassDescription,
                onHandQty: item.onHandQty,
                wareHouseCode: item.wareHouseCode,
                bin: item.bin,
                uom: item.uom,
                unitCost: item.unitCost,
                totalCost: item.totalCost,
                class: item.class,
                subclass: item.subclass,
                plant: item.plant,
                stockno:item.partCode +'-'+'ST00'+i

            })
        }
    });
    return ar
}
const loopQrcode = (QrCodeValue) => {
    const ar = []
    QrCodeValue.map((map) => {
        for (let i = 1; i <= map.qty; i++) {
            ar.push({
                i: i,
                part_no:map.part_no,
                partdescription:map.partdescription,
                fanmodel:map.fanmodel,
                fanmodel_no:map.fanmodel_no,
                qty:map.qty,
                material:map.material,
                weight:map.weight,
                stockno:map.stockno,
                location:map.location,
                rackwise:map.rackwise,
                location_rackno:map.location_rackno,
                dia:map.dia,
                job_no:map.job_no ,
                stockno:(map.part_no.slice(0, 2))+'-'+map.fanmodel+'-'+'ST00'+i,
                customer:map.customer
            })
        }
    });
    return ar
}




const loopStoreData = (data) => {
    const ar = []
    data.map((item) => {
        for (let i = 1; i <= item.onHandQty; i++) {
            ar.push({
                i: i,
                storeId: item.storeId,
                partCode: item.partCode,
                partDescriptions: item.partDescriptions,
                classDescription: item.classDescription,
                subClassDescription: item.subClassDescription,
                onHandQty: item.onHandQty,
                wareHouseCode: item.wareHouseCode,
                bin: item.bin,
                uom: item.uom,
                unitCost: item.unitCost,
                totalCost: item.totalCost,
                class: item.class,
                subclass: item.subclass,
                plant: item.plant
            })
        }
    });
    return ar
}

const saleexport = (data) => {
    const execlData = [];
    const roleName = JSON.parse(localStorage.getItem('roles'));
    data.map((excel) => {
     const obj = {
                  
                 "SONum":excel.SONum,
            "SOCategory":excel.SOCategory,
                "SODate":nullFinderandDateswapperSecond(excel.SODate),
            "CustomerPONumber": excel.CustomerPONum,
        "CustomerPODate":nullFinderandDateswapperSecond(excel.CustomerPODate),
         "POClearedDate":nullFinderandDateswapperSecond(excel.POClearedDate),
            "NeedByDate":nullFinderandDateswapperSecond(excel.NeedByDate),
           "RequestDate":nullFinderandDateswapperSecond(excel.RequestDate),
          "PaymentTerms":excel.PaymentTerms,
          "CustomerName":excel.CustomerName,
      "SOReleasedStatus":excel.SOReleasedStatus,
        "SOReleasedDate":nullFinderandDateswapperSecond(excel.SOReleasedDate),
          "SalesRepName":excel.SalesRepName,
              "FanModel":excel.FanModel,
   "ClassOfConstruction":excel.ClassOfConstruction,
             "DriveType":excel.DriveType,
          "InspectionTy":excel.InspectionTy,
           "PackingType":excel.PackingType,
           "Arrangement":excel.Arrangement,
     "OutletOrientation":excel.OutletOrientation,
               "Airflow":excel.Airflow,
             "CasingMOC":excel.CasingMOC,
              "StandMOC":excel.StandMOC,
           "ImpellerMOC":excel.ImpellerMOC,
              "ShaftMOC":excel. ShaftMOC,
          "InletConeMOC":excel.InletConeMOC,
          "BaseframeMOC":excel.BaseframeMOC,
            "MotorScope":excel.MotorScope,
            "MotorPower":excel.MotorPower,
            "MotorPoles":excel. MotorPoles,
             "MotorMake":excel.MotorMake,
            "MotorFrame":excel.MotorFrame,
                "SOType":excel.SOType,
            "SOQuantity":excel.SOQuantity,
        }
        if (roleName === 'Super Admin' || roleName === 'Admin') {
            obj["TotalOrderValue"] = excel.TotalOrderValue;
            obj["SOUnitPrice"] = excel.SOUnitPrice;
        }
        execlData.push(obj);
    })
    return execlData
}

const saleorderexport = (saleOrderReport) => {
    const execlData = [];
    saleOrderReport.map((excel) => {
        execlData.push({
            'SaleOrderNumber':excel.SaleOrderNumber,
            'SaleOrderCategory':excel.SaleOrderCategory,
            'SaleOrderDate':excel.SaleOrderDate,
            'CustomerPONumber':excel.CustomerPONumber,
            'CustomerPODate':excel.CustomerPODate,
            'POClearedDate':excel.POClearedDate,
            'NeedByDate':excel.NeedByDate,  
            'ShipByDate':excel.ShipByDate,  
            'PaymentTerms':excel.PaymentTerms,  
            'CustomerName':excel.CustomerName,
            'SaleOrderReleasedStatus':excel.SaleOrderReleasedStatus,
            'SaleOrderReleasedDate':excel.SaleOrderReleasedDate,
            'SalesPerson':excel.SalesPerson,
            'TotalValue':excel.TotalValue, 
            'MotorScope':excel.MotorScope, 
            'YearMonth':excel.YearMonth, 
       
        })
    })
    return execlData
}

const saleOrderChartDetailedReport = (detailedReport) => {
    const execlData = [];
    detailedReport.map((excel) => {
                execlData.push({ 
                    'SaleOrderNumber':excel.SaleOrderNumber,
                    'SaleOrderCategory':excel.SaleOrderCategory,
                    'SaleOrderDate':excel.SaleOderDate,
                    'FanType':excel.FanType,
                    'FanModel':excel.FanModel,
                    'ClassOfConstruction':excel.ClassOfConstruction,
                    'DriveType':excel.DriveType,
                    'InspectionType':excel.InspectionType,
                    'ArrangementType':excel.ArrangementType,  
                    'CasingMOC':excel.CasingMOC,  
                    'StandMOC':excel.StandMOC,  
                    'ImpellerMOC':excel.ImpellerMOC,
                    'ShaftMOC':excel.ShaftMOC,
                    'InletConeMOC':excel.InletConeMOC,
                    'BaseFrameMOC':excel.BaseFrameMOC,
                    'MotorPower':excel.MotorPower, 
                    'MotorPoles':excel.MotorPoles, 
                    'MotorMake':excel.MotorMake, 
                    'MotorScope':excel.MotorScope, 
                    'SaleOrderQuantity':excel.SaleOrderQuantity, 
                    'SaleOrderUnitPrice':excel.SaleOrderUnitPrice, 
                    'YearMonth':excel.YearMonth,
                })
            })
    return execlData
  
}

const salecategoryamountexport = (standardReport) => {
    const execlData = [];
    standardReport.map((excel) => {
        execlData.push({
            'SaleOrderNumber':excel.SaleOrderNumber,
            'SaleOrderCategory':excel.SaleOrderCategory,
            'SaleOrderDate':excel.SaleOrderDate,
            'CustomerPONumber':excel.CustomerPONumber,
            'CustomerPODate':excel.CustomerPODate,
            'POClearedDate':excel.POClearedDate,
            'NeedByDate':excel.NeedByDate,  
            'ShipByDate':excel.ShipByDate,  
            'PaymentTerms':excel.PaymentTerms,  
            'CustomerName':excel.CustomerName,
            'SaleOrderReleasedStatus':excel.SaleOrderReleasedStatus,
            'SaleOrderReleasedDate':excel.SaleOrderReleasedDate,
            'SalesPerson':excel.SalesPerson,
            'TotalValue':excel.TotalValue, 
            'MotorScope':excel.MotorScope, 
            'YearMonth':excel.YearMonth, 
    
        })
    })
    return execlData
}
const salecategoryamexport = (sparesReport) => {
    const execlData = [];
    sparesReport.map((excel) => {
                execlData.push({
                    'SaleOrderNumber':excel.SaleOrderNumber,
            'SaleOrderCategory':excel.SaleOrderCategory,
            'SaleOrderDate':excel.SaleOrderDate,
            'CustomerPONumber':excel.CustomerPONumber,
            'CustomerPODate':excel.CustomerPODate,
            'POClearedDate':excel.POClearedDate,
            'NeedByDate':excel.NeedByDate,  
            'ShipByDate':excel.ShipByDate,  
            'PaymentTerms':excel.PaymentTerms,  
            'CustomerName':excel.CustomerName,
            'SaleOrderReleasedStatus':excel.SaleOrderReleasedStatus,
            'SaleOrderReleasedDate':excel.SaleOrderReleasedDate,
            'SalesPerson':excel.SalesPerson,
            'TotalValue':excel.TotalValue, 
            'MotorScope':excel.MotorScope, 
            'YearMonth':excel.YearMonth,
                
                })
            })
    return execlData
  
}
const salecategoryotherexport = (sparesReport) => {
    const execlData = [];
    sparesReport.map((excel) => {
                execlData.push({
                    'SaleOrderNumber':excel.SaleOrderNumber,
                    'SaleOrderCategory':excel.SaleOrderCategory,
                    'SaleOrderDate':excel.SaleOrderDate,
                    'CustomerPONumber':excel.CustomerPONumber,
                    'CustomerPODate':excel.CustomerPODate,
                    'POClearedDate':excel.POClearedDate,
                    'NeedByDate':excel.NeedByDate,  
                    'ShipByDate':excel.ShipByDate,  
                    'PaymentTerms':excel.PaymentTerms,  
                    'CustomerName':excel.CustomerName,
                    'SaleOrderReleasedStatus':excel.SaleOrderReleasedStatus,
                    'SaleOrderReleasedDate':excel.SaleOrderReleasedDate,
                    'SalesPerson':excel.SalesPerson,
                    'TotalValue':excel.TotalValue, 
                    'MotorScope':excel.MotorScope, 
                    'YearMonth':excel.YearMonth,
                
                })
            })
    return execlData
  
}

const stdqtyexport = (standardQtyReport) => {
    const execlData = [];
    standardQtyReport.map((excel) => {
        execlData.push({
            'SaleOrderNumber':excel.SaleOrderNumber,
            'SaleOrderCategory':excel.SaleOrderCategory,
            'SaleOrderDate':excel.SaleOrderDate,
            'CustomerPONumber':excel.CustomerPONumber,
            'CustomerPODate':excel.CustomerPODate,
            'POClearedDate':excel.POClearedDate,
            'NeedByDate':excel.NeedByDate,  
            'ShipByDate':excel.ShipByDate,  
            'PaymentTerms':excel.PaymentTerms,  
            'CustomerName':excel.CustomerName,
            'SaleOrderReleasedStatus':excel.SaleOrderReleasedStatus,
            'SaleOrderReleasedDate':excel.SaleOrderReleasedDate,
            'SalesPerson':excel.SalesPerson,
            'TotalValue':excel.TotalValue, 
            'MotorScope':excel.MotorScope, 
            'YearMonth':excel.YearMonth,
        })
    })
    return execlData
}
const spareqtyexport = (spareQtyReport) => {
    const execlData = [];
    spareQtyReport.map((excel) => {
                execlData.push({
                    'SaleOrderNumber':excel.SaleOrderNumber,
                    'SaleOrderCategory':excel.SaleOrderCategory,
                    'SaleOrderDate':excel.SaleOrderDate,
                    'CustomerPONumber':excel.CustomerPONumber,
                    'CustomerPODate':excel.CustomerPODate,
                    'POClearedDate':excel.POClearedDate,
                    'NeedByDate':excel.NeedByDate,  
                    'ShipByDate':excel.ShipByDate,  
                    'PaymentTerms':excel.PaymentTerms,  
                    'CustomerName':excel.CustomerName,
                    'SaleOrderReleasedStatus':excel.SaleOrderReleasedStatus,
                    'SaleOrderReleasedDate':excel.SaleOrderReleasedDate,
                    'SalesPerson':excel.SalesPerson,
                    'TotalValue':excel.TotalValue, 
                    'MotorScope':excel.MotorScope, 
                    'YearMonth':excel.YearMonth,
                
                })
            })
    return execlData
  
}
const otherqtyexport = (otherQtyReport) => {
    const execlData = [];
    otherQtyReport.map((excel) => {
                execlData.push({
                    'SaleOrderNumber':excel.SaleOrderNumber,
                    'SaleOrderCategory':excel.SaleOrderCategory,
                    'SaleOrderDate':excel.SaleOrderDate,
                    'CustomerPONumber':excel.CustomerPONumber,
                    'CustomerPODate':excel.CustomerPODate,
                    'POClearedDate':excel.POClearedDate,
                    'NeedByDate':excel.NeedByDate,  
                    'ShipByDate':excel.ShipByDate,  
                    'PaymentTerms':excel.PaymentTerms,  
                    'CustomerName':excel.CustomerName,
                    'SaleOrderReleasedStatus':excel.SaleOrderReleasedStatus,
                    'SaleOrderReleasedDate':excel.SaleOrderReleasedDate,
                    'SalesPerson':excel.SalesPerson,
                    'TotalValue':excel.TotalValue, 
                    'MotorScope':excel.MotorScope, 
                    'YearMonth':excel.YearMonth,
                
                })
            })
    return execlData
  
}

const dispacthedChartReport = (dispatchedReport) => {
    const execlData = [];
    dispatchedReport.map((excel) => {
                execlData.push({
                    'OrderNumber':excel.OrderNumber,
                    'JobNumber':excel.JobNumber,
                    'SerialNumber':excel.SerialNumber, 
                    'Order Date':excel.OrderDate,
                    'ReleaseDate':excel.ReleaseDate,
                    'SalesRepName':excel.SalesRepName,
                    'CustomerName':excel.CustomerName,
                    'OrderQty':excel.OrderQty,
                    'CustomerType':excel.CustomerType,
                    'BillingGroup':excel.BillingGroup,
                    'Project':excel.Project,
                    'PODate':excel.PODate,
                    'PONum':excel.PONum,
                    'UnitPrice':excel.UnitPrice,  
                    'TotalValue':excel.TotalValue,                    
                    'NeedByDate':excel.NeedByDate,  
                    'RequestDate':excel.RequestDate, 
                    'MotorScope':excel.MotorScope, 
                    'MotorDriveType':excel.MotorDriveType,
                    'FanType':excel.FanType,
                    'FanModel':excel.FanModel,
                    'YearMonth':excel.YearMonth,
                
                })
            })
    return execlData
  
}
const readyTodispacthedChartReport = (readyToDispatchedReport) => {
    const execlData = [];
    readyToDispatchedReport.map((excel) => {
                execlData.push({
                    'OrderNumber':excel.OrderNumber,
                    'JobNumber':excel.JobNumber,
                    'SerialNumber':excel.SerialNumber, 
                    'Order Date':excel.OrderDate,
                    'ReleaseDate':excel.ReleaseDate,
                    'SalesRepName':excel.SalesRepName,
                    'CustomerName':excel.CustomerName,
                    'OrderQty':excel.OrderQty,
                    'CustomerType':excel.CustomerType,
                    'BillingGroup':excel.BillingGroup,
                    'Project':excel.Project,
                    'PODate':excel.PODate,
                    'PONum':excel.PONum,
                    'UnitPrice':excel.UnitPrice,  
                    'TotalValue':excel.TotalValue,                    
                    'NeedByDate':excel.NeedByDate,  
                    'RequestDate':excel.RequestDate, 
                    'MotorScope':excel.MotorScope, 
                    'MotorDriveType':excel.MotorDriveType,
                    'FanType':excel.FanType,
                    'FanModel':excel.FanModel,
                    'YearMonth':excel.YearMonth,
                                
                })
            })
    return execlData
  
}
const totalFanChartReport = (fanQtyReport) => {
    const execlData = [];
    fanQtyReport.map((excel) => {
                execlData.push({
                    'OrderNumber':excel.OrderNumber,
                    'JobNumber':excel.JobNumber,
                    'SerialNumber':excel.SerialNumber, 
                    'Order Date':excel.OrderDate,
                    'ReleaseDate':excel.ReleaseDate,
                    'SalesRepName':excel.SalesRepName,
                    'CustomerName':excel.CustomerName,
                    'OrderQty':excel.OrderQty,
                    'CustomerType':excel.CustomerType,
                    'BillingGroup':excel.BillingGroup,
                    'Project':excel.Project,
                    'PODate':excel.PODate,
                    'PONum':excel.PONum,
                    'UnitPrice':excel.UnitPrice,  
                    'TotalValue':excel.TotalValue,                    
                    'NeedByDate':excel.NeedByDate,  
                    'RequestDate':excel.RequestDate, 
                    'MotorScope':excel.MotorScope, 
                    'MotorDriveType':excel.MotorDriveType,
                    'FanType':excel.FanType,
                    'FanModel':excel.FanModel,
                    'YearMonth':excel.YearMonth,            
                })
            })
    return execlData
  
}
const DelayedProcessChartReport = (delayedReport) => {
    const execlData = [];
    delayedReport.map((excel) => {
                execlData.push({
                    'OrderNumber':excel.OrderNumber,
                    'JobNumber':excel.JobNumber,
                    'SerialNumber':excel.SerialNumber, 
                    'Order Date':excel.OrderDate,
                    'ReleaseDate':excel.ReleaseDate,
                    'SalesRepName':excel.SalesRepName,
                    'CustomerName':excel.CustomerName,
                    'OrderQty':excel.OrderQty,
                    'CustomerType':excel.CustomerType,
                    'BillingGroup':excel.BillingGroup,
                    'Project':excel.Project,
                    'PODate':excel.PODate,
                    'PONum':excel.PONum,
                    'UnitPrice':excel.UnitPrice,  
                    'TotalValue':excel.TotalValue,                    
                    'NeedByDate':excel.NeedByDate,  
                    'RequestDate':excel.RequestDate, 
                    'MotorScope':excel.MotorScope, 
                    'MotorDriveType':excel.MotorDriveType,
                    'FanType':excel.FanType,
                    'FanModel':excel.FanModel,
                    'YearMonth':excel.YearMonth,               
                })
            })
    return execlData
  
}

const salesTargetChartReport = (salesTargetReport) => {
    const execlData = [];
   salesTargetReport.map((excel) => {
                execlData.push({ 
                    'SaleOrderNumber':excel.SaleOrderNumber,
                    'SaleOrderCategory':excel.SaleOrderCategory,
                    'SaleOrderDate':excel.SaleOrderDate,
                    'CustomerPONumber':excel.CustomerPONumber,
                    'CustomerPODate':excel.CustomerPODate,
                    'POClearedDate':excel.POClearedDate,
                    'NeedByDate':excel.NeedByDate,  
                    'ShipByDate':excel.ShipByDate,  
                    'PaymentTerms':excel.PaymentTerms,  
                    'CustomerName':excel.CustomerName,
                    'SaleOrderReleasedStatus':excel.SaleOrderReleasedStatus,
                    'SaleOrderReleasedDate':excel.SaleOrderReleasedDate,
                    'SalesPerson':excel.SalesPerson,
                    'TotalValue':excel.TotalValue, 
                    'MotorScope':excel.MotorScope, 
                    'YearMonth':excel.YearMonth,
                })
            })
    return execlData
  
}

const processScreenChartReport = (processScreenReport) => {
    const execlData = [];
   processScreenReport.map((excel) => {
                execlData.push({
                    'OrderNumber':excel.OrderNumber,
                    'JobNumber':excel.JobNumber,
                    'SerialNumber':excel.SerialNumber, 
                    'Order Date':excel.OrderDate,
                    'ReleaseDate':excel.ReleaseDate,
                    'SalesRepName':excel.SalesRepName,
                    'CustomerName':excel.CustomerName,
                    'OrderQty':excel.OrderQty,
                    'CustomerType':excel.CustomerType,
                    'BillingGroup':excel.BillingGroup,
                    'Project':excel.Project,
                    'PODate':excel.PODate,
                    'PONum':excel.PONum,
                    'UnitPrice':excel.UnitPrice,  
                    'TotalValue':excel.TotalValue,                    
                    'NeedByDate':excel.NeedByDate,  
                    'RequestDate':excel.RequestDate, 
                    'MotorScope':excel.MotorScope, 
                    'MotorDriveType':excel.MotorDriveType,
                    'FanType':excel.FanType,
                    'FanModel':excel.FanModel,
                    'YearMonth':excel.YearMonth,    
                })
            })
    return execlData
  
}






const exporttoexcel = (data) => {
    const execlData = [];
    const roleName = JSON.parse(localStorage.getItem('roles'));
    data.map((excel) => {
       const obj = {
            "Order Number": excel.order_num,
            "Job Number": excel.job_num,
            "Order date": nullFinderandDateswapperSecond(excel.order_date),
            "Job release date": nullFinderandDateswapperSecond(excel.release_date),
            "Selling req qty": excel.order_qty,
            "Sales rep": excel.sales_rep_code,
            "Customer Name": excel.customer_name,
            "Customer Contact Number": excel.customer_contact_name,
            "Fan model": excel.short_char_01,
            "Customer city": excel.city,
            "Customer State": excel.state,
            "Customer contact phone": excel.phone_num,
            "Customer contact cellphone": excel.mobile_num,
            "Customer contact E-mail": excel.email_address,
            "Customer type": excel.customer_type,
            "Billing group": excel.billing_group,
            "Needby": nullFinderandDateswapperSecond(excel.need_by_date),
            "Shipby": nullFinderandDateswapperSecond(excel.request_date),
            "PO Cleared": nullFinderandDateswapperSecond(excel.date_06),
            "Inspection type": excel.reference,
            "Motor scope": excel.short_char_05,
            "Payment terms": excel.terms_code,
            "Motor drive type": excel.character_08,
            "Project": excel.project,
            "PO date": nullFinderandDateswapperSecond(excel.date_12),
            "Part number": excel.part_num,
            "Part Description": excel.line_desc,
            "PO Number": excel.prc_con_num,
            "Territory": excel.territory_id,
        }
        if (roleName === 'Super Admin' || roleName === 'Admin') {
            obj["Total value"] = excel.doc_order_amt;
            obj["Unit price"] = excel.doc_unit_price;
            obj["Discount"] = excel.doc_total_discount;
        }
    execlData.push(obj);
    })
    return execlData
}

const exportpmstoexcel = (data) => {
    const execlData = [];
    data.map((excel) => {
        execlData.push({
            "orderid": excel.orderid,
            "serial_no": excel.serial_no,
            "id_no": excel.id_no,
            "order_num": excel.order_num,
            "job_num": excel.job_num,
            "bom_released_edd": nullFinderandDateswapperSecond(excel.bom_released_edd),
            "production_drawing_edd": nullFinderandDateswapperSecond(excel.production_drawing_edd),
            "stores_edd": nullFinderandDateswapperSecond(excel.stores_edd),
            "cnc_edd": nullFinderandDateswapperSecond(excel.cnc_edd),
            "hub_edd": nullFinderandDateswapperSecond(excel.hub_edd),
            "machining_edd": nullFinderandDateswapperSecond(excel.machining_edd),
            "impeller_edd": nullFinderandDateswapperSecond(excel.impeller_edd),
            "casing_edd": nullFinderandDateswapperSecond(excel.casing_edd),
            "accessories_edd": nullFinderandDateswapperSecond(excel.accessories_edd),
            "first_coat_paint_edd": nullFinderandDateswapperSecond(excel.first_coat_paint_edd),
            "assembly_edd": nullFinderandDateswapperSecond(excel.assembly_edd),
            "final_paint_edd": nullFinderandDateswapperSecond(excel.final_paint_edd),
            "packing_dispatch_edd": nullFinderandDateswapperSecond(excel.packing_dispatch_edd),
            "mom_planning_edd": nullFinderandDateswapperSecond(excel.mom_planning_edd),
            "production_dg_committed_date": nullFinderandDateswapperSecond(excel.production_dg_committed_date),
            "bom_committed_date": nullFinderandDateswapperSecond(excel.bom_committed_date),
            "production_remarks": nullFinderandDateswapperSecond(excel.production_remarks),
            "mom_released_date": nullFinderandDateswapperSecond(excel.mom_released_date),
            "mom_remarks": nullFinderandDateswapperSecond(excel.mom_remarks),
            "report_submitted": nullFinderandDateswapperSecond(excel.report_submitted),
            "cnc_issued_date": nullFinderandDateswapperSecond(excel.cnc_issued_date),
            "cnc_completed_date": nullFinderandDateswapperSecond(excel.cnc_completed_date),
            "cnc_remarks": nullFinderandDateswapperSecond(excel.cnc_remarks),
            "hub_issued_date": nullFinderandDateswapperSecond(excel.hub_issued_date),
            "hub_completed_date": nullFinderandDateswapperSecond(excel.hub_completed_date),
            "hub_remarks": nullFinderandDateswapperSecond(excel.hub_remarks),
            "machining_issued_date": nullFinderandDateswapperSecond(excel.machining_issued_date),
            "machining_completed_date": nullFinderandDateswapperSecond(excel.machining_completed_date),
            "machining_remarks": nullFinderandDateswapperSecond(excel.machining_remarks),
            "impeller_issued_date": nullFinderandDateswapperSecond(excel.impeller_issued_date),
            "impeller_completed_date": nullFinderandDateswapperSecond(excel.impeller_completed_date),
            "impeller_remarks": nullFinderandDateswapperSecond(excel.impeller_remarks),
            "casing_issued_date": nullFinderandDateswapperSecond(excel.casing_issued_date),
            "casing_completed_date": nullFinderandDateswapperSecond(excel.casing_completed_date),
            "casing_remarks": nullFinderandDateswapperSecond(excel.casing_remarks),
            "accessories_issued_date": nullFinderandDateswapperSecond(excel.accessories_issued_date),
            "accessories_completed_date": nullFinderandDateswapperSecond(excel.accessories_completed_date),
            "accessories_remarks": nullFinderandDateswapperSecond(excel.accessories_remarks),
            "firstcoatpaint_actual_date": nullFinderandDateswapperSecond(excel.firstcoatpaint_actual_date),
            "assembly_actual_date": nullFinderandDateswapperSecond(excel.assembly_actual_date),
            "testing_actual_date": nullFinderandDateswapperSecond(excel.testing_actual_date),
            "finalpaint_actual_date": nullFinderandDateswapperSecond(excel.finalpaint_actual_date),
            "packing_actual_date": nullFinderandDateswapperSecond(excel.packing_actual_date),
        })
    })
    return execlData
}

const exportStoreDatatoexcel = (data) => {
    const execlData = [];
    data.map((item) => {
        execlData.push({
                "Part Code": item.partCode,
                "Part Descriptions": item.partDescriptions,
                "UOM" : item.uom,
                "onHandQty": item.onHandQty,
                "Unit Cost": item.unitCost,
                "Total Cost": item.totalCost,
                "Class Description": item.classDescription,
                "sub ClassDescription": item.subClassDescription,
                "WareHouseCode": item.wareHouseCode,
                "Bin": item.bin,
                "Class": item.class,
                "Sub Class": item.subclass,
                "Plant": item.plant,
                "stockno":item.partCode +'-'+ item.subclass+'-'+'ST00'+item.onHandQty,
        })
    })
    return execlData
}
const exportStockJobScreenDatatoexcel = (data) => {
    const execlData = [];
    data.map((item) => {
        execlData.push({
               "Work Order Number" : item.workOrderNum,
                "Part Code": item.partCode,
                "Stock Number": item.stockNumber,
                "Customer Name": item.customerName,
                "Fan Model": item.fanModel,
                "Work Type 1": item.workType1,
                "Work Type 2": item.workType2,
                "Qty": item.Qty,
                "ERP Job Num": item.ERPJobNum,
                "Weight": item.weight,
        })
    })
    return execlData
}
const exportPendingStocksScreenDatatoexcel = (data) => {
    const execlData = [];
    data.map((item) => {
        execlData.push({
               "Work Order Number" : item.workOrderNum,
                "Part Code": item.partCode,
                "Stock Number": item.stockNumber,
                "Customer Name": item.customerName,
                "Fan Model": item.fanModel,
                "Work Type 1": item.workType1,
                "Work Type 2": item.workType2,
                "Qty": item.Qty,
                "ERP Job Num": item.ERPJobNum,
                "Weight": item.weight,
                "workOrderStatus":item.workOrderStatus,
        })
    })
    return execlData
}
const exportIssuedStocksScreenDatatoexcel = (data) => {
    const execlData = [];
    data.map((item) => {
        execlData.push({
               "Work Order Number" : item.workOrderNum,
                "Part Code": item.partCode,
                "Stock Number": item.stockNumber,
                "Stock IssueNo":item.stockIssueNo,
                "Customer Name": item.customerName,
                "Fan Model": item.fanModel,
                "Work Type 1": item.workType1,
                "Work Type 2": item.workType2,
                "Qty": item.Qty,
                "ERP Job Num": item.ERPJobNum,
                "Weight": item.weight,
        })
    })
    return execlData
}

const exportjonbreporttoexcel= (data) => {
    const execlData = [];
    data.map((excel) => {
       const obj = {
            "Order Number": excel.OrderNumber,
            "Job Number": excel.JobNumber,
            "Billed Planned": nullFinderandDateswapperSecond(excel.BillPlanned),
            "Year Planned": nullFinderandDateswapperSecond(excel.YearPlanne),
            "Total Weight": excel.TotalWeight,
            "Order Date": nullFinderandDateswapperSecond(excel.OrderDate),
            "Job Release Date": nullFinderandDateswapperSecond(excel.JobReleaseDate),
            "Order Quantity": excel.OrderQuantity,
            "SalesRepName": excel.SalesRepName,
            "Customer Name": excel.CustomerName,
            "Customer city": excel.CustomerCity,
            "Customer State": excel.CustomerState,
            "Customer contact phone": excel.CustomerContactPhone,
            "Customer contact cellphone": excel.CustomerContactCellPhone,
            "Customer contact E-mail": excel.CustomerMail,
            "Customer Type": excel.CustomerType,
            "Billing group": excel.BillingGroup,
            "PO date": nullFinderandDateswapperSecond(excel.PODate),
            "PO Number": excel.PONumber,
            "Part number": excel.PartNumber,
            "Part Description": excel.PartDescription,
            "Needby": nullFinderandDateswapperSecond(excel.NeedBy),
            "Shipby": nullFinderandDateswapperSecond(excel.ShipBy),
            "PO Cleared": nullFinderandDateswapperSecond(excel.POCleared),
            "Motor scope": excel.MotorScope,
            "Payment terms": excel.PaymentTerms,
            "Motor drive type": excel.MotorDriveType,
            "Fan model": excel.FanModel,
        }
        if (roles === 'Super Admin' || roles === 'Admin') {
            obj["Total value"] = excel.doc_order_amt;
            obj["Unit price"] = excel.doc_unit_price;
            obj["Discount"] = excel.doc_total_discount;
        }
        execlData.push(obj);
    })
    return execlData
}


// Dashbrd Annual and Month Table Report

const DshbrdTableReportExport = (data) => {
    const execlData = [];
    const roleName = JSON.parse(localStorage.getItem('roles'));
    data.map((excel) => {
        const obj = {
            "Order Number": excel.order_num,
            "Order Date": excel.order_date,
            "Customer Number": excel.cust_num,
            "Release Date": nullFinderandDateswapperSecond(excel.release_date),
            "Territory ID": excel.territory_id,
            "Sales Rep Code": excel.sales_rep_code,
            "Sales Rep Name": excel.sales_rep_name,
            "Billing Group": excel.billing_group,
            "Customer Name": excel.customer_name,
            "Need By Date": excel.need_by_date,
            "Request Date": excel.request_date,
            "Order Line": excel.order_line,
            "Part Number": excel.part_num,
            "Line Description": excel.line_desc,
            "Order Quantity": excel.order_qty,
            "Product Code": excel.prod_code,
            "Document Total Miscellaneous": excel.doc_total_misc,
            "Document Total Discount": excel.doc_total_discount,
            "Exchange Rate": excel.exchange_rate,
            "Sales Category": excel.sales_category,
            };
        if (roleName === 'Super Admin' || roleName === 'Admin') {
            obj["Document Extended Price Detail"]= excel.doc_ext_price_dtl;
            obj["Document Total Charges"]= excel.doc_total_charges;
            obj["Order Book Value"] = excel.order_book_value;
            obj["Total Line Amount"]= excel.total_line_amount;
            obj["Doc_Unit_Price"] = excel.doc_unit_price;
            // obj["Line_Amount"] = excel.line_amount;
           }
           execlData.push(obj);
          });
    return execlData;
}


const DshbrdTableReportSalesExport = (data) => {
    const execlData = [];
    const roleName = JSON.parse(localStorage.getItem('roles'));
    data.map((excel) => {
        const obj = {
            "Order Number": excel.order_num,
            "Billing Group": excel.billing_group,
            "Job Number": excel.job_num,
            "Order Quantity": excel.order_qty,
            "Release_Date": nullFinderandDateswapperSecond(excel.release_date),
            "Sales_Rep_Code": excel.sales_rep_code,
            "Customer Name": excel.customer_name,
            "Prod_Code": excel.prod_code,
            "part_num": excel.part_num,
            "order_line": excel.order_line,
            "Line_Desc": excel.line_desc,
            "legal_number": excel.legal_number,
            "Invoice_Num": excel.invoice_num,
            "Invoice_line": excel.invoice_line,
            "Invoice_Date": nullFinderandDateswapperSecond(excel.invoice_date),
        };
        if (roleName === 'Super Admin' || role === 'Admin') {
            obj["Doc_Unit_Price"] = excel.doc_unit_price;
            // obj["Line_Amount"] = excel.line_amount;
            obj["Sale_Order_Value"] = excel.sale_order_value;
        }
        execlData.push(obj);
    });
    return execlData;
}



const PlannedChartReport = (data, title,titleKeyMap) => {
  const execlData = [];
    
  data.map((excel) => {
    const keyMap = titleKeyMap[title] || {};
    const mappedData = {};
    
    for (const [key, value] of Object.entries(keyMap)) {
      mappedData[key] = excel[value] || null;
    }
    
    execlData.push(mappedData);
  });

  return execlData;
};



const AllPlannedChartReport  = (data) => {
    const execlData = [];
    const roleName = JSON.parse(localStorage.getItem('roles'));
     data.map((excel) => {
        const obj = {
            "Order Number": excel.OrderNumber,
            "Job Number": excel.JobNumber,
            "Fan model": excel.FanModel,
            "Order Date": nullFinderandDateswapperSecond(excel.OrderDate),
             "Job Release Date": nullFinderandDateswapperSecond(excel.JobReleaseDate),
            "Customer Name": excel.CustomerName,
            "Order Quantity": excel.OrderQty,
        };
         if (roleName === 'Super Admin' || roleName === 'Admin') {
            obj["Unit price"] = excel.UnitPrice;
            obj["Total value"] = excel.TotalValue;
        }
         if (excel.PlannedDate) {
             obj["Planned Date"] = excel.PlannedDate ? nullFinderandDateswapperSecond(excel.PlannedDate) : null;
         }
        execlData.push(obj);
    });
    return execlData;
}


const ActualChartReport  = (data) => {
    const execlData = [];
    const roleName = JSON.parse(localStorage.getItem('roles'));
     data.map((excel) => {
          const obj = {
            "Order Number": excel.OrderNumber,
            "Job Number": excel.JobNumber,
            "Fan model": excel.FanModel,
            "Delayed Days": excel.DelayedDays,
            "Order Date": nullFinderandDateswapperSecond(excel.OrderDate),
            "Job Release Date":nullFinderandDateswapperSecond(excel.JobReleaseDate),
            "Customer Name": excel.CustomerName,
            "Order Quantity": excel.OrderQty,
        };
         if (roleName === 'Super Admin' || roleName === 'Admin') {
            obj["Unit price"] = excel.UnitPrice;
            obj["Total value"] = excel.TotalValue;
        };
         if (excel.PlannedDate || excel.ActualDate) {
             obj["Planned Date"] = excel.PlannedDate ? nullFinderandDateswapperSecond(excel.PlannedDate) : null;
             obj["Actual Date"] = excel.ActualDate ? nullFinderandDateswapperSecond(excel.ActualDate) : null;
         }
        execlData.push(obj);
    });
    return execlData
}

const DelayedChartReport = (data) => {
    const execlData = [];
     data.map((excel) => {
        execlData.push({
            "Job Number": excel.JobNumber,
            "Fan model": excel.FanModel,
            "Customer Name": excel.CustomerName,
            "Order Quantity": excel.OrderQty,
        })
    })
    return execlData
}


// Bom file sample report 


const bomFilesampleReport  = (data) => {
    const execlData = [];
     data.map((excel) => {
        execlData.push({
            "QA NO":'',
            "Line No":'',
            "Jobn No":'',
            "SFG Part Name":'',
            "SFG Code":'',
            "Part No":'',
            "Part Code":'',
            "Description":'',
            "Qty":'',
            "Length":'',
            "Width":'',
             "Area":'',
            "Finished Weight (kg.)":'',
            "Matl. Part code":'',
     })
    })
    return execlData
}

const momandplanFilesampleReport  = (data) => {
    const execlData = [];
     data.map((excel) => {
        execlData.push({
            "pms_id":excel.orderId,
            "job_num":excel.job_num,
            "production_drawing_edd":'',
            "bom_released_edd":'',
            "mom_planning_edd":'',
            "motor_delivery_edd":'',
            "cnc_edd":'',
            "casing_edd":'',
            "hub_edd":'',
            "machining_edd":'',
            "impeller_edd":'',
            "first_coat_paint_edd":'',
             "assembly_edd":'',
            "accessories_edd":'',
            "testing_edd":'',
            "final_paint_edd":'',
            "packing_dispatch_edd":'',
     })
    })
    return execlData
}


// const jobUpdateProductionReport  = (productionreport) => {
//     const execlData = [];
//     productionreport.map((excel) => {
//         execlData.push({
//             "Order Number": excel.OrderNumber,
//             "Job Number": excel.JobNumber,
//             "Fan model": excel.FanModel,
//             "Order Date": nullFinderandDateswapperSecond(excel.OrderDate),
//             "Job Release Date": nullFinderandDateswapperSecond(excel.JobReleaseDate),
//             "Customer Name": excel.CustomerName,
//             "Order Quantity": excel.OrderQty,
//             "Unit price": excel.UnitPrice,
//             "Total value": excel.TotalValue,
//         })
//     })
//     return execlData
// }
// const jobUpdatePlanningReport  = (planningreport) => {
//     const execlData = [];
//     planningreport.map((excel) => {
//         execlData.push({
//             "Order Number": excel.OrderNumber,
//             "Job Number": excel.JobNumber,
//             "Fan model": excel.FanModel,
//             "Order Date": nullFinderandDateswapperSecond(excel.OrderDate),
//             "Job Release Date": nullFinderandDateswapperSecond(excel.JobReleaseDate),
//             "Customer Name": excel.CustomerName,
//             "Order Quantity": excel.OrderQty,
//             "Unit price": excel.UnitPrice,
//             "Total value": excel.TotalValue,
//         })
//     })
//     return execlData
// }
// const jobUpdateMomReport  = (Momreport) => {
//     const execlData = [];
//     Momreport.map((excel) => {
//         execlData.push({
//             "Order Number": excel.OrderNumber,
//             "Job Number": excel.JobNumber,
//             "Fan model": excel.FanModel,
//             "Order Date": nullFinderandDateswapperSecond(excel.OrderDate),
//             "Job Release Date": nullFinderandDateswapperSecond(excel.JobReleaseDate),
//             "Customer Name": excel.CustomerName,
//             "Order Quantity": excel.OrderQty,
//             "Unit price": excel.UnitPrice,
//             "Total value": excel.TotalValue,
//         })
//     })
//     return execlData
// }
// const jobUpdatePackingReport  = (packingreport) => {
//     const execlData = [];
//     packingreport.map((excel) => {
//         execlData.push({
//             "Order Number": excel.OrderNumber,
//             "Job Number": excel.JobNumber,
//             "Fan model": excel.FanModel,
//             "Order Date": nullFinderandDateswapperSecond(excel.OrderDate),
//             "Job Release Date": nullFinderandDateswapperSecond(excel.JobReleaseDate),
//             "Customer Name": excel.CustomerName,
//             "Order Quantity": excel.OrderQty,
//             "Unit price": excel.UnitPrice,
//             "Total value": excel.TotalValue,
//         })
//     })
//     return execlData
// }
// const jobUpdateDispatchReport  = (dispatchreport) => {
//     const execlData = [];
//     dispatchreport.map((excel) => {
//         execlData.push({
//             "Order Number": excel.OrderNumber,
//             "Job Number": excel.JobNumber,
//             "Fan model": excel.FanModel,
//             "Order Date": nullFinderandDateswapperSecond(excel.OrderDate),
//             "Job Release Date": nullFinderandDateswapperSecond(excel.JobReleaseDate),
//             "Customer Name": excel.CustomerName,
//             "Order Quantity": excel.OrderQty,
//             "Unit price": excel.UnitPrice,
//             "Total value": excel.TotalValue,
//         })
//     })
//     return execlData
// }

const fanqtyexcel = (data) => {
    const execlData = [];
    data.map((excel) => {
        execlData.push({
         

        })
    })
    return execlData
}

const now = new Date();
const currentDate = format(now, 'yyyy-MM-dd');


const dctableheading = [
    {
        id: uuidv1(),
        title: 'No'
    },
    {
        id: uuidv1(),
        title: 'Subcontract Name',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'Job Num',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'Dc Num',
        filter: Boolean(true)
    },
    {
        id: uuidv1(),
        title: 'Dc Num view'
    }
]

export { targettableheadings, bomFilesampleReport,momandplanFilesampleReport
     ,pendingtaskheading, JobAmountUpdateslabels ,ActualChartReport,PlannedChartReport,
     DelayedChartReport,exportjonbreporttoexcel, JobUpdateslabels,jobreportscreenheadingnonadmin
      ,headingLabelsfornonadmin,jobreportscreenheading , jobScreenTableHeading,
      subcontractorOutwardsheading, subcontractorboughtoutInwardsheading, 
      subcontractorInwardsheading , exportpmstoexcel,saleOrderChartDetailedReport
    ,salesTargetChartReport,DelayedProcessChartReport,processScreenChartReport 
    ,totalFanChartReport ,dispacthedChartReport,readyTodispacthedChartReport,
    exportIssuedStocksScreenDatatoexcel ,exportPendingStocksScreenDatatoexcel
    ,exportStockJobScreenDatatoexcel,qrcodeStockJobsingleLayoutTableHeadings,
    qrcodeStockJobTableHeadings,singlestockhead,loopQrcode,exportStoreDatatoexcel
    ,loopStoreData,loopStoreBarcode,pendingStocksTableHeadings,IssuedStocksTabeleHeading
    ,StockIssueTabeleHeading,storescreendatatableheadings, headingLabels, 
    soloMomandPlaningTabelHeading, activeOrderTableHeading, displayMomandPlanning
    , pmsTabelHeadings, showjobscreentableheading, pmsTableHeader, 
    exportdataExeclJob, labels, borderColor, nullFinderandDateswapper
    , backgroundColor, editRegisterTableHeading, bomTableHeading, 
    domesticlabels, processlabels, gelabels, alstormlabels, barclabels,
    purchasebomTableHeading, exportchartdataexecl, subcontractorheading,
    singlejobScreenTableHeading, singleMomandPlaningTabelHeading, 
    subcontractorboughtoutheading, subcontractorinventryheadings,
    mapSubJobValues, mapSubBroughtOutValues, exportdataExeclSubcontractorInventory, 
    loopappend, displaymomandplaningtableheading, displaysingleMomandPlanning,
    loopBarcode, exporttoexcel, currentDate, mapSubJobinwardValues, 
    mapSubBroughtOutinwardValues, dctableheading,procesborderColor,processbackgroundColor,
    mapmaterialpartcode, mapboughtoutpartcode, partcodeTableHeading, DshbrdTableReportExport,
    bomExcelHeading, saleorderheading, displaySingleSalefooter, saleorderheadingnonadmin,
    saleexport, saleorderexport, fanqtyexcel, salecategoryamountexport, nullFinderandDateswapperSecond,
    salecategoryamexport, salecategoryotherexport, stdqtyexport, spareqtyexport, otherqtyexport, AllPlannedChartReport, DshbrdTableReportSalesExport }
