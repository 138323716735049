import React, { useState } from 'react'
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Card } from '@mui/material';
import { subcontractorinventryheadings } from './Variables/Variables';
import { filterData } from './FilterData';
import Filter from './Filter';
import Statusbar from './Statusbar';

function Subcontractorinventrytable({ data }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <Box sx={{ p: 2, borderRadius: 5 }} component={Card}>
            <TableContainer >
                <Table >
                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                        <TableRow>
                            {subcontractorinventryheadings.map((labels) =>
                                <TableCell key={labels.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ my: 'auto' }}>
                                            {labels.title}
                                        </Box>
                                        <Box>
                                            {labels.filter ? <Filter label={labels.title} setSearch={setSearch} /> : ''}
                                        </Box>
                                    </Box>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.filter((data) => filterData(data, search, {
                                searchFeildOne: data.job_num,
                                searchFeildTwo: data.order_num
                            })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((inventory, index) =>
                                <TableRow>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{inventory.serial_no}</TableCell>
                                    <TableCell align='center'>{inventory.job_num}</TableCell>
                                    <TableCell align='center'>{inventory.order_num}</TableCell>
                                    <TableCell>{inventory.subcontract_name}</TableCell>
                                    <TableCell>{inventory.address}</TableCell>
                                    <TableCell>{inventory.gst_no}</TableCell>
                                    <TableCell align='center'>{inventory.work_type}</TableCell>
                                    <TableCell>
                                        <Box>
                                            <Statusbar label={inventory.subcontract_job_status === 'true' ? 'Completed' : 'Pending'} color={inventory.subcontract_job_status === 'true' ? 'success' : 'error'} />
                                        </Box>
                                    </TableCell>
                                    <TableCell>{inventory.descriptions}</TableCell>
                                    <TableCell align='center'>{inventory.part_no}</TableCell>
                                    <TableCell>{inventory.prod_code}</TableCell>
                                    <TableCell>{inventory.part_code}</TableCell>
                                    <TableCell align='center'>{inventory.qty}</TableCell>
                                    <TableCell align='center'>{inventory.finished_weight}</TableCell>
                                    <TableCell align='center'>{inventory.actual_fw}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    )
}

export default Subcontractorinventrytable