import React from 'react'
import { Chip, Box } from '@mui/material';

function Statusbar({ label, color }) {
  return (
    <Box>
      <Chip sx={{ borderRadius: 1,width:'100%'}} label={label} color={color} />
    </Box>

  )
}

export default Statusbar