import React, { useEffect, useState } from 'react';
import { useBarcode } from 'react-barcodes';
import { Box, Typography, Grid } from '@mui/material';
import QRCode from 'qrcode';
import { TableContainer, TableHead, TableRow, TableCell, Table, TableBody } from '@mui/material';




function AllQrcode({ generateQrcode }) {
    
    const [imageUrl, setImageUrl] = useState('');
    useEffect(() => {
        const imageData = 'Part No - '+generateQrcode.part_no+'\nPART DESCRIPTION - ' + generateQrcode.partdescription+'\nFAN MODEL - ' + generateQrcode.fanmodel+'\nQTY - ' + generateQrcode.qty+'\nMaterial - '+ generateQrcode.material+'\nWeight (Kg) - '+ generateQrcode.weight+'\nStock No - '+ generateQrcode.stockno+'\nLocation - ' + generateQrcode.location+ '\nRack Wise - ' + generateQrcode.rackwise +'\nLocation (Rack No) - '+ generateQrcode.location_rackno +'\nDIA - '+ generateQrcode.dia +'\nCustomer - '+ generateQrcode.customer+'\nJob No - '+ generateQrcode.job_no 
        const generateQrCode = async () => {
            try {
                const response = await QRCode.toDataURL(imageData);

                setImageUrl(response);

            } catch (error) {
                console.log(error);
            }
        }
        generateQrCode()
    }, [])

  
    return (
        <Box sx={{ py: 1 }}>
            {/* <svg ref={inputRef} /> */}
            <TableContainer>
                            <Table>
                                
                                <TableBody>
                                  <TableCell>
                    {imageUrl && (
                        <a href={imageUrl} download>
                            <img src={imageUrl} alt="img" height="150" width="150" />
                        </a>
                    )}
               </TableCell>
               <TableCell>
      
         
               <Box> {'Part No - ' + generateQrcode?.part_no}</Box>
  
  <Box> {'PART DESCRIPTION - ' + generateQrcode?.partdescription}</Box>

  <Box> {'FAN MODEL - ' + generateQrcode?.fanmodel}</Box>

  <Box> {'QTY - ' + generateQrcode?.qty}</Box>

  <Box>
    {'Material - '+ generateQrcode?.material}
  </Box>
  <Box>
    {'Weight (Kg) - '+ generateQrcode?.weight}
  </Box>
  <Box>
    {'Stock No - '+ generateQrcode?.stockno}
  </Box>
  <Box>
    {'Job No - '+ generateQrcode.job_no }
  </Box>
    
       </TableCell> 
        </TableBody>
        </Table>
       </TableContainer>
                           
        </Box>
    )
}

export default AllQrcode