import React, { useState } from 'react'
import { Box, TableContainer, Card, TableHead, TableRow, TableCell, TableBody, Table, TablePagination, Checkbox } from '@mui/material';
import Filter from './../Filter';
import { nullFinderandDateswapper, singleMomandPlaningTabelHeading } from '../Variables/Variables';
import { filterJobScreen } from '../FilterData';


function MomandPlanningSingle({ data, setSelected, selected }) {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // this function is for selecting row checkbox
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  // this function is for select all checkbox
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data;
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <Box>
      {
        data.length !== 0 && (
          <>
            <TableContainer component={Card} sx={{ borderRadius: 2 }} >
              <Table >
                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                  <TableRow >
                    <TableCell sx={{
                      color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                    }} >
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box>
                          <Checkbox className='text-white' onChange={handleSelectAllClick} />
                        </Box>
                        <Box>
                          Select
                        </Box>
                      </Box>
                    </TableCell>
                    {
                      singleMomandPlaningTabelHeading.map(items =>
                        <TableCell key={items.id} sx={{ fontWeight: 600, color: 'white' }}>
                          <Box sx={{ display: 'flex',justifyContent:'center' }}>
                            <Box sx={{ my: 'auto' }}>
                              {items.label}
                            </Box>
                            <Box>
                              {items.filter ? <Filter label={items.label} setSearch={setSearch} /> : ''}
                            </Box>
                          </Box>
                        </TableCell>
                      )
                    }

                  </TableRow>
                </TableHead>
                <TableBody>

                  {
                    data.filter((data) => filterJobScreen(data, search, {
                      searchFeildOne: data.job_num_1,
                      searchFeildTwo: data.prod_code,
                      searchFeildThree: data.short_char_01
                    })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) => {
                      const isItemSelected = isSelected(items);
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={index}
                          role="checkbox"
                          onClick={(event) => handleClick(event, items)}
                        >
                          <TableCell align='center'>
                            <Box>
                              <Checkbox checked={isItemSelected} />
                            </Box>
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {items.id_no}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {items.serial_no}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {items.job_num_1}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>1</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )
      }
    </Box>
  )
}

export default MomandPlanningSingle