import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { displaySingleOrders, methodPost } from '../Services/Apivariables';
import useFetch from './../Services/useFetch';
import { TableContainer, TableHead, TableRow, TableCell, TablePagination, TableBody, Box, Table, Card, Paper } from '@mui/material';
import Filter from './../Comps/Filter';
import { displaymomandplaningtableheading, nullFinderandDateswapper } from '../Comps/Variables/Variables';
import Nodata from './../Comps/Nodata';
import { filterJobScreen } from '../Comps/FilterData';
import Heading from './../Comps/Heading';
import Statusloader from './../Comps/Statusloader';

function Displaysingleorders() {
  const location = useLocation();
  const orderid = location.state.id
  const jobNumber = new FormData();
  jobNumber.append("order_num", orderid);
  const { data, isloading } = useFetch(displaySingleOrders, methodPost, jobNumber);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!isloading) {
    return (
      <Statusloader />
    )
  } else {
    return (
      <>
        <Box sx={{ p: 3, borderRadius: 4 }}>
          <Box sx={{ py: 3 }}>
            <Heading title={'job screen orders'} />
          </Box>
          {data.length === 0 ? <Nodata /> : <>
            <TableContainer component={Card} sx={{ borderRadius: 2 }} >
              <Table >
                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                  <TableRow >
                    {
                      displaymomandplaningtableheading.map(items =>
                        <TableCell key={items.id} sx={{ fontWeight: 600, color: 'white' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ my: 'auto' }}>
                              {items.label}
                            </Box>
                            <Box>
                              {items.filter ? <Filter label={items.label} setSearch={setSearch} /> : ''}
                            </Box>
                          </Box>
                        </TableCell>
                      )
                    }
                  </TableRow>
                </TableHead>
                <TableBody>

                  {
                    data.filter((data) => filterJobScreen(data, search, {
                      searchFeildOne: data.job_num_1,
                      searchFeildTwo: data.prod_code,
                      searchFeildThree: data.short_char_01
                    })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) => (
                      <TableRow
                        hover
                        key={items.id}
                      >
                        <TableCell sx={{ textAlign: 'center' }}>
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {items.serial_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {items.job_num_1}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>1</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{items.bill_planned}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{items.year_planne}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{items.motor_scope}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{items.payment_status_on_date}</TableCell>
                        <TableCell sx={{ textAlign: 'justify' }}>{items.payment_terms}</TableCell>
                        <TableCell sx={{ textAlign: 'justify' }}>{items.remarks}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> </>}
        </Box>
      </>
    )
  }
}

export default Displaysingleorders