import React from 'react'
import { Box } from '@mui/system';
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, TablePagination, Card } from '@mui/material';
import Filter from './Filter';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { filterData } from './FilterData';
import { nullFinderandDateswapper } from './Variables/Variables';


function AllOrdersTabel({ data, headingLabels, subpage, planned }) {

    const roles = JSON.parse(localStorage.getItem('roles'));
    const allowedRoles = ["Super Admin", "Admin"];
    const role = allowedRoles.includes(roles) ? roles : null;
    
    const center = 'center';
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    return (
        <Box>
            <Box sx={{ p: 1, borderRadius: 4 }} component={Card}>
                <TableContainer sx={{ borderRadius: 2 }} >
                    <Table>
                        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                            <TableRow >
                                {
                                    headingLabels.map((hd) =>
                                        <TableCell key={hd.id} sx={{
                                            color: 'white', fontWeight: 600, textAlign: 'center'
                                        }}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    {hd.label}
                                                </Box>
                                                <Box>
                                                    {hd.filter ? <Filter search={search} label={hd.label} setSearch={setSearch} /> : ''}
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.filter((data) => filterData(data, search, {
                                    searchFeildOne: data.job_num,
                                    searchFeildTwo: data.order_num,
                                   
                                })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                                    <TableRow hover key={item.id} sx={{ cursor: 'pointer' }} onClick={() => {
                                        navigate(subpage, {
                                            state: {
                                                id: item.order_num,
                                                order_num: item.order_num,
                                                job_num: item.job_num,
                                                release_date: item.release_date,
                                                sales_rep_code: item.sales_rep_code,
                                                customer_name: item.customer_name,
                                                city: item.city,
                                                billing_group: item.billing_group,
                                                doc_order_amt: item.doc_order_amt,
                                                need_by_date: item.need_by_date,
                                                request_date: item.request_date,
                                                date_06: item.date_06,
                                                character_08: item.character_08,
                                                date_12: item.date_12,
                                                prc_con_num: item.prc_con_num
                                            }
                                        });
                                    }}>
                                        <TableCell sx={{ textAlign: center }}>{item.order_num}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.job_num}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.order_date)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.release_date)}</TableCell>
                                        {planned && (
                                            <>
                                                <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.bill_planned)}</TableCell>
                                                <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.year_planne)}</TableCell>
                                            </>
                                        )}
                                        <TableCell sx={{ textAlign: center }}>{item.order_qty}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.sales_rep_code}</TableCell>
                                        <TableCell >{item.customer_name}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.customer_contact_name}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.short_char_01}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.city}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.state}</TableCell>
                                        {/* {
                                            roles ===  role ?
                                            <>
                                            <TableCell sx={{ textAlign: center }}>{item.phone_num}</TableCell>
                                            <TableCell sx={{ textAlign: center }}>{item.mobile_num}</TableCell>
                                            <TableCell sx={{ textAlign: center }}>{item.email_address}</TableCell>
                                            </> :
                                            <></>
                                        } */}
                                        <TableCell sx={{ textAlign: center }}>{item.customer_type}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.billing_group}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.doc_order_amt}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.need_by_date)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.request_date)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.date_06)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.reference}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.short_char_05}</TableCell>
                                        <TableCell>{item.terms_code}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.character_08}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.project}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.date_12)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.part_num}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.line_desc}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.prc_con_num}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.territory_id}</TableCell>

                                        {roles === role && (
                                            <>
                                                <TableCell sx={{ textAlign: center }}>{item.doc_unit_price}</TableCell>
                                                <TableCell sx={{ textAlign: center }}>{item.doc_total_discount}</TableCell>
                                            </>
                                        )}

                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    )
}

export default AllOrdersTabel