import React, { Suspense } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

// this is pages
import Dashboard from './Pages/Dashboard';
import MomandPlaningScreen from './Pages/MomandPlaningScreen';
import SubContractorScreen from './Pages/SubContractorScreen';
import JobScreen from './Pages/JobScreen';
import SingleJobScreen from './Pages/SingleJobScreen';
import Solemomandplanning from './Pages/Solemomandplanning';
import TargetScreen from './Pages/TargetScreen';
import OtherScreenLayout from './Pages/OtherScreenLayout';
import OtherScreenLayoutSingle from './Pages/OtherScreenlayoutSingle';
import Login from './Pages/Login';
import Layout from './Layout';
import Displayordersotherscreen from './Pages/Displayordersotherscreen';
import Displaymomandplanningorders from './Pages/Displaymomandplanningorders';
import Displaysingleordersorderscreen from './Pages/Displaysingleordersorderscreen';
import Displayjobscreen from './Pages/Displayjobscreen';
import { useState } from 'react';
import Pagenotfound from './Pages/Pagenotfound';
import Register from './Pages/Register';
import Allregisteredusers from './Pages/Allregisteredusers';
import Editregistereduser from './Pages/Editregistereduser';
import Subcontractorinwardscreen from './Pages/Subcontractorinwardscreen';
import Subcontractorinventory from './Pages/Subcontractorinventory';
import Displaysingleorders from './Pages/Displaysingleorders';
import Displaymomandplanningsingleorder from './Pages/Displaymomandplanningsingleorder';
import Allsubcontractordcnumber from './Pages/Allsubcontractordcnumber';
import BomFileupload from './Pages/BomFileupload';
import MaterialPartCodeScreen from './Pages/MaterialPartCodeScreen';
import BoughtoutPartCodeScreen from './Pages/BoughtoutPartCodeScreen';
import SubComponentPartCodeScreen from './Pages/SubComponentPartCodeScreen';
import SFGPartCodeScreen from './Pages/SFGPartCodeScreen';
import FGPartCodeScreen from './Pages/FGPartCodeScreen';
import GenerateQRCode from './Comps/Qrcode/GenerateQRCode';
import Homepage from './Homepage';
import SaleOrder from './SaleOrder/SaleOrder';
import SaleOrderSingle from './SaleOrder/SaleOrderSingle';
import MgmtDashboard from './Pages/MgmtDashboard';
import StockScreen from './Pages/StockScreen';
import StoreScreen from './Pages/StoreScreen';
import StoreScreenQRTotal from './Pages/StoreScreenQRTotal';
import IssuedStockScreen from './Pages/IssuedStockScreen';
import PendingStockScreen from './Pages/PendingStocksScreen';
import StockIssueScreenLayoutSingle from './Pages/StockIssueScreenLayoutSingle';
import StoreScreenlayoutSingle from './Pages/StoreScreenLayoutSingle';
import Stock from './Comps/BarCodeModule/Stock';
import SingleStock from './Comps/BarCodeModule/SingleStock';
import QRCodeGenerationStockJobs from './Pages/QRCodeGenerationStockJobs';
import QRStockJobOrderSingleLayout from './Pages/QRStockJobOrderSingleLayout';
import DailyTasks from './Pages/DailyTasks';
import DelayedandPendingTasks from './Pages/DelayedandPendingTasks';
import JobReportScreen from './Pages/JobReportScreen';
import TrackStatus from './Pages/TrackStatus';
const Pms = React.lazy(() => import('./Pages/Pms'));



// this variable is for starting url
//this variable is for nadi local development 
// export const startUrl = `/nadipms/`;

//this variable is for local development 
export const startUrl = `/`;

// this variable for testing server
// export const startUrl = `/nadi/testing/`;

// this variable is for live server
// export const startUrl = `/nadi/web/`;

function Routes() {
    const auth = localStorage.getItem('auth');
    const [successOpen, setsuccessOpen] = useState(false);
    const [successMessage, setsuccessMessage] = useState('');
    const [successStatus, setsuccessStatus] = useState(false);
    const [successColor, setsuccessColor] = useState(false);


    return useRoutes([
        {
            path: `*`,
            element: <Pagenotfound />
        },
        {
            path: startUrl,
            element: <Login  setsuccessOpen={setsuccessOpen} setsuccessMessage={setsuccessMessage} setsuccessStatus={setsuccessStatus} setsuccessColor={setsuccessColor} />
        },
        {
            path: `${startUrl}app`,
            element: auth ? <Layout  successOpen={successOpen} setsuccessOpen={setsuccessOpen} successMessage={successMessage} successStatus={successStatus} successColor={successColor} /> : <Navigate to={startUrl} />,
            children: [

                { path: `${startUrl}app`, element: <Navigate to={`home`} /> },
                { path: `logout`, element: <Navigate to={startUrl} /> },
                { path: `dashboard`, element: <Dashboard /> },
                { path: `mgmtdashboard`, element: <MgmtDashboard /> },
                { path: `home`, element: <Homepage /> },
                { path: `orders`, element: <JobScreen /> },
                { path: `displayjoborders`, element: <Displayjobscreen /> },
                { path: `displayjoborders/displayjobsingleorders`, element: <Displaysingleorders /> },
                { path: `orders/singleorder`, element: <SingleJobScreen /> },
                { path: `momandplanning`, element: <MomandPlaningScreen /> },
                { path: `bomfileupload`, element: <BomFileupload /> },
                { path: `displaymomandplanningorders`, element: <Displaymomandplanningorders /> },
                { path: `displaymomandplanningorders/momandplanningsinglejobs`, element: <Displaymomandplanningsingleorder /> },
                { path: `momandplanning/singlemomandplanning`, element: <Solemomandplanning /> },
                { path: `otherscreen`, element: <OtherScreenLayout /> },
                { path: `pms`, element: <Suspense fallback={<div>Loading...</div>} > <Pms /></Suspense> },
                { path: `displayorders`, element: <Displayordersotherscreen /> },
                { path: `displayorders/displaysingleorder`, element: <Displaysingleordersorderscreen /> },
                { path: `otherscreen/processscreen`, element: <OtherScreenLayoutSingle /> },
                { path: `target`, element: <TargetScreen /> },
                { path: `materialpartcode`, element: <MaterialPartCodeScreen /> },
                { path: `boughtoutpartcode`, element: <BoughtoutPartCodeScreen /> },
                { path: `fgpartcode`, element: <FGPartCodeScreen /> },
                { path: `sfgpartcode`, element: <SFGPartCodeScreen /> },
                { path: `subcomponentpartcode`, element: <SubComponentPartCodeScreen />},
                { path: `register`, element: <Register /> },
                { path: `allregistedusers`, element: <Allregisteredusers /> },
                { path: `allregistedusers/editregistereduser`, element: <Editregistereduser /> },
                { path: `subcontractordcnumbers`, element: <Allsubcontractordcnumber /> },
                { path: `subcontractoroutwards`, element: <SubContractorScreen /> },
                { path: `subcontractorinwards`, element: <Subcontractorinwardscreen /> },
                { path: `subcontractorinventory`, element: <Subcontractorinventory /> },
                { path: `GenerateQRCode`, element: <GenerateQRCode /> },
                { path: `saleorder`, element: <SaleOrder /> },
                { path: `saleorder/saleordersingle`, element: <SaleOrderSingle /> },
                { path: `stockscreen`, element: <StockScreen /> },
                { path: `pendingstocks`, element: <PendingStockScreen /> },
                { path: `pendingstocks/pendingstocks2`, element: <StockIssueScreenLayoutSingle /> },
                { path: `issuedstocks`, element: <IssuedStockScreen /> },
                { path: `storescreen`, element: <StoreScreen /> },
                { path: `storescreen/storeQRscreen`, element: <StoreScreenlayoutSingle /> },
                { path: `storescreenQRTotal`, element: <StoreScreenQRTotal /> },
                { path: `stock`, element: <Stock /> },
                { path: `statustrack`, element: <TrackStatus /> },
                { path: `stock/singlestock`, element: <SingleStock /> },
                { path: `generateQRforstockjobs`, element: <QRCodeGenerationStockJobs /> },
                { path: `generateQRforstockjobs/generateQRforstockjobs2`, element: <QRStockJobOrderSingleLayout /> },
                { path: `dailytask`, element: <DailyTasks /> },
                { path: `delayedtask`, element: <DelayedandPendingTasks /> },
                { path: `jobreportscreen`, element: <JobReportScreen /> },
            ]
        }
    ])
}

export default Routes