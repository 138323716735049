import React, { useState } from "react";
import {
  Card,
  Grid,
  TextField,
  Button,
  Box,
  TablePagination,
  Typography,
  Checkbox,
} from "@mui/material";
import useFetch from "../Services/useFetch";
import Heading from "../Comps/Heading";
import {
  insertSFGPartcode,
  methodPost,
  methodGet
} from "../Services/Apivariables";
import SnackBar from "../Comps/SnackBar";
import { useForm } from "react-hook-form";
import axios from "axios";
import SFGPartCodeTable from "../Comps/SFGPartCodeTable";

function SFGPartCodeScreen() {
    const { register, formState: { errors }, reset, handleSubmit } = useForm();
    const { setrefreshpage: refreshpage } = useFetch(methodGet);
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [dummystate, setdummystate] = useState(false);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { data } = useFetch(methodGet);
     const [emptydata, setemptydata] = useState(false);
   
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };



const onSubmit = (data) => {
  const serverData = new FormData()
  for (var key in data) {
      serverData.append(key, data[key]);
  }
  axios({
      method: methodPost,
      url: insertSFGPartcode,
      data: serverData,
  }).then(res => {
      if (res.data.error) {
          setMessage(res.data.message)
          setOpen(true)
          setStatus(false)
          setColor(false)
      } else {
          setOpen(true)
          setMessage(res.data.message)
          setStatus(true)
          setColor(true)
          setdummystate(true)
          reset({
              source: "",
              type_code: "",
              item_code: "",
              size: "",
              length: ""
          })
      }
  }).catch(err => {
      console.log(err)
  });
}



  return (
    <Box variant="div" sx={{ p: 2 }}>
      <SnackBar
        open={open}
        message={message}
        setOpen={setOpen}
        color={color}
        status={status}
      />
  
      <Box sx={{ py: 3 }}>
        <Heading title={"SFG Part Code"} />
      </Box>
      <Box sx={{ p: 2, borderRadius: 4 }} component={Card}>
      
              <Box>
                <Grid container justifyContent="center">
                  <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                    <Grid container sx={{ p: 2 }} spacing={2}>
                      <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                        <TextField   
                          fullWidth
                          size="small"
                          label="Fan Model"
                          variant="outlined"
                          {...register("fanmodel", { required: true })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ maxLength: 8 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Fan Size"
                          variant="outlined"
                          {...register("fansize", { required: true })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ maxLength: 4 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Component Code"
                          variant="outlined"
                          {...register("componentcode", { required: true })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ maxLength: 8 }}
                        />
                      </Grid>
                    
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Box sx={{ pt: 3 }}>
                      <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
          
  
      </Box>
      <SFGPartCodeTable dummystate={dummystate} setdummystate={setdummystate} />
    </Box>
  );
}
// }
export default SFGPartCodeScreen;
