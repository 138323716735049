import React from 'react'
import { Box } from '@mui/system';
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, TablePagination, Card } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { filterJobScreen, filtersaleOrder } from '../Comps/FilterData';
import { nullFinderandDateswapper, nullFinderandDateswapperSecond, saleorderheading, saleorderheadingnonadmin } from '../Comps/Variables/Variables';
import Filter from '../Comps/Filter';


function SaleOrderTable({ data, subpage, planned }) {
    const center = 'center';
    const navigate = useNavigate();
    const roles = JSON.parse(localStorage.getItem('roles'));
    const role = "Super Admin" || "Admin";
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const headingLables = roles === role ? saleorderheading : saleorderheadingnonadmin; 

    return (
        <Box>
            <Box sx={{ p: 1, borderRadius: 4 }} component={Card}>
                <TableContainer sx={{ borderRadius: 2 }} >
                    <Table>
                        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                            <TableRow >
                                {
                                    headingLables.map((hd) =>
                                        <TableCell key={hd.id} sx={{
                                            color: 'white', fontWeight: 600, textAlign: 'center'
                                        }}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    {hd.label}
                                                </Box>
                                                <Box>
                                                    {hd.filter ? <Filter search={search} label={hd.label} setSearch={setSearch} /> : ''}
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.filter((data) => filtersaleOrder(data, search, {
                                    searchFeildOne: data.SONum,
                                    searchFeildTwo: data.CustomerPONum,
                                    searchFeildThree: data.SOReleasedStatus
                                })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                                    <TableRow hover key={item.SOId} sx={{ cursor: 'pointer' }} onClick={() => {
                                        navigate(subpage, {
                                            state: {
                                                SOId: item.SOId,
                                                sale_order_num: item.SONum,
                                                category: item.SOCategory,
                                                Sale_date: item.SODate,
                                                CustomerPONum: item.CustomerPONum,
                                                CustomerPODate: item.CustomerPODate,
                                                POClearedDate: item.POClearedDate,
                                                NeedByDate: item.NeedByDate,
                                                RequestDate: item.RequestDate,
                                                PaymentTerms: item.PaymentTerms,
                                                CustomerName: item.CustomerName,
                                                SOReleasedStatus: item.SOReleasedStatus,
                                                SOReleasedDate: item.SOReleasedDate,
                                                TotalOrderValue: item.TotalOrderValue,
                                                SalesRepName: item.SalesRepName,
                                                FanModel:item.FanModel,
                                                ClassOfConstruction:item.ClassOfConstruction,
                                                DriveType:item.DriveType,
                                                InspectionType:item.InspectionType,
                                                PackingType:item.PackingType,
                                                ArrangementType:item.ArrangementType,
                                                OutletOrientation:item.OutletOrientation,
                                                Airflow:item.Airflow,
                                                CasingMOC:item.CasingMOC,
                                                StandMOC:item.StandMOC,
                                                ImpellerMOC:item.ImpellerMOC,
                                                ShaftMOC:item.ShaftMOC,
                                                InletConeMOC:item.InletConeMOC,
                                                BaseframeMOC:item.BaseframeMOC,
                                                MotorScope:item.MotorScope,
                                                MotorPower:item.MotorPower,
                                                MotorPoles:item.MotorPoles,
                                                MotorMake:item.MotorMake,
                                                MotorFrame:item.MotorFrame,
                                                SOType:item.SOType,
                                                SOQuantity:item.SOQuantity,
                                                SOUnitPrice:item.SOUnitPrice

                                            }
                                        });
                                    }}>
                                        <TableCell sx={{ textAlign: center }}>{item.SONum}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.SOCategory}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.SODate)}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.CustomerPONum}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.CustomerPODate)}</TableCell>
                                                <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.POClearedDate)}</TableCell>
                                                <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.NeedByDate)}</TableCell>
                                                <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.RequestDate)}</TableCell>
                                           
                                        <TableCell sx={{ textAlign: center }}>{item.PaymentTerms}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.CustomerName}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.SOReleasedStatus}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.SOReleasedDate)}</TableCell>
                                        {roles === role && (
                                            <>
                                                <TableCell sx={{ textAlign: center }}>{item.TotalOrderValue}</TableCell>
                                            </>
                                        )}   
                                        
                                        
                                        <TableCell sx={{ textAlign: center }}>{item.SalesRepName}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.FanModel}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.ClassOfConstruction}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.DriveType}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.InspectionType}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.PackingType}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.ArrangementType}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.OutletOrientation}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.Airflow}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.CasingMOC}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.StandMOC}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.ImpellerMOC}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.ShaftMOC}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.InletConeMOC}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.BaseframeMOC}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.MotorScope}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.MotorPower}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.MotorPoles}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.MotorMake}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.MotorFrame}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.SOType}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.SOQuantity}</TableCell>
                                        { roles === role && (
                                        <>
                                         <TableCell sx={{ textAlign: center }}>{item.SOUnitPrice}</TableCell>
                                        </>
                                         )}                                                      
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    )
}

export default SaleOrderTable