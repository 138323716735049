import React from 'react'
import { Box, Typography ,Button, TextField, Checkbox, FormGroup, FormControlLabel, Grid } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from "axios";
import {jobupdatereport, jobupdates, pms} from '../../Services/Apivariables';
import { backgroundColor, borderColor,  ActualChartReport, JobUpdateslabels, AllPlannedChartReport, DelayedChartReport} from '../Variables/Variables';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ExportJobUpdateReportCSV } from '../ExportJobUpdateReportCSV';
import ChartPop_UpDialog from '../ChartPop_UpDialog';
import SnackBar from '../SnackBar';
import Pmsexporttoexecl from '../Exporttoexecl';

function JobUpdateTable({ data, title }) {

    const controller = new AbortController();
    const signal = controller.signal;
    const [dataOne, setDataOne] = useState([]);
    const [PMSdata, setPMSData] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [errorData , setErrorData]  = useState(true);
    const [checkDisable1 , setCheckDisable1] = useState(false);
    const [checkDisable2 , setCheckDisable2] = useState(false);
    const [checkDisable3 , setCheckDisable3] = useState(false);
    const [showyear , setShowyear] = useState(false);
    const [showWeeks, setShowWeeks] = useState(false);
    const [hideWeekly , setHideWeekly] = useState(false);
    const [currentCheck , setCurrentCheck] = useState('Yesterday');
    const [popupopen, setPopupopen] = useState(false);
    const [refreshWeeks, setRefreshWeeks] = useState(false);

  //   datepicker
       const [value, setValue] = React.useState(null);
       const [refresh, setRefresh] = React.useState(false);
 // Year Conversion
        const date = new Date(value);
        const YearDate = date.getFullYear();
    
  //   checkbox
      const [yesterdaychecked, setYesterdayChecked] = React.useState(true);
      const [weeklychecked, setWeeklyChecked] = React.useState(true);
      const [monthlychecked, setMonthlyChecked] = React.useState(true);

// report data 
        const [ActualJobReport, setActualJobReport] = useState([]);
        const [ActualPlanningReport, setActualPlanningReport] = useState([]);
        const [ActualProductionReport, setActualProductionReport] = useState([]);
        const [ActualMomReport, setActualMomReport] = useState([]);
        const [ActualPackingReport, setActualPackingReport] = useState([]);
        const [ActualDispatchReport, setActualDispatchReport] = useState([]);
        const [ActualCasings, setActualCasing]= useState([]);
        const [ActualPurchases, setActualPurchase]= useState([]);
        const [ActualFcp, setActualFcp]= useState([]);
        const [ActualStores, setActualStore]= useState([]);
        const [ActualFinalpaints, setActualFinalpaint]= useState([]);
        const [ActualAssemblys, setActualAssembly]= useState([]);
        const [ActualHubs, setActualHub]= useState([]);
        const [ActualCncs, setActualCnc]= useState([]);
        const [ActualAccessoriess, setActualAccessories]= useState([]);
        const [ActualImpellers, setActualImpeller]= useState([]);
        const [ActualTestings, setActualTesting]= useState([]);
        const [ActualMachinings, setActualMachining]= useState([]);

      const [PlannedJobReport, setPlannedJobReport] = useState([]);
      const [PlannedPlanningReport, setPlannedPlanningReport] = useState([]);
      const [PlannedProductionReport, setPlannedProductionReport] = useState([]);
      const [PlannedMomReport, setPlannedMomReport] = useState([]);
      const [PlannedPackingReport, setPlannedPackingReport] = useState([]);
      const [PlannedDispatchReport, setPlannedDispatchReport] = useState([]);
      const [PlannedCasings, setPlannedCasing]= useState([]);
      const [PlannedPurchases, setPlannedPurchase]= useState([]);
      const [PlannedFcp, setPlannedFcp]= useState([]);
      const [PlannedStores, setPlannedStore]= useState([]);
      const [PlannedFinalpaints, setPlannedFinalpaint]= useState([]);
      const [PlannedAssemblys, setPlannedAssembly]= useState([]);
      const [PlannedHubs, setPlannedHub]= useState([]);
      const [PlannedCncs, setPlannedCnc]= useState([]);
      const [PlannedAccessoriess, setPlannedAccessories]= useState([]);
      const [PlannedImpellers, setPlannedImpeller]= useState([]);
      const [PlannedTestings, setPlannedTesting]= useState([]);
      const [PlannedMachinings, setPlannedMachining]= useState([]);

      const [DelayedJobReport, setDelayedJobReport] = useState([]);
      const [DelayedPlanningReport, setDelayedPlanningReport] = useState([]);
      const [DelayedProductionReport, setDelayedProductionReport] = useState([]);
      const [DelayedMomReport, setDelayedMomReport] = useState([]);
      const [DelayedPackingReport, setDelayedPackingReport] = useState([]);
      const [DelayedDispatchReport, setDelayedDispatchReport] = useState([]);
      const [DelayedCasings, setDelayedCasing]= useState([]);
      const [DelayedPurchases, setDelayedPurchase]= useState([]);
      const [DelayedFcp, setDelayedFcp]= useState([]);
      const [DelayedStores, setDelayedStore]= useState([]);
      const [DelayedFinalpaints, setDelayedFinalpaint]= useState([]);
      const [DelayedAssemblys, setDelayedAssembly]= useState([]);
      const [DelayedHubs, setDelayedHub]= useState([]);
      const [DelayedCncs, setDelayedCnc]= useState([]);
      const [DelayedAccessoriess, setDelayedAccessories]= useState([]);
      const [DelayedImpellers, setDelayedImpeller]= useState([]);
      const [DelayedTestings, setDelayedTesting]= useState([]);
      const [DelayedMachinings, setDelayedMachining]= useState([]);
      

// To Show Pop Up List
      const [jobList, setJobList]=useState([]);


//  checkbox disabling and enabling

  const handleCheckBoxYesterdayChange = (event) => {
    setYesterdayChecked(event.target.checked);
    setCurrentCheck(event.target.name)
    if(event.target.checked){
    setCheckDisable1(false);
    setCheckDisable2(true);
    setCheckDisable3(true);
    }
    else{
        setCheckDisable1(false);
        setCheckDisable2(false);
        setCheckDisable3(false);
    }

    };

    const handleCheckBoxMonthlyChange = (event) => {
    setMonthlyChecked(event.target.checked);
    setCurrentCheck(event.target.name)
    if(event.target.checked){
    setCheckDisable1(true);
    setCheckDisable2(true);
    setCheckDisable3(false);
    setShowyear(true)
    setRefresh(false);
    setShowWeeks(false);
    setHideWeekly(true);
    }
    else{
        setCheckDisable1(false);
        setCheckDisable2(false);
        setCheckDisable3(false);
        setShowyear(false);
        setCurrentCheck('Yesterday');
        setValue(null);
        setRefresh(true);
        setErrorData(true);
        setHideWeekly(false);
    }
    };

    const handleCheckBoxWeekylChange = (event) => {
      setWeeklyChecked(event.target.checked);
      setCurrentCheck(event.target.name)
      if(event.target.checked){
      setCheckDisable1(true);
      setCheckDisable2(false);
      setCheckDisable3(true);
      setShowWeeks(true);
      setRefreshWeeks(true);
      }
      else{
          setCheckDisable1(false);
          setCheckDisable2(false);
          setCheckDisable3(false);
          setShowWeeks(false);
          setShowyear(false);
          setCurrentCheck('Yesterday');
          setSelectedWeek(result);
          setRefreshWeeks(false);
      }
  
      };


//  Graph Data Fetch

  // Yesterday Graph
    useEffect(() => {
     if (currentCheck === 'Yesterday'){
        const sendData = new FormData();
        sendData.append('checkbox', currentCheck)
        sendData.append('Department','' )
          axios({
            method:'POST',
            url:jobupdates,
            data:sendData,
          }).then(res => {
            if (res.data.error) {
              setMessage(res.data.message)
          } else {
            setDataOne(res.data.Data);
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)

          }
          }).catch(err => {
            console.log(err)
          });
     }
    }, [currentCheck])


    //Weekly Graph

    // get Current Week

    const todaydate = new Date();

    //find the year of the current date
     const oneJan =  new Date(todaydate.getFullYear(), 0, 1); 

     // calculating number of days in given year before a given date 
     const numberOfDays =  Math.floor((todaydate - oneJan) / (24 * 60 * 60 * 1000)); 

     // adding 1 since to current date and returns value starting from 0 
     const result = Math.ceil(( todaydate.getDay() + 1 + numberOfDays) / 7);  
     const [selectedWeek , setSelectedWeek] = useState(result); 
     let weekNumber;
     weekNumber = selectedWeek;
     if(weekNumber < 10){
      weekNumber = '0' + selectedWeek ;
     }
     
    
    useEffect(()=>{
      if (currentCheck === 'Weekly' && selectedWeek !== null){
          if(weekNumber !== null){
          const sendData = new FormData();
          sendData.append('checkbox', currentCheck)
          sendData.append('weekNumber', weekNumber)
          sendData.append('Department','' )
            axios({
              method:'POST',
              url:jobupdates,
              data:sendData,
            }).then(res => {
              if (res.data.error) {
                setMessage(res.data.message)
            } else {
              setDataOne(res.data.Data);
              setRefreshWeeks(true);
              setOpen(true)
              setMessage(res.data.message)
              setStatus(true)
              setColor(true)
            }
            }).catch(err => {
              console.log(err)
            });
    }
    }
    },[refreshWeeks]);


// Year Graph
    useEffect(() => {
        if (currentCheck === 'Year'){
            const sendData = new FormData();
            sendData.append('checkbox', YearDate)
            sendData.append('Department','' )
              axios({
                method:'POST',
                url:jobupdates,
                data:sendData,
              }).then(res => {
                if (res.data.error) {
                  setMessage(res.data.message)
              } else {
                setDataOne(res.data.Data);
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
              }
              }).catch(err => {
                console.log(err)
              });
         }
    }, [value])   


// DialogBox

  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedDept, setSelectedDept] = useState(null);

  const handleCellClick = (cellData, cellDept) => {
    setSelectedCell(cellData);
    setSelectedDept(cellDept);
    setPopupopen(true);
  };




// Report Fetching for Excel

    useEffect(() => {
        if (currentCheck === 'Yesterday'){
            const sendData = new FormData();
            sendData.append('checkbox', currentCheck)
            sendData.append('Department','' )
              axios({
                method:'POST',
                url:jobupdatereport,
                data:sendData,
              }).then(res => {
                if (res.data.error) {
                  setMessage(res.data.message)
              } else {

                setPlannedProductionReport(res.data.PlannedProduction);
                setPlannedMomReport(res.data.PlannedMom);
                setPlannedPackingReport(res.data.PlannedPacking);
                setPlannedDispatchReport(res.data.PlannedDispatch);
                setPlannedMachining(res.data.PlannedMachining)
                setPlannedAccessories(res.data.PlannedAccessories)
                setPlannedAssembly(res.data.PlannedAssembly)
                setPlannedCnc(res.data.PlannedCnc)
                setPlannedHub(res.data.PlannedHub)
                setPlannedFinalpaint(res.data.PlannedFinalPaint)
                setPlannedFcp(res.data.PlannedFcp)
                setPlannedImpeller(res.data.PlannedImpeller)
                setPlannedCasing(res.data.PlannedCasing)
                setPlannedTesting(res.data.PlannedTesting)

                setActualProductionReport(res.data.ActualProduction);
                setActualMomReport(res.data.ActualMom);
                setActualPackingReport(res.data.ActualPacking);
                setActualDispatchReport(res.data.ActualDispatch);
                setActualMachining(res.data.ActualMachining)
                setActualAccessories(res.data.ActualAccessories)
                setActualAssembly(res.data.ActualAssembly)
                setActualCnc(res.data.ActualCnc)
                setActualHub(res.data.ActualHub)
                setActualFinalpaint(res.data.ActualFinalPaint)
                setActualFcp(res.data.ActualFcp)
                setActualImpeller(res.data.ActualImpeller)
                setActualCasing(res.data.ActualCasing)
                setActualTesting(res.data.ActualTesting)

                setDelayedProductionReport(res.data.DelayedProduction);
                setDelayedMomReport(res.data.DelayedMom);
                setDelayedPackingReport(res.data.DelayedPacking);
                setDelayedDispatchReport(res.data.DelayedDispatch);
                setDelayedMachining(res.data.DelayedMachining)
                setDelayedAccessories(res.data.DelayedAccessories)
                setDelayedAssembly(res.data.DelayedAssembly)
                setDelayedCnc(res.data.DelayedCnc)
                setDelayedHub(res.data.DelayedHub)
                setDelayedFinalpaint(res.data.DelayedFinalPaint)
                setDelayedFcp(res.data.DelayedFcp)
                setDelayedImpeller(res.data.DelayedImpeller)
                setDelayedCasing(res.data.DelayedCasing)
                setDelayedTesting(res.data.DelayedTesting)


                setErrorData(res.data.error)
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
              }
              }).catch(err => {
                console.log(err)
              });
         }
      }, [currentCheck])

      useEffect(() => {
        if (currentCheck === 'Year'){
            const sendData = new FormData();
            sendData.append('checkbox', YearDate)
       sendData.append('Department','' )
              axios({
                method:'POST',
                url:jobupdatereport,
                data:sendData,
              }).then(res => {
                setPlannedProductionReport(res.data.PlannedProduction);
                setPlannedMomReport(res.data.PlannedMom);
                setPlannedPackingReport(res.data.PlannedPacking);
                setPlannedDispatchReport(res.data.PlannedDispatch);
                setPlannedMachining(res.data.PlannedMachining)
                setPlannedAccessories(res.data.PlannedAccessories)
                setPlannedAssembly(res.data.PlannedAssembly)
                setPlannedCnc(res.data.PlannedCnc)
                setPlannedHub(res.data.PlannedHub)
                setPlannedFinalpaint(res.data.PlannedFinalPaint)
                setPlannedFcp(res.data.PlannedFcp)
                setPlannedImpeller(res.data.PlannedImpeller)
                setPlannedCasing(res.data.PlannedCasing)
                setPlannedTesting(res.data.PlannedTesting)

                setActualProductionReport(res.data.ActualProduction);
                setActualMomReport(res.data.ActualMom);
                setActualPackingReport(res.data.ActualPacking);
                setActualDispatchReport(res.data.ActualDispatch);
                setActualMachining(res.data.ActualMachining)
                setActualAccessories(res.data.ActualAccessories)
                setActualAssembly(res.data.ActualAssembly)
                setActualCnc(res.data.ActualCnc)
                setActualHub(res.data.ActualHub)
                setActualFinalpaint(res.data.ActualFinalPaint)
                setActualFcp(res.data.ActualFcp)
                setActualImpeller(res.data.ActualImpeller)
                setActualCasing(res.data.ActualCasing)
                setActualTesting(res.data.ActualTesting)

                setDelayedProductionReport(res.data.DelayedProduction);
                setDelayedMomReport(res.data.DelayedMom);
                setDelayedPackingReport(res.data.DelayedPacking);
                setDelayedDispatchReport(res.data.DelayedDispatch);
                setDelayedMachining(res.data.DelayedMachining)
                setDelayedAccessories(res.data.DelayedAccessories)
                setDelayedAssembly(res.data.DelayedAssembly)
                setDelayedCnc(res.data.DelayedCnc)
                setDelayedHub(res.data.DelayedHub)
                setDelayedFinalpaint(res.data.DelayedFinalPaint)
                setDelayedFcp(res.data.DelayedFcp)
                setDelayedImpeller(res.data.DelayedImpeller)
                setDelayedCasing(res.data.DelayedCasing)
                setDelayedTesting(res.data.DelayedTesting)

                setErrorData(res.data.error)
                setRefresh(false);
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
              }).catch(err => {
                console.log(err)
              });
         }
    }, [refresh])

    useEffect(() => {
      if (currentCheck === 'Weekly' && selectedWeek !== null){
        if(weekNumber !== null){
        const sendData = new FormData();
        sendData.append('checkbox', currentCheck)
        sendData.append('weekNumber', weekNumber)
     sendData.append('Department','' )
            axios({
              method:'POST',
              url:jobupdatereport,
              data:sendData,
            }).then(res => {
              setPlannedProductionReport(res.data.PlannedProduction);
              setPlannedMomReport(res.data.PlannedMom);
              setPlannedPackingReport(res.data.PlannedPacking);
              setPlannedDispatchReport(res.data.PlannedDispatch);
              setPlannedMachining(res.data.PlannedMachining)
              setPlannedAccessories(res.data.PlannedAccessories)
              setPlannedAssembly(res.data.PlannedAssembly)
              setPlannedCnc(res.data.PlannedCnc)
              setPlannedHub(res.data.PlannedHub)
              setPlannedFinalpaint(res.data.PlannedFinalPaint)
              setPlannedFcp(res.data.PlannedFcp)
              setPlannedImpeller(res.data.PlannedImpeller)
              setPlannedCasing(res.data.PlannedCasing)
              setPlannedTesting(res.data.PlannedTesting)

              setActualProductionReport(res.data.ActualProduction);
              setActualMomReport(res.data.ActualMom);
              setActualPackingReport(res.data.ActualPacking);
              setActualDispatchReport(res.data.ActualDispatch);
              setActualMachining(res.data.ActualMachining)
              setActualAccessories(res.data.ActualAccessories)
              setActualAssembly(res.data.ActualAssembly)
              setActualCnc(res.data.ActualCnc)
              setActualHub(res.data.ActualHub)
              setActualFinalpaint(res.data.ActualFinalPaint)
              setActualFcp(res.data.ActualFcp)
              setActualImpeller(res.data.ActualImpeller)
              setActualCasing(res.data.ActualCasing)
              setActualTesting(res.data.ActualTesting)

              setDelayedProductionReport(res.data.DelayedProduction);
              setDelayedMomReport(res.data.DelayedMom);
              setDelayedPackingReport(res.data.DelayedPacking);
              setDelayedDispatchReport(res.data.DelayedDispatch);
              setDelayedMachining(res.data.DelayedMachining)
              setDelayedAccessories(res.data.DelayedAccessories)
              setDelayedAssembly(res.data.DelayedAssembly)
              setDelayedCnc(res.data.DelayedCnc)
              setDelayedHub(res.data.DelayedHub)
              setDelayedFinalpaint(res.data.DelayedFinalPaint)
              setDelayedFcp(res.data.DelayedFcp)
              setDelayedImpeller(res.data.DelayedImpeller)
              setDelayedCasing(res.data.DelayedCasing)
              setDelayedTesting(res.data.DelayedTesting)

              setErrorData(res.data.error)
              setRefresh(false);
              setOpen(true)
              setMessage(res.data.message)
              setStatus(true)
              setColor(true)
            }).catch(err => {
              console.log(err)
            });
       }
      }
  }, [refreshWeeks])  

    const resetReport = () =>{
      setRefresh(true);
    }

 

    const plannedproduction = PlannedProductionReport && AllPlannedChartReport(PlannedProductionReport);
    const plannedmom = PlannedMomReport && AllPlannedChartReport(PlannedMomReport);
    const plannedpacking = PlannedPackingReport && AllPlannedChartReport(PlannedPackingReport);
    const planneddispatch = PlannedDispatchReport && AllPlannedChartReport(PlannedDispatchReport);
    const plannedcasing =PlannedCasings && AllPlannedChartReport(PlannedCasings);
    const plannedimpeller =PlannedImpellers && AllPlannedChartReport(PlannedImpellers);
    const plannedassembly =PlannedAssemblys && AllPlannedChartReport(PlannedAssemblys);
    const plannedFcp =PlannedFcp && AllPlannedChartReport(PlannedFcp);
    const plannedaccessories =PlannedAccessoriess && AllPlannedChartReport(PlannedAccessoriess);
    const plannedcnc = PlannedCncs && AllPlannedChartReport(PlannedCncs);
    const plannedhub = PlannedHubs && AllPlannedChartReport(PlannedHubs);
    const plannedmachining = PlannedMachinings && AllPlannedChartReport(PlannedMachinings);
    const plannedfinalpaint = PlannedFinalpaints && AllPlannedChartReport(PlannedFinalpaints);
    const plannedtesting = PlannedTestings && AllPlannedChartReport(PlannedTestings);

    const Actualproduction = ActualProductionReport && ActualChartReport(ActualProductionReport);
    const Actualmom = ActualMomReport && ActualChartReport(ActualMomReport);
    const Actualpacking = ActualPackingReport && ActualChartReport(ActualPackingReport);
    const Actualdispatch = ActualDispatchReport && ActualChartReport(ActualDispatchReport);
    const Actualcasing =ActualCasings && ActualChartReport(ActualCasings);
    const Actualimpeller =ActualImpellers && ActualChartReport(ActualImpellers);
    const Actualassembly =ActualAssemblys && ActualChartReport(ActualAssemblys);
    const ActualFcps = ActualFcp && ActualChartReport(ActualFcp);
    const Actualaccessories =ActualAccessoriess && ActualChartReport(ActualAccessoriess);
    const Actualcnc = ActualCncs && ActualChartReport(ActualCncs);
    const Actualhub = ActualHubs && ActualChartReport(ActualHubs);
    const Actualmachining = ActualMachinings && ActualChartReport(ActualMachinings);
    const Actualfinalpaint = ActualFinalpaints && ActualChartReport(ActualFinalpaints);
    const Actualtesting = ActualTestings && ActualChartReport(ActualTestings);

    const Delayedproduction = DelayedProductionReport && DelayedChartReport(DelayedProductionReport);
    const Delayedmom = DelayedMomReport && DelayedChartReport(DelayedMomReport);
    const Delayedpacking = DelayedPackingReport && DelayedChartReport(DelayedPackingReport);
    const Delayeddispatch = DelayedDispatchReport && DelayedChartReport(DelayedDispatchReport);
    const Delayedcasing =DelayedCasings && DelayedChartReport(DelayedCasings);
    const Delayedimpeller =DelayedImpellers && DelayedChartReport(DelayedImpellers);
    const Delayedassembly =DelayedAssemblys && DelayedChartReport(DelayedAssemblys);
    const DelayedFcps = DelayedFcp && DelayedChartReport(DelayedFcp);
    const Delayedaccessories =DelayedAccessoriess && DelayedChartReport(DelayedAccessoriess);
    const Delayedcnc = DelayedCncs && DelayedChartReport(DelayedCncs);
    const Delayedhub = DelayedHubs && DelayedChartReport(DelayedHubs);
    const Delayedmachining = DelayedMachinings && DelayedChartReport(DelayedMachinings);
    const Delayedfinalpaint = DelayedFinalpaints && DelayedChartReport(DelayedFinalpaints);
    const Delayedtesting = DelayedTestings && DelayedChartReport(DelayedTestings);


   const index = jobList.map((i)=>i.dataIndex.toString());
   const reports = jobList.map((i)=>i.dataset.dummydata[index]);
   const mapped = reports.flatMap(i=>i.map((rep)=>rep));

   
  return (
    <Box>
       <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <Box py={2}>

        <Grid container>
        <Grid item xs={6} sm={6} md={6} lg={6}>
        <Box display='flex' justifyContent='start'>
        <Typography
          variant="h3"
          sx={{width:250 ,  textAlign: 'start', fontWeight: 600, fontSize: 25, textTransform: 'uppercase', color: 'primary.main' }}
        >
          {title}
        </Typography>
        </Box>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6} display='flex' justifyContent='end'>
        <Box display='flex' justifyContent='end'>

         {/* <Box>
          <Pmsexporttoexecl tableid={'pms-report'} filename={"PMS REPORT"} data={PMSdata}/>
        </Box> */}

        <Box>
        {
            errorData ? <></> :<ExportJobUpdateReportCSV
            plannedproduction={plannedproduction}  plannedmom={plannedmom} plannedcasing={plannedcasing} 
            plannedimpeller={plannedimpeller} plannedfinalpaint={plannedfinalpaint}
            plannedfirstcoatpaint={plannedFcp} plannedpacking={plannedpacking} 
            plannedassembly={plannedassembly} plannedcnc={plannedcnc} plannedhub={plannedhub} 
            plannedmachining={plannedmachining} plannedaccessories={plannedaccessories}
            plannedtesting={plannedtesting} planneddispatch={planneddispatch}
            Actualproduction={Actualproduction}  Actualmom={Actualmom} Actualcasing={Actualcasing} 
            Actualimpeller={Actualimpeller} Actualfinalpaint={Actualfinalpaint}
            Actualfirstcoatpaint={ActualFcps} Actualpacking={Actualpacking} 
            Actualassembly={Actualassembly} Actualcnc={Actualcnc} Actualhub={Actualhub} 
            Actualmachining={Actualmachining} Actualaccessories={Actualaccessories}
            Actualtesting={Actualtesting} Actualdispatch={Actualdispatch}
            Delayedproduction={Delayedproduction}  Delayedmom={Delayedmom} Delayedcasing={Delayedcasing} 
            Delayedimpeller={Delayedimpeller} Delayedfinalpaint={Delayedfinalpaint}
            Delayedfirstcoatpaint={DelayedFcps} Delayedpacking={Delayedpacking} 
            Delayedassembly={Delayedassembly} Delayedcnc={Delayedcnc} Delayedhub={Delayedhub} 
            Delayedmachining={Delayedmachining} Delayedaccessories={Delayedaccessories}
            Delayedtesting={Delayedtesting} Delayeddispatch={Delayeddispatch}
            fileName={'PLAN & ACTUAL DATA & INQUEUE'}/>
        }
        </Box>
        </Box>
       </Grid>
      </Grid>
      </Box>



  <Box sx={{ p: 2}} display='flex' justifyContent='center'>
{/* Default Checkbox view on Loading */}

            {
                !showyear && !showWeeks ? 
                <>
        <FormGroup sx={{px:1}}>
        <FormControlLabel control={<Checkbox onChange={handleCheckBoxYesterdayChange} name='Yesterday' disabled={checkDisable1}  />} label="Yesterday" />
        </FormGroup>
        </>:<></>
}
  {
    !hideWeekly ?  
    <FormGroup sx={{px:1}}>
    <FormControlLabel control={<Checkbox onChange={handleCheckBoxWeekylChange} name='Weekly' disabled={checkDisable2} />} label="Weekly" />
    </FormGroup>
    :<></>
  }

 {
    !showWeeks ?  
    <FormGroup sx={{px:1}}>
    <FormControlLabel control={<Checkbox onChange={handleCheckBoxMonthlyChange} name='Year' disabled={checkDisable3} />} label="Annual" />
    </FormGroup>
    :<></>
 }

{/* Month and Year Checkbox */}
        {
            showyear ? <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={['year']}
          label="Year"
          openTo='year'
          value={value}
          dateFormat="yyyy"
          onChange={(newValue) => {
            setValue(newValue);
          }}
          onClose={resetReport}
          renderInput={(params) => <TextField {...params} helperText={null} inputProps={{...params.inputProps, readOnly: true}} size='small' />}
        />
        </LocalizationProvider>
            </>
            :
            <></>
           }

{/* Weeks Checkbox */}



           {
            showWeeks ?
            <>
              <TextField
          onChange={(e)=>{
            setSelectedWeek(e.target.value)
            setRefreshWeeks(false);
          }}
          sx={{width:180}}
          value={selectedWeek}
          defaultValue={selectedWeek}
          type='number'
          size="small"
          label="Week Number"
          id="outlined-size-small"
          InputLabelProps={{
            shrink: true,
          }}
            /> 
            
            </>: <></>
          
           }

        
       <Box>
      <ChartPop_UpDialog open={popupopen} setOpen={setPopupopen} report={selectedCell} dept={selectedDept} />
      </Box>
      </Box>
          
          <Box>
        <TableContainer component={Paper} sx={{boxShadow:4}}>
        <Table>
        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
        <TableRow>    
         <TableCell align='center' sx={{ fontWeight: 600, color: 'white' , fontSize:13 }}>Department</TableCell>
        <TableCell align='center' sx={{ fontWeight: 600, color: 'white' , fontSize:13 }}>Planned Qty</TableCell>
        <TableCell align='center' sx={{ fontWeight: 600, color: 'white', fontSize:13 }}>Actual Qty</TableCell>
          <TableCell align='center' sx={{ fontWeight: 600, color: 'white', fontSize: 13 }}>InQueue Qty</TableCell>
     
        </TableRow>
        </TableHead>
        <TableBody>       
              {dataOne && dataOne.map((i) => (
                <TableRow>
              <TableCell align='center' >{i.Department}</TableCell>
                    <TableCell align='center' onClick={() => handleCellClick(i.Planned, i.Department)}>{i.Planned.length}</TableCell>
                    <TableCell align='center' onClick={() => handleCellClick(i.Actual, i.Department)}>{i.Actual.length}</TableCell>
                  <TableCell align='center' onClick={() => handleCellClick(i.Delayed, i.Department)}>{i.Delayed.length}</TableCell>
                 </TableRow>
                ))}
        </TableBody>
        </Table>
        </TableContainer>
        </Box>

    </Box>
  )
}

export default JobUpdateTable