import React, { useContext, useState } from 'react'
import {
    Paper,
    TextField,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    IconButton,
    TablePagination,
} from '@mui/material';
import { nullFinderandDateswapper, soloMomandPlaningTabelHeading } from './Variables/Variables';
import Filter from './Filter';
import DoneIcon from '@mui/icons-material/Done';
import submitForm from '../Services/ProcessScreenPostData';
import DialogBox from './DialogBox';
import SnackBar from './SnackBar';
import { getsingleMomandPlanning, insertMomandPlanning, methodPost, updateMomandPlanning } from '../Services/Apivariables';
import { filterJobScreen } from './FilterData';
import { MomdataContext } from '../Pages/Solemomandplanning';
import Nodata from './Nodata';
import styled from '@emotion/styled';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import sampleBom from '../Assets/sampleexcel/sampleBom.xls'
import MomandPlanningSingle from './OrdersModule/MomandPlanningSingle';
import { appendData } from './Variables/ProcessVariables';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';



const Input = styled('input')({
    display: 'none',
});

function Solomomandplanningtable({ setrefreshpage }) {
    const data = useContext(MomdataContext);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [id, setid] = useState('');
    const [selected, setSelected] = useState([]);
    const [multipleOrders, setMultipleOrders] = useState([]);
    const [fileUploaded, setFileUploaded] = useState(false);

    // this stores all inputs feilds in one state
    const [inputs, setInputs] = useState({});
    const [bomExcelFile, setBomExcelFile] = useState({});
    const [quantity, setQuantity] = useState('');

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const submit = (id) => {
        if (selected.length > 0) {
            const selectedJobs = selected.map((element, i) => {
                const obj = {
                    i: i,
                    orderid: element.orderid,
                    job_num: element.job_num_1,
                    production_drawing_edd: inputs.production_drawing_edd,
                    bom_released_edd: inputs.bom_released_edd,
                    mom_planning_edd: inputs.mom_planning_edd,
                    stores_edd: inputs.stores_edd,
                    motor_delivery_edd: inputs.motor_delivery_edd,
                    cnc_edd: inputs.cnc_edd,
                    hub_edd: inputs.hub_edd,
                    machining_edd: inputs.machining_edd,
                    impeller_edd: inputs.impeller_edd,
                    casing_edd: inputs.casing_edd,
                    accessories_edd: inputs.accessories_edd,
                    first_coat_paint_edd: inputs.first_coat_paint_edd,
                    assembly_edd: inputs.assembly_edd,
                    testing_edd: inputs.testing_edd,
                    final_paint_edd: inputs.final_paint_edd,
                    packing_dispatch_edd: inputs.packing_dispatch_edd,
                    remarks: inputs.remarks ? inputs.remarks : ''
                }
                return obj
            });
            const sendData = {
                "planning": JSON.stringify(selectedJobs),
                "orderqty": quantity,
                // "bom_excel_file": bomExcelFile
            }
            const lData = appendData(sendData)
            submitForm(insertMomandPlanning, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage)
            setInputs({
                production_drawing_edd: "",
                bom_released_edd: "",
                mom_planning_edd: "",
                stores_edd: "",
                motor_delivery_edd: "",
                cnc_edd: "",
                hub_edd: "",
                machining_edd: "",
                impeller_edd: "",
                casing_edd: "",
                accessories_edd:"",
                first_coat_paint_edd: "",
                assembly_edd: "",
                testing_edd: "",
                final_paint_edd: "",
                packing_dispatch_edd: "",
                remarks: ""
            });
            // setBomExcelFile({})
            fetchsinglejob(id)
            setIsEdit(false);
            setid(null)
            setSelected([])
        } else {
            setMessage("Please select atleast one job")
            setOpen(true)
            setStatus(false)
            setColor(false)
        }
    }

    const fetchsinglejob = (id) => {
        const oid = { job_num: id }
        const sendid = appendData(oid)
        axios({
            method: methodPost,
            url: getsingleMomandPlanning,
            data: sendid
        }).then(res => {
            if (res.data.data !== undefined) {
                setMultipleOrders(res.data.data)
            } else {
                setMessage("All orders are completed")
                setOpen(true)
                setStatus(false)
                setColor(false)
            }
        }).catch(err => {
            console.log(err)
        });
    }
    return (
        <>
            <Paper sx={{ p: 3, borderRadius: 4 }}>
                {data.length === 0 ? <Nodata /> : <>
                    <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
                    <DialogBox message={message} open={openDialog} setOpenDialog={setOpenDialog} />
                    {/* <Box sx={{ py: 1, textAlign: 'end' }}>
                        <Box>
                            <Button
                                sx={{ whiteSpace: 'nowrap' }}
                                variant="contained"
                                href={sampleBom}
                                target='_blank'
                                startIcon={<FileDownloadIcon fontSize='small'
                                />}>
                                Sample Bom File
                            </Button>
                        </Box>
                    </Box> */}
                    <TableContainer component={Card} sx={{ borderRadius: 2 }} >
                        <Table >
                            <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                                <TableRow >
                                    {
                                        soloMomandPlaningTabelHeading.map(items =>
                                            <TableCell key={items.id} sx={{ fontWeight: 600, color: 'white' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box sx={{ my: 'auto' }}>
                                                        {items.label}
                                                    </Box>
                                                    <Box>
                                                        {items.filter ? <Filter label={items.label} setSearch={setSearch} /> : ''}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    data.filter((data) => filterJobScreen(data, search, {
                                        searchFeildOne: data.job_num_1,
                                        searchFeildTwo: data.prod_code,
                                        searchFeildThree: data.short_char_01
                                    })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) => (
                                        <TableRow
                                            hover
                                            key={items.id}

                                        >
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                {index + 1}
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                {items.serial_no}
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                {items.job_num_1}
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <IconButton aria-label="edit" onClick={() => {
                                                    setIsEdit(true)
                                                    setid(items.id)
                                                    fetchsinglejob(items.job_num_1)
                                                    setQuantity(items.order_qty_1)
                                                    setInputs({
                                                        production_drawing_edd: "",
                                                        bom_released_edd: "",
                                                        mom_planning_edd: "",
                                                        stores_edd: "",
                                                        motor_delivery_edd: "",
                                                        cnc_edd: "",
                                                        hub_edd: "",
                                                        machining_edd: "",
                                                        impeller_edd: "",
                                                        casing_edd: "",
                                                        accessories_edd:"",
                                                        first_coat_paint_edd: "",
                                                        assembly_edd: "",
                                                        testing_edd: "",
                                                        final_paint_edd: "",
                                                        packing_dispatch_edd: "",
                                                        remarks: ""
                                                    });
                                                    // setBomExcelFile({})
                                                }}>
                                                    {isEdit && id === items.id ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        value={isEdit && id === items.id ? inputs.production_drawing_edd : ""}
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        name="production_drawing_edd"
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label="Production Drawing EDD"
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        value={isEdit && id === items.id ? inputs.bom_released_edd : ""}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        name="bom_released_edd"
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label="BOM Released Edd"
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            {/* <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <Box >
                                                        <label htmlFor="contained-button-file">
                                                            <Input
                                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                                onChange={(e) => {
                                                                    setBomExcelFile(e.target.files[0]);
                                                                    setFileUploaded(!fileUploaded)
                                                                }} id="contained-button-file" type="file" />
                                                            <Button
                                                                disabled={isEdit && id === items.id ? false : true}
                                                                variant="contained"
                                                                color={fileUploaded ? 'secondary' : 'primary'}
                                                                sx={{ whiteSpace: 'nowrap' }}
                                                                component="span"
                                                                startIcon={fileUploaded ? <DownloadDoneIcon /> : <UploadFileIcon />}>
                                                                {fileUploaded ? 'Bom Uploaded' : 'Bom Upload'}
                                                            </Button>
                                                        </label>
                                                    </Box>
                                                </Box>
                                            </TableCell> */}
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        value={isEdit && id === items.id ? inputs.mom_planning_edd : ""}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        name='mom_planning_edd'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='MOM Generation / Planning EDD'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        value={isEdit && id === items.id ? inputs.stores_edd : ""}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        name="stores_edd"
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label="Stores EDD"
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        value={isEdit && id === items.id ? inputs.motor_delivery_edd : ""}
                                                        name="motor_delivery_edd"
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='Motor Delivery EDD'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        value={isEdit && id === items.id ? inputs.cnc_edd : ""}
                                                        name='cnc_edd'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='CNC EDD'
                                                        variant="outlined"
                                                    />
                                                    {items.cnc_edd}
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        value={isEdit && id === items.id ? inputs.hub_edd : ""}
                                                        name='hub_edd'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='Hub EDD'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        value={isEdit && id === items.id ? inputs.machining_edd : ""}
                                                        name='machining_edd'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='Machining EDD'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        value={isEdit && id === items.id ? inputs.impeller_edd : ""}
                                                        name='impeller_edd'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='Impeller EDD'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        value={isEdit && id === items.id ? inputs.casing_edd : ""}
                                                        name='casing_edd'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='Casing EDD'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        value={isEdit && id === items.id ? inputs.Accessories_edd : ""}
                                                        name='accessories_edd'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='Accessories EDD'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        value={isEdit && id === items.id ? inputs.first_coat_paint_edd : ""}
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        name='first_coat_paint_edd'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='First Coat Paint EDD'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        value={isEdit && id === items.id ? inputs.assembly_edd : ""}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        name='assembly_edd'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='Assembly EDD'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        value={isEdit && id === items.id ? inputs.testing_edd : ""}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        name='testing_edd'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='Testing EDD'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        value={isEdit && id === items.id ? inputs.final_paint_edd : ""}
                                                        name='final_paint_edd'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='Final Paint EDD'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        type='date'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{ min: items.order_date }}
                                                        value={isEdit && id === items.id ? inputs.packing_dispatch_edd : ""}
                                                        name='packing_dispatch_edd'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='Packing &amp; Dispatch EDD'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <TextField
                                                        disabled={isEdit && id === items.id ? false : true}
                                                        value={isEdit && id === items.id ? inputs.remarks : ""}
                                                        sx={{ width: 140 }}
                                                        multiline
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        name='remarks'
                                                        onChange={handleChange}
                                                        size="small"
                                                        fullWidth
                                                        label='Remarks'
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>
                                                    <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => submit(items.id)} startIcon={<DoneIcon fontSize='small' />}>
                                                        submit
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
            </Paper>
            <Box sx={{ py: 3 }}>
                <MomandPlanningSingle data={multipleOrders} setSelected={setSelected} selected={selected} />
            </Box>
        </>
    )
}

export default Solomomandplanningtable