import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Card, Typography } from '@mui/material';
import { exportdataExeclJob, showjobscreentableheading } from '../Comps/Variables/Variables';
import Heading from './../Comps/Heading';
import Filter from './../Comps/Filter';
import { filterJobScreen } from '../Comps/FilterData';
import { useState } from 'react';
import { displayJobs, methodGet } from '../Services/Apivariables';
import useFetch from './../Services/useFetch';
import Statusloader from './../Comps/Statusloader';
import Nodata from './../Comps/Nodata';
import { ExportCSV } from './../Comps/ExportCSV';

function Displayjobscreen() {
    const { data, isloading, emptydata } = useFetch(displayJobs, methodGet);
    const navigate = useNavigate();
    const center = 'center';
    const justify = 'justify';
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // this variable is for export execl label custome structure
    const exportdata = exportdataExeclJob(data);

    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box variant="div" sx={{ p: 3 }}>
                <Box sx={{ py: 3 }}>
                    <Heading title={'Display Job Orders'} />
                </Box>
                <Box sx={{ py: 3 }}>
                    <Box>
                        {emptydata && data.length === 0 ? <Nodata /> : <>
                            <Box sx={{ py: 2 }}>
                                <ExportCSV csvData={exportdata} fileName={'Job_Orders_report'} />
                            </Box>
                            <Box sx={{ p: 1, borderRadius: 4 }} component={Card}>
                                <TableContainer sx={{ borderRadius: 2 }} >
                                    <Table>
                                        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                                            <TableRow >
                                                {
                                                    showjobscreentableheading.map((hd) =>
                                                        <TableCell key={hd.id} sx={{
                                                            px: 3,
                                                            color: 'white', fontWeight: 600
                                                        }}>
                                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Box sx={{ my: 'auto' }}>
                                                                    {hd.heading}
                                                                </Box>
                                                                <Box>
                                                                    {hd.filter ? <Filter search={search} label={hd.heading} setSearch={setSearch} /> : ''}
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                data.sort((a, b) => (a.job_status > b.job_status) ? 1 : -1).filter((data) => filterJobScreen(data, search, {
                                                    searchFeildOne: data.order_num,
                                                    searchFeildTwo: data.job_num_1,
                                                    searchFeildThree: data.prod_code
                                                })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                                                    <TableRow hover key={item.orderid}
                                                        // onClick={() => {
                                                        //     navigate("displayjobsingleorders", {
                                                        //         state: {
                                                        //             id: item.order_num,
                                                        //         }
                                                        //     });
                                                        // }}
                                                    >
                                                        <TableCell sx={{ textAlign: center }}>{item.job_num_1}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{item.order_num}</TableCell>
                                                        {/* <TableCell sx={{ textAlign: center }}>{item.job_num_1}</TableCell> */}
                                                        <TableCell sx={{ textAlign: center }}>{item.prod_code}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{item.short_char_01}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{item.order_qty_1}</TableCell>
                                                        <TableCell sx={{ textAlign: justify }}>{item.purchase_status}</TableCell>
                                                        <TableCell sx={{ textAlign: justify }}>{item.job_pending_status}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{item.bill_planned}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{item.year_planne}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{item.short_char_05_1}</TableCell>
                                                        {/* <TableCell sx={{ textAlign: center }}>{item.payment_status_on_date}</TableCell> */}
                                                        <TableCell sx={{ textAlign: justify }}>{item.terms_code_1}</TableCell>
                                                        <TableCell sx={{ textAlign: justify }}>{item.remarks}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box></>}
                    </Box>
                </Box>
                <Outlet />
            </Box>
        )
    }
}

export default Displayjobscreen