import React from 'react'
import { Box, Typography, Button , TextField } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { backgroundColor, borderColor, exporttoexcel, labels, totalFanChartReport } from '../Variables/Variables';
import { ExportCSV } from '../ExportCSV';
import { useForm } from "react-hook-form";
import { useState, useEffect } from 'react';
import { appendData } from '../Variables/ProcessVariables';
import axios from "axios";
import { fanqtyreport } from '../../Services/Apivariables';
import useFetch from '../../Services/useFetch';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

function BarChart({ data, title }) {

  const {register, formState: { errors }, handleSubmit, reset} = useForm();
  const[dateData , setDateData] = useState([]);
  const[fanQtyReport , setFanQtyReport] = useState([]);
  const [message, setMessage] = useState("");
  const [resetData , setresetData ] = useState(false);
  const [errorData , setErrorData]  = useState(true);

  const chartData = {
    labels: labels,
    datasets: [{
      label: 'Total Fan Quantity',
      data: data.map((graphdata) => graphdata.Quantity),
      backgroundColor: backgroundColor,
      borderColor: borderColor,
      borderWidth: 1,
    }
    ]
  };

  const handleChange =(e)=>{
    const {name, value} = e.target;
    setDateData({...dateData , [name]:value})
  }
 
  const handleClick = () =>{
    const sendData = {
      "fromDate": dateData.fromDate,
      'toDate' : dateData.toDate
    }
    const lData = appendData(sendData)
  
    axios({
      method:'POST',
      url:fanqtyreport,
      data:lData,
    }).then(res => {
      if (res.data.error) {
        setMessage(res.data.message)
    } else {
      setFanQtyReport(res.data.fanQtyReport)
      setMessage(res.data.message)
    }
    }).catch(err => {
      console.log(err)
    });
  }

useEffect(() => {

  const sendData = {
    "fromDate": "",
    'toDate' : ""
  }
  const lData = appendData(sendData)

  axios({
    method:'POST',
    url:fanqtyreport,
    data:lData,
  }).then(res => {
    if (res.data.error) {
      setMessage(res.data.message)
  } else {
    setFanQtyReport(res.data.fanQtyReport)
    setMessage(res.data.message)
    setErrorData(res.data.error)
  }
  }).catch(err => {
    console.log(err)
  });
}, [resetData])


  const resetClick =()=>{
    reset({
      fromDate:'',
      toDate:'',
    })
    setDateData({
      fromDate:"",
      toDate:""
    })
    setresetData(true);
  }

  const exporttoExcel = totalFanChartReport(fanQtyReport);

  return (
    <Box>
    <Box sx={{ py: 2, display:'flex',justifyContent:'space-evenly' }}>
      <Box>
        <Typography
          variant="h3"
          sx={{width:200 ,  textAlign: 'start', fontWeight: 600, fontSize: 25, textTransform: 'uppercase', color: 'primary.main' }}
        >
          {title}
        </Typography>
      </Box>
        <Box>
        {
            errorData ? <></> : <ExportCSV csvData={exporttoExcel} fileName={"Total Fan Qty Report"} />
        }
        </Box>
        </Box>
        <Box sx={{ py: 2}} display='flex' justifyContent='center'>
        <TextField
      error={errors.fromDate ? true : false}
      helperText={errors.fromDate ? "FromDate is required" : ""}
      {...register("fromDate", { required: true })}
      onChange={handleChange}
      sx={{width:180 , marginRight:2}}
      required
      type='date'
      size="small"
      label="FromDate"
      id="outlined-size-small"
      InputLabelProps={{
        shrink: true,
      }}
        />

      <TextField
    error={errors.toDate ? true : false}
    helperText={errors.toDate ? "ToDate is required" : ""}
    {...register("toDate", { required: true })}
    required
    onChange={handleChange}
    sx={{width:180}}
     type='date'
     size="small"
    label="ToDate"
    id="outlined-size-small"
    InputLabelProps={{
      shrink: true,
    }}
      />{
        dateData.length !== 0 && <>
      <Button onClick={handleClick}><FilterAltIcon size='small'  /></Button>
       <Button onClick={resetClick}><RestartAltIcon size='small' sx={{marginRight:3}} /></Button>
       </>
      }
      </Box>
      <Box>
        <Bar data={chartData} options={{
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: 'black',
                font: {
                  size: 12,
                  weight: '500',
                  family: "'Jost', sans-serif"
                }
              }
            },
            x: {
              ticks: {
                color: 'black',
                font: {
                  size: 12,
                  weight: '500',
                  family: "'Jost', sans-serif"
                }
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                font: {
                  size: 15,
                  weight: '500',
                  family: "'Jost', sans-serif"
                }
              },
              display: true,
              position: 'top'
            }
          }
        }} />
      </Box>
    </Box>
  )
}

export default BarChart