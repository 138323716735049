const filterData = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo } = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeildOne.toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toLowerCase().includes(search.toLowerCase())) {
    return data
  }
}

const filterJobScreen = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo, searchFeildThree } = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeildOne.toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toLowerCase().includes(search.toLowerCase()) || searchFeildThree.toLowerCase().includes(search.toLowerCase())) {
    return data
  }
}

const filtersaleOrder = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo, searchFeildThree } = searchfeild;
if (search === '') {
  return data
} else if ((searchFeildOne && searchFeildOne.toString().toLowerCase().includes(search.toString().toLowerCase())) || (searchFeildTwo && searchFeildTwo.toString().toLowerCase().includes(search.toString().toLowerCase())) || (searchFeildThree && searchFeildThree.toString().toLowerCase().includes(search.toString().toLowerCase()))) {
  return data
}
}


const filterJobReportScreen = (data, search, searchfeild) => {
  const { searchFeild1, searchFeild2, searchFeild3 } = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeild1.toLowerCase().includes(search.toLowerCase())){
    return data
  }
  
   else if (searchFeild2.toLowerCase().includes(search.toLowerCase())){
    return data
   }
   
   else if (searchFeild3.toLowerCase().includes(search.toLowerCase())) {
    return data
  }
}



const filterQRStockJobScreen = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo, searchFeildThree } = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeildOne.toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toLowerCase().includes(search.toLowerCase()) || searchFeildThree.toLowerCase().includes(search.toLowerCase())) {
    return data
  }
}
const filterIssuedStockJobScreen = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo, searchFeildThree,searchFeildFour } = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeildOne.toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toLowerCase().includes(search.toLowerCase()) || searchFeildThree.toLowerCase().includes(search.toLowerCase()) || searchFeildFour.toLowerCase().includes(search.toLowerCase()) ) {
    return data
  }
}


const filterStoreScreen = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo, searchFeildThree, searchFeildFour,
    searchFeildFive} = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeildOne.toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toLowerCase().includes(search.toLowerCase()) || 
  searchFeildThree.toLowerCase().includes(search.toLowerCase()) || searchFeildFour.toLowerCase().includes(search.toLowerCase()) || 
  searchFeildFive.toLowerCase().includes(search.toLowerCase())) {
    return data
  }
}




export { filterData ,filtersaleOrder, filterJobReportScreen ,filterIssuedStockJobScreen, filterJobScreen,filterStoreScreen,filterQRStockJobScreen  };