import React, { useContext, useState } from 'react'
import { Box } from '@mui/system';
import { Card, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, TablePagination, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { barCodeTableHeadings, singleImpellerTableHeading } from '../Variables/ProcessVariables';
import Heading from '../Heading';
import GenerateBarcode from './GenerateBarcode';
import ImpellerBom from './ImpellerBom';
import { bomdataContext } from './../../Pages/OtherScreenlayoutSingle';
import Nodata from './../Nodata';
import { loopBarcode } from '../Variables/Variables';
import PrintMultipleBarcodeButton from './PrintMultipleBarcodeButton';
import ImpellerSFGtable from './SFGQrCodetable/ImpellerSFGtable';
import Statusloader from '../Statusloader';

function ImpellerBarCodeTable({ impellerscreen, impellerBarcodeData, isloading }) {
    // const { impellerscreen, impellerBarcodeData } = useContext(bomdataContext)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const impellerscreenbarcodedata = loopBarcode(impellerscreen);

    if (!isloading) {
        return (
            <Statusloader />
        )
    } else { 
    
    return (
        <Box sx={{ py: 3 }}>
            <Box sx={{ py: 3 }}>
                <Heading title={'BarCode Table'} />
            </Box>
            {impellerscreen.length === 0 ? <Nodata /> : <TabContext value={value}>
                <Box>
                    <TabList
                        centered
                        onChange={handleChange} aria-label="lab API tabs example">
                        <Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Impeller Qrcode Generation Operations" value="1" />
                        <Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Impeller Total Qrcode Generation Operations" value="2" />
                        {impellerBarcodeData.length > 0 && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Impeller SFG Qrcode Generation Operations" value="3" />)}
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Box sx={{ py: 3 }}>
                        <Heading title={'Impeller Qrcode Generation Operations'} />
                    </Box>
                    <Box sx={{ p: 2 }} component={Card}>
                        <TableContainer>
                            <Table>
                                <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                    <TableRow >
                                        {
                                            barCodeTableHeadings.map((headingLabels) =>
                                                <TableCell key={headingLabels.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                    {headingLabels.name}
                                                </TableCell >
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        impellerscreenbarcodedata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((barCodeData) =>
                                            <TableRow>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.order_num}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.job_num}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.serial_no}</TableCell>
                                                {/* <TableCell sx={{ textAlign: 'center' }}>{barCodeData.part_code}{('00' + barCodeData.i).substring(1)}-{barCodeData.job_num}</TableCell> */}
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.part_code}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.descriptions}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                <GenerateBarcode barCodeValue={{job_num:barCodeData.job_num,serial_no:barCodeData.serial_no,short_char_01:barCodeData.short_char_01,finished_weight:barCodeData.finished_weight ,part_code:barCodeData.part_code,descriptions:barCodeData.descriptions,matl_part_code:barCodeData.matl_part_code,stage:'MET CODE - '}}  QrCodeData={'Manufacturer Name - NADI'+'\nCust.  Name - ' + barCodeData.customer_name +'\nFAN MODEL - ' + barCodeData.short_char_01 + '\nJOB NO - ' + barCodeData.job_num + '\nSERIAL NUMBER - ' +   barCodeData.serial_no+'\nPART CODE - '+ barCodeData.part_code + '\nPART DESC - ' + barCodeData.descriptions + '\nMet Code - ' + barCodeData.matl_part_code  + '\nWT / QTY - '+ barCodeData.finished_weight+' / '+barCodeData.qty}/></TableCell>
                                            </TableRow>
                                        )
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={impellerscreenbarcodedata.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </TabPanel>
                <TabPanel value="2">
                    {/* <ImpellerBom data={impellerscreen} /> */}
                    <Box sx={{ py: 3 }}>
                        <Heading title={'Impeller Total Qrcode Generation Operations'} />
                    </Box>
                    <PrintMultipleBarcodeButton barcodedata={impellerscreen} />
                    <Box sx={{ p: 2 }} component={Card}>
                        <TableContainer>
                            <Table>
                                <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                    <TableRow >
                                        {
                                            barCodeTableHeadings.map((headingLabels) =>
                                                <TableCell key={headingLabels.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                    {headingLabels.name}
                                                </TableCell >
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        impellerscreen.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((barCodeData) =>
                                            <TableRow>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.order_num}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.job_num}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.part_code}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{barCodeData.descriptions}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                <GenerateBarcode barCodeValue={{job_num:barCodeData.job_num,serial_no:barCodeData.serial_no,short_char_01:barCodeData.short_char_01,finished_weight:barCodeData.finished_weight ,part_code:barCodeData.part_code,descriptions:barCodeData.descriptions,matl_part_code:barCodeData.matl_part_code,stage:'MET CODE - '}}  QrCodeData={'Manufacturer Name - NADI'+'\nCust.  Name - ' + barCodeData.customer_name +'\nFAN MODEL - ' + barCodeData.short_char_01 + '\nJOB NO - ' + barCodeData.job_num + '\nSERIAL NUMBER - ' +   barCodeData.serial_no+'\nPART CODE - '+ barCodeData.part_code + '\nPART DESC - ' + barCodeData.descriptions + '\nMet Code - ' + barCodeData.matl_part_code  + '\nWT / QTY - '+ barCodeData.finished_weight+' / '+barCodeData.qty}/>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={impellerscreen.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </TabPanel>
                {impellerBarcodeData.length > 0 && (
                    <TabPanel value="3">
                        <ImpellerSFGtable isloading={isloading} data={impellerBarcodeData} title={"Impeller SFG Qrcode Generation Operations"} tableHeadings={barCodeTableHeadings} impellerBarcode={true} />
                    </TabPanel>
                    
                )}

            </TabContext>}
        </Box>
    )
}
}
export default ImpellerBarCodeTable