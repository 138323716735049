import React from 'react'
import { Box } from '@mui/system';
import Heading from './../Comps/Heading';
import RegisterForm from '../Comps/RegisterForm';

function Register() {

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ py: 3 }}>
                <Heading title={'Register'} />
            </Box>
            <RegisterForm />
        </Box>
    )
}

export default Register