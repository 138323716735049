import React from 'react'
import { useState } from 'react';
import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { filterJobScreen } from './../FilterData';
import Filter from './../Filter';
import Heading from './../Heading';
import { showHubTableHeading } from '../Variables/ProcessVariables';
import Statusbar from './../Statusbar';
import { nullFinderandDateswapper } from '../Variables/Variables';
import Nodata from './../Nodata';
import { useEffect } from 'react';
import axios from 'axios';
import { otherScreenSingleOrders } from '../../Services/Apivariables';
import HubBarCodeTable from '../BarCodeModule/HubBarCodeTable';


function Displayhub({ data, title , jobnum }) {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [isloading, setIsloading] = useState(false);
  const [refreshpage, setrefreshpage] = useState(false);

  const [hubscreen, setHubscreen] = useState([]);
  const [hubBarcodeData, setHubBarcodeData] = useState([]);
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  useEffect(() => {
      const sendData = new FormData();
      sendData.append('job_num', jobnum)
      sendData.append('department', title)
      axios({
        method: 'POST',
        url: otherScreenSingleOrders,
        data: sendData,
      }).then(res => {
        if (res.data.error) {
          setMessage(res.data.message)
          setrefreshpage(false)
        } else {
          setHubscreen(res.data.hub);
          setHubBarcodeData(res.data.hub_sfg);
          setOpen(true)
          setMessage(res.data.message)
          setStatus(true)
          setColor(true)
          setrefreshpage(false)
          setIsloading(true);
        }
      }).catch(err => {
        console.log(err)
      });
  }, [])  

  return (
    <Box>
      <Box sx={{ py: 3 }}>
        <Heading title={title} />
      </Box>
      <Box sx={{ py: 3 }}>
       {data.length === 0 ? <Nodata /> : (
          <Box sx={{ p: 2 }} component={Card}>
          <TableContainer >
            <Table>
              <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                <TableRow >
                  {
                    showHubTableHeading.map((headings) =>
                      <TableCell key={headings.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{ my: 'auto' }}>
                            {headings.label}
                          </Box>
                          <Box>
                            {headings.filter ? <Filter label={headings.label} setSearch={setSearch} /> : ''}
                          </Box>
                        </Box>
                      </TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  data.sort((a, b) => (a.hubstatus > b.hubstatus) ? 1 : -1).filter((data) => filterJobScreen(data, search, {
                    searchFeildOne: data.job_num_1,
                    searchFeildTwo: data.prod_code,
                    searchFeildThree: data.short_char_01
                  })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items,index) =>
                    <TableRow key={items.hubid}>
                      <TableCell sx={{ textAlign: 'center' }}>{index+1}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.serial_no}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.job_num_1}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        <Box>
                          <Statusbar label={items.hubstatus === 'true' ? 'Completed' : 'Pending'} color={items.hubstatus === 'true' ? 'success' : 'error'} />
                        </Box>
                      </TableCell> */}
                      <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.hub_edd)}</TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        {items.hub_planned_date}
                      </TableCell> */}
                      <TableCell sx={{ textAlign: 'center' }}>
                        {nullFinderandDateswapper(items.hub_issued_date)}
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        {items.hub_required_date}
                      </TableCell> */}
                      <TableCell sx={{ textAlign: 'center' }}>
                        {nullFinderandDateswapper(items.hub_completed_date)}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {items.hub_remarks}
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        {nullFinderandDateswapper(items.hub_revised_date)}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {items.hub_revised_remarks}
                      </TableCell> */}
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
       )}
      </Box>

      <HubBarCodeTable hubscreen={hubscreen} hubBarcodeData={hubBarcodeData} isloading={isloading} />
    </Box>
  )
}

export default Displayhub