import React from 'react'
import { Box, Grid, Card ,Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, 
  TableRow, Paper, Divider} from '@mui/material';
import { Chart as Chartjs } from 'chart.js/auto';
import BarChart from '../Comps/Charts/BarChart';
import Doughnut from '../Comps/Charts/Doughnut';
import LineChart from './../Comps/Charts/LineChart';
import Heading from './../Comps/Heading';
import { Outlet } from 'react-router-dom';
import useFetch from './../Services/useFetch';
import {barGraph, fanqtyreport, jobupdates, methodGet, salecategoryamount,salecategoryqty,salestargetchart } from '../Services/Apivariables';
import Statusloader from './../Comps/Statusloader';
import { exporttoexcel, processlabels, labels, totalFanChartReport, JobUpdateslabels, JobAmountUpdateslabels } from '../Comps/Variables/Variables';
import Salesbarchart from '../Comps/Charts/Salesbarchart';
import { ExportCSV } from '../Comps/ExportCSV';
import SaleOrderChart from '../Comps/Charts/SaleOrderChart';
import SaleCategoryAmount from '../Comps/Charts/SaleCategoryAmount';
import SaleCategoryQty from '../Comps/Charts/SaleCategoryQty';
import JobUpdateChart from '../Comps/Charts/JobUpdateChart';
import JobAmountUpdates from '../Comps/Charts/JobAmountUpdates';
import AnnualReportTable from '../Comps/ManagementDsgbrdTable/AnnualReportTable';
import MonthlyReportTable from '../Comps/ManagementDsgbrdTable/MonthlyReportTable';
import FanModelQty from '../Comps/ManagementDsgbrdTable/FanModelQty';
import FanModelValue from '../Comps/ManagementDsgbrdTable/FanModelValue';
import TabListPanel from '../Comps/ManagementDsgbrdTable/TabListPanel';
import JobUpdateTable from '../Comps/Charts/JobUpdateTable';

function MgmtDashboard() {
  
  const { data, isloading } = useFetch(barGraph, methodGet);
  // const { data: graphdata } = useFetch(fanqtyreport, methodGet);
  // const exporttoExcel =totalFanChartReport(graphdata);

  if (!isloading) {
    return (
      <Statusloader />
    )
  } else {
    return (
      <Box sx={{ p: 3 }}>
        <Box sx={{ py: 3 }}>
          <Heading title={'dashboard'} />
        </Box>
        {/* <Box sx={{ py: 2 }}>
          <ExportCSV csvData={exporttoExcel} fileName={'report'} />
        </Box> */}
        <Box>
          <Box sx={{ py: 3 }}>
            <Grid container justifyContent={'center'} spacing={4} rowSpacing={4}>

            <Grid item xs={12}  sm={12} md={12} lg={11} xl={4} mt={3}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
              <JobUpdateTable  title={'Plan vs Actual & InQueue'} />
              </Box>
              </Grid>


           <Grid item xs={12}  sm={12} md={12} lg={12} xl={12} mt={3}>
              <AnnualReportTable />
           </Grid>


            <Grid item xs={12}  sm={12} md={12} lg={12} xl={12} mt={3}>
            <MonthlyReportTable />
           </Grid>
{/* 
          <Grid item xs={12}  sm={12} md={12} lg={12} xl={12} mt={3}>
            <FanModelQty />
           </Grid>


          <Grid item xs={12}  sm={12} md={12} lg={12} xl={12} mt={3}>
            <FanModelValue />
           </Grid> */}





            {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
                  <SaleOrderChart title={'Total Sale Order'} />
                </Box>
              </Grid> */}

              {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
                  <SaleCategoryAmount url={salecategoryamount}  labels={labels} title={'Sale Category Total Value'} />
                </Box>
              </Grid> */}

              {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
                  <SaleCategoryQty url={salecategoryqty}  labels={labels} title={'Sale Category Quantity'} />
                </Box>
              </Grid> */}

              {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
                  <BarChart data={data} title={'Total Fan Quantity'} />
                </Box>
              </Grid> */}




              {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
                   <JobAmountUpdates  title={'Job Amount Updates'} />
                </Box>
              </Grid> */}
{/*   
              <Grid item xs={12}  sm={12} md={12} lg={11} xl={4}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
                  <Salesbarchart url={salestargetchart} title={'Sales Target'} labels={labels} />
                </Box>
              </Grid> */}
                
            </Grid>
          </Box>
         
        </Box>
        <Outlet />
      </Box>
    )
  }
}

export default MgmtDashboard