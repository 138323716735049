import React from 'react'
import { Box, TableContainer, TableHead, TableRow, TableCell, TablePagination, Button, TableBody, Table, Card } from '@mui/material';
import { useState } from 'react';
import { dctableheading } from '../Comps/Variables/Variables';
import { filterData } from '../Comps/FilterData';
import Filter from '../Comps/Filter';
import useFetch from '../Services/useFetch';
import { getsubcontractdcnumber, getsubcontractinventrayinwards, methodGet, methodPost } from '../Services/Apivariables';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Heading from '../Comps/Heading';
import SnackBar from './../Comps/SnackBar';
import axios from 'axios';
import Pdfdialogcomp from '../Comps/PdfModule/Pdfdialogcomp';
import Nodata from './../Comps/Nodata';

function Allsubcontractordcnumber() {
  const { data } = useFetch(getsubcontractdcnumber, methodGet);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [subcontractorDetails, setSubcontractorDetails] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [boughtOutAllOrders, setBoughtOutAllOrders] = useState([]);
  const [pdfdialogopen, setpdfdialogopen] = useState(false);
  const [dialogdata, setdialogdata] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchAllOrders = (url, method, data) => {
    const subid = new FormData()
    subid.append("order_num", data)
    axios({
      method: method,
      url: url,
      data: subid
    }).then(res => {
      if (!res.data.error) {
        if (res.data.sub_contract !== undefined || res.data.boughtout !== undefined) {
          setAllOrders(res.data.sub_contract);
          setBoughtOutAllOrders(res.data.boughtout);
          setSubcontractorDetails(res.data.contractor_details);
          subcontractorDetails.map((details) => {
            const obj = {
              subcontract_name: details.subcontract_name,
              address: details.address,
              gst_no: details.gst_no,
              subcontractor_worktype: details.work_type,
              dcnumber: data,
              subcontractorjobData: allOrders,
              subcontractorbrogData: boughtOutAllOrders
            }
            setdialogdata(obj);
          });
        }
      } else {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
      }
    }).catch(err => {
      console.log(err)
    });
    setpdfdialogopen(!pdfdialogopen)
  }

  return (
    <Box>
      <Box sx={{ p: 3 }}>
        <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
        <Box sx={{ py: 3 }}>
          <Heading title={'sub contract dc numbers'} />
        </Box>
        {data.length === 0 ? (<Nodata />) : (
          <Box sx={{ p: 3, borderRadius: 4 }} component={Card}>
          <TableContainer sx={{ py: 4 }}>
            <Table aria-label="simple table">
              <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                <TableRow>
                  {dctableheading.map((labels) =>
                    <TableCell key={labels.id} sx={{
                      color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                    }} >
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box>
                          {labels.title}
                        </Box>
                        <Box>
                          {labels.filter && <Filter label={labels.title} setSearch={setSearch} />}
                        </Box>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.filter((data) => filterData(data, search, {
                  searchFeildOne: data.job_num,
                  searchFeildTwo: data.prod_code
                })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((orders, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                    >
                      <TableCell align='center'>
                        {index + 1}
                      </TableCell>
                      <TableCell align='center'>
                        {orders.subcontract_name}
                      </TableCell>
                      <TableCell align='center'>
                        {orders.job_num}
                      </TableCell>
                      <TableCell align='center'>
                        {orders.dc_number}
                      </TableCell>
                      <TableCell align="center">
                        <Box>
                          <Button variant='contained' startIcon={<VisibilityIcon />}
                            onClick={() => {
                              fetchAllOrders(getsubcontractinventrayinwards, methodPost, orders.dc_number)
                            }}
                          >dc view</Button>
                          <Pdfdialogcomp data={dialogdata} setpdfdialogopen={setpdfdialogopen} pdfdialogopen={pdfdialogopen} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        )}
      </Box>
    </Box>
  )
}

export default Allsubcontractordcnumber