import React from 'react'
import { Grid, TextField, Box, Card } from '@mui/material';


function StoreScreenLayoutHeader({ data }) {
  const access = JSON.parse(localStorage.getItem('access'));
  const { role } = access;
  return (
    <Box sx={{ p: 2 }}>
      {
        [data].map((item) =>
          <Grid sx={{ p: 3, borderRadius: 4 }} key={item.storeId} container component={Card} spacing={2}>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}  >
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.partCode}
                  size="small"
                  fullWidth
                  label='Part Code'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.partDescriptions}
                  size="small"
                  fullWidth
                  label='Part Descriptions'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.classDescription}
                  size="small"
                  fullWidth
                  label='Class Description'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.subClassDescription}
                  size="small"
                  fullWidth
                  label='SubClass Descriptions'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.onHandQty}
                  size="small"
                  fullWidth
                  label='onHandQty'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.wareHouseCode}
                  size="small"
                  fullWidth
                  label='WareHouseCode'
                  variant="outlined"

                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.bin}
                  size="small"
                  fullWidth
                  label='Bin'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
              <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.uom}
                  size="small"
                  fullWidth
                  label='UOM'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.unitCost}
                  size="small"
                  fullWidth
                  label='Unit Cost'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.totalCost}
                  size="small"
                  fullWidth
                  label='Total Cost'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.class}
                  size="small"
                  fullWidth
                  label='Class'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.subclass}
                  size="small"
                  fullWidth
                  label='Sub Class'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={item.plant}
                  size="small"
                  fullWidth
                  label='Plant'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )
      }
    </Box>
  )
}

export default StoreScreenLayoutHeader