import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Card, Grid, TextField, InputLabel, FormControl, Select, MenuItem, Button, FormHelperText, Checkbox, InputAdornment, IconButton, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TableContainer, TableHead, TableRow, TableCell, TableBody, Table } from '@mui/material';
import { Form } from 'react-bootstrap';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Heading from './../Comps/Heading';
import SendIcon from '@mui/icons-material/Send';
import { methodPost, updateRegister } from '../Services/Apivariables';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import SnackBar from './../Comps/SnackBar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useFetch from '../Services/useFetch';
import { departmentlist } from '../Services/Apivariables';

function Editregistereduser() {
    const location = useLocation()
    const data = location.state;
    const { register, formState: { errors }, control, watch, handleSubmit } = useForm();
    const [showPassword, setShowPassword] = useState(true);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");
    const [allView, setAllView] = useState(false);
    const processscreeneditall = watch('processscreeneditall')

    const onSubmit = (data) => {
        const serverData = new FormData()
        for (var key in data) {
            serverData.append(key, data[key]);
        }

        if (!navigator.onLine) {
            setMessage('Your internet is in Offline')
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            axios({
                method: methodPost,
                url: updateRegister,
                data: serverData,
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(true)
                    setColor(true)
                }
            }).catch(err => {
                alert('Oops something went wrong ' + err)
            });
        }
    };

    
    const {dept} = useFetch(departmentlist,'GET');
    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ py: 3 }}>
                <Heading title={'Edit Registered User'} />
            </Box>
            <Box sx={{ py: 2 }}>
                <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
                {[data].map((item) =>
                    <Box>
                        <Grid container justifyContent={'center'} spacing={2}>
                            <Grid item textAlign={'center'} xs={12} lg={8}>
                                <Box component={Card} sx={{ p: 2, boxShadow: 3, borderRadius: 5 }}>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Grid container justifyContent='start' spacing={1}>
                                            <Grid item xs={12} lg={4}>
                                                <Box sx={{ py: 1 }}>
                                                    <input className='d-none' value={item.id} {...register("id")} />
                                                    <TextField
                                                        fullWidth
                                                        label="Employee Id"
                                                        defaultValue={item.employee_id}
                                                        variant="filled"
                                                        {...register("employee_id", { required: true })}
                                                        error={errors.employee_id ? true : false}
                                                        helperText={(errors.employee_id && errors.employee_id.type === "required") ? "Employee id is required" : ""}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <Box sx={{ py: 1 }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Email"
                                                        variant="filled"
                                                        defaultValue={item.email}
                                                        {...register("email", { required: true })}
                                                        error={errors.email ? true : false}
                                                        helperText={(errors.email && errors.email.type === "required") ? "Email id is required" : ""}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <Box sx={{ py: 1 }}>
                                                    <TextField
                                                        fullWidth
                                                        label="User Name"
                                                        variant="filled"
                                                        defaultValue={item.username}
                                                        {...register("username", { required: true, maxLength: 15 })}
                                                        error={errors.username ? true : false}
                                                        helperText={(errors.username && errors.username.type === "required") ? "User name is required" : "" || (errors.username && errors.username.type === "maxLength") ? "User name can be max 15 characters long." : ""}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <Box sx={{ py: 1 }}>
                                                    <TextField
                                                        fullWidth
                                                        variant="filled"
                                                        type={showPassword ? 'password' : 'text'}
                                                        {...register("pass")}
                                                        label="Password"
                                                        InputProps={{
                                                            endAdornment:
                                                                (<InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => setShowPassword(!showPassword)}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>),
                                                        }}
                                                        helperText={(errors.pass && errors.pass.type === "required") ? "Password is required" : "" || (errors.pass && errors.pass.type === "maxLength") ? "Password can be max 15 characters long." : ""}
                                                        error={errors.pass ? true : false}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <Box sx={{ py: 1 }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Contact Number"
                                                        variant="filled"
                                                        defaultValue={item.mobile_no}
                                                        {...register("mobile_no", { required: true, maxLength: 15, pattern: /^\d{10}$/ })}
                                                        error={errors.mobile_no ? true : false}
                                                        helperText={(errors.mobile_no && errors.mobile_no.type === "required") ? "Contact number is required" : "" || (errors.mobile_no && errors.mobile_no.type === "maxLength") ? "Contact number can be max 15 characters long." : "" || (errors.mobile_no && errors.mobile_no.type === 'pattern') ? "Mobile number contains only numbers" : ""}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <Box sx={{ py: 1 }}>
                                                    <FormControl fullWidth error={errors.roles ? true : false}>
                                                        <InputLabel>Role</InputLabel>
                                                        <Select
                                                            defaultValue={item.roles}
                                                            sx={{ textAlign: 'start' }}
                                                            label="Role"
                                                            {...register('roles', { required: true })}
                                                        >
                                                            <MenuItem value={"Admin"}>Admin</MenuItem>
                                                            <MenuItem value={"Manager"}>Manager</MenuItem>
                                                            <MenuItem value={"Assistant Manager"}>Assistant Manager</MenuItem>
                                                            <MenuItem value={"Head Of Department"}>Head Of Department</MenuItem>
                                                            <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                                                            <MenuItem value={"Senior Executive"}>Senior Executive</MenuItem>
                                                            <MenuItem value={"Executive"}>Executive</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{errors.roles ? "Role is required" : ""}</FormHelperText>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <Box sx={{ py: 1 }}>
                                                    <FormControl fullWidth error={errors.roles ? true : false}>
                                                        <InputLabel>Department</InputLabel>
                                                        <Select
                                                         defaultValue={item.department}
                                                         sx={{ textAlign: 'start' }}
                                                        label="department" 
                                                        {...register("department")}>
                                        {
                                            dept.map((dept) =>
                                                <MenuItem key={dept.departmentId} value={dept.departmentName}>{dept.departmentName}</MenuItem>
                                            )
                                        }
                                    </Select>
                                                        <FormHelperText>{errors.roles ? "Role is required" : ""}</FormHelperText>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Management Dashboard</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        View
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                       Management Dashboard
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.management === 'true' && true}
                                                                            name="management"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.management === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>


                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Production Dashboard</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        View
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Production Dashboard
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.dashboard_view === 'true' && true}
                                                                            name="dashboard_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.dashboard_view === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Sale Order</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Edit
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                    Sale Order
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.sale_order === 'true' && true}
                                                                            name="sale_order"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.sale_order === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Job Screen Access</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Edit
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        View
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Job Screen
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.jobscreenedit === 'true' && true}
                                                                            name="jobscreenedit"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.jobscreenedit === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.jobscreenview === 'true' && true}
                                                                            name="jobscreenview"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.jobscreenview === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Mom And Planning Screen Access</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Edit
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        View
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Mom and Planning Screen
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.momscreenedit === 'true' && true}
                                                                            name="momscreenedit"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.momscreenedit === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.momscreenview === 'true' && true}
                                                                            name="momscreenview"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.momscreenview === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>

                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Bom file</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Edit
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Bom File Upload
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.bom_file_edit === 'true' && true}
                                                                            name="bom_file_edit"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.bom_file_edit === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>

                                        {/* JOb Order Screen */}

                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Job Order</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Edit
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Job Order
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.monthly_job === 'true' && true}
                                                                            name="bom_file_edit"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.monthly_job === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>



                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Process Screen Access</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer >
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Edit
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        View
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                               
                                                                {/* <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        All
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox {...register('processscreeneditall')} />
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Checkbox onChange={handleChangeView} />
                                                                    </TableCell> 
                                                                </TableRow> */}
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Production Drawing And Bom
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.production === 'true'? true : false}
                                                                            name="production"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={(item.production === 'true') ? true : false}  {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(prod || item.production === 'true') && true} onChange={(e) => setProd(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.production_view === 'true' && true}
                                                                            name="production_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={(item.production_view === 'true') && true} {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(prodView || item.production_view === 'true') && true} onChange={(e) => setProdView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Mom
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.mom === 'true' && true}
                                                                            name="mom"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.mom === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(mom || item.mom === 'true') && true} onChange={(e) => setMom(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.mom_view === 'true' && true}
                                                                            name="mom_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.mom_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(momView || item.mom_view === 'true') && true} onChange={(e) => setMomView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Store
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.store === 'true' && true}
                                                                            name="store"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.store === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(store || item.store === 'true') && true} onChange={(e) => setStore(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.store_view === 'true' && true}
                                                                            name="store_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.store_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(storeView || item.store_view === 'true') && true} onChange={(e) => setStoreView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Purchase
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.purchase === 'true' && true}
                                                                            name="purchase"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.purchase === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(purchase || item.purchase === 'true') && true} onChange={(e) => setPurchase(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.purchase_view === 'true' && true}
                                                                            name="purchase_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.purchase_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(purchaseView || item.purchase_view === 'true') && true} onChange={(e) => setPurchaseView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Cnc
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.cnc === 'true' && true}
                                                                            name="cnc"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.cnc === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(cnc || item.cnc === 'true') && true} onChange={(e) => setCnc(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.cnc_view === 'true' && true}
                                                                            name="cnc_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.cnc_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(cncView || item.cnc_view === 'true') && true} onChange={(e) => setCncView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Hub
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.hub === 'true' && true}
                                                                            name="hub"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.hub === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(hub || item.hub === 'true') && true} onChange={(e) => setHub(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.hub_view === 'true' && true}
                                                                            name="hub_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.hub_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(hubView || item.hub_view === 'true') && true} onChange={(e) => setHubView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Machining
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.machining === 'true' && true}
                                                                            name="machining"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.machining === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(machining || item.machining === 'true') && true} onChange={(e) => setMachining(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.machining_view === 'true' && true}
                                                                            name="machining_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.machining_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(machiningView || item.machining_view === 'true') && true} onChange={(e) => setMachiningView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Impeller
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.impeller === 'true' && true}
                                                                            name="impeller"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.impeller === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(impeller || item.impeller === 'true') && true} onChange={(e) => setImpeller(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.impeller_view === 'true' && true}
                                                                            name="impeller_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.impeller_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(impellerView || item.impeller_view === 'true') && true} onChange={(e) => setImpellerView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Casing
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.casing === 'true' && true}
                                                                            name="casing"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.casing === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(casing || item.casing === 'true') && true} onChange={(e) => setCasing(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.casing_view === 'true' && true}
                                                                            name="casing_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.casing_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(casingView || item.casing_view === 'true') && true} onChange={(e) => setCasingView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        First Coat Paint
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.first_paint === 'true' && true}
                                                                            name="first_paint"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.first_paint === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(firstCoatPaint || item.first_paint === 'true') && true} onChange={(e) => setFirstCoatPaint(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.first_paint_view === 'true' && true}
                                                                            name="first_paint_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.first_paint_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(firstCoatPaintView || item.first_paint_view === 'true') && true} onChange={(e) => setFirstCoatPaintView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Assembly
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.assembly === 'true' && true}
                                                                            name="assembly"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.assembly === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(assembly || item.assembly === 'true') && true} onChange={(e) => setAssembly(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.assembly_view === 'true' && true}
                                                                            name="assembly_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.assembly_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(assemblyView || item.assembly_view === 'true') && true} onChange={(e) => setAssemblyView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Testing
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.testing === 'true' && true}
                                                                            name="testing"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.testing === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(testing || item.testing === 'true') && true} onChange={(e) => setTesting(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.testing_view === 'true' && true}
                                                                            name="testing_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.testing_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(testingView || item.testing_view === 'true') && true} onChange={(e) => setTestingView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Final Paint
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.final_paint === 'true' && true}
                                                                            name="final_paint"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.final_paint === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(finalPaint || item.final_paint === 'true') && true} onChange={(e) => setFinalPaint(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.final_paint_view === 'true' && true}
                                                                            name="final_paint_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.final_paint_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(finalPaintView || item.final_paint_view === 'true') && true} onChange={(e) => setFinalPaintView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Packing &amp; Dispatch
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.packing === 'true' && true}
                                                                            name="packing"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.packing === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(packingAndDispatching || item.packing === 'true') && true} onChange={(e) => setPackingAndDispatching(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.packing_view === 'true' && true}
                                                                            name="packing_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.packing_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(packingAndDispatchingView || item.packing_view === 'true') && true} onChange={(e) => setPackingAndDispatchingView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        Accessories
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.accessories === 'true' && true}
                                                                            name="accessories"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.accessories === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(finalPaint || item.accessories === 'true') && true} onChange={(e) => setFinalPaint(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.accessories_view === 'true' && true}
                                                                            name="accessories_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.accessories_view === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(finalPaintView || item.displayaccessories === 'true') && true} onChange={(e) => setFinalPaintView(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        PMS
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.pms === 'true' && true}
                                                                            name="pms"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.pms === 'true' && true}   {...field} />}
                                                                        />
                                                                        {/* <Checkbox checked={(pms || item.pms === 'true') && true} onChange={(e) => setPms(e.target.checked)} /> */}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Sub Contract</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Edit
                                                                    </TableCell>
                                                                  
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                       Inventory
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.inventory === 'true' && true}
                                                                            name="inventory"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.inventory === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                   
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                       Outwards
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.outwards === 'true' && true}
                                                                            name="outwards"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.outwards === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                   
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                       Intwards
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.inwards === 'true' && true}
                                                                            name="inwards"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.inwards === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                   
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                       Dc numbers
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.dc_numbers === 'true' && true}
                                                                            name="dc_numbers"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.dc_numbers === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                   
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>

                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Target</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Edit
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                    Target
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.sales_target_edit === 'true' && true}
                                                                            name="sales_target_edit"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.sales_target_edit === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>



                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Part Code Screen Access</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Edit & View
                                                                    </TableCell>
                                                                   
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                       Material Part Code Screen
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.partcode_edit === 'true' && true}
                                                                            name="partcode_edit"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.partcode_edit === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                   
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                      Boughtout Part Code Screen
                                                                    </TableCell>
                                                              
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.partcode_view === 'true' && true}
                                                                            name="partcode_view"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.partcode_view === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                      FG Part Code Screen
                                                                    </TableCell>
                                                              
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.fgpartcode_edit === 'true' && true}
                                                                            name="fgpartcode_edit"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.fgpartcode_edit === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                      SFG Part Code Screen
                                                                    </TableCell>
                                                              
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.sfgpartcode_edit === 'true' && true}
                                                                            name="sfgpartcode_edit"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.sfgpartcode_edit === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                      Sub Component Part Code Screen
                                                                    </TableCell>
                                                              
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.subcomppartcode_edit === 'true' && true}
                                                                            name="subcomppartcode_edit"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.subcomppartcode_edit === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>

                                        {/* Stock Job Screen */}

                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Stock Job Screen</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Edit
                                                                    </TableCell>
                                                                  
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                       Stock Job Order
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.stock_job === 'true' && true}
                                                                            name="stock_job"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.stock_job === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                   
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                       Stock QR
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.stock_qr === 'true' && true}
                                                                            name="stock_qr"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.stock_qr === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                   
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                       Pending Stocks
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.stock_pending === 'true' && true}
                                                                            name="stock_pending"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.stock_pending === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                   
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                       Issued Stocks
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.stock_issued === 'true' && true}
                                                                            name="stock_issued"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.stock_issued === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                   
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>

                                       


                                       {/* Store Screen edit */}

                                       <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Store Screen</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Edit
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                    Store Screen
                                                                    </TableCell>
                                                                    
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.store_stock === 'true' && true}
                                                                            name="store_stock"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.store_stock === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                        
                                      
                                        
                                        <Box sx={{ py: 2 }}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Register</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Screens
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                                        Edit
                                                                    </TableCell>
                                                                  
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                       Add Users
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.add_register === 'true' && true}
                                                                            name="add_register"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.add_register === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                   
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                       Edit Users
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'center' }}>
                                                                        <Controller
                                                                            defaultValue={item.edit_register === 'true' && true}
                                                                            name="edit_register"
                                                                            control={control}
                                                                            render={({ field }) => <Checkbox defaultChecked={item.edit_register === 'true' && true}   {...field} />}
                                                                        />
                                                                    </TableCell>
                                                                   
                                                                </TableRow>
                                                             
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                        <Box sx={{ py: 2, textAlign: 'center' }}>
                                            <Button variant="contained" type='submit' endIcon={<SendIcon />}>Submit</Button>
                                        </Box>
                                    </Form>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default Editregistereduser