import React from 'react'
import { useState } from 'react';
import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from '@mui/material';
import { filterJobScreen } from './../FilterData';
import Filter from './../Filter';
import Heading from './../Heading';
import { showStoreTableHead } from '../Variables/ProcessVariables';
import Statusbar from './../Statusbar';
import { nullFinderandDateswapper } from '../Variables/Variables';
import Nodata from './../Nodata';
import { useEffect } from 'react';
import { otherScreenSingleOrders } from '../../Services/Apivariables';
import axios from 'axios';
import Storebom from '../ProcessScreenModule/Bom/Storebom';

function Displaystore({ data, title, jobnum }) {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [isloading, setIsloading] = useState(false);
  const [refreshpage, setrefreshpage] = useState(false);
  const [storebomscreen, setStorebomscreen] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  useEffect(() => {
      const sendData = new FormData();
      sendData.append('job_num', jobnum)
      sendData.append('department', title)
      axios({
        method: 'POST',
        url: otherScreenSingleOrders,
        data: sendData,
      }).then(res => {
        if (res.data.error) {
          setMessage(res.data.message)
          setrefreshpage(false)
        } else {
          setStorebomscreen(res.data.storebom);
          setOpen(true)
          setMessage(res.data.message)
          setStatus(true)
          setColor(true)
          setrefreshpage(false)
          setIsloading(true);
        }
      }).catch(err => {
        console.log(err)
      });
  }, [])  

  return (
    <Box>
      <Box sx={{ py: 3 }}>
        <Heading title={title} />
      </Box>
      <Box sx={{ py: 3 }}>
       {data.length === 0 ? <Nodata /> : (
          <Box sx={{ p: 2 }} component={Card}>
          <TableContainer >
            <Table>
              <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                <TableRow >
                  {
                    showStoreTableHead.map((headings) =>
                      <TableCell key={headings.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{ my: 'auto' }}>
                            {headings.label}
                          </Box>
                          <Box>
                            {headings.filter ? <Filter label={headings.label} setSearch={setSearch} /> : ''}
                          </Box>
                        </Box>
                      </TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  data.sort((a, b) => (a.stores_status > b.stores_status) ? 1 : -1).filter((data) => filterJobScreen(data, search, {
                    searchFeildOne: data.job_num_1,
                    searchFeildTwo: data.prod_code,
                    searchFeildThree: data.short_char_01
                  },data.stores_status)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items,index) =>
                    <TableRow key={items.productionid}>
                      <TableCell sx={{ textAlign: 'center' }}>{index+1}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.serial_no}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.job_num_1}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        <Box>
                          <Statusbar label={items.stores_status === 'true' ? 'Completed' : 'Pending'} color={items.stores_status === 'true' ? 'success' : 'error'} />
                        </Box>
                      </TableCell> */}
                      <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.stores_edd)}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                      {items.bom_released_date}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                      {items.report_submitted}
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                      {items.no_of_items_available}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                      {items.no_of_items_not_available}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                      {items.ack}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                      {items.part_code}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                      {items.part_dec}
                      </TableCell> */}
                      <TableCell sx={{ textAlign: 'center' }}>
                      {items.stores_remarks}
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                      {nullFinderandDateswapper(items.stores_revised_date)}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                      {items.stores_revised_remarks}
                      </TableCell> */}
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
       )}
      </Box>
      <Storebom setrefreshpage={setrefreshpage} storebomscreen={storebomscreen} isloading={isloading} />
    </Box>
  )
}

export default Displaystore