import React from 'react'
import { Box, Typography } from '@mui/material';
import Heading from './../Comps/Heading';
import Subcontractorinventrytable from '../Comps/Subcontractorinventrytable';
import { getsubcontractinventrayjobs, methodGet } from '../Services/Apivariables';
import useFetch from './../Services/useFetch';
import Statusloader from './../Comps/Statusloader';
import { exportdataExeclSubcontractorInventory } from '../Comps/Variables/Variables';
import { ExportCSV } from '../Comps/ExportCSV';
import emptyInventory from '../Assets/empty_inventory.svg';

function Subcontractorinventory() {
  const { data, isloading } = useFetch(getsubcontractinventrayjobs, methodGet);
  const exportdata = exportdataExeclSubcontractorInventory(data);
  if (!isloading) {
    return (
      <Statusloader />
    )
  } else {
    return (
      <Box varaint='div' sx={{ p: 3 }}>
        <Box sx={{ py: 3 }}>
          <Heading title={'sub contract inventory'} />
        </Box>
        {data.length > 0 ? (
          <Box>
            <Box sx={{ py: 2 }}>
              <ExportCSV csvData={exportdata} fileName={'subcontractor_inventory_nadi_report'} />
            </Box>
            <Box>
              <Subcontractorinventrytable data={data} />
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{
              height: {
                sm: '100%',
                lg: '40%',
                xl: '40%'
              }, width: {
                sm: '100%',
                lg: '40%',
                xl: '40%'
              }
            }}>
              <Box>
                <img src={emptyInventory} className='img-fluid' alt='No items found in inventory' />
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant='h6' sx={{ fontWeight: '600',textTransform:'uppercase' }}>No items found in inventory</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    )
  }
}

export default Subcontractorinventory