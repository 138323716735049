import React, { useState } from 'react';
import nadiLogo from '../Assets/nadi_logo.png';
import {
  Drawer,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import AddchartIcon from '@mui/icons-material/Addchart';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import { Link } from 'react-router-dom'
import ResponsiveBd from './ResponsiveBd';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import IosShareIcon from '@mui/icons-material/IosShare';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SellIcon from '@mui/icons-material/Sell';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import InventoryIcon from '@mui/icons-material/Inventory';
import QrCodeIcon from '@mui/icons-material/QrCode';
import StoreIcon from '@mui/icons-material/Store';
import TaskIcon from '@mui/icons-material/Task';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TerminalIcon from '@mui/icons-material/Terminal';
import TimelineIcon from '@mui/icons-material/Timeline';
import { SignalCellularNullSharp } from '@mui/icons-material';

function SideBar({ setMobileOpen, mobileOpen }) {

      const [open, setOpen] = useState(false);
      const [openOne, setOpenOne] = useState(false);
      const [openThree, setOpenThree] = useState(false);
      const [openFour, setOpenFour] = useState(false);
      const [openFive, setOpenFive] = useState(false);
      const [openSix, setOpenSix] = useState(false);
      const [openSeven, setOpenSeven] = useState(false);
      const [openEight, setOpenEight] = useState(false);
      const [openNine, setOpenNine] = useState(false);
      const responsive = ResponsiveBd();
      const drawerWidth = 280;
      const access = JSON.parse(localStorage.getItem('access'));
      const department = JSON.parse(localStorage.getItem('department'));
      const { role, jobscreenedit, jobscreenview, momscreenedit, momscreenview, monthly_job, view_all, process_view, process_edit, partcode_edit, fgpartcode_edit, sfgpartcode_edit, subcomppartcode_edit,store_stock,stock_job, stock_pending, stock_qr, stock_issued,partcode_view, pms, dashboard_view, bom_file_edit, sales_target_edit, inventory, outwards, inwards, dc_numbers, edit_register, add_register,sale_order, management } = access

      const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
      };

      const handleClick = () => {
        setOpen(!open);
      };

      const handleClickOne = () => {
        setOpenOne(!openOne);
      };

      const handleClickThree = () => {
        setOpenThree(!openThree);
      };

      const handleClickFour = () => {
        setOpenFour(!openFour);
      };

      const handleClickFive = () => {
        setOpenFive(!openFive);
      };
      const handleClickSix = () => {
        setOpenSix(!openSix);
      };
      const handleClickSeven = () => {
        setOpenSeven(!openSeven);
      };
      const handleClickEight = () => {
        setOpenEight(!openEight);
      };
      const handleClickNine = () => {
        setOpenNine(!openNine);
      };


    const roles = 'Super Admin' || 'Admin' ;


  const drawer = (<Box sx={{ height: '100%' }}>
    {/* <Box sx={{p: 2, display: responsive ? 'block' : 'none'}} textAlign='right'>
    <IconButton color='primary' aria-label="delete" >
      <ClearIcon />
    </IconButton>
  </Box> */}
    <Box sx={{ px: 3, py: 4 }}>
      <Box sx={{ textAlign: 'center', p: 2 }}>
        <Link to="dashboard"> <img src={nadiLogo} width="60" alt="nadi logo" /></Link>
      </Box>
    </Box>
    <Box >
      <List sx={{ px: 1 }} >
      {management==='true' && ( <Link to="mgmtdashboard" className={" text-nowrap text-decoration-none fs-5 "}>
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><InsertChartIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Management Dashboard</Typography>
            </ListItemText>
          </ListItem>
        </Link> )}
     
      {dashboard_view === 'true' && ( <Link to="dashboard" className={" text-nowrap text-decoration-none fs-5 "}>
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddchartIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Production Dashboard</Typography>
            </ListItemText>
          </ListItem>
        </Link> )}

        {roles === role  && (
        <Link to="statustrack" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><TimelineIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Track Status</Typography>
            </ListItemText>
          </ListItem>
        </Link>
         )} 

        {sale_order === 'true' && (
        <Link to="saleorder" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><SellIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Sale Order</Typography>
            </ListItemText>
          </ListItem>
        </Link>
         )} 





        {(jobscreenedit === 'true' || jobscreenview === 'true') && (<>
          <ListItem onClick={handleClickThree} button sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }} >
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><WorkIcon /></ListItemIcon>
            <ListItemText >
              <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
                <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> Job Screen</Box><Box>{openThree ? <ExpandLess /> : <ExpandMore />}</Box>
              </Typography>
            </ListItemText>
          </ListItem>
          <Collapse in={openThree} timeout="auto" unmountOnExit>
            <List sx={{ px: 2 }}>
              {jobscreenedit === 'true' && (<Link to="orders" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <PendingActionsIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Orders
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>)}
              {jobscreenview === 'true' && (<Link to="displayjoborders" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Job Order Status
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>)}
            </List>
          </Collapse>
        </>)}
        {(momscreenedit === 'true' || momscreenview === 'true') && (<>
          <ListItem onClick={handleClickOne} button sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }} >
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><LibraryBooksIcon /></ListItemIcon>
            <ListItemText >
              <Typography component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> Mom and Planning</Box><Box>{openOne ? <ExpandLess /> : <ExpandMore />}</Box>
              </Typography>
            </ListItemText>
          </ListItem>
          <Collapse in={openOne} timeout="auto" unmountOnExit>
            <List sx={{ px: 2 }}>
              {momscreenedit === 'true' && (<Link to="momandplanning" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <PendingActionsIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography className="fw-500">
                      Orders
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>)}
              {momscreenview === 'true' && (<Link to="displaymomandplanningorders" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Order Status
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>)}
            </List>
          </Collapse>
        </>)}
          {bom_file_edit === 'true' && (<Link to="bomfileupload" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><InsertDriveFileIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Bom File upload</Typography>
            </ListItemText>
          </ListItem>
        </Link>)}

        {/* {monthly_job === 'true' && (
          <Link to="jobreportscreen" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><SummarizeIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Job Report</Typography>
            </ListItemText>
          </ListItem>
          </Link>
        )} */}
       



        {(view_all === 'true') && (<>
          <ListItem onClick={handleClick} button sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }} >
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><MobileScreenShareIcon /></ListItemIcon>
            <ListItemText >
              <Typography component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}>Processing Screens</Box><Box>{open ? <ExpandLess /> : <ExpandMore />}</Box>
              </Typography>
            </ListItemText>
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List sx={{ px: 2 }}>
              {process_edit === 'true' && (<Link to="otherscreen" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <PendingActionsIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Orders
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>)}
              {process_view === 'true' && (<Link to="displayorders" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Order Status
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>)}
              {pms === 'true' && (<>
                <Link to="pms" className=" text-nowrap text-decoration-none fs-5 ">
                  <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                    <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                      <IosShareIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ color: 'primary.main' }} >
                      <Typography sx={{ fontWeight: 500 }}>
                        PMS
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </Link>
              </>)}
            </List>
          </Collapse>
        </>)}
        {(inventory==='true' || outwards==='true' || inwards==='true' || dc_numbers==='true') && (<>
          <ListItem onClick={handleClickFive} button sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }} >
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AssignmentIcon /></ListItemIcon>
            <ListItemText >
              <Typography component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}>Sub Contract</Box><Box>{openFive ? <ExpandLess /> : <ExpandMore />}</Box>
              </Typography>
            </ListItemText>
          </ListItem>
          <Collapse in={openFive} timeout="auto" unmountOnExit>
            <List sx={{ px: 2 }}>
            {inventory==='true' && <Link to="subcontractorinventory" className="text-nowrap text-decoration-none fs-5">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <Inventory2Icon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Inventory
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
             }
             {outwards==='true' && <Link to="subcontractoroutwards" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <LocalShippingIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Outwards
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
             }
             {inwards==='true' && <Link to="subcontractorinwards" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <WarehouseIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Inwards
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
              } 
              {dc_numbers==='true' && <Link to="subcontractordcnumbers" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <ConfirmationNumberIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Dc numbers
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
                }
            </List>
          </Collapse>
        </>)}

        {role === 'Super Admin' && (
        <Link to="stock" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><InventoryIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Stock</Typography>
            </ListItemText>
          </ListItem>
        </Link>
         )} 

        {sales_target_edit === 'true' && (<Link to="target" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><TrackChangesIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Target</Typography>
            </ListItemText>
          </ListItem>
        </Link>)}
       
        {(fgpartcode_edit === 'true' || sfgpartcode_edit === 'true' || subcomppartcode_edit === 'true' || partcode_edit === 'true' || partcode_view === 'true') && (<>
          <ListItem onClick={handleClickSix} button sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }} >
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><TerminalIcon /></ListItemIcon>
            <ListItemText >
              <Typography component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> Part Code</Box><Box>{openSix ? <ExpandLess /> : <ExpandMore />}</Box>
              </Typography>
            </ListItemText>
          </ListItem>
          <Collapse in={openSix} timeout="auto" unmountOnExit>
            <List sx={{ px: 2 }}>
              {(fgpartcode_edit === 'true') && (
        <Link to="fgpartcode" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddBoxIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>FG Code</Typography>
            </ListItemText>
          </ListItem>
        </Link>)} 
        {(sfgpartcode_edit === 'true') && (
        <Link to="sfgpartcode" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddBoxIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>SFG Code</Typography>
            </ListItemText>
          </ListItem>
        </Link>)}
         {(subcomppartcode_edit === 'true') && (
        <Link to="subcomponentpartcode" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddBoxIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Sub Component Code</Typography>
            </ListItemText>
          </ListItem>
        </Link>)}
        {(partcode_edit === 'true') && (
        <Link to="materialpartcode" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddBoxIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Material Code</Typography>
            </ListItemText>
          </ListItem>
        </Link>)}
        {(partcode_view === 'true') && (
        <Link to="boughtoutpartcode" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddBoxIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Boughtout Code</Typography>
            </ListItemText>
          </ListItem>
        </Link>)}

            </List>
          </Collapse>
        </>)}

        {/* {role === 'Super Admin' && (
        <Link to="stock" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><InventoryIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Stock</Typography>
            </ListItemText>
          </ListItem>
        </Link>
         )}  */}




 {/* Stocks and Store Screen SideBar */}



        {(stock_job === 'true') && (<>
          <ListItem onClick={handleClickSeven} button sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }} >
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><InventoryIcon /></ListItemIcon>
            <ListItemText >
              <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
                <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}>Stock Job Screen</Box><Box>{openSeven ? <ExpandLess /> : <ExpandMore />}</Box>
              </Typography>
            </ListItemText>
          </ListItem>
          <Collapse in={openSeven} timeout="auto" unmountOnExit>
            <List sx={{ px: 2 }}>
              {stock_job === 'true' && (<Link to="stockscreen" className=" text-nowrap text-decoration-none fs-5 ">
                          <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                  <AddBoxIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Stock Job Order
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>)}
              {stock_qr === 'true' && (<Link to="generateQRforstockjobs" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <QrCodeIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      QR Code Generation
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>)}
              {stock_pending=== 'true' && (<Link to="pendingstocks" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                  <PendingActionsIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Pending Stocks
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>)}
            {stock_issued === 'true' && (<Link to="issuedstocks" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                  <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Issued Stocks
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>)}
            </List>
          </Collapse>
          </>)}



        {(store_stock === 'true') && (<>
          <ListItem onClick={handleClickEight} button sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }} >
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><StoreIcon /></ListItemIcon>
            <ListItemText >
              <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
                <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}>StoreScreen</Box><Box>{openEight ? <ExpandLess /> : <ExpandMore />}</Box>
              </Typography>
            </ListItemText>
          </ListItem>
          <Collapse in={openEight} timeout="auto" unmountOnExit>
            <List sx={{ px: 2 }}>

             <Link to="storescreen" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <QrCodeIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      QR Codes
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
              
               <Link to="storescreenQRtotal" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <QrCodeScannerIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Total QR Code
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </List>
          </Collapse>
        </>)}

{/* pending tasks */}


          {(department !== '') && (<>
          <ListItem onClick={handleClickNine} button sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }} >
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><TaskIcon /></ListItemIcon>
            <ListItemText >
              <Typography component={'div'} sx={{ display: 'flex', fontWeight: 500, justifyContent: 'space-between' }} >
                <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}>Tasks</Box><Box>{openNine ? <ExpandLess /> : <ExpandMore />}</Box>
              </Typography>
            </ListItemText>
          </ListItem>
          <Collapse in={openNine} timeout="auto" unmountOnExit>
            <List sx={{ px: 2 }}>

             <Link to="dailytask" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <AddTaskIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Daily Tasks
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
              
               <Link to="delayedtask" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <AssignmentLateIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Delayed & Pending Tasks
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          </>)}
        






       

        {/* {(fgpartcode_edit === 'true') && (
        <Link to="fgpartcode" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddBoxIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>FG Code</Typography>
            </ListItemText>
          </ListItem>
        </Link>)} 
        {(sfgpartcode_edit === 'true') && (
        <Link to="sfgpartcode" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddBoxIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>SFG Code</Typography>
            </ListItemText>
          </ListItem>
        </Link>)}
         {(subcomppartcode_edit === 'true') && (
        <Link to="subcomponentpartcode" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddBoxIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Sub Component Code</Typography>
            </ListItemText>
          </ListItem>
        </Link>)}
        {(partcode_edit === 'true') && (
        <Link to="materialpartcode" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddBoxIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Material Code</Typography>
            </ListItemText>
          </ListItem>
        </Link>)}
        {(partcode_view === 'true') && (
        <Link to="boughtoutpartcode" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={handleDrawerToggle} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><AddBoxIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Boughtout Code</Typography>
            </ListItemText>
          </ListItem>
        </Link>)}
         */}

        {(add_register === 'true' || edit_register === 'true') && (<><ListItem onClick={handleClickFour} button sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }} >
          <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><PersonAddAltIcon /></ListItemIcon>
          <ListItemText >
            <Typography component={'div'} sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
              <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}>Register</Box><Box>{openFour ? <ExpandLess /> : <ExpandMore />}</Box>
            </Typography>
          </ListItemText>
        </ListItem>
          <Collapse in={openFour} timeout="auto" unmountOnExit>
            <List sx={{ px: 2 }}>
              {add_register === 'true' && <Link to="register" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography className="fw-500">
                      Add Users
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
                }
             {edit_register === 'true' && <Link to="allregistedusers" className=" text-nowrap text-decoration-none fs-5 ">
                <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                  <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: 'primary.main' }} >
                    <Typography sx={{ fontWeight: 500 }}>
                      Edit Users
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
              }
            </List>
          </Collapse></>)}




          
        <Link to="logout" className=" text-nowrap text-decoration-none fs-5 ">
          <ListItem button onClick={() => localStorage.clear()} sx={{ textAlign: 'left', borderRadius: 2, py: 1, color: 'primary.main' }}>
            <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><LogoutIcon /></ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontWeight: 500 }}>Logout</Typography>
            </ListItemText>
          </ListItem>
        </Link>
      </List>
    </Box>
  </Box>)
  return (
    <Box>
      <Drawer
        open={mobileOpen}
        variant={responsive ? 'temporary' : 'permanent'}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: 'block',
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            bgcolor: '#eaf4fc'
          },
        }}
        anchor="left"
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default SideBar