import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { Button, Box, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Card, Grid, TextField } from '@mui/material';
import { loopQrcode, singlestockhead, stockhead } from '../Variables/Variables';
import Heading from '../Heading';
import Filter from '../Filter';
import { filterJobScreen } from '../FilterData';
import { useState } from 'react';
import Nodata from '../Nodata';
// import { ExportCSV } from './../Comps/ExportCSV';
import GenerateQrcode from '../StockQrCode/GenerateQrCode';
import PrintmultiQr from '../StockQrCode/PrintmultiQr';
import { getstock, methodGet, methodPost, searchstock } from '../../Services/Apivariables';
import useFetch from '../../Services/useFetch';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import SnackBar from './../SnackBar';





function Stocktable({subpage, data}) {
 
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [dummystate, setdummystate] = useState(false);
    //setapi filter data in qrsearch
    const [qrsearch, setqrsearch] = useState("");
    const center = 'center';
    const justify = 'justify';
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const stocksearch = loopQrcode(qrsearch==''?(data):(qrsearch));
  
    const navigate = useNavigate();
    const { register, formState: { errors }, reset, handleSubmit } = useForm();
    
    const onSubmit = (data) => {
        const serverData = new FormData()
        for (var key in data) {
            serverData.append(key, data[key]);
        }
        axios({
            method: methodPost,
            url: searchstock,
            data: serverData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setdummystate(true)
                setqrsearch(res.data.data)
            }
        }).catch(err => {
            console.log(err)
        });
      }
      
      
  
        return (

            <Box variant="div" sx={{ p: 3 }}>
                 <SnackBar open={open} message={message} setOpen={setOpen}  color={color} status={status} />
                <Box sx={{ py: 3 }}>
                    <Heading title={'Current Stock'} />
                </Box>
                <Box sx={{ py: 3 }}>
                    <Box>
                        {data.length === 0 ? <Nodata /> : <>
                            <Box sx={{ py: 2 }}>
                            <Grid container>
                             <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                           <PrintmultiQr QrcodeData={stocksearch} />
                            </Grid>
                       <Grid item xs={12} sm={4} md={4} lg={3} xl={3} sx={{ py:1}}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Stock Set"
                          variant="outlined"
                          {...register("stock_set", { required: true })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ maxLength: 20 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={3} xl={3} sx={{ py:1, px:2}}>
                  
                      <Button variant="contained" sx={{ backgroundcolor: '#153c86' }} onClick={handleSubmit(onSubmit)}>
                        Submit
                      </Button>
                   
                    </Grid>
                  </Grid>
                           
                            </Box>
                            <Box sx={{ p: 1, borderRadius: 4 }} component={Card}>
                                <TableContainer sx={{ borderRadius: 2 }} >
                                    <Table>
                                        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                                            <TableRow >
                                                {
                                                    singlestockhead.map((hd) =>
                                                        <TableCell key={hd.id} sx={{
                                                            px: 3,
                                                            color: 'white', fontWeight: 600
                                                        }}>
                                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Box sx={{ my: 'auto' }}>
                                                                    {hd.heading}
                                                                </Box>
                                                                <Box>
                                                                    {hd.filter ? <Filter search={search} label={hd.heading} setSearch={setSearch} /> : ''}
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                data.filter((data) => filterJobScreen(data, search, {
                                                    searchFeildOne: data.part_no,
                                                    searchFeildTwo: data.partdescription,
                                                    searchFeildThree: data.fanmodel
                                                })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((stock) =>
                                                    <TableRow hover key={stock.id} sx={{ cursor: 'pointer' }} onClick={() => {
                                                        navigate(subpage, {
                                                            state: {
                                                                id: stock.id
                                                            }
                                                        });
                                                    }} 
                                                    >
                                                        <TableCell sx={{ textAlign: center }}>{stock.part_no}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.partdescription}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.fanmodel_no}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.qty}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.material}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.weight}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.location}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.rackwise}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.location_rackno}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.dia}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.part_no+'-'+stock.fanmodel+'-'+'ST001-'+stock.qty}</TableCell>
                                                        <TableCell sx={{ textAlign: center }}>{stock.customer}</TableCell>
                                                        {/* <TableCell sx={{ textAlign: center }}>
                                                <GenerateQrcode QrCodeValue={{partno:stock.part_no,partdescription:stock.partdescription,fanmodel:stock.fanmodel,qty:stock.qty,material:stock.material,weight:stock.weight,stockno:stock.stockno}}  QrCode={'Location - ' + stock.location+ '\nRack Wise - ' + stock.rackwise +'\nLocation (Rack No) - '+ stock.location_rackno +'\nDIA - '+ stock.dia}/>
                                                </TableCell> */}
                                                </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box></>}
                            
                    </Box>
                </Box>
                <Outlet />
            </Box>
        )
    }

export default Stocktable