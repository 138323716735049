import React from 'react'
import { useState } from 'react';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, IconButton, Tooltip } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { addremarks, fetchsinglemom, insertsingleprocessjob, methodPost, otherScreenSingleOrders } from '../../Services/Apivariables';
import SnackBar from './../SnackBar';
import DialogBox from './../DialogBox';
import Heading from './../Heading';
import { momTableHeading } from '../Variables/ProcessVariables';
import Filter from './../Filter';
import { filterJobScreen } from '../FilterData';
import { nullFinderandDateswapper } from '../Variables/Variables';
import submitForm from '../../Services/ProcessScreenSubmitForm';
import Statusbar from './../Statusbar';
import { appendData } from '../Variables/ProcessVariables'
import axios from 'axios';
import Momsinglejob from './Processsinglejob/Momsinglejob'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Mom({ data, title, setrefreshpage }) {

  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [status, setStatus] = useState();
  const [color, setColor] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [multipleOrders, setMultipleOrders] = useState([])
  const [id, setid] = useState('');

  // this state holds all input values as obj
  const [momInputs, setMomInputs] = useState({})
  const [selected, setSelected] = useState([]);
  const jobnum = data.map((i)=>i.job_num);
  const orderid = data.map((i)=>i.id);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = () => {
    if (selected.length > 0) {
      if(momInputs.mom_executed_date !== '' || momInputs.mom_released_date !== ''){
      const selectedjobs = selected.map((element, i) => {
        const obj = {
          i: i,
          orderid: element.orderid,
          mom_executed_date: momInputs.mom_executed_date,
          mom_released_date: momInputs.mom_released_date,
          mom_remarks: momInputs.mom_remarks ? momInputs.mom_remarks : ''
        }
        return obj
      })
      const sendData = {
        "job_no":jobnum,
        "mom": JSON.stringify(selectedjobs)
      }
      const lData = appendData(sendData)
        submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchsinglejob, jobnum)
        setMomInputs({
        mom_executed_date: '',
        mom_released_date: '',
        mom_remarks: ''
      });
    }else{
      const selectedjobs = selected.map((element, i) => {
        const obj = {
          i: i,
          orderid: element.orderid,
          mom_remarks: momInputs.mom_remarks
        }
        return obj
      })
      const sendData = {
        "job_no":jobnum,
        "mom": JSON.stringify(selectedjobs)
      }
      const lData = appendData(sendData)
     submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchsinglejob, jobnum)
       setMomInputs({
        mom_executed_date: '',
        mom_released_date: '',
        mom_remarks: ''
      });
    }} else {
      setMessage("Please select atleast one job")
      setOpen(true)
      setStatus(false)
      setColor(false)
    }
  }

//  const onUpdate =() =>{
//     if(momInputs.mom_executed_date !== '' || momInputs.mom_released_date !== ''){
//     const selectedjobs = data.map((element, i) => {
//       const obj = {
//         i: i,
//         orderid: orderid.toString(),
//         mom_executed_date: momInputs.mom_executed_date,
//         mom_released_date: momInputs.mom_released_date,
//         mom_remarks: momInputs.mom_remarks ? momInputs.mom_remarks : ''
//       }
//       return obj
//     })
//     const sendData = {
//       "job_no":jobnum,
//       "mom": JSON.stringify(selectedjobs)
//     }
//     const lData = appendData(sendData)
//     submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage)
//     setMomInputs({
//       mom_executed_date: '',
//       mom_released_date: '',
//       mom_remarks: ''
//     });
//     setSelected([]);
//     setIsEdit(false);
//     setid(null);
    
//   }else if (momInputs.mom_remarks !== ""){
//     const selectedjobs = data.map((element, i) => {
//       const obj = {
//         i: i,
//         orderid: orderid.toString(),
//         mom_remarks: momInputs.mom_remarks
//       }
//       return obj
//     })
//     const sendData = {
//       "job_no":jobnum,
//       "mom": JSON.stringify(selectedjobs)
//     }
//     const lData = appendData(sendData)
//     submitForm(addremarks, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage)
//     setMomInputs({
//       mom_executed_date: '',
//       mom_released_date: '',
//       mom_remarks: ''
//     });
//     setSelected([]);
//     setIsEdit(false);
//     setid(null);
//   }else {
//     setMessage("Please Fill atleast One Input Field")
//     setOpen(true)
//     setStatus(false)
//     setColor(false)
//   }
//  }

  const fetchsinglejob = (id) => {
    const oid = { job_num: id }
    const sendid = appendData(oid)
    axios({
      method: methodPost,
      url: fetchsinglemom,
      data: sendid
    }).then(res => {
      setMultipleOrders(res.data.data)
    }).catch(err => {
      console.log(err)
    });
  }
 
  // const edd = data.map((i)=> i.mom_planning_edd.toString());
  // const date = new Date(edd);
  // const day = date.getDate()- 6;
  // const minDay =  date.setDate(day);
  // let d , m ;
  // d = date.getDate();
  // m = date.getMonth()+1;
  // if (d < 10) {
  //     d = '0' + d;
  //   }
    
  //   if (m < 10) {
  //     m = '0' + m;
  //   }
  // const convert = [date.getFullYear(),(m), (d)].join('-');

  return (
    <Box>
      <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
      <Box sx={{ py: 3 }}>
        <Heading title={title} />
      </Box>
      <Box sx={{ py: 3 }}>
        <Box sx={{ p: 2 }} component={Card}>
          <TableContainer >
            <Table>
              <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                <TableRow >
                  {
                    momTableHeading.map((headings) =>
                      <TableCell key={headings.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box sx={{ my: 'auto' }}>
                            {headings.label}
                          </Box>
                          <Box>
                            {headings.filter ? <Filter label={headings.label} setSearch={setSearch} /> : ''}
                          </Box>
                        </Box>
                      </TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  data.filter((data) => filterJobScreen(data, search, {
                    searchFeildOne: data.job_num,
                    searchFeildTwo: data.prod_code,
                    searchFeildThree: data.short_char_01
                  })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) =>
                    <TableRow key={items.id} hover>
                      <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.serial_no}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.job_num}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        <Box>
                          <Statusbar label={items.mom_status === 'true' ? 'Completed' : 'Pending'} color={items.mom_status === 'true' ? 'success' : 'error'} />
                        </Box>
                      </TableCell> */}
                      <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                      <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                      <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.mom_planning_edd)}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setIsEdit(!isEdit)
                            setid(items.id)
                            fetchsinglejob(items.job_num)
                            setMomInputs({
                              mom_executed_date: '',
                              mom_released_date: '',
                              mom_remarks: ''
                            });
                          }}>
                          {isEdit && id === items.id ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
                        </IconButton>
                      </TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          disabled={isEdit && id === items.id ? false : true}
                          type='date'
                          value={isEdit && id === items.id ? momInputs.mom_executed_date : ''}
                          onChange={(e) => setMomInputs({ ...momInputs, "mom_executed_date": e.target.value })}
                          fullWidth
                          size='small'
                          label='Mom Executed Date'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ min: items.mom_planning_edd }}
                        />
                      </TableCell> */}
                      <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          disabled={isEdit && id === items.id ? false : true}
                          type='date'
                          value={isEdit && id === items.id ? momInputs.mom_released_date : ''}
                          onChange={(e) => setMomInputs({ ...momInputs, "mom_released_date": e.target.value })}
                          fullWidth
                          size='small'
                          label='Mom Released Date'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // inputProps={{ min: [convert] }}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <TextField
                          sx={{ width: 140 }}
                          multiline
                          value={isEdit && id === items.id ? momInputs.mom_remarks : ''}
                          disabled={isEdit && id === items.id ? false : true}
                          onChange={(e) => setMomInputs({ ...momInputs, "mom_remarks": e.target.value })}
                          fullWidth
                          size='small'
                          label='Remarks'
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell>

                          <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box> 

                      {/* {
                          multipleOrders.length !== 0 ? 
                          <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box> : 
                         <Box>
                         <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                           onUpdate()
                          
                         }} startIcon={<CheckCircleOutlineIcon />}>
                           Update
                         </Button>
                       </Box>
                        } */}
                        
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <Box sx={{ py: 3 }}>
        <Momsinglejob isEdit={isEdit} data={multipleOrders} selected={selected} setSelected={setSelected} />
      </Box>
    </Box>
  )
}

export default Mom