import axios from "axios";
import { useState, useEffect } from "react";

// thsi function is for get data with and without id from the db

const useFetch = (url, method, id) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const [data, setData] = useState([]);
  const [dept, setDept] = useState([]);
  const [dataOne, setDataOne] = useState([]);
  const [workType2, setWorkType2] = useState([]);
  const [cncscreen, setcncsreen] = useState([]);
  const [hubscreen, setHubscreen] = useState([]);
  const [machiningscreen, setMachiningscreen] = useState([]);
  const [impellerscreen, setImpellerscreen] = useState([]);
  const [accessoriesscreen, setAccessoriesscreen] = useState([]);
  const [storebomscreen, setStorebomscreen] = useState([]);
  const [purchasebomscreen, setPurchasebomscreen] = useState([]);
  const [refreshpage, setrefreshpage] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [emptydata, setemptydata] = useState(false);
  const [displayProduction, setDisplayProduction] = useState([]);
  const [displayStore, setDisplayStore] = useState([]);
  const [displayMom, setDisplayMom] = useState([]);
  const [displayPurchase, setDisplayPurchase] = useState([]);
  const [displayCnc, setDisplayCnc] = useState([]);
  const [displayHub, setDisplayHub] = useState([]);
  const [displayMachining, setDisplayMachining] = useState([]);
  const [displayImpeller, setDisplayImpeller] = useState([]);
  const [displayCasing, setDisplayCasing] = useState([]);
  const [displayFirstCoatPaint, setDisplayFirstCoatPaint] = useState([]);
  const [displayAssembly, setDisplayAssembly] = useState([]);
  const [displayTesting, setDisplayTesting] = useState([]);
  const [displayFinalPaint, setDisplayFinalPaint] = useState([]);
  const [displayPacking, setDisplayPacking] = useState([]);
  const [displayAccessories, setDisplayAccessories] = useState([]);
  const [impellerBarcodeData, setImpellerBarcodeData] = useState([]);
  const [casingBarcodeData, setCasingBarcodeData] = useState([]);
  const [assemblyBarcodeData, setAssemblyBarcodeData] = useState([]);
  const [hubBarcodeData, setHubBarcodeData] = useState([]);
  const [accessoriesBarcodeData, setAccessoriesBarcodeData] = useState([]);
  const [firstcoatpaintBarcodeData, setFirstCoatPaintBarcodeData] = useState([]);
  const [saleOrder, setsaleOrder] = useState([]);
  const [saleOrderReport, setsaleOrderReport] = useState([]);
  const [standardReport, setstandardreport] = useState([]);
  const [sparesReport, setsparesreport] = useState([]);
  const [otherReport, setotherReport] = useState([]);
  const [standardQtyReport, setstandardQtyReport] = useState([]);
  const [spareQtyReport, setspareQtyReport] = useState([]);
  const [otherQtyReport, setotherQtyReport] = useState([]);
  const [totalFanQtyReport, settotalFanQtyReport] = useState([]);

  const salereport = { standardReport,sparesReport,otherReport };
  const barCodeData = { impellerBarcodeData, accessoriesBarcodeData, casingBarcodeData, assemblyBarcodeData, hubBarcodeData, firstcoatpaintBarcodeData  };
  const processDisplayData = { displayProduction, displayStore, displayMom, displayPurchase, displayCnc, displayHub, displayMachining, displayImpeller, displayAccessories, displayCasing, displayFirstCoatPaint, displayAssembly, displayTesting, displayFinalPaint, displayPacking }
  useEffect(() => {
    if (!navigator.onLine) {
      alert("Your internet is in Offline")
    } else {
      axios({
        method: method,
        url: url,
        data: id,
        signal: signal
      }).then(res => {
        setData(res.data.data);
        setDept(res.data.department);
        setDataOne(res.data.data);
        setWorkType2(res.data.data);
        setIsloading(true);
        setcncsreen(res.data.cnc);
        setHubscreen(res.data.hub);
        setImpellerscreen(res.data.impeller);
        setAccessoriesscreen(res.data.accessoriesbom);
        setMachiningscreen(res.data.machining);
        setStorebomscreen(res.data.storebom);
        setPurchasebomscreen(res.data.purchasebom)
        setDisplayProduction(res.data.production);
        setDisplayStore(res.data.stores);
        setDisplayMom(res.data.mom);
        setDisplayPurchase(res.data.purchase);
        setDisplayCnc(res.data.cnc);
        setDisplayHub(res.data.hub);
        setDisplayMachining(res.data.machining);
        setDisplayImpeller(res.data.impeller);
        setDisplayAccessories(res.data.accessories);
        setDisplayCasing(res.data.casing);
        setDisplayFirstCoatPaint(res.data.fc_paint);
        setDisplayAssembly(res.data.assembly);
        setDisplayTesting(res.data.testing);
        setDisplayFinalPaint(res.data.final_paint);
        setDisplayPacking(res.data.packing);
        setCasingBarcodeData(res.data.casing_sfg);
        setImpellerBarcodeData(res.data.impeller_sfg);
        setAccessoriesBarcodeData(res.data.accessories_sfg);
        setAssemblyBarcodeData(res.data.assembly_sfg);
        setHubBarcodeData(res.data.hub_sfg);
        setFirstCoatPaintBarcodeData(res.data.fcpsfg);
        setsaleOrder(res.data.saleOrder);
        setsaleOrderReport(res.data.saleOrderReport);
        setstandardreport(res.data.standardReport);
        setsparesreport(res.data.sparesReport);
        setotherReport(res.data.otherReport);
        setstandardQtyReport(res.data.standardQtyReport);
        setspareQtyReport(res.data.spareQtyReport);
        setotherQtyReport(res.data.otherQtyReport);
        settotalFanQtyReport(res.data.fanQtyReport);
        setrefreshpage(false)
      }).catch(err => {
        console.log(err)
      });
    }
    return () => controller.abort();
  }, [refreshpage]);
  return { data, dataOne, workType2,dept , totalFanQtyReport, isloading, emptydata, cncscreen, hubscreen, machiningscreen, impellerscreen, accessoriesscreen, storebomscreen, purchasebomscreen, processDisplayData, barCodeData, setrefreshpage, saleOrder,saleOrderReport,salereport,standardReport,sparesReport,otherReport, standardQtyReport,spareQtyReport,otherQtyReport };
};

export default useFetch;