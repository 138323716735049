import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Box, Card, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, TablePagination, TextField, Button, IconButton, Typography } from '@mui/material';
import Heading from './../Comps/Heading';
import QRStockJobOrderLayoutTable from '../Comps/BarCodeModule/QRStockJobOrderLayoutTable';
import  QRStockJobOrderLayoutHeader from '../Comps/QRStockJobOrderLayoutHeader';
import { qrcodeStockJobsingleLayoutTableHeadings } from '../Comps/Variables/Variables';
import Nodata from '../Comps/Nodata';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { footerstockjob, stockcompletion } from '../Services/Apivariables';
import axios from 'axios';
import { appendData } from '../Comps/Variables/ProcessVariables';
import SnackBar from './../Comps/SnackBar';


function QRStockJobitemSingleLayout() {
    const location = useLocation();
    const StockQRScreenLayoutData = location.state; 
    const [emptydata, setemptydata] = useState(false);
    const [refreshpage, setrefreshpage] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [inputs, setInputs] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [id, setid] = useState('');
    const [showFooter , setShowFooter]=useState(false);
    const [isloading, setIsloading] = useState(false);
     const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [showQR , setShowQR] = useState(false);
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({ ...values, [name]: value }))
    }


    const fetchfooterstock = (workOrderNumber) => {
      const worknum  = { workOrderNum: workOrderNumber }
      const sendid = appendData(worknum);
      axios({
        method: 'POST',
        url: footerstockjob,
        data: sendid
      }).then(res => {
        if (res.data.data !== undefined) {
          setData(res.data.data)
          setrefreshpage(false);
        }
        else
        {
          setrefreshpage(true)
        }
      }).catch(err => {
        console.log(err)
      });
    }
      

  const submit = (date) =>{
    if(inputs.completedDate !== ''){
    const stockDate = data.map((element) => {
      const obj = {
        stockNumber: element.stockNumber,
        partCode: element.partCode,
        stockCompleted: date,
      }
      return obj
    })
    const sendData = {
      'workOrderNum': StockQRScreenLayoutData.workOrderNum,
      "stockDate":JSON.stringify(stockDate)
    }
    const body = appendData(sendData);

    axios({
      method:'POST',
      url: stockcompletion,
      data:body,
    }).then(res => {
      if (res.data.error) {
        setMessage(res.data.message)
        setOpen(true)
        setStatus(false)
        setColor(false)
        setrefreshpage(false)
    } else {
        setOpen(true)
        setMessage(res.data.message)
        setStatus(true)
        setColor(true)
        setrefreshpage(false)
    }
    }).catch(err => {
      console.log(err)
    });
  }
  fetchfooterstock(StockQRScreenLayoutData.workOrderNum);
  }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

return (
                <Box variant='div' sx={{ p: 3 }}>
                    <Box sx={{ py: 3 }}>
                        <Heading title={'Generate QR Code'} />
                    </Box>
                    {/* Header Section */}

                    <Box sx={{ py: 3 }}>
                        <QRStockJobOrderLayoutHeader data={StockQRScreenLayoutData} />
                    </Box>

                    {/* MiddleSection for QR Generation with completion Date */}

                     
                    <Box sx={{ py: 3 }}>
        <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
          <Box sx={{ p: 2, bitemRadius: 4 }} component={Card}>
            {emptydata && StockQRScreenLayoutData.length === 0 ? <Nodata /> : <>
              <TableContainer sx={{ bitemRadius: 2 }} >
                <Table>
                  <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                    <TableRow >
                      {
                       qrcodeStockJobsingleLayoutTableHeadings.map((hd, index) =>

                          <TableCell sx={{
                            color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                          }} key={index}>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ my: 'auto' }}>
                                {hd.label}
                              </Box>
                            </Box>
                          </TableCell>
                        )
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                     [StockQRScreenLayoutData].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                        <TableRow hover key={item.id}>
                          <TableCell sx={{ textAlign: 'center' }}>{item.workOrderNum}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{item.fanModel}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{item.workType1}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{item.Qty}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{item.ERPJobNum}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            <IconButton aria-label="edit" onClick={() => {
                              setIsEdit(true)
                              setid(item.id)
                              setShowFooter(true)
                              fetchfooterstock(item.workOrderNum)
                              setrefreshpage(true)
                            }}>
                              {isEdit && id === item.id ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <TextField
                              disabled={isEdit && id === item.id ? false : true}
                              value={isEdit && id === item.id ? inputs.completedDate : ""}
                              name='completedDate'
                              type='date'
                              fullWidth
                              onChange={handleChange}
                              required
                              size='small'
                              label='Completed Date'
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Button disabled={inputs.completedDate ? false : true} variant="contained"
                             onClick={()=> submit(inputs.completedDate)}
                              >
                              submit
                              </Button>
                            </Box>
                          </TableCell>
                          </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={[StockQRScreenLayoutData].length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>}
          </Box>
        </Box>


                    {/* Footer Section */}
                    {
                      showFooter ? (
                        <Box>
                        <QRStockJobOrderLayoutTable  data={data} />
                    </Box> 
                      ) : (
                        <Box>
                      <Nodata />
                      </Box>
                      )
                    }
                                    
                </Box>

        )
    }


export default QRStockJobitemSingleLayout
