import React, { useState } from 'react'
import { Box, Card, Grid, FormControl, InputLabel, MenuItem, Select, TextField, Button, FormHelperText } from '@mui/material';
import useFetch from '../Services/useFetch';
import Heading from './../Comps/Heading';
import { useForm } from 'react-hook-form';
import { insertTargetScreen, location, methodGet, methodPost } from '../Services/Apivariables';
import axios from 'axios';
import SnackBar from './../Comps/SnackBar';
import Targettable from '../Comps/Targettable';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 200,
        },
    },
};
function Target() {
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const { data } = useFetch(location, methodGet);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [message, setMessage] = useState("");
    const [dummystate, setdummystate] = useState(false);
    const onSubmit = (data) => {
        const serverData = new FormData()
        for (var key in data) {
            serverData.append(key, data[key]);
        }
        axios({
            method: methodPost,
            url: insertTargetScreen,
            data: serverData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setOpen(true)
                setStatus(false)
                setColor(false)
            } else {
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setdummystate(true)
                reset({
                    sales_person_type: "",
                    locations: "",
                    team: "",
                    sales_person_name: "",
                    monthly_target: "",
                    annual_target: "",
                    financial_year: ""
                })
            }
        }).catch(err => {
            console.log(err)
        });
    }
    return (
        <Box variant="div" sx={{ p: 3 }}>
            <Box sx={{ py: 3 }}>
                <Heading title={'Target'} />
                <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
            </Box>
            <Box sx={{ py: 3, borderRadius: 4 }} component={Card}>
                <Grid container justifyContent='center'>
                    <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                        <Grid container sx={{ p: 2 }} spacing={2}>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                <Box>
                                    <FormControl size='small' fullWidth error={errors.sales_person_type ? true : false}>
                                        <InputLabel>Sales Person Type</InputLabel>
                                        <Select label="Sales Person Type" {...register("sales_person_type", { required: true })}>
                                            <MenuItem value={"External"}>External</MenuItem>
                                            <MenuItem value={"Internal"}>Internal</MenuItem>
                                        </Select>
                                        <FormHelperText>{errors.sales_person_type ? "Sales person type is required" : ""}</FormHelperText>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                <FormControl size='small' fullWidth error={errors.locations ? true : false}>
                                    <InputLabel>Location</InputLabel>
                                    <Select
                                        MenuProps={MenuProps} label="Location" {...register("locations", { required: true })}>
                                        {
                                            data.map((location) =>
                                                <MenuItem key={location.city_id} value={location.city_name}>{location.city_name}</MenuItem>
                                            )
                                        }
                                    </Select>
                                    <FormHelperText>{errors.locations ? "location is required" : ""}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                <FormControl size='small' fullWidth error={errors.team ? true : false}>
                                    <InputLabel>Team</InputLabel>
                                    <Select label="Team" {...register('team', { required: true })}>
                                        <MenuItem value={"Sales"}>Sales</MenuItem>
                                        <MenuItem value={"Project"}>Project</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.team ? "Team is required" : ""}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                <TextField
                                    error={errors.sales_person_name ? true : false}
                                    helperText={errors.sales_person_name ? "Sales person name is required" : ""}
                                    {...register("sales_person_name", { required: true })}
                                    fullWidth
                                    size='small'
                                    label="Sales Person Name"
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                <TextField
                                    error={errors.monthly_target ? true : false}
                                    helperText={errors.monthly_target ? "Monthly Target is required" : ""}
                                    {...register("monthly_target", { required: true })}
                                    fullWidth
                                    size='small'
                                    label="Monthly Target"
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                <TextField
                                    error={errors.annual_target ? true : false}
                                    helperText={errors.annual_target ? "Annual Target is required" : ""}
                                    {...register("annual_target", { required: true })}
                                    fullWidth
                                    size='small'
                                    label="Annual Target"
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                <TextField
                                    error={errors.financial_year ? true : false}
                                    helperText={errors.financial_year ? "Financial Year is required" : ""}
                                    {...register("financial_year", { required: true })}
                                    fullWidth
                                    size='small'
                                    label="Financial Year"
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                <Box>
                                    <Button variant="contained" fullWidth onClick={handleSubmit(onSubmit)}>Submit</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Targettable dummystate={dummystate} setdummystate={setdummystate} />
        </Box>
    )
}

export default Target