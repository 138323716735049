import React, { useState } from 'react'
import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import GenerateBarcode from './../GenerateBarcode';
import Heading from './../../Heading';
import PrintMultipleSFGCode from './../PrintMultipleSFGCode';
import Statusloader from '../../Statusloader';
import Nodata from "../../Nodata";

function AccessoriesSFGtable({ data, title, tableHeadings, isloading }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {

    return (
        <>
            <Box sx={{ py: 3 }}>
                <Heading title={title} />
            </Box>
            {data.length > 0 && (
                <PrintMultipleSFGCode barcodedata={data} totalqr={{stage:'Accessories'}}/>
            )}
            {data.length > 0 ? (
            <Box sx={{ p: 2 }} component={Card}>
                <TableContainer>
                    <Table>
                        <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                            <TableRow >
                                {
                                    tableHeadings.map((headingLabels) =>
                                        <TableCell key={headingLabels.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                            {headingLabels.name}
                                        </TableCell >
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((barCodeData) =>
                                    <TableRow>
                                        <TableCell sx={{ textAlign: 'center' }}>{barCodeData.order_num}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{barCodeData.job_num}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{barCodeData.serial_no}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{barCodeData.part_code}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{barCodeData.descriptions}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                        <GenerateBarcode barCodeValue={{customer_name:barCodeData.customer_name,prc_con_num:barCodeData.prc_con_num,order_num:barCodeData.order_num,job_num:barCodeData.job_num,serial_no:barCodeData.serial_no,short_char_01:barCodeData.short_char_01,finished_weight:barCodeData.finished_weight ,part_code:barCodeData.part_code,descriptions:barCodeData.descriptions,matl_part_code:'Accessories',stage:'STAGE / SEC - '}} QrCodeData={'Manufacturer Name - NADI'+'\nCust.  Name - ' + barCodeData.customer_name +'\nFAN MODEL - ' + barCodeData.short_char_01 + '\nJOB NO - ' + barCodeData.job_num + '\nSERIAL NUMBER - ' + barCodeData.serial_no+'\nPART CODE - '+ barCodeData.part_code + '\nPART DESC - ' + barCodeData.descriptions + '\nWEIGHT - '+ barCodeData.finished_weight}/>
                                        </TableCell>
                                    </TableRow>
                                )
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
            )
                :
                <Nodata />
            }
        </>
    )
}
}
export default AccessoriesSFGtable