import React, { useState } from 'react'
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useLocation } from 'react-router-dom';
import OrderDetails from '../Comps/OrderDetails';
import { displayProcessFetch, methodPost } from '../Services/Apivariables';
import Heading from './../Comps/Heading';
import Statusloader from './../Comps/Statusloader';
import useFetch from './../Services/useFetch';

// this imports are comps used in process screen 
import Displayproductiondrawing from './../Comps/DisplayProcessScreenModule/Displayproductiondrawing';
import Displaymom from './../Comps/DisplayProcessScreenModule/Displaymom';
import Displaystore from './../Comps/DisplayProcessScreenModule/Displaystore';
import Displaypurchase from './../Comps/DisplayProcessScreenModule/Displaypurchase';
import Displaycnc from './../Comps/DisplayProcessScreenModule/Displaycnc';
import Displayhub from './../Comps/DisplayProcessScreenModule/Displayhub';
import Displaymachining from './../Comps/DisplayProcessScreenModule/Displaymachining';
import Displayimpeller from './../Comps/DisplayProcessScreenModule/Displayimpeller';
import Displaycasing from './../Comps/DisplayProcessScreenModule/Displaycasing';
import Displayfirstcoat from './../Comps/DisplayProcessScreenModule/Displayfirstcoat';
import Displayassembly from './../Comps/DisplayProcessScreenModule/Displayassembly';
import Displaytesting from './../Comps/DisplayProcessScreenModule/Displaytesting';
import Displayfinalpaint from './../Comps/DisplayProcessScreenModule/Displayfinalpaint';
import Displaypacking from './../Comps/DisplayProcessScreenModule/Displaypacking';
import Displayaccessories from './../Comps/DisplayProcessScreenModule/Displayaccessories';

function Displaysingleordersorderscreen() {
    const location = useLocation();
    const orderData = location.state;
    const id = location.state.job_num
    const job_num = new FormData();
    job_num.append("job_num", id);
    const { processDisplayData, isloading } = useFetch(displayProcessFetch, methodPost, job_num);
    const access = JSON.parse(localStorage.getItem('access'));
    const { production_view, mom_view, store_view, purchase_view, cnc_view, hub_view, machining_view, impeller_view, casing_view, first_paint_view, assembly_view, testing_view, final_paint_view, packing_view, accessories_view } = access;
    // this condition is for inital tab index where user based view
    const i = (production_view === 'true' && ('1')) || (mom_view === 'true' && ('2')) || (store_view === 'true' && ('3')) || (purchase_view === 'true' && ('4')) || (cnc_view === 'true' && ('5')) || (hub_view === 'true' && ('6')) || (machining_view === 'true' && ('7')) || (impeller_view === 'true' && ('8')) || (casing_view === 'true' && ('9')) || (first_paint_view === 'true' && ('10')) || (assembly_view === 'true' && ('11')) || (testing_view === 'true' && ('12')) || (final_paint_view === 'true' && ('13')) || (packing_view === 'true' && ('14')) || (accessories_view === 'true' && ('15'))
    const [value, setValue] = useState(i);
    // Tab functionality
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box variant='div' sx={{ p: 3 }}>
                <Box sx={{ py: 3 }}>
                    <Heading title={'process screen'} />
                </Box>
                <Box sx={{ py: 3 }}>
                    <OrderDetails data={orderData} />
                </Box>
                <Box>
                    <Box sx={{ py: 3 }}>
                        <TabContext value={value}>
                            <Box>
                                <TabList
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    onChange={handleChange} aria-label="tabs">
                                    {production_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Production Drawing " value="1" />)}
                                    {mom_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Mom" value="2" />)}
                                    {store_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Store" value="3" />)}
                                    {purchase_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Purchase" value="4" />)}
                                    {cnc_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="CNC" value="5" />)}
                                    {hub_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="HUB" value="6" />)}
                                    {machining_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Machining" value="7" />)}
                                    {impeller_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Impeller" value="8" />)}
                                    {casing_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Casing" value="9" />)}
                                    {first_paint_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="First Coat Paint" value="10" />)}
                                    {assembly_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Assembly" value="11" />)}
                                    {testing_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Testing" value="12" />)}
                                    {final_paint_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Final Paint" value="13" />)}
                                    {packing_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Packing Dispatch" value="14" />)}
                                    {accessories_view === 'true' && (<Tab sx={{ fontSize: 15, fontWeight: 700, color: 'primary.main' }} label="Accessories" value="15" />)}
                                </TabList>
                            </Box>
                            {production_view === 'true' && (<TabPanel value="1">
                                <Box>
                                    <Displayproductiondrawing data={processDisplayData.displayProduction} title={'Production Drawing And Bom'} />
                                </Box>
                            </TabPanel>)}
                            {mom_view === 'true' && (<TabPanel value="2">
                                <Box>
                                    <Displaymom data={processDisplayData.displayMom} title={'Mom'} />
                                </Box>
                            </TabPanel>)}
                            {store_view === 'true' && (<TabPanel value="3">
                                <Box>
                                    <Displaystore jobnum={id} data={processDisplayData.displayStore} title={'Store'} />
                                </Box>
                            </TabPanel>)}
                            {purchase_view === 'true' && (<TabPanel value="4">
                                <Box>
                                    <Displaypurchase jobnum={id} data={processDisplayData.displayPurchase} title={'Purchase'} />
                                </Box>
                            </TabPanel>)}
                            {cnc_view === 'true' && (<TabPanel value="5">
                                <Box>
                                    <Displaycnc jobnum={id} data={processDisplayData.displayCnc} title={'CNC'} />
                                </Box>
                            </TabPanel>)}
                            {hub_view === 'true' && (<TabPanel value="6">
                                <Box>
                                    <Displayhub jobnum={id} data={processDisplayData.displayHub} title={'Hub'} />
                                </Box>
                            </TabPanel>)}
                            {machining_view === 'true' && (<TabPanel value="7">
                                <Box>
                                    <Displaymachining jobnum={id} data={processDisplayData.displayMachining} title={'Machining'} />
                                </Box>
                            </TabPanel>)}
                            {impeller_view === 'true' && (<TabPanel value="8">
                                <Box>
                                    <Displayimpeller jobnum={id} data={processDisplayData.displayImpeller} title={'Impeller'} />
                                </Box>
                            </TabPanel>)}
                            {casing_view === 'true' && (<TabPanel value="9">
                                <Box>
                                    <Displaycasing jobnum={id} data={processDisplayData.displayCasing} title={'Casing'} />
                                </Box>
                            </TabPanel>)}
                            {first_paint_view === 'true' && (<TabPanel value="10">
                                <Box>
                                    <Displayfirstcoat jobnum={id} data={processDisplayData.displayFirstCoatPaint} title={'First Coat Paint'} />
                                </Box>
                            </TabPanel>)}
                            {assembly_view === 'true' && (<TabPanel value="11">
                                <Box>
                                    <Displayassembly jobnum={id} data={processDisplayData.displayAssembly} title={'Assembly'} />
                                </Box>
                            </TabPanel>)}
                            {testing_view === 'true' && (<TabPanel value="12">
                                <Box>
                                    <Displaytesting jobnum={id} data={processDisplayData.displayTesting} title={'Testing'} />
                                </Box>
                            </TabPanel>)}
                            {final_paint_view === 'true' && (<TabPanel value="13">
                                <Box>
                                    <Displayfinalpaint jobnum={id} data={processDisplayData.displayFinalPaint} title={'Final Paint'} />
                                </Box>
                            </TabPanel>)}
                            {packing_view === 'true' && (<TabPanel value="14">
                                <Box>
                                    <Displaypacking jobnum={id} data={processDisplayData.displayPacking} title={'Packing & Dispatching'} />
                                </Box>
                            </TabPanel>)}
                            {accessories_view === 'true' && (<TabPanel value="15">
                                <Box>
                                    <Displayaccessories jobnum={id} data={processDisplayData.displayAccessories} title={'Accessories'} />
                                </Box>
                            </TabPanel>)}
                        </TabContext>
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default Displaysingleordersorderscreen