import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import QRCode from 'qrcode';
import { TableContainer, TableHead, TableRow, TableCell, Table, TableBody } from '@mui/material';




function Allstorebarcode({ generateBarcode }) {
    const [imageUrl, setImageUrl] = useState('');
    useEffect(() => {
        const imageData = 'Manufacturer Name - NADI STORE'  +'\nPart Code - '+
         generateBarcode.partCode + '\nPart Desc - ' + generateBarcode.partDescriptions +'\nClass - ' + generateBarcode.class 
        + '\nSub Class - ' + generateBarcode.subclass + '\nuom - ' + generateBarcode.uom+
        '\nStock No - '+ generateBarcode.stockno 
        +  '\nQty - '+ generateBarcode.onHandQty + '\nUnit Price - '+ generateBarcode.unitCost 
        +  '\nTotal Stock Value - '+ generateBarcode.totalCost ;
        
        const generateQrCode = async () => {
            try {
                const response = await QRCode.toDataURL(imageData);

                setImageUrl(response);

            } catch (error) {
                console.log(error);
            }
        }
        generateQrCode()
    }, [])

  
    return (
        <Box sx={{ py: 1 }}>
            {/* <svg ref={inputRef} /> */}
            <TableContainer>
                            <Table>
                                
                                <TableBody>
                                  <TableCell>
                    {imageUrl && (
                        <a href={imageUrl} download>
                            <img src={imageUrl} alt="img" height="150" width="150" />
                        </a>
                    )}
               </TableCell>
               <TableCell>
                <Box sx={{fontWeight:600 , fontSize:16 , marginBottom:1}}>{generateBarcode?.partCode}</Box>
               <Box sx={{fontWeight:600 , fontSize:16 , marginBottom:1}}>{generateBarcode?.partDescriptions}</Box></TableCell> 
        </TableBody>
        </Table>
       </TableContainer>
                           
        </Box>
    )
}

export default Allstorebarcode