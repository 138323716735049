import React, { useEffect, useState } from 'react';
import { useBarcode } from 'react-barcodes';
import { Box, Typography, Grid } from '@mui/material';
import QRCode from 'qrcode';
import { TableContainer, TableHead, TableRow, TableCell, Table, TableBody } from '@mui/material';




function AllBarcodes({ generateBarcode }) {
    console.log(generateBarcode.customer_name)
    const [imageUrl, setImageUrl] = useState('');
    useEffect(() => {
        const imageData = 'Manufacturer Name - NADI'+'\nCust. Name - ' + generateBarcode.customer_name +'\nFAN MODEL - ' + generateBarcode.short_char_01 + '\nJOB NO - ' + generateBarcode.job_num + '\nSERIAL NUMBER - ' + generateBarcode.serial_no + '\nPART CODE - '+ generateBarcode.part_code +'\nPART DESC - ' + generateBarcode.descriptions + '\nMet Code - ' + generateBarcode.matl_part_code  +'\nWT / QTY - '+ generateBarcode.finished_weight+' / '+generateBarcode.qty
        const generateQrCode = async () => {
            try {
                const response = await QRCode.toDataURL(imageData);

                setImageUrl(response);

            } catch (error) {
                console.log(error);
            }
        }
        generateQrCode()
    }, [])

  
    return (
        <Box sx={{ py: 1 }}>
            {/* <svg ref={inputRef} /> */}
            <TableContainer>
                            <Table>
                                
                                <TableBody>
                                  <TableCell>
                    {imageUrl && (
                        <a href={imageUrl} download>
                            <img src={imageUrl} alt="img" height="150" width="150" />
                        </a>
                    )}
               </TableCell>
               <TableCell>
      
         
       <Box>
       {' FAN MODEL - '+generateBarcode?.short_char_01}
       </Box>

       <Box>
       {'JOB NO - '+ generateBarcode?.job_num}
       </Box>
       
       <Box>
       {'SERIAL NO- '+generateBarcode?.serial_no}
       </Box>
      
       <Box>
       {' PART DESC - '+generateBarcode?.descriptions}
       </Box>
      
       <Box>
       {'MET CODE - '+generateBarcode?.matl_part_code}
       </Box>

       <Box>
       {'WT / QTY - '+ generateBarcode.finished_weight+' / '+generateBarcode.qty}
       </Box>

    
       </TableCell> 
        </TableBody>
        </Table>
       </TableContainer>
                           
        </Box>
    )
}

export default AllBarcodes