import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { getallboughtoutcode, methodGet } from '../Services/Apivariables';
import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import Nodata from './Nodata';
import axios from 'axios';
import { partcodeTableHeading } from './Variables/Variables';
import { filterData } from './FilterData';
import Statusloader from './Statusloader';
import Filter from './Filter';

function BoughtoutTable({dummystate,setdummystate}) {
    const controller = new AbortController();
    const signal = controller.signal;
    const [targetdata, setTargetdata] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [emptydata, setemptydata] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [labels] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    useEffect(() => {
        if (!navigator.onLine) {
            alert("Your internet is in Offline")
        } else {
            axios({
                method: methodGet,
                url: getallboughtoutcode,
                signal: signal
            }).then(res => {
                if (res.data.data !== undefined) {
                    setTargetdata(res.data.data);
                    setIsloading(true);
                    setdummystate(false)
                } else {
                    setIsloading(true)
                    setemptydata(true)
                }
            }).catch(err => {
                console.log(err)
            });
        }
        return () => controller.abort();
    }, [dummystate]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box sx={{ my: 3, borderRadius: 4 }} component={Card}>
                {emptydata && targetdata.length === 0 ? <Nodata /> : (<Box sx={{ p: 2 }} >
                    <TableContainer >
                        <Table>
                            <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                            <TableRow >
                                    
                                    <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                        <Box sx={{ display: 'flex', justifyContent:'center',alignItems:'center' }}>
                                          { 'Boughtout Code' }
                                      
                           {labels.filter && <Filter label={labels.title} setSearch={setSearch} />}
                        </Box>
                                    </TableCell>
                                   <TableCell sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                   <Box sx={{ display: 'flex', justifyContent:'center',alignItems:'center' }}>
                                     { 'Created Date' }
                                            
                           {labels.filter && <Filter label={labels.title} setSearch={setSearch} />}
                                   </Box>
                               </TableCell>
                            
                        </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    targetdata.filter((data) => filterData(data, search, {
                                        searchFeildOne: data.boughtoutCode,
                                        searchFeildTwo: data.boughtout_created_at
                                    })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                                        <TableRow>
                                         <TableCell>  <Box sx={{ display: 'flex', justifyContent:'center',alignItems:'center' }}>{item.boughtoutCode}</Box></TableCell>
                                            <TableCell > <Box sx={{ display: 'flex', justifyContent:'center',alignItems:'center' }}>{item.boughtout_created_at}</Box></TableCell>
                                          
                                          
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={targetdata.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>)}
            </Box>
        )
    }
}

export default BoughtoutTable