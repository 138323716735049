import React from 'react'
import { Box } from '@mui/material';


function Homepage(){

    return (
        <Box>
         <Box>
            <h1>Welcome </h1>
            </Box>
 
            <Box sx={{ display:'flex',justifyContent:'center', alignItems:'center', height:'80vh', zIndex: (theme) => theme.zIndex.drawer + 1  }}>
        <img src={require('./Assets/nadiloader.gif')} width='150' height='150' alt="loading..." />
     </Box>
     </Box>
    )
}

export default Homepage
