import React from 'react'
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { exporttoexcel, headingLabels, headingLabelsfornonadmin} from '../Comps/Variables/Variables';
import AllOrdersTabel from '../Comps/AllOrdersTabel';
import Heading from './../Comps/Heading';
import Nodata from './../Comps/Nodata';
import useFetch from './../Services/useFetch';
import { allOrdersFetch, methodGet } from '../Services/Apivariables';
import Statusloader from './../Comps/Statusloader';
import { ExportCSV } from './../Comps/ExportCSV';


function JobScreen() {
    const {data, isloading, emptydata} = useFetch(allOrdersFetch, methodGet);
    const exporttoExcel = exporttoexcel(data);
    const roles = JSON.parse(localStorage.getItem('roles'));
  const role = "Super Admin" || "Admin" ;

    const headinglabel = roles === role ? headingLabels : headingLabelsfornonadmin;

    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box variant="div" sx={{ p: 3 }}>
                <Box sx={{ py: 3 }}>
                    <Heading title={'job screen'} />
                </Box>

                {emptydata && data.length === 0 ? <Nodata /> : <Box>
                    <Box sx={{ py: 2 }}>
                        <ExportCSV csvData={exporttoExcel} fileName={'JobScreen_report'} />
                    </Box>
                    <Box sx={{ py: 3 }}>
                        <AllOrdersTabel data={data} headingLabels={headinglabel} subpage={`singleorder`} />
                    </Box>
                </Box>}
                <Outlet />
            </Box>)
    }

}

export default JobScreen