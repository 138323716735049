import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import React, { useEffect, useState } from 'react'
import { Box, TableContainer, TableHead, TableRow, TableCell, TablePagination, Card, TableBody, Table , Button} from '@mui/material';
import useFetch from '../Services/useFetch';
import {  exportpmstoexcel, pmsTabelHeadings, pmsTableHeader } from '../Comps/Variables/Variables';
import { pms, methodGet } from '../Services/Apivariables';
import Heading from './../Comps/Heading';
import Filter from './../Comps/Filter';
import { filterData } from '../Comps/FilterData';
import Statusloader from './../Comps/Statusloader';
import Nodata from './../Comps/Nodata';
import { ExportCSV } from '../Comps/ExportCSV';
import axios from 'axios';
    
function Pmsexporttoexecl({filename , data}) {
  const center = "center";
  const [search, setSearch] = useState("");
 return (
        <>
        <Box display='none'>
         <Table id="pms-report">
          <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
               <TableRow >
                  {
                    pmsTableHeader.map((pmsheader) =>
                      <TableCell key={pmsheader.id} colSpan={pmsheader.colSize} sx={{ color: 'white', fontWeight: 600, textAlign: 'center' }}>{pmsheader.name}</TableCell>
                    )
                  }
                    </TableRow>
                    <TableRow >
                      {
                        pmsTabelHeadings.map((hd) =>
                          <TableCell key={hd.id} sx={{
                            color: 'white', fontWeight: 600, textAlign: 'center'
                          }}>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ my: 'auto' }}>
                                {hd.label}
                              </Box>
                              <Box>
                                {hd.filter ? <Filter label={hd.label} setSearch={setSearch} /> : ''}
                              </Box>
                            </Box>
                          </TableCell>
                        )
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      data && data.filter((data) => filterData(data, search, {
                        searchFeildOne: data.order_num,
                        searchFeildTwo: data.job_num
                      })).map((item , index) =>
                        <TableRow hover key={index} >
                       <TableCell sx={{ textAlign: center }}>{item.order_num}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.job_num}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.customerName}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.fanType}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.fanModel}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.orderQty}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.EDD}</TableCell>
            <TableCell sx={{ textAlign: center }} type='date'>{item.production_drawing_edd}</TableCell>
            <TableCell sx={{ textAlign: center }} type='date'>{item.production_dg_committed_date}</TableCell>     
            <TableCell sx={{ textAlign: center }}>{item.productionDelay}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.production_remarks}</TableCell>
            <TableCell sx={{ textAlign: center }} type='date'>{item.mom_planning_edd}</TableCell>
            <TableCell sx={{ textAlign: center }} type='date'>{item.mom_released_date}</TableCell>
             <TableCell sx={{ textAlign: center }}>{item.momDelay}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.mom_remarks}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.bom_released_edd}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.bom_committed_date}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.bomDelay}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.production_remarks}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.stores_edd}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.report_submitted}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.cnc_edd}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.cnc_issued_date}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.cnc_completed_date}</TableCell>
             <TableCell sx={{ textAlign: center }}>{item.cncDelay}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.cnc_remarks}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.hub_edd}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.hub_issued_date}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.hub_completed_date}</TableCell>
             <TableCell sx={{ textAlign: center }}>{item.hubDelay}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.hub_remarks}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.machining_edd}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.machining_issued_date}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.machining_completed_date}</TableCell>
             <TableCell sx={{ textAlign: center }}>{item.machiningDelay}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.machining_remarks}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.hub_completed_date}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.impeller_edd}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.impeller_issued_date}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.impeller_completed_date}</TableCell>
             <TableCell sx={{ textAlign: center }}>{item.impellerDelay}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.impeller_remarks}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.cnc_completed_date}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.casing_edd}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.casing_issued_date}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.casing_completed_date}</TableCell>
             <TableCell sx={{ textAlign: center }}>{item.casingDelay}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.casing_remarks}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.first_coat_paint_edd}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.firstcoatpaint_actual_date}</TableCell>
            <TableCell sx={{ textAlign: center }}>{item.firstCoatPaintRemarks}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.assembly_edd}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.assembly_actual_date}</TableCell>
             <TableCell sx={{ textAlign: center }}>{item.assemblyRemarks}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.testing_edd}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.testing_actual_date}</TableCell>
             <TableCell sx={{ textAlign: center }}>{item.testingRemarks}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.final_paint_edd}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.finalpaint_actual_date}</TableCell>
             <TableCell sx={{ textAlign: center }}>{item.finalPaintRemarks}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.packing_dispatch_edd}</TableCell>
              <TableCell sx={{ textAlign: center }}type='date'>{item.PackingDate}</TableCell>
            <TableCell sx={{ textAlign: center }}type='date'>{item.packing_actual_date}</TableCell>
             <TableCell sx={{ textAlign: center }}>{item.packingDispatchRemarks}</TableCell>
</TableRow>
                      )
                    }
                  </TableBody>
                </Table>
                </Box>
        <ReactHTMLTableToExcel
                id="pms-report-button"
                className="btn btn-primary fw-bold"
                table="pms-report"
                filename={filename}
                sheet="pms_report"
                buttonText="Export PMS" 
                />
                </>
      )
    }
    
    export default Pmsexporttoexecl