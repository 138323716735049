import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { headingLabels,headingLabelsfornonadmin } from '../Comps/Variables/Variables';
import AllOrdersTabel from '../Comps/AllOrdersTabel';
import Heading from '../Comps/Heading';
import useFetch from './../Services/useFetch';
import { fetch_process_order_status, methodGet, otherScreenFetch } from '../Services/Apivariables';
import Statusloader from './../Comps/Statusloader';
import Nodata from './../Comps/Nodata';
import axios from 'axios';

function Displayordersotherscreen() {
       const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [isloading, setIsloading] = useState(false);

    const department = JSON.parse(localStorage.getItem('department'));

       useEffect(() => {
        const sendData = new FormData()
        sendData.append('department', department)
              axios({
                method:'POST',
                url:fetch_process_order_status,
                data:sendData
              }).then(res => {
                if (res.data.error) {
                  setMessage(res.data.message)
                  setIsloading(false);
              } else {
                setData(res.data.data);
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setIsloading(true);
              }
              }).catch(err => {
                console.log(err)
              });
    }, [])   
    
    const roles = JSON.parse(localStorage.getItem('roles'));
    const role = "Super Admin" || "Admin" ;

    const tableHead = roles === role ? headingLabels : headingLabelsfornonadmin ;

    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box variant="div" sx={{ p: 3 }}>
                <Box sx={{ py: 3 }}>
                    <Heading title={'Active Orders'} />
                </Box>
                {data.length === 0 ? <Nodata /> : <Box sx={{ py: 3 }}>
              <AllOrdersTabel data={data} headingLabels={tableHead} subpage={`displaysingleorder`} />
                </Box>}
                <Outlet />
            </Box>
        )
    }
}

export default Displayordersotherscreen