import React from 'react'
import { useState } from 'react';
import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { filterJobScreen } from './../FilterData';
import Filter from './../Filter';
import Heading from './../Heading';
import { showImpellerTableHeading } from '../Variables/ProcessVariables';
import Statusbar from './../Statusbar';
import { nullFinderandDateswapper } from '../Variables/Variables';
import Nodata from './../Nodata';
import { useEffect } from 'react';
import { otherScreenSingleOrders } from '../../Services/Apivariables';
import axios from 'axios';
import ImpellerBarCodeTable from '../BarCodeModule/ImpellerBarCodeTable';

function Displayimpeller({ data, title , jobnum }) {
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [isloading, setIsloading] = useState(false);
    const [refreshpage, setrefreshpage] = useState(false);
    const [impellerBarcodeData, setImpellerBarcodeData] = useState([]);
    const [impellerscreen, setImpellerscreen] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

        useEffect(() => {
        const sendData = new FormData();
        sendData.append('job_num', jobnum)
        sendData.append('department', title)
        axios({
            method: 'POST',
            url: otherScreenSingleOrders,
            data: sendData,
        }).then(res => {
            if (res.data.error) {
                setMessage(res.data.message)
                setrefreshpage(false)
            } else {
                setImpellerscreen(res.data.impeller);
                setImpellerBarcodeData(res.data.impeller_sfg);
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
                setrefreshpage(false)
                setIsloading(true);
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])  


    return (
        <Box>
            <Box sx={{ py: 3 }}>
                <Heading title={title} />
            </Box>
            <Box sx={{ py: 3 }}>
                {data.length === 0 ? <Nodata /> : (
                    <Box sx={{ p: 2 }} component={Card}>
                        <TableContainer >
                            <Table>
                                <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                    <TableRow >
                                        {
                                            showImpellerTableHeading.map((headings) =>
                                                <TableCell key={headings.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box sx={{ my: 'auto' }}>
                                                            {headings.label}
                                                        </Box>
                                                        <Box>
                                                            {headings.filter ? <Filter label={headings.label} setSearch={setSearch} /> : ''}
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.sort((a, b) => (a.impeller_status > b.impeller_status) ? 1 : -1).filter((data) => filterJobScreen(data, search, {
                                            searchFeildOne: data.job_num_1,
                                            searchFeildTwo: data.prod_code,
                                            searchFeildThree: data.short_char_01
                                        })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) =>
                                            <TableRow key={items.impellerid}>
                                                <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{items.serial_no}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{items.job_num_1}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                                                {/* <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <Statusbar label={items.impeller_status === 'true' ? 'Completed' : 'Pending'} color={items.impeller_status === 'true' ? 'success' : 'error'} />
                                                </Box>
                                            </TableCell> */}
                                                <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.impeller_edd)}</TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.hub_status)}</TableCell>
                                                {/* <TableCell sx={{ textAlign: 'center' }}>
                                                {items.impeller_planned_date}
                                            </TableCell> */}
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    {nullFinderandDateswapper(items.impeller_issued_date)}
                                                </TableCell>
                                                {/* <TableCell sx={{ textAlign: 'center' }}>
                                                {items.impeller_required_date}
                                            </TableCell> */}
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    {nullFinderandDateswapper(items.impeller_completed_date)}
                                                </TableCell>
                                                <TableCell sx={{ textAlign: 'center' }}>
                                                    {items.impeller_remarks}
                                                </TableCell>
                                                {/* <TableCell sx={{ textAlign: 'center' }}>
                                                {nullFinderandDateswapper(items.impeller_revised_date)}
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                {items.impeller_revised_remarks}
                                            </TableCell> */}
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                )}
            </Box>
            <ImpellerBarCodeTable isloading={isloading} impellerBarcodeData={impellerBarcodeData} impellerscreen={impellerscreen} />
        </Box>
    )
}

export default Displayimpeller