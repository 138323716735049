import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './VerticalStepper.styles.css';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function VerticalStepper({data}) {
  return (
    <div>

        <div className="step">
           <div>
              <div className={data[0].ProductionandDrawing === 'true' ? 'circle1' : data[0].ProductionandDrawing === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].ProductionandDrawing === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].ProductionandDrawing === 'NA' ? <HighlightOffIcon color='red' /> : 1}
              </div>
           </div>
           <div>
              <div className="title">Production and Drawing</div>
           </div>
        </div>


        <div className="step">
           <div>
              <div className={data[0].Mom === 'true' ? 'circle1' : data[0].Mom === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].Mom === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].Mom === 'NA' ? <HighlightOffIcon color='red' /> : 2}
              </div>
           </div>
           <div>
              <div className="title">Mom</div>
           </div>
        </div>


        <div className="step">
           <div>
              <div className={data[0].CNC === 'true' ? 'circle1' : data[0].CNC === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].CNC === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].CNC === 'NA' ? <HighlightOffIcon color='red' /> : 3}
              </div>
           </div>
           <div>
              <div className="title">CNC</div>
           </div>
        </div>


        <div className="step">
           <div>
              <div className={data[0].Hub === 'true' ? 'circle1' : data[0].Hub === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].Hub === 'true' ? <CheckCircleOutlineIcon className='step-active' /> : data[0].Hub === 'NA' ? <HighlightOffIcon color='red' /> : 4}
              </div>
           </div>
           <div>
              <div className="title">Hub</div>
           </div>
        </div>

        <div className="step">
           <div>
              <div className={data[0].Shaft === 'true' ? 'circle1' : data[0].Shaft === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].Shaft === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].Shaft === 'NA' ? <HighlightOffIcon color='red' /> : 5}
              </div>
           </div>
           <div>
              <div className="title">Shaft</div>
           </div>
        </div>

        <div className="step">
           <div>
              <div className={data[0].Impeller === 'true' ? 'circle1' : data[0].Impeller === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].Impeller === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].Impeller === 'NA' ? <HighlightOffIcon color='red' /> : 6}
              </div>
           </div>
           <div>
              <div className="title">Impeller</div>
           </div>
        </div>

        <div className="step">
           <div>
              <div className={data[0].Casing === 'true' ? 'circle1' : data[0].Casing === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].Casing === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].Casing === 'NA' ? <HighlightOffIcon color='red' /> : 7}
              </div>
           </div>
           <div>
              <div className="title">Casing</div>
           </div>
        </div>

        <div className="step">
           <div>
              <div className={data[0].Accessories === 'true' ? 'circle1' : data[0].Accessories === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].Accessories === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].Accessories === 'NA' ? <HighlightOffIcon color='red' /> : 8}
              </div>
           </div>
           <div>
              <div className="title">Accessories</div>
           </div>
        </div>

        <div className="step">
           <div>
              <div className={data[0].FirstCoatPaint === 'true' ? 'circle1' : data[0].FirstCoatPaint === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].FirstCoatPaint === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].FirstCoatPaint === 'NA' ? <HighlightOffIcon color='red' /> : 9}
              </div>
           </div>
           <div>
              <div className="title">First Coat Paint</div>
           </div>
        </div>


        <div className="step">
           <div>
              <div className={data[0].Assembly === 'true' ? 'circle1' : data[0].Assembly === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].Assembly === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].Assembly === 'NA' ? <HighlightOffIcon color='red' /> : 10}
              </div>
           </div>
           <div>
              <div className="title">Assembly</div>
           </div>
        </div>

        <div className="step">
           <div>
              <div className={data[0].Testing === 'true' ? 'circle1' : data[0].Testing === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].Testing === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].Testing === 'NA' ? <HighlightOffIcon color='red' /> : 11}
              </div>
           </div>
           <div>
              <div className="title">Testing</div>
           </div>
        </div>

        <div className="step">
           <div>
              <div className={data[0].FinalPaint === 'true' ? 'circle1' : data[0].FinalPaint === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].FinalPaint === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].FinalPaint === 'NA' ? <HighlightOffIcon color='red' /> : 12}
              </div>
           </div>
           <div>
              <div className="title">Final Paint</div>
           </div>
        </div>

        <div className="step">
           <div>
              <div className={data[0].Packing === 'true' ? 'circle1' : data[0].Packing === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].Packing === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].Packing === 'NA' ? <HighlightOffIcon color='red' /> : 13}
              </div>
           </div>
           <div>
              <div className="title">Packing</div>
           </div>
        </div>

        <div className="step">
           <div>
              <div className={data[0].Dispatch === 'true' ? 'circle1' : data[0].Dispatch === 'NA' ? 'circle-red' : 'circle'}>
                 {data[0].Dispatch === 'true' ? <CheckCircleOutlineIcon color='#2196f3' /> : data[0].Dispatch === 'NA' ? <HighlightOffIcon color='red' /> : 14}
              </div>
           </div>
           <div>
              <div className="title">Dispatch</div>
           </div>
        </div>


    </div>
  )
}
