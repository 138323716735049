import React, { useContext } from 'react'
import { useState } from 'react';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { addremarks, fetchsinglecnc, methodPost, otherScreenSingleOrders } from '../../Services/Apivariables';
import SnackBar from './../SnackBar';
import DialogBox from './../DialogBox';
import Heading from './../Heading';
import { appendData, cncScreenTableHeading } from '../Variables/ProcessVariables';
import Filter from './../Filter';
import { filterJobScreen } from '../FilterData';
import { nullFinderandDateswapper } from '../Variables/Variables';
import CncBarcodeTable from './../BarCodeModule/CncBarcodeTable';
import submitForm from '../../Services/ProcessScreenSubmitForm';
import Cncsinglejob from './Processsinglejob/Cncsinglejob'
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { bomdataContext } from '../../Pages/OtherScreenlayoutSingle';
import { useEffect } from 'react';


function Cnc({ data, title, setrefreshpage, fetchCommonFirstTable }) {
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [id, setid] = useState('');
    const [multipleOrders, setMultipleOrders] = useState([])
    const [selected, setSelected] = useState([]);
    const [isloading, setIsloading] = useState(false);
    // this state hold all the value in obj
    const [cncInputs, setCncInputs] = useState({})
    const jobnum = data.map((i) => i.job_num);
    const orderid = data.map((i) => i.id);
    const [cncscreen, setcncsreen] = useState([]);

    const [receivedDate  , setReceivedDate] = useState(null);

    // useEffect(() => {
    //     if (data.length !== 0) {
    //     const sendData = new FormData();
    //     sendData.append('job_num', jobnum)
    //     sendData.append('department', title)
    //     axios({
    //         method: 'POST',
    //         url: otherScreenSingleOrders,
    //         data: sendData,
    //     }).then(res => {
    //         if (res.data.error) {
    //             setMessage(res.data.message)
    //             setcncsreen([]);
    //         } else {
    //             setcncsreen(res.data.cnc);
    //             setOpen(true)
    //             setMessage(res.data.message)
    //             setStatus(true)
    //             setColor(true)
    //             setIsloading(true);
    //         }
    //     }).catch(err => {
    //         console.log(err)
    //     });
    // }
    // }, [])  

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    
    const handleSubmit = () => {
        if (receivedDate === null) {
            if (cncInputs.issued_date !== '' && cncInputs.issued_date !== null) {
                const sendData = {
                    "job_no": jobnum,
                    "cnc": JSON.stringify([{issued_date : cncInputs.issued_date}])
                }
                const lData = appendData(sendData)
                submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchCommonFirstTable, fetchsinglejob, jobnum) 
                        setCncInputs({
                            issued_date: '',
                            completed_date: '',
                            cnc_remarks: ''
                        });
                        }
                        else
                        {
                        setMessage("Please Fill Received Date")
                        setOpen(true)
                        setStatus(false)
                        setColor(false)
                    }
                        
                }

       else if (selected.length > 0) {
            if (cncInputs.completed_date !== '' && cncInputs.completed_date !== null){
            const selectedjobs = selected.map((element, i) => {
                const obj = {
                    i: i,
                    orderid: element.orderid,
                    completed_date: cncInputs.completed_date,
                    cnc_remarks: cncInputs.cnc_remarks ? cncInputs.cnc_remarks : ''
                }
                return obj
            })
            const sendData = {
                "job_no":jobnum,
                "cnc": JSON.stringify(selectedjobs)
            }
            const lData = appendData(sendData)
            submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchCommonFirstTable, fetchsinglejob, jobnum) 
            setCncInputs({
                issued_date: '',
                completed_date: '',
                cnc_remarks: ''
            });
         }
            fetchsinglejob(jobnum);
        }
        else {
            setMessage("Please select atleast one job")
            setOpen(true)
            setStatus(false)
            setColor(false)
        }
    }

//   const onUpdate = () =>{
//     if(cncInputs.issued_date !=='' || cncInputs.completed_date !==''){
//         const selectedjobs = data.map((element, i) => {
//             const obj = {
//                 i: i,
//                 orderid: orderid.toString(),
//                 issued_date: cncInputs.issued_date,
//                 completed_date: cncInputs.completed_date,
//                 cnc_remarks: cncInputs.cnc_remarks ? cncInputs.cnc_remarks : ''
//             }
//             return obj
//         })
//         const sendData = {
//             "job_no":jobnum,
//             "cnc": JSON.stringify(selectedjobs)
//         }
//         const lData = appendData(sendData)
//         submitForm(otherScreenSingleOrders, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
//         setCncInputs({
//             issued_date: '',
//             completed_date: '',
//             cnc_remarks: ''
//         });
//         setIsEdit(false);
//         setid(null);
//      }else if(cncInputs.cnc_remarks!==''){
//         const selectedjobs = selected.map((element, i) => {
//             const obj = {
//                 i: i,
//                 orderid: orderid.toString(),
//                 cnc_remarks: cncInputs.cnc_remarks
//             }
//             return obj
//         })
//         const sendData = {
//             "job_no":jobnum,
//             "cnc": JSON.stringify(selectedjobs)
//         }
//         const lData = appendData(sendData)
//         submitForm(addremarks, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
//         setCncInputs({
//             issued_date: '',
//             completed_date: '',
//             cnc_remarks: ''
//         });
//         setIsEdit(false);
//         setid(null);
//      }
//      else {
//         setMessage("Please Fill atleast One Input Field")
//         setOpen(true)
//         setStatus(false)
//         setColor(false)
//     }
//   }

    const fetchsinglejob = (id) => {
        const oid = { job_num: id }
        const sendid = appendData(oid)
        axios({
            method: methodPost,
            url: fetchsinglecnc,
            data: sendid
        }).then(res => {
            setMultipleOrders(res.data.data)
            setMessage(res.data.message)
        }).catch(err => {
            console.log(err)
        });
    }

    // const edd = data.map((i)=> i.cnc_edd.toString());
    // const date = new Date(edd);
    // const day = date.getDate()- 6;
    // const minDay =  date.setDate(day);
    // let d , m ;
    // d = date.getDate();
    // m = date.getMonth()+1;
    // if (d < 10) {
    //     d = '0' + d;
    //   }
      
    //   if (m < 10) {
    //     m = '0' + m;
    //   }
    // const convert = [date.getFullYear(),(m), (d)].join('-');
   

    return (
        <Box>
            <SnackBar open={open} message={message} setOpen={setOpen} color={color} status={status} />
            <Box sx={{ py: 3 }}>
                <Heading title={title} />
            </Box>
            <Box sx={{ py: 3 }}>
                <Box sx={{ p: 2 }} component={Card}>
                    <TableContainer >
                        <Table>
                            <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                                <TableRow >
                                    {
                                        cncScreenTableHeading.map((headings) =>
                                            <TableCell key={headings.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box sx={{ my: 'auto' }}>
                                                        {headings.label}
                                                    </Box>
                                                    <Box>
                                                        {headings.filter ? <Filter label={headings.label} setSearch={setSearch} /> : ''}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                <TableBody>
                    {
                        data.filter((data) => filterJobScreen(data, search, {
                            searchFeildOne: data.job_num,
                            searchFeildTwo: data.prod_code,
                            searchFeildThree: data.short_char_01
                        })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((items, index) =>
                            <TableRow key={items.id}>
                                <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{items.serial_no}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{items.job_num}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{items.prod_code}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{items.short_char_01}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{items.order_qty_1}</TableCell>
                                {/* <TableCell sx={{ textAlign: 'center' }}>
                                    <Box>
                                        <Statusbar label={items.cncstatus === 'true' ? 'Completed' : 'Pending'} color={items.cncstatus === 'true' ? 'success' : 'error'} />
                                    </Box>
                                </TableCell> */}
                                <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.need_by_date)}</TableCell>
                                <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{nullFinderandDateswapper(items.cnc_edd)}</TableCell>

                                <TableCell sx={{ textAlign: 'center' }}>
                                    <IconButton

                                        aria-label="edit" onClick={() => {
                                            setIsEdit(!isEdit)
                                            setid(items.id)
                                            setReceivedDate(items.cnc_date)
                                            fetchsinglejob(items.job_num)
                                            setCncInputs({
                                                issued_date: '',
                                                completed_date: '',
                                                cnc_remarks: ''
                                            });
                                        }}>
                                        {isEdit && id === items.id ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
                                    </IconButton>
                                </TableCell>
                                {
                                items.cnc_date === null || items.cnc_date === '' ?
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <TextField
                                        disabled={isEdit && id === items.id ? false : true}
                                        type='date'
                                        value={isEdit && id === items.id ? cncInputs.issued_date : ''}
                                        onChange={(e) => setCncInputs({ ...cncInputs, 'issued_date': e.target.value })}
                                        fullWidth
                                        size='small'
                                        label='Drawing Received Date'
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // inputProps={{min: [convert] }}
                                    />
                                </TableCell>
                                :
                                        <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(items.cnc_date)}</TableCell>
                                }

                                <TableCell sx={{ textAlign: 'center' }}>
                                    <TextField
                                    disabled={isEdit && id === items.id && items.cnc_date !== null ? false : true}
                                        type='date'
                                        value={isEdit && id === items.id ? cncInputs.completed_date : ''}
                                        onChange={(e) => setCncInputs({ ...cncInputs, 'completed_date': e.target.value })}
                                        fullWidth
                                        size='small'
                                        label='Completed Date'
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                     inputProps={{ min: items.cnc_date }}
                                    />
                                </TableCell>

                                <TableCell sx={{ textAlign: 'center' }}>
                                    <TextField
                                        sx={{ width: 140 }}
                                        multiline
                                        disabled={isEdit && id === items.id ? false : true}
                                        value={isEdit && id === items.id ? cncInputs.cnc_remarks : ''}
                                        onChange={(e) => setCncInputs({ ...cncInputs, 'cnc_remarks': e.target.value })}
                                        fullWidth
                                        size='small'
                                        label='Remarks'
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </TableCell>
                                <TableCell>

                                                 <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box>
                                            {/* {
                          multipleOrders.length !== 0 ? 
                          <Box>
                          <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                            handleSubmit()
                            fetchsinglejob(items.job_num)
                          }} startIcon={<CheckCircleOutlineIcon />}>
                            Submit
                          </Button>
                        </Box> : 
                         <Box>
                         <Button disabled={isEdit && id === items.id ? false : true} variant="contained" onClick={() => {
                           onUpdate()
                          
                         }} startIcon={<CheckCircleOutlineIcon />}>
                           Update
                         </Button>
                       </Box>
                        } */}
                        
                                            </TableCell>
                                            {/* <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    type='date'
                                                    onChange={(e) => setCncRemarksRevisedDate(e.target.value)}
                                                    fullWidth
                                                    size='small'
                                                    label='Revised Date'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <TextField
                                                    sx={{ width: 140 }}
                                                    multiline
                                                    onChange={(e) => setCncRevisedRemarks(e.target.value)}
                                                    fullWidth
                                                    size='small'
                                                    label='Remarks'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Box>
                                                    <Button variant="contained" onClick={() => {handleSubmitRevised(items.id)
                                                      fetchsinglejob(items.job_num)
                                                    }} startIcon={<TelegramIcon />}>
                                                        Request
                                                    </Button>
                                                </Box>
                                            </TableCell> */}
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
             {
                receivedDate !== null &&
                <Box>
                    <Cncsinglejob data={multipleOrders} selected={selected} setSelected={setSelected} />
                </Box>
             }
            {/* <CncBarcodeTable cncscreen={cncscreen} isloading={isloading} /> */}
        </Box>
    )
}

export default Cnc