import React from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';

export const DshbrdTableExportExcel = ({ Domestic, Alstom, WabtecIndia, WabtecUSA, Export, fileName }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (Domestic, Alstom, WabtecIndia, WabtecUSA, Export, fileName) => {
        const ws = XLSX.utils.json_to_sheet(Domestic);
        const wc = XLSX.utils.json_to_sheet(Alstom);
        const wl = XLSX.utils.json_to_sheet(WabtecIndia);
        const wv = XLSX.utils.json_to_sheet(WabtecUSA);
        const wt = XLSX.utils.json_to_sheet(Export);
        const wb = {
            Sheets: { 'Domestic': ws, 'Alstom': wc, 'WabtecIndia': wl, 'WabtecUSA': wv, 'Export': wt },
            SheetNames: ['Domestic', 'Alstom', 'WabtecIndia', 'WabtecUSA', 'Export'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button variant="contained"
            onClick={(e) => exportToCSV(Domestic, Alstom, WabtecIndia, WabtecUSA, Export, fileName)}
            startIcon={<ExitToAppIcon />}
        >
            Export
        </Button>
    )
}