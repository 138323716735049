import React, { useState } from 'react'
import { Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import Heading from './Heading';
import { subcontractorboughtoutheading, subcontractorheading } from './Variables/Variables';
import { filterData } from './FilterData';
import Filter from './Filter';

function SubcontractorBoughtoutTable({ data, broughtOutSelected, setBroughtOutSelected }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState('');

    // this function is for selecting single row
    const handleClick = (event, name) => {
        const selectedIndex = broughtOutSelected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(broughtOutSelected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(broughtOutSelected.slice(1));
        } else if (selectedIndex === broughtOutSelected.length - 1) {
            newSelected = newSelected.concat(broughtOutSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                broughtOutSelected.slice(0, selectedIndex),
                broughtOutSelected.slice(selectedIndex + 1),
            );
        }

        setBroughtOutSelected(newSelected);
    };

    // this function is for select all checkbox
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const suborder = data.flatMap((order)=>order);
            const newSelecteds = suborder;
            setBroughtOutSelected(newSelecteds);
            return;
        }
        setBroughtOutSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const flatBoughtAllOrder = data.flatMap((orders) => orders);

    const isSelected = (name) => broughtOutSelected.indexOf(name) !== -1;
    return (
        <Box>
            {data.length !== 0 && (
                <>
                    <Box>
                        <Heading title={'Bought Out Details'} />
                    </Box>
                    <Box>
                        <TableContainer sx={{ py: 4 }}>
                            <Table aria-label="simple table">
                                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                                    <TableRow>
                                        <TableCell sx={{
                                            color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                                        }} >
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Box>
                                                    <Checkbox className='text-white' onChange={handleSelectAllClick} />
                                                </Box>
                                                <Box>
                                                    Select
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        {subcontractorboughtoutheading.map((labels) =>
                                            <TableCell key={labels.id} sx={{
                                                color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                                            }} >
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Box>
                                                        {labels.title}
                                                    </Box>
                                                    <Box>
                                                        {labels.filter && <Filter label={labels.title} setSearch={setSearch} />}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {flatBoughtAllOrder.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((orders, index) => {
                                        const isItemSelected = isSelected(orders);
                                        return (
                                            <TableRow hover key={index} onClick={(event) => handleClick(event, orders)}>
                                                <TableCell align='center'>
                                                    <Box>
                                                        <Checkbox checked={isItemSelected} />
                                                    </Box>
                                                </TableCell>
                                                {/* <TableCell align='center'>
                                                    {index + 1}
                                                </TableCell> */}
                                                <TableCell align='center'>
                                                    {orders.job_num}
                                                </TableCell>
                                                <TableCell align="center">{orders.sfg_part_name}</TableCell>
                                                <TableCell align="center">{orders.sfg_code}</TableCell>
                                                <TableCell align="center">{orders.part_code}</TableCell>
                                                {/* <TableCell align="center">{orders.part_no}</TableCell> */}
                                                <TableCell align="center">{orders.descriptions}</TableCell>
                                                <TableCell align="center">{orders.qty}</TableCell>
                                                {/* <TableCell align="center">{orders.prod_code}</TableCell>
                                                <TableCell align="center">{orders.finished_weight}</TableCell>
                                                <TableCell align="center">{orders.available_qty}</TableCell>
                                                <TableCell align="center">{orders.short_qty}</TableCell> */}
                                            </TableRow>
                                        )
                                             })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={data.flat().length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </>
            )}
        </Box>
    )
}

export default SubcontractorBoughtoutTable