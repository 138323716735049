import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { getsaleorder, methodPost } from '../Services/Apivariables';
import useFetch from './../Services/useFetch';
import { TableContainer, TableHead, TableRow, TableCell, TablePagination, TableBody, Box, Table, Card, Paper, Grid, TextField } from '@mui/material';
import { displaySingleSalefooter, nullFinderandDateswapper } from '../Comps/Variables/Variables';
import Heading from './../Comps/Heading';
import Statusloader from './../Comps/Statusloader';

function SaleOrderSingle() {
  const location = useLocation();
  const SOid = location.state.SOId
  const Saleorderid = new FormData();
  Saleorderid.append("SOId", SOid);
  const { data, isloading } = useFetch(getsaleorder, methodPost, Saleorderid);

  if (!isloading) {
    return (
      <Statusloader />
    )
  } else {
    return (
      <>
        <Box sx={{ p: 3, borderRadius: 4 }}>

        <Box sx={{ py: 3 }}>
            <Heading title={'Sale Order'} />
          </Box>
{/* header */}
          <Box sx={{ p: 2 }}>

          <Grid sx={{ p: 3, borderRadius: 4 }} container component={Card} spacing={2}>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}  >
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={data.SONum}
                  size="small"
                  fullWidth
                  label='SO Number'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
        
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={nullFinderandDateswapper(data.SODate)}
                  size="small"
                  fullWidth
                  label='SO Date'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={data.CustomerPONum}
                  size="small"
                  fullWidth
                  label='Customer PO Number'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
           
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={nullFinderandDateswapper(data.CustomerPODate)}
                  size="small"
                  fullWidth
                  label='Customer PO Date'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" xs={6} md={6} lg={3}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={nullFinderandDateswapper(data.POClearedDate)}
                  size="small"
                  fullWidth
                  label='PO Cleared Date'
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
 
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={nullFinderandDateswapper(data.NeedByDate)}
                  size="small"
                  fullWidth
                  label='Need By Date'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={nullFinderandDateswapper(data.RequestDate)}
                  size="small"
                  fullWidth
                  label='Request Date'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={data.PaymentTerms}
                  size="small"
                  fullWidth
                  label='Payment Terms'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
      
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={data.CustomerName}
                  size="small"
                  fullWidth
                  label='Customer Name'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={data.SOReleasedStatus}
                  size="small"
                  fullWidth
                  label='SO Released Status'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={data.SOReleasedDate}
                  size="small"
                  fullWidth
                  label='SO Released Date'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={data.TotalOrderValue}
                  size="small"
                  fullWidth
                  label='Total Order Value'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="center" lg={3} xs={6} md={6}>
              <Box variant="div">
                <TextField
                  disabled={true}
                  value={data.SalesRepName}
                  size="small"
                  fullWidth
                  label='SalesRep Name'
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
              </Box>
            </Grid>
          </Grid>
       
    </Box>


{/* Footer */}
<Box>
            <TableContainer component={Card} sx={{ borderRadius: 2 }} >
              <Table >
                <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                  <TableRow >
                    {
                      displaySingleSalefooter.map(items =>
                        <TableCell key={items.id} sx={{ fontWeight: 600, color: 'white' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ my: 'auto' }}>
                              {items.label}
                            </Box>
                            {/* <Box>
                              {items.filter ? <Filter label={items.label} setSearch={setSearch} /> : ''}
                            </Box> */}
                          </Box>
                        </TableCell>
                      )
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
           
                      <TableRow>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {data.SONum}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          {data.SOCategory}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{data.FanModel}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(data.ClassOfConstruction)}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(data.DriveType)}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(data.InspectionType)}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{nullFinderandDateswapper(data.PackingType)}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{data.ArrangementType}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{data.OutletOrientation}</TableCell>
                        <TableCell sx={{ textAlign: 'justify' }}>{data.Airflow}</TableCell>
                        <TableCell sx={{ textAlign: 'justify' }}>{nullFinderandDateswapper(data.CasingMOC)}</TableCell>
                        <TableCell sx={{ textAlign: 'justify' }}>{data.StandMOC}</TableCell>
                        <TableCell sx={{ textAlign: 'justify' }}>{data.ImpellerMOC}</TableCell>
                        <TableCell sx={{ textAlign: 'justify' }}>{data.ShaftMOC}</TableCell>
                        <TableCell sx={{ textAlign: 'justify' }}>{data.InletConeMOC}</TableCell>
                        <TableCell sx={{ textAlign: 'justify' }}>{data.BaseframeMOC}</TableCell>
                        <TableCell sx={{ textAlign:  'justify' }}>{data.MotorScope}</TableCell>
                        <TableCell sx={{ textAlign:  'justify' }}>{data.MotorPower}</TableCell>
                        <TableCell sx={{ textAlign:  'justify' }}>{data.MotorPoles}</TableCell>
                        <TableCell sx={{ textAlign:  'justify' }}>{data.MotorMake}</TableCell>
                        <TableCell sx={{ textAlign:  'justify' }}>{data.MotorFrame}</TableCell>
                        <TableCell sx={{ textAlign:  'justify' }}>{data.SOType}</TableCell>
                        <TableCell sx={{ textAlign:  'justify' }}>{data.SOQuantity}</TableCell>
                        <TableCell sx={{ textAlign:  'justify' }}>{data.SOUnitPrice}</TableCell>
                      </TableRow>
                  
                </TableBody>
              </Table>
            </TableContainer>
            
            
            </Box>
        </Box>
      </>
    )
  }
}

export default SaleOrderSingle