import React, { useState } from 'react'
import { Autocomplete, Box, Button, Card, Grid, TextField } from '@mui/material';
import { bomfilepost, getjobinbom, methodGet, methodPost } from '../Services/Apivariables';
import Heading from '../Comps/Heading';
import useFetch from './../Services/useFetch';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SendIcon from '@mui/icons-material/Send';
import SnackBar from '../Comps/SnackBar';
import axios from 'axios';
import Nodata from './../Comps/Nodata';
import { appendData } from '../Comps/Variables/ProcessVariables';
import Statusloader from '../Comps/Statusloader';
import { ExportCSV } from '../Comps/ExportCSV';
import { bomFilesampleReport } from '../Comps/Variables/Variables';
import { ExportSampleBomReport } from '../Comps/ExportSampleBomReport';

function BomFileupload() {
    const { data, isloading, setrefreshpage } = useFetch(getjobinbom, methodGet);
    const [sendData, setSendData] = useState(null);
    const [bomfile, setbomfile] = useState(null);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [color, setColor] = useState(false);
    const [message, setMessage] = useState("");

    const autocompletedata = data.map((element) => {
        let obj = {
            label: element.job_num,
            year: element.order_qty
        }
        return obj
    });

    const serverData = {
        job_num: sendData?.label,
        orderqty: sendData?.year,
        bom_excel_file: bomfile
    }
    const lData = appendData(serverData);
    const submit = () => {
        if (bomfile === null || sendData === null) {
            setMessage(bomfile === null ? 'file is required' : 'Job number is required')
            setOpen(true)
            setStatus(false)
            setColor(false)
        } else {
            axios({
                method: methodPost,
                url: bomfilepost,
                data: lData,
            }).then(res => {
                if (res.data.error) {
                    setMessage(res.data.message)
                    setOpen(true)
                    setStatus(false)
                    setColor(false)
                } else {
                    setOpen(true)
                    setMessage(res.data.message)
                    setStatus(true)
                    setColor(true)
                    setbomfile(null);
                    setSendData(null);
                    setrefreshpage(true);
                }
            }).catch(err => {
                console.log(err)
            });

        }

    };
    const samplereport  = ["Order Number","Job Number","Fan model","Order Date","Job Release Date","Customer Name","Order Quantity","Unit price","Total value",]
    const csvdata = bomFilesampleReport(samplereport);

    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box sx={{ p: 3 }}>
                <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
                <Box sx={{ py: 3 }} display='flex' justifyContent='space-between'>
                    <Heading title={'Bom File Upload section'} />
                    <ExportSampleBomReport csvData={csvdata} fileName='sampleRert' />
                </Box>
                {data.length === 0 ? <Nodata /> : (
                    <Box sx={{ py: 3 }}>
                        <Box sx={{ p: 2, borderRadius: 4 }} component={Card}>
                            <Grid container justifyContent={'center'} alignItems={'center'} spacing={1}>
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                                    <Autocomplete
                                        size='small'
                                        onChange={(event, newValue) => {
                                            setSendData(newValue)
                                        }}
                                        disablePortal
                                        options={autocompletedata}
                                        renderInput={(params) => <TextField  {...params} label="Job Number" />}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <label htmlFor="bomfile">
                                            <input onChange={(e) => setbomfile(e.target.files[0])} className='d-none' id="bomfile" type="file" />
                                            <Button
                                                startIcon={<InsertDriveFileIcon />}
                                                variant="contained"
                                                color={bomfile !== null ? 'secondary' : 'primary'}
                                                component="span">
                                                {bomfile !== null ? 'File Uploaded' : 'Upload file'}
                                            </Button>
                                        </label>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                                    <Button
                                        onClick={submit}
                                        endIcon={<SendIcon />}
                                        variant="contained"
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
            </Box>
        )
    }


}

export default BomFileupload