import React from 'react'
import { Box, TableContainer, TableHead, TableRow, TableCell, TablePagination, Card, TableBody, Table } from '@mui/material';
import useFetch from '../Services/useFetch';
import { displayMomandPlanning, nullFinderandDateswapper } from '../Comps/Variables/Variables';
import { displayMomandPlanningOrders, methodGet } from '../Services/Apivariables';
import Heading from './../Comps/Heading';
import Filter from './../Comps/Filter';
import { useState } from 'react';
import { filterData } from '../Comps/FilterData';
import Statusloader from './../Comps/Statusloader';
import Nodata from '../Comps/Nodata';
import { useNavigate, Outlet } from 'react-router-dom';

function Displaymomandplanningorders() {
  const navigate = useNavigate();
  const center = 'center';
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { data, isloading, emptydata } = useFetch(displayMomandPlanningOrders, methodGet);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  if (!isloading) {
    return (
      <Statusloader />
    )
  } else {
    return (
      <Box variant="div" sx={{ p: 3 }}>
        <Box sx={{ py: 3 }}>
          <Heading title={'mom and planning status'} />
        </Box>
        <Box sx={{ py: 3 }}>
          <Box>
            {emptydata && data.length === 0 ? <Nodata /> : <Box sx={{ p: 1, borderRadius: 4 }} component={Card}>
              <TableContainer sx={{ borderRadius: 2 }} >
                <Table id='display-mom_and_planning'>
                  <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                    <TableRow >
                      {
                        displayMomandPlanning.map((hd) =>
                          <TableCell key={hd.id} sx={{
                            color: 'white', fontWeight: 600, textAlign: 'center'
                          }}>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ my: 'auto' }}>
                                {hd.label}
                              </Box>
                              <Box>
                                {hd.filter ? <Filter search={search} label={hd.label} setSearch={setSearch} /> : ''}
                              </Box>
                            </Box>
                          </TableCell>
                        )
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      data.sort((a, b) => (a.planning_status > b.status) ? 1 : -1).filter((data) => filterData(data, search, {
                        searchFeildOne: data.order_num,
                        searchFeildTwo: data.job_num_1
                      })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                        <TableRow
                          hover
                          key={item.orderid}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            navigate("momandplanningsinglejobs", {
                              state: {
                                id: item.order_num,
                              }
                            });
                          }}
                        >
                          <TableCell sx={{ textAlign: center }} >{item.order_num}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.production_drawing_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.bom_released_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.mom_planning_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.stores_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.motor_delivery_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.cnc_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.hub_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.machining_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.impeller_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.casing_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.first_coat_paint_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.assembly_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.testing_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.final_paint_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{nullFinderandDateswapper(item.packing_dispatch_edd)}</TableCell>
                          <TableCell sx={{ textAlign: center }}>{item.remarks}</TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>}
          </Box>
        </Box>
        <Outlet />
      </Box>
    )
  }

}

export default Displaymomandplanningorders