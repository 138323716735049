import React from 'react'
import { Box } from '@mui/system';
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, TablePagination, Card } from '@mui/material';
import Filter from './Filter';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { filterStoreScreen} from './FilterData';
import StoreNStockQRGenerate from './BarCodeModule/StoreNStockBarCode/StoreNStockQRGenerate';

function StoreScreenQRTotalTable({ data, headingLabels}) {
    const center = 'center';
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <Box>
            <Box sx={{ p: 1, borderRadius: 4 }} component={Card}>
                <TableContainer sx={{ borderRadius: 2 }} >
                    <Table>
                        <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                            <TableRow >
                                {
                                    headingLabels.map((hd) =>
                                        <TableCell key={hd.id} sx={{
                                            color: 'white', fontWeight: 600, textAlign: 'center'
                                        }}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    {hd.label}
                                                </Box>
                                                <Box>
                                                {hd.filter ? <Filter  label={hd.label} setSearch={setSearch} /> : ''}
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    )
                                }
                                <TableCell sx={{color: 'white', fontWeight: 600, textAlign: 'center'}}>
                                <Box sx={{ display: 'flex' }}>
                                <Box sx={{ my: 'auto' }}>Generate QR</Box>
                                </Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.filter((data) => filterStoreScreen(data, search, {
                                    searchFeildOne: data.partCode,
                                    searchFeildTwo: data.partDescriptions,
                                    searchFeildThree:data.uom,
                                    searchFeildFour:data.classDescription,
                                    searchFeildFive:data.subClassDescription
                                })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                                    <TableRow hover key={item.storeId} sx={{ cursor: 'pointer' }}>
                                        <TableCell sx={{ textAlign: center }}>{item.partCode}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.partDescriptions}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.uom}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.onHandQty}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{ item.unitCost}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.totalCost}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.classDescription}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.subClassDescription}</TableCell>                                     
                                        <TableCell sx={{ textAlign: center }}>{item.wareHouseCode}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.bin}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.class}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.subclass}</TableCell>
                                        <TableCell sx={{ textAlign: center }}>{item.plant}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>
                                           <StoreNStockQRGenerate 
                                            barCodeValue={{partCode: item.partCode,
                                                partDescriptions: item.partDescriptions,
                                                onHandQty: item.onHandQty, uom: item.uom,
                                                unitCost: item.unitCost,
                                                totalCost: item.totalCost,
                                                class: item.class,
                                                subclass: item.subclass,
                                                stockno:item.partCode +'-'+'ST00'+item.onHandQty}}   
                                            QrCodeData={'Manufacturer Name - NADI STORE'  +'\nPart Code - ' +
                                            item.partCode + '\nPart Dsec - ' + item.partDescriptions + 
                                            '\n Class - ' + item.class 
                                           + '\nSub Class - ' + item.subclass + '\nuom - ' + item.uom 
                                           +  '\nQty - '+ item.onHandQty + '\nUnit Price - '+ item.unitCost 
                                           + '\nStock No - '+ item.partCode +'-'+'ST00'+item.onHandQty + '\n Total Stock Value - '+ item.totalCost}                                           
                                           />
                                           </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    )
}

export default StoreScreenQRTotalTable;