import React from 'react'
import { Box, Typography ,Button, TextField, Checkbox, FormGroup, FormControlLabel, Grid } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from "axios";
import {jobupdatereport, jobupdates, process_screen_dashboard_report} from '../../Services/Apivariables';
import { backgroundColor, borderColor, JobAmountUpdateslabels, ActualChartReport, JobUpdateslabels, PlannedChartReport} from '../Variables/Variables';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ExportJobUpdateReportCSV } from '../ExportJobUpdateReportCSV';
import ChartPop_UpDialog from '../ChartPop_UpDialog';
import SnackBar from '../SnackBar';
import { ExportSeparateDeptCSV } from '../ExportSeparateDeptCSV';
import { ExportCSV } from '../ExportCSV';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RefreshIcon from '@mui/icons-material/Refresh';

function Displaycnc({ data, title }) {

    const controller = new AbortController();
    const signal = controller.signal;
    const [dataOne, setDataOne] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [errorData , setErrorData]  = useState(true);
    const [checkDisable1 , setCheckDisable1] = useState(false);
    const [checkDisable2 , setCheckDisable2] = useState(false);
    const [checkDisable3 , setCheckDisable3] = useState(false);
    const [showyear , setShowyear] = useState(false);
    const [showWeeks, setShowWeeks] = useState(false);
    const [hideWeekly , setHideWeekly] = useState(false);
    const [currentCheck , setCurrentCheck] = useState('Yesterday');
    const [popupopen, setPopupopen] = useState(false);
    const [refreshWeeks, setRefreshWeeks] = useState(false);

  // NEWLY ADDED STATES
  const [checkDisable4, setCheckDisable4] = useState(false);
  const [refreshMonthDate, setRefreshMonthDate] = useState(false);
  const [showMonths, setShowMonths] = useState(false);
  const [monthToMonthchecked, setmonthToMonthChecked] = useState(true);
  const [selectedFromMonth, setSelectedFromMonth] = useState('');
  const [selectedToMonth, setSelectedToMonth] = useState('');
  const [trunOnFilter, setTurnOnFilter] = useState(false);

  //   datepicker
       const [value, setValue] = React.useState(null);
       const [refresh, setRefresh] = React.useState(false);
 // Year Conversion
        const date = new Date(value);
        const YearDate = date.getFullYear();
    
  //   checkbox
      const [yesterdaychecked, setYesterdayChecked] = React.useState(true);
      const [weeklychecked, setWeeklyChecked] = React.useState(true);
      const [monthlychecked, setMonthlyChecked] = React.useState(true);

// report data 
     const [ActualReport, setActualReport] = useState([]);

      const [PlannedReport, setPlannedReport] = useState([]);


      

// To Show Pop Up List
      const [jobList, setJobList]=useState([]);

//Bar Color
      const backgroundColorOne = [
        'rgb(153, 102, 255)'
      ]
    
      const backgroundColorTwo = [
        'rgb(255, 159, 64)'
      ]

      const backgroundColorThree = [
         'rgb(255, 99, 132)'
      ]

//   grpah Data
  const linedata = {
    labels: ['PLANNED','ACTUAL','INQUEUE'],
    datasets: [{
    label: title,
    backgroundColor: ['teal', 'blueviolet', 'darkgoldenrod'],
    borderWidth: 1,
    data: dataOne && dataOne.map((i)=>i.Quantity === null ? 0 : i.Quantity),
    },
    ]
  };




//  checkbox disabling and enabling

  const handleCheckBoxYesterdayChange = (event) => {
    setYesterdayChecked(event.target.checked);
    setCurrentCheck(event.target.name)
    if(event.target.checked){
    setCheckDisable1(false);
    setCheckDisable2(true);
    setCheckDisable3(true);
     setCheckDisable4(true);
    }
    else{
        setCheckDisable1(false);
        setCheckDisable2(false);
        setCheckDisable3(false);
         setCheckDisable4(true);
    }

    };

    const handleCheckBoxMonthlyChange = (event) => {
    setMonthlyChecked(event.target.checked);
    setCurrentCheck(event.target.name)
    if(event.target.checked){
    setCheckDisable1(true);
    setCheckDisable2(true);
    setCheckDisable3(false);
    setShowyear(true)
    setRefresh(false);
    setShowWeeks(false);
    setHideWeekly(true);
    }
    else{
        setCheckDisable1(false);
        setCheckDisable2(false);
        setCheckDisable3(false);
        setShowyear(false);
        setCurrentCheck('Yesterday');
        setValue(null);
        setRefresh(true);
        setErrorData(true);
        setHideWeekly(false);
    }
    };

    const handleCheckBoxWeekylChange = (event) => {
      setWeeklyChecked(event.target.checked);
      setCurrentCheck(event.target.name)
      if(event.target.checked){
      setCheckDisable1(true);
      setCheckDisable2(false);
      setCheckDisable3(true);
      setShowWeeks(true);
      setRefreshWeeks(true);
      }
      else{
          setCheckDisable1(false);
          setCheckDisable2(false);
          setCheckDisable3(false);
          setShowWeeks(false);
          setShowyear(false);
          setCurrentCheck('Yesterday');
          setSelectedWeek(result);
          setRefreshWeeks(false);
      }
  
      };

  const handleCheckBoxMonthToMonthChange = (event) => {
    setmonthToMonthChecked(event.target.checked);
    setCurrentCheck(event.target.name)
    if (event.target.checked) {
      setCheckDisable4(false);
      setCheckDisable2(true);
      setCheckDisable1(true);
      setCheckDisable3(true);
      setShowMonths(true);
      setRefreshMonthDate(true);
      setShowWeeks(false)
      setShowWeeks(false);
      setHideWeekly(true);
      setShowyear(false);
    }
    else {
      setCheckDisable1(false);
      setCheckDisable2(false);
      setCheckDisable3(false);
      setShowMonths(false);
      setShowyear(false);
      setHideWeekly(false);
      setCurrentCheck('Yesterday');
      setRefreshMonthDate(false);
      setSelectedFromMonth('')
      setSelectedToMonth('');
    }

  }


//  Graph Data Fetch

  // Yesterday Graph
    useEffect(() => {
     if (currentCheck === 'Yesterday'){
        const sendData = new FormData();
        sendData.append('checkbox', currentCheck)
        sendData.append('Department',title )
          axios({
            method:'POST',
            url:jobupdates,
            data:sendData,
          }).then(res => {
            if (res.data.error) {
              setMessage(res.data.message)
          } else {
            setDataOne(res.data.Data);
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
          }
          }).catch(err => {
            console.log(err)
          });
     }
    }, [currentCheck])


    //Weekly Graph

    // get Current Week

    const todaydate = new Date();

    //find the year of the current date
     const oneJan =  new Date(todaydate.getFullYear(), 0, 1); 

     // calculating number of days in given year before a given date 
     const numberOfDays =  Math.floor((todaydate - oneJan) / (24 * 60 * 60 * 1000)); 

     // adding 1 since to current date and returns value starting from 0 
     const result = Math.ceil(( todaydate.getDay() + 1 + numberOfDays) / 7);  
     const [selectedWeek , setSelectedWeek] = useState(result); 
     let weekNumber;
     weekNumber = selectedWeek;
     if(weekNumber < 10){
      weekNumber = '0' + selectedWeek ;
     }
    
    useEffect(()=>{
      if (currentCheck === 'Weekly' && selectedWeek !== null){
          if(weekNumber !== null){
          const sendData = new FormData();
          sendData.append('checkbox', currentCheck)
          sendData.append('weekNumber', weekNumber)
         sendData.append('Department',title )
            axios({
              method:'POST',
              url:jobupdates,
              data:sendData,
            }).then(res => {
              if (res.data.error) {
                setMessage(res.data.message)
            } else {
              setDataOne(res.data.Data);
              setRefreshWeeks(true);
              setOpen(true)
              setMessage(res.data.message)
              setStatus(true)
              setColor(true)
            }
            }).catch(err => {
              console.log(err)
            });
    }
    }
    },[refreshWeeks]);


// Year Graph
    useEffect(() => {
        if (currentCheck === 'Year'){
            const sendData = new FormData();
            sendData.append('checkbox', YearDate)
           sendData.append('Department',title )
              axios({
                method:'POST',
                url:jobupdates,
                data:sendData,
              }).then(res => {
                if (res.data.error) {
                  setMessage(res.data.message)
              } else {
                setDataOne(res.data.Data);
                setOpen(true)
                setMessage(res.data.message)
                setStatus(true)
                setColor(true)
              }
              }).catch(err => {
                console.log(err)
              });
         }
    }, [value])   



  // Monthly Graph

  const handleFilter = () => {
    if (currentCheck === 'Monthly') {
      if (selectedFromMonth !== '' && selectedToMonth !== '') {
        const sendData = new FormData();
        sendData.append('checkbox', currentCheck)
        sendData.append('Department', title)
        sendData.append('fromDate', selectedFromMonth);
        sendData.append('toDate', selectedToMonth);
        axios({
          method: 'POST',
          url: jobupdates,
          data: sendData,
        }).then(res => {
          if (res.data.error) {
            setMessage(res.data.message)
          } else {
            setDataOne(res.data.Data);
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setTurnOnFilter(!trunOnFilter);
          }
        }).catch(err => {
          console.log(err)
        });
      }
    }
  }

  const handleReset = () => {
    setSelectedFromMonth('');
    setSelectedToMonth('');
    setTurnOnFilter(!trunOnFilter);
  }




// Report Fetching for Excel

  // Yesterday
  useEffect(() => {
    if (currentCheck === 'Yesterday') {
      const sendData = new FormData();
      sendData.append('checkbox', currentCheck)
      sendData.append('Department', title)
      axios({
        method: 'POST',
        url: process_screen_dashboard_report,
        data: sendData,
      }).then(res => {
        if (res.data.error) {
          setMessage(res.data.message)
        } else {
          setPlannedReport(res.data.data);
          setErrorData(res.data.error)
          setOpen(true)
          setMessage(res.data.message)
          setStatus(true)
          setColor(true)
        }
      }).catch(err => {
        console.log(err)
      });
    }
  }, [currentCheck])

  // WEEKLY
  useEffect(() => {
    if (currentCheck === 'Weekly' && selectedWeek !== null) {
      if (weekNumber !== null) {
        const sendData = new FormData();
        sendData.append('checkbox', currentCheck)
        sendData.append('weekNumber', weekNumber)
        sendData.append('Department', title)
        axios({
          method: 'POST',
          url: process_screen_dashboard_report,
          data: sendData,
        }).then(res => {
          if (res.data.error) {
            setMessage(res.data.message)
          } else {
            setPlannedReport(res.data.data);
            setErrorData(res.data.error)
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
          }
        }).catch(err => {
          console.log(err)
        });
      }
    }
  }, [refreshWeeks])


  // MONTH REPORT 
  useEffect(() => {
    if (currentCheck === 'Monthly') {
      if (selectedFromMonth !== '' && selectedToMonth !== '') {
        const sendData = new FormData();
        sendData.append('checkbox', currentCheck)
        sendData.append('Department', title)
        sendData.append('fromDate', selectedFromMonth);
        sendData.append('toDate', selectedToMonth);
        axios({
          method: 'POST',
          url: process_screen_dashboard_report,
          data: sendData,
        }).then(res => {
          if (res.data.error) {
            setMessage(res.data.message)
          } else {
            setPlannedReport(res.data.data);
            setErrorData(res.data.error)
            setOpen(true)
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
          }
        }).catch(err => {
          console.log(err)
        });
      }
    }
  }, [trunOnFilter])


  // YEAR REPORT 
  useEffect(() => {
    if (currentCheck === 'Year') {
      const sendData = new FormData();
      sendData.append('checkbox', YearDate)
      sendData.append('Department', title)
      axios({
        method: 'POST',
        url: process_screen_dashboard_report,
        data: sendData,
      }).then(res => {
        if (res.data.error) {
          setMessage(res.data.message)
        } else {
          setPlannedReport(res.data.data);
          setErrorData(res.data.error)
          setOpen(true)
          setMessage(res.data.message)
          setStatus(true)
          setColor(true)
        }
      }).catch(err => {
        console.log(err)
      });
    }
  }, [refresh])

  const resetReport = () => {
    setRefresh(true);
  }

  const titleKeyMap = {
  'Title A': {
    'Order Number': 'order_num',
    'Job Number': 'job_num',
    'Customer Name': 'customerName',
    'Fan Type' : 'fanType',
    'Fan model': 'fanModel',
    'Order Quantity': 'orderQty',
    'EDD': 'EDD',
    'CNC_EDD': 'cnc_edd',
    'Drawing_Received_Date': 'drawing_received_date',
    'CNC_Completed_Date': 'cnc_completed_date',
    'CNC Delay': 'cncDelay',
    'CNC_Remarks': 'cnc_remarks',
  },
};
    const titleName = 'Title A';

    const planned = PlannedReport && PlannedChartReport(PlannedReport,titleName, titleKeyMap);


   const index = jobList.map((i)=>i.dataIndex.toString());
   const reports = jobList.map((i)=>i.dataset.dummydata[index]);
   const mapped = reports.flatMap(i=>i.map((rep)=>rep));


   


  return (
    <Box>
       <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
      <Box py={2}>
      <Grid container>
        <Grid item xs={6} sm={6} md={6} lg={6}>
        <Box display='flex' justifyContent='start'>
        <Typography
          variant="h3"
          sx={{width:250 ,  textAlign: 'start', fontWeight: 600, fontSize: 25, textTransform: 'uppercase', color: 'primary.main' }}
        >
          {title}
        </Typography>
      </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
        <Box display='flex' justifyContent='end'>
        <Box display='flex' justifyContent='end'>
                <ExportCSV csvData={planned} fileName={`${title}_INQUEUE_REPORT`}/>
        </Box>
        </Box>
       </Grid>
      </Grid>
      </Box>




      <Box sx={{ p: 2 }} display='flex' justifyContent='center'>
        {/* Default Checkbox view on Loading */}

        {
          !showyear && !showWeeks && !showMonths ?
            <>
              <FormGroup sx={{ px: 1 }}>
                <FormControlLabel control={<Checkbox onChange={handleCheckBoxYesterdayChange} name='Yesterday' disabled={checkDisable1} />} label="Yesterday" />
              </FormGroup>
            </> : <></>
        }
        {
          !hideWeekly ?
            <FormGroup sx={{ px: 1 }}>
              <FormControlLabel control={<Checkbox onChange={handleCheckBoxWeekylChange} name='Weekly' disabled={checkDisable2} />} label="Weekly" />
            </FormGroup>
            : <></>
        }


        {
          !showWeeks && !showyear ?
            <FormGroup sx={{ px: 1 }}>
              <FormControlLabel control={<Checkbox onChange={handleCheckBoxMonthToMonthChange} name='Monthly' disabled={checkDisable4} />} label="Monthly" />
            </FormGroup>
            : <></>
        }

        {
          !showWeeks && !showMonths ?
            <FormGroup sx={{ px: 1 }}>
              <FormControlLabel control={<Checkbox onChange={handleCheckBoxMonthlyChange} name='Year' disabled={checkDisable3} />} label="Annual" />
            </FormGroup>
            : <></>
        }


        {/* Month and Year Checkbox */}
        {
          showyear && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={['year']}
                label="Year"
                openTo='year'
                value={value}
                dateFormat="yyyy"
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                onClose={resetReport}
                renderInput={(params) => <TextField {...params} helperText={null} inputProps={{ ...params.inputProps, readOnly: true }} size='small' />}
              />
            </LocalizationProvider>
          )
        }

        {/* Weeks Checkbox */}
        {
          showWeeks && (
            <TextField
              onChange={(e) => {
                setSelectedWeek(e.target.value)
                setRefreshWeeks(false);
              }}
              sx={{ width: 180 }}
              value={selectedWeek}
              defaultValue={selectedWeek}
              type='number'
              size="small"
              label="Week Number"
              id="outlined-size-small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )
        }


        {/* Months Checkbox */}
        {
          showMonths && (
            <Box display='flex' flexDirection='row' gap={2}>
              <TextField
                onChange={(e) => {
                  setSelectedFromMonth(e.target.value)
                  setRefreshMonthDate(false);
                }}
                sx={{ width: 180 }}
                value={selectedFromMonth}
                defaultValue=''
                type='date'
                size="small"
                label="From Month"
                id="outlined-size-small"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                onChange={(e) => {
                  setSelectedToMonth(e.target.value)
                  setRefreshMonthDate(false);
                }}
                sx={{ width: 180 }}
                value={selectedToMonth}
                defaultValue=''
                type='date'
                size="small"
                label="From Month"
                id="outlined-size-small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {
                !trunOnFilter ?
                  <FilterAltOffIcon fontSize='large' onClick={handleFilter} sx={{ verticalAlign: 'middle', color: '#616e80' }} />
                  :
                  <Box display='flex' gap={3}>
                    <FilterAltIcon fontSize='large' sx={{ verticalAlign: 'middle', color: 'success.main' }} />
                    <RefreshIcon fontSize='large' onClick={handleReset} sx={{ verticalAlign: 'middle', color: 'primary.main' }} />
                  </Box>
              }
            </Box>
          )}





        <Box>
          <ChartPop_UpDialog open={popupopen} setOpen={setPopupopen} report={mapped} />
        </Box>

      </Box>
      <Box>
        <Bar data={linedata} options={{
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                color: 'black',
                font: {
                  size: 8,
                  weight: '500',
                  family: "'Jost', sans-serif"
                }
              },
            },
            x: {
              ticks: {
                color: 'black',
                font: {
                  size: 10,
                  weight: '600',
                  family: "'Jost', sans-serif"
                }
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                font: {
                  size: 12,
                  weight: '500',
                  family: "'Jost', sans-serif"
                }
              },
              display: true,
              position: 'top'
            }
          },
        }} />
      </Box>
    </Box>
  )
}

export default Displaycnc