import React from 'react'
import { Box, Grid, Card } from '@mui/material';
import { Chart as Chartjs } from 'chart.js/auto';
import BarChart from '../Comps/Charts/BarChart';
import Doughnut from '../Comps/Charts/Doughnut';
import LineChart from './../Comps/Charts/LineChart';
import Heading from './../Comps/Heading';
import { Outlet } from 'react-router-dom';
import useFetch from './../Services/useFetch';
import { allOrdersFetch, barGraph, doughnutFour, doughnutOne, doughnutThree, doughnutTwo, methodGet, processchart, salestargetchart , dispacthQty,delayedprocess  } from '../Services/Apivariables';
import Statusloader from './../Comps/Statusloader';
import { alstormlabels, backgroundColor, barclabels, borderColor, domesticlabels, exporttoexcel, gelabels, labels, procesborderColor, processbackgroundColor, processlabels } from '../Comps/Variables/Variables';
import Salesbarchart from '../Comps/Charts/Salesbarchart';
import { ExportCSV } from '../Comps/ExportCSV';
import DispatchQtyChart from '../Comps/Charts/DispatchQtyChart';
import DelayedProcessChart from '../Comps/Charts/DelayedProcessChart';
import JobUpdateChart from '../Comps/Charts/JobUpdateChart';
import AnnualReportTable from '../Comps/ManagementDsgbrdTable/AnnualReportTable';
import MonthlyReportTable from '../Comps/ManagementDsgbrdTable/MonthlyReportTable';
import FanModelQty from '../Comps/ManagementDsgbrdTable/FanModelQty';
import FanModelValue from '../Comps/ManagementDsgbrdTable/FanModelValue';
import TabListPanel from '../Comps/ManagementDsgbrdTable/TabListPanel';
import JobUpdateTable from '../Comps/Charts/JobUpdateTable';

function Dashboard() {
  const { data, isloading } = useFetch(barGraph, methodGet);
  const { data: graphdata } = useFetch(allOrdersFetch, methodGet);
  const access = JSON.parse(localStorage.getItem('access'));
  const { production_view, mom_view, store_view, purchase_view, cnc_view, hub_view, machining_view, impeller_view, casing_view, first_paint_view, assembly_view, testing_view, final_paint_view, packing_view, accessories_view } = access;
  const exporttoExcel = exporttoexcel(graphdata);
  const donutchartres = { xs: '100%', sm: '100%', md: '100%', lg: '95%', xl: '95%' }





  if (!isloading) {
    return (
      <Statusloader />
    )
  } else {
    return (
      <Box sx={{ p: 3 }}>
        <Box sx={{ py: 3 }}>
          <Heading title={'dashboard'} />
        </Box>
        {/* <Box sx={{ py: 2 }}>
          <ExportCSV csvData={exporttoExcel} fileName={'report'} />
        </Box> */}
        <Box>
          <Box sx={{ py: 3 }}>
            <Grid container justifyContent={'center'} spacing={4} rowSpacing={4}>
              {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
                  <BarChart data={data} title={'Total Fan Quantity'} />
                </Box>
              </Grid> */}

              {production_view === 'true' || mom_view === 'true' || store_view === 'true' || purchase_view === 'true' || cnc_view === 'true' || hub_view === 'true' || machining_view === 'true' || impeller_view === 'true' || casing_view === 'true' || first_paint_view === 'true' || assembly_view === 'true' || testing_view === 'true' || final_paint_view === 'true' || packing_view === 'true' || accessories_view === 'true' ? (
                <Grid item xs={12} sm={12} md={12} lg={11} xl={4} mt={3}>
                  <Box component={Card} sx={{ p: 3, borderRadius: 5, '&:hover': { boxShadow: 5 } }}>
                    <TabListPanel />
                  </Box>
                </Grid>
              ) : null}

                  <Grid item xs={12}  sm={12} md={12} lg={11} xl={4}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
                   <JobUpdateTable  title={'Plan vs Actual & InQueue'} />
                </Box>
              </Grid>



            {/* <Grid item xs={12}  sm={12} md={12} lg={12} xl={12}>
            <AnnualReportTable />
           </Grid>

            <Grid item xs={12}  sm={12} md={12} lg={12} xl={12}>
            <MonthlyReportTable />
           </Grid>

            <Grid item xs={12}  sm={12} md={12} lg={12} xl={12} mt={3}>
            <FanModelQty />
           </Grid>


          <Grid item xs={12}  sm={12} md={12} lg={12} xl={12} mt={3}>
            <FanModelValue />
           </Grid> */}

              {/* <Grid item xs={12} sm={12} md={12} lg={11} xl={4}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
                  <LineChart url={processchart} title={'Process screen'} labels={processlabels} position={'top'} backgroundColor={processbackgroundColor} borderColor={procesborderColor} /> */}
                  {/* <LineChart url={processchart} title={'Process screen'} labels={processlabels} /> */}
                {/* </Box>
              </Grid> */}
              {/* <Grid item xs={12} lg={11} sm={12} md={12} xl={6}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
                  <Salesbarchart url={salestargetchart} title={'Sales Target'} labels={labels} />
                </Box>
              </Grid> */}

              
                 {/* Dispatch Quantity chart */}

                 {/* <Grid item xs={12} sm={12} md={12} lg={11} xl={4}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
                  <DispatchQtyChart url={dispacthQty} title={'Dispatch Quantity'} labels={labels} />
                </Box>
              </Grid> */}

               {/* Dispatch Quantity chart */}
               {/* <Grid item xs={12} sm={12} md={12} lg={11} xl={4}>
                <Box component={Card} sx={{
                  p: 3, borderRadius: 5, '&:hover': {
                    boxShadow: 5
                  }
                }}>
                  <DelayedProcessChart url={delayedprocess} title={'Delayed Process'} labels={processlabels} />
                </Box>
              </Grid> */}

            </Grid>


          </Box>
          {/* <Box component={Card} sx={{
            py: 3, borderRadius: 5, '&:hover': {
              boxShadow: 5
            }
          }}>
            <Grid container justifyContent={'center'} alignItems={'center'} spacing={4} rowSpacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
                <Box sx={{ width: donutchartres, height: donutchartres, mx: 'auto' }}>
                  <Doughnut url={doughnutOne} title={'Domestic Fans'} labels={domesticlabels} position={'right'} backgroundColor={backgroundColor} borderColor={borderColor} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
                <Box sx={{ width: donutchartres, height: donutchartres, mx: 'auto' }}>
                  <Doughnut url={doughnutTwo} title={'GE Export Fans'} labels={gelabels} position={'right'} backgroundColor={backgroundColor} borderColor={borderColor} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
                <Box sx={{ width: donutchartres, height: donutchartres, mx: 'auto' }}>
                  <Doughnut url={doughnutThree} title={'Alstom Fans'} labels={alstormlabels} position={'right'} backgroundColor={backgroundColor} borderColor={borderColor} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
                <Box sx={{ width: donutchartres, height: donutchartres, mx: 'auto' }}>
                  <Doughnut url={doughnutFour} title={'Barc Fans'} labels={barclabels} position={'right'} backgroundColor={backgroundColor} borderColor={borderColor} />
                </Box>
              </Grid>
            </Grid>
          </Box> */}
        </Box>
        <Outlet />
      </Box>
    )
  }
}

export default Dashboard