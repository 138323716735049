import axios from "axios";



// this is for post data

const submitForm = (url, method, data, setMessage, setOpen, setStatus, setColor, setrefreshpage, setSelected, setIsEdit, setid, fetchCommonFirstTable, fetchsinglejob, jobnum) => {
    axios({
        method: method,
        url: url,
        data: data,
    }).then(res => {
        if (!res.data.error) {
            setMessage(res.data.message)
            setStatus(true)
            setColor(true)
            setrefreshpage(true)
            setSelected([]);
            setIsEdit(false);
            setid(null);
            fetchCommonFirstTable()
            fetchsinglejob(jobnum);
            setrefreshpage(false);
        } else {
            setMessage(res.data.message)
            setStatus(false)
            setColor(false)
            setOpen(true);
        }
    }).catch(err => {
        console.log(err)
    });
}

export default submitForm;