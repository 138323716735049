import React from 'react'
import { Box, Typography, Button } from '@mui/material';
import { Bar, Line } from 'react-chartjs-2';
import { backgroundColor, borderColor, processScreenChartReport } from '../Variables/Variables';
import { useState } from 'react';
import { useEffect } from 'react';
import { ExportCSV } from './../ExportCSV';
import useFetch from '../../Services/useFetch';
import { pendingjobsreport } from '../../Services/Apivariables';
import { ExportProcessScreenCSV } from '../ExportProcessScreenCSV';
import axios from 'axios';

function LineChart({ url, title, labels }) {
  const controller = new AbortController();
  const signal = controller.signal;
  const [data, setData] = useState([]);
  const [productions, setproduction]= useState([]);
  const [moms, setmom]= useState([]);
  const [casings, setcasing]= useState([]);
  const [purchases, setpurchase]= useState([]);
  const [firstcoatpaints, setfirstcoatpaint]= useState([]);
  const [stores, setstore]= useState([]);
  const [finalpaints, setfinalpaint]= useState([]);
  const [assemblys, setassembly]= useState([]);
  const [hubs, sethub]= useState([]);
  const [cncs, setcnc]= useState([]);
  const [packings, setpacking]= useState([]);
  const [accessoriess, setaccessories]= useState([]);
  const [impellers, setimpeller]= useState([]);
  const [testings, settesting]= useState([]);
  const [machinings, setmachining]= useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!navigator.onLine) {
      alert("Your internet is in Offline")
    } else {
      fetch(url, {
        signal: signal
      })
        .then(response => response.json())
        .then(data => {
          setData(data.data)
        })
        .catch(err => {
          console.log(err)
        });
    }
    return () => controller.abort();
  }, []);
  const linedata = {
    labels: labels,
    datasets: [{
      label: ['Pending Fans'],
      backgroundColor: backgroundColor,
      borderColor: borderColor,
      borderWidth: 1,
      data: data.map((graphdata) => graphdata.Quantity),
    }]
  };

  useEffect(() => {
    axios({
      method:'GET',
      url:pendingjobsreport
    }).then(res => {
      if (res.data.error) {
        setMessage(res.data.message)
    } else {
       setproduction(res.data.production);
       setmachining(res.data.machining)
       setmom(res.data.mom)
       setaccessories(res.data.accessories)
       setassembly(res.data.assembly)
       setcnc(res.data.cnc)
       sethub(res.data.hub)
       setfinalpaint(res.data.finalpaint)
       setfirstcoatpaint(res.data.firstcoatpaint)
       setimpeller(res.data.impeller)
       setpacking(res.data.packing)
       setpurchase(res.data.purchase)
       setcasing(res.data.casing)
       setstore(res.data.store)
       settesting(res.data.testing)
    }
    }).catch(err => {
      console.log(err)
    });
  }, [])
  
  






  const production = processScreenChartReport(productions);
  const mom = processScreenChartReport(moms);
  const casing = processScreenChartReport(casings);
  const store = processScreenChartReport(stores);
  const purchase = processScreenChartReport(purchases);
  const impeller = processScreenChartReport(impellers);
  const assembly = processScreenChartReport(assemblys);
  const firstcoatpaint = processScreenChartReport(firstcoatpaints);
   const accessories = processScreenChartReport(accessoriess);
   const cnc = processScreenChartReport(cncs);
   const hub = processScreenChartReport(hubs);
   const machining = processScreenChartReport(machinings);
   const finalpaint = processScreenChartReport(finalpaints);
   const testing = processScreenChartReport(testings);
   const packing = processScreenChartReport(packings);

  return (
    <Box>
      <Box sx={{ p: 2,display:'flex',justifyContent:'space-between' }}>
        <Box>
        <Typography
          variant="h3"
          sx={{ textAlign: 'start', fontWeight: 600, fontSize: 25, textTransform: 'uppercase', color: 'primary.main' }}
        >
          {title}
        </Typography>
        </Box>
        <Box sx={{textAlign: 'end'}}>
        <ExportProcessScreenCSV
        production={production}  mom={mom} casing={casing}  impeller={impeller} store={store}  
        finalpaint={finalpaint} firstcoatpaint={firstcoatpaint} packing={packing} assembly={assembly} 
         cnc={cnc} hub={hub} machining={machining} accessories={accessories} purchase={purchase}
         testing={testing}  fileName={'Process screen chart data'}/>
        </Box>
      </Box>
      <Box>
        <Bar data={linedata} options={{
          scales: {
            y: {
              beginAtZero: true,
              ticks:{
                color: 'black',
                font:{
                  size:12,
                  weight:'500',
                  family:"'Jost', sans-serif"
                }
              }
            },  
            x: {
              ticks:{
                color: 'black',
                font:{
                  size:12,
                  weight:'500',
                  family:"'Jost', sans-serif"
                }
              }
            },         
          },
          plugins: {
            legend: {
              labels:{
                font:{
                  size:15,
                  weight:'500',
                  family:"'Jost', sans-serif"
                }
              },
                display: true,
                position:'top'
            }
        }
        }} />
      </Box>
    </Box>
  )
}

export default LineChart