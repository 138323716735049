import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system';
import { Card, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, TablePagination, Tab, Checkbox, Button } from '@mui/material';
import { StockIssueTabeleHeading } from '../Variables/Variables';
import Filter from '../Filter';
import { footerstockjob } from '../../Services/Apivariables';
import axios from "axios";
import PrintStockQRMulti from '../BarCodeModule/StockJobQRCode/PrintStockQRMulti';
function QRStockJobOrderLayoutTable({data}) {

    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState();
    const [color, setColor] = useState();
    const [message, setMessage] = useState("");
    const [dummystate, setdummystate] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState("");
    const [labels] = useState([]);
    const [dataOne , setDataOne] = useState([]);

    // useEffect(()=>{
    //     const worknumber = new FormData()
    //     worknumber.append('workOrderNum', data.workOrderNum);
     
    //     const methPost = 'POST';
    //       axios({
    //         method: methPost,
    //         url: footerstockjob,
    //         data: worknumber,
    //     }).then(res => {
    //           if (res.data.error) {
    //               setMessage(res.data.message)
    //               setOpen(true)
    //               setStatus(false)
    //               setColor(false)
    //           } else {
    //               setOpen(true)
    //               setMessage(res.data.message)
    //               setStatus(true)
    //               setColor(true)
    //               setdummystate(true)
    //               setDataOne(res.data.data)
    //           }
    //       }).catch(err => {
    //           console.log(err)
    
    //       });
    // },[])

     const emptycell= data.map((item)=> item.stockCompleted); 

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ py: 3 }}>
                       <Box sx={{ py: 1 }}>
                        {
                         emptycell[0] !== null &&
                         <PrintStockQRMulti barcodedata={data} /> 
                        }
                    </Box>
                    <Box sx={{ p: 2 }} component={Card}>
                        <TableContainer sx={{ borderRadius: 2 }}>
                            <Table>
                                <TableHead sx={{ bgcolor: 'primary.main', whiteSpace: 'nowrap' }}>
                            <TableRow >
                        {
                            StockIssueTabeleHeading.map((hd) =>
                                <TableCell key={hd.id} sx={{ textAlign: 'center', color: 'white', fontWeight: 600 }}>
                                        <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                                                <Box sx={{ my: 'auto'}}>
                                                    {hd.label}
                                                </Box>
                                            </Box>
                                            </TableCell>
                            )
                        }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                                <TableRow key={item.id}>
                                <TableCell sx={{ textAlign: 'center' }}>{item.workOrderNum}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.stockNumber}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.partCode}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.Qty}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{item.stockCompleted}</TableCell>
                                </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      rowsPerPage={rowsPerPage}
                      page={page}
                      count={data.length}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Box>
        </Box>
    )
}

export default QRStockJobOrderLayoutTable;