import React from 'react'
import { Box } from '@mui/system';
import { TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Card, Table, IconButton } from '@mui/material';
import Filter from './Filter';
import { useState } from 'react';
import { editRegisterTableHeading } from './Variables/Variables';
import { filterJobScreen } from './FilterData';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';

function Registertable({ data, subpage }) {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <Box sx={{ p: 1, borderRadius: 4 }} component={Card}>
            <TableContainer sx={{ borderRadius: 2 }} >
                <Table >
                    <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                        <TableRow >
                            {
                                editRegisterTableHeading.map((hd) =>
                                    <TableCell key={hd.id} sx={{
                                        color: 'white', fontWeight: 600, textAlign: 'center'
                                    }}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ my: 'auto' }}>
                                                {hd.name}
                                            </Box>
                                            <Box>
                                                {hd.filter ? <Filter search={search} label={hd.name} setSearch={setSearch} /> : ''}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.filter((data) => filterJobScreen(data, search, {
                                searchFeildOne: data.employee_id,
                                searchFeildTwo: data.username,
                                searchFeildThree: data.roles
                            })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
                                <TableRow hover key={item.id}>
                                    <TableCell>
                                        <Box>
                                            <IconButton aria-label="edit" onClick={() => {
                                                navigate(subpage, {
                                                    state: {
                                                        id: item.id,
                                                        order_num: item.order_num,
                                                        employee_id: item.employee_id,
                                                        username: item.username,
                                                        email: item.email,
                                                        pass: item.pass,
                                                        mobile_no: item.mobile_no,
                                                        roles: item.roles,
                                                        view: item.view,
                                                        edit: item.edit,
                                                        production: item.production,
                                                        mom: item.mom,
                                                        store: item.store,
                                                        purchase: item.purchase,
                                                        cnc: item.cnc,
                                                        hub: item.hub,
                                                        machining: item.machining,
                                                        impeller: item.impeller,
                                                        casing: item.casing,
                                                        first_paint: item.first_paint,
                                                        assembly: item.assembly,
                                                        testing: item.testing,
                                                        final_paint: item.final_paint,
                                                        packing: item.packing,
                                                        accessories: item.accessories,
                                                        production_view: item.production_view,
                                                        mom_view: item.mom_view,
                                                        store_view: item.store_view,
                                                        purchase_view: item.purchase_view,
                                                        cnc_view: item.cnc_view,
                                                        hub_view: item.hub_view,
                                                        machining_view: item.machining_view,
                                                        impeller_view: item.impeller_view,
                                                        casing_view: item.casing_view,
                                                        first_paint_view: item.first_paint_view,
                                                        assembly_view: item.assembly_view,
                                                        testing_view: item.testing_view,
                                                        final_paint_view: item.final_paint_view,
                                                        packing_view: item.packing_view,
                                                        accessories_view: item.accessories_view,
                                                        edit_all: item.edit_all,
                                                        view_all: item.view_all,
                                                        jobscreenedit: item.jobscreenedit,
                                                        jobscreenview: item.jobscreenview,
                                                        momscreenedit: item.momscreenedit,
                                                        momscreenview: item.momscreenview,
                                                        partcode_edit: item.partcode_edit,
                                                        partcode_view: item.partcode_view,
                                                        dashboard_view: item.dashboard_view,
                                                        fgpartcode_edit:item.fgpartcode_edit,
                                                        sfgpartcode_edit:item.sfgpartcode_edit,
                                                        subcomppartcode_edit:item.subcomppartcode_edit,
                                                        bom_file_edit:item.bom_file_edit,
                                                        sales_target_edit:item.sales_target_edit,
                                                        pms: item.pms,
                                                        inventory:item.inventory,
                                                        outwards: item.outwards,
                                                        inwards:item.inwards,
                                                        dc_numbers:item.dc_numbers,
                                                        add_register:item.add_register,
                                                        edit_register:item.edit_register,
                                                        sale_order:item.sale_order,
                                                        stock_job:item.stock_job,
                                                        stock_qr:item.stock_qr,
                                                        stock_pending:item.stock_pending,
                                                        stock_issued:item.stock_issued,
                                                        store_stock:item.store_stock,
                                                        management :item.management_dashboard,
                                                        department:item.department,
                                                        monthly_job:item.monthly_job          
                                                    }
                                                });
                                            }}>
                                                <EditIcon color='primary' />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{item.employee_id}</TableCell>
                                    <TableCell>{item.username}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>{item.mobile_no}</TableCell>
                                    <TableCell>{item.roles}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    )
}

export default Registertable