import React, { useState } from 'react'
import { Box, Card, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, TablePagination, TextField, Button, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import OrderDetails from '../Comps/OrderDetails';
import { jobScreenTableHeading } from '../Comps/Variables/Variables'
import { filterJobScreen } from './../Comps/FilterData';
import Filter from '../Comps/Filter';
import { methodPost, singlejobFooter, getinsertedJobs, insertselectedjobs } from '../Services/Apivariables';
import Heading from './../Comps/Heading';
import SnackBar from './../Comps/SnackBar';
import DialogBox from './../Comps/DialogBox';
import submitForm from '../Services/ProcessScreenPostData';
import Statusloader from './../Comps/Statusloader';
import Nodata from '../Comps/Nodata';
import { useEffect } from 'react';
import axios from 'axios';
import { appendData } from '../Comps/Variables/ProcessVariables';
import JobscreenSingle from './../Comps/OrdersModule/JobscreenSingle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


function JobScreen() {
  const location = useLocation();
  const orderData = location.state;
  const jobnum = location.state.job_num
  const jobNumber = new FormData();
  jobNumber.append("job_num", jobnum);
  const [isloading, setIsloading] = useState(false);
  const [emptydata, setemptydata] = useState(false);
  const controller = new AbortController();
  const signal = controller.signal;
  const [data, setData] = useState([]);
  const [month, setMonth] = useState([]);
  const [year, setYear] = useState([]);
  const [refreshpage, setrefreshpage] = useState(false);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (!navigator.onLine) {
      alert("Your internet is in Offline")
    } else {
      axios({
        method: methodPost,
        url: singlejobFooter,
        data: jobNumber,
        signal: signal
      }).then(res => {
        if (res.data.data !== undefined) {
          setData(res.data.data);
          setIsloading(true);
          setMonth(res.data.month);
          setYear(res.data.year);
          setrefreshpage(false);
        } else {
          setIsloading(true)
          setemptydata(true)
        }
      }).catch(err => {
        console.log(err)
      });
    }
    return () => controller.abort();
  }, [refreshpage]);
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [inputs, setInputs] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [id, setid] = useState('');
  const [multipleOrders, setMultipleOrders] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fetchsinglejob = (id) => {
    const oid = { job_num: id }
    const sendid = appendData(oid)
    axios({
      method: methodPost,
      url: getinsertedJobs,
      data: sendid
    }).then(res => {
      if (res.data.data !== undefined) {
        setMultipleOrders(res.data.data)
      }
    }).catch(err => {
      console.log(err)
    });
  }

  const submit = (job_num) => {
    if (selected.length > 0) {
      const selectedjobs = selected.map((element, i) => {
        const obj = {
          i: i,
          orderid: element.orderid,
          bill_planned: inputs.bill_planned,
          year_planne: inputs.year_planne,
          // motor_scope: inputs.motor_scope,
          // payment_status_on_date: inputs.payment_status_on_date,
          // payment_terms: inputs.payment_terms,
          remarks: inputs.remarks ? inputs.remarks : ''
        }
        return obj
      })
      const sendData = {
        "job_screen": JSON.stringify(selectedjobs)
      }
      const lData = appendData(sendData)
      submitForm(insertselectedjobs, methodPost, lData, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage);
      setInputs({
        // payment_status_on_date: '',
        payment_terms: '',
        remarks: ''
      });
      setIsEdit(false);
      setid(null);
      setSelected([])
      if (status) {
        fetchsinglejob(job_num)
      }
    } else {
      setMessage("Please select atleast one job")
      setOpen(true)
      setStatus(false)
      setColor(false)
    }
  }

  if (!isloading) {
    return (
      <Statusloader />
    )
  } else {
    return (
      <Box variant="div" sx={{ p: 3 }}>
        <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />
        <DialogBox message={message} open={openDialog} setOpenDialog={setOpenDialog} />
        <Box sx={{ py: 3 }}>
          <Heading title={'single order details'} />
        </Box>
        <Box sx={{ py: 3 }}>
          <OrderDetails data={orderData} />
        </Box>
        <Box  >
          <Box sx={{ p: 2, borderRadius: 4 }} component={Card}>
            {emptydata && data.length === 0 ? <Nodata /> : <>
              <TableContainer sx={{ borderRadius: 2 }} >
                <Table>
                  <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
                    <TableRow >
                      {
                        jobScreenTableHeading.map((headings, index) =>

                          <TableCell sx={{
                            color: 'white', fontWeight: 600, textAlign: 'center', px: 5
                          }} key={index}>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ my: 'auto' }}>
                                {headings.heading}
                              </Box>
                              <Box>
                                {headings.filter ? <Filter label={headings.heading} setSearch={setSearch} /> : ''}
                              </Box>
                            </Box>
                          </TableCell>
                        )
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      data.filter((data) => filterJobScreen(data, search, {
                        searchFeildOne: data.job_num_1,
                        searchFeildTwo: data.prod_code,
                        searchFeildThree: data.short_char_01
                      })).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) =>
                        <TableRow hover key={order.id}>
                          <TableCell sx={{ textAlign: 'center' }}>{order.serial_no}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{order.job_num_1}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{order.prod_code}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{order.short_char_01}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{order.order_qty_1}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            <IconButton aria-label="edit" onClick={() => {
                              setIsEdit(true)
                              setid(order.id)
                              fetchsinglejob(order.job_num_1)
                            }}>
                              {isEdit && id === order.id ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
                            </IconButton>
                          </TableCell>
                          {/* <TableCell sx={{ textAlign: 'center' }}>
                          <Box>
                            <Statusbar label={order.job_status === 'true' ? 'Completed' : 'Pending'} color={order.job_status === 'true' ? 'success' : 'error'} />
                          </Box>
                        </TableCell> */}
                          <TableCell>
                            {/* <TextField
                              name='bill_planned'
                              onChange={handleChange}
                              fullWidth
                              size='small'
                              label='Bill Planned'
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            /> */}
                            <FormControl
                              sx={{ minWidth: 140 }}
                              fullWidth
                              size='small'
                              disabled={isEdit && id === order.id ? false : true}
                            >
                              <InputLabel>Bill planned</InputLabel>
                              <Select
                                label='Bill Planned'
                                name='bill_planned'
                                onChange={handleChange}
                                defaultValue=''
                                value={isEdit && id === order.id && inputs.bill_planned}
                              >
                                {
                                  month.map((months) =>
                                    <MenuItem key={months.id} value={months.month_name}>{months.month_name}</MenuItem>
                                  )
                                }
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            {/* <TextField
                              name='year_planne'
                              onChange={handleChange}
                              fullWidth
                              size='small'
                              label='Year Planned'
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            /> */}
                            <FormControl
                              disabled={isEdit && id === order.id ? false : true}
                              fullWidth
                              size='small'

                            >
                              <InputLabel>Year planned</InputLabel>
                              <Select
                                label='Year Planned'
                                name='year_planne'
                                onChange={handleChange}
                                defaultValue=''
                                value={isEdit && id === order.id && inputs.year_planne}
                              >
                                {
                                  year.map((years) =>
                                    <MenuItem value={years.YearNum}>{years.YearNum}</MenuItem>
                                  )
                                }
                              </Select>
                            </FormControl>
                          </TableCell>
                          {/* <TableCell>
                            <TextField
                              multiline
                              name='production_category'
                              onChange={handleChange}
                              fullWidth
                              size='small'
                              label='Production Category'
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell> */}
                          <TableCell>
                          <Box sx={{textAlign:'center'}}>
                          {order.short_char_05_1 }
                          </Box>
                            {/* <FormControl
                              disabled={isEdit && id === order.id ? false : true}
                              sx={{ minWidth: 140 }}
                              fullWidth
                              size='small'
                            >
                              <InputLabel>Motor scope</InputLabel>
                              <Select
                                label='Motor Scope'
                                name='motor_scope'
                                onChange={handleChange}
                                defaultValue=''
                                value={isEdit && id === order.id && inputs.motor_scope}
                              >
                                <MenuItem value={'NADI SCOPE'}>NADI SCOPE</MenuItem>
                                <MenuItem value={'CUSTOMER'}>CUSTOMER</MenuItem>
                                <MenuItem value={'NOT APPLICABLE'}>NOT APPLICABLE</MenuItem>
                              </Select>
                            </FormControl> */}
                          </TableCell>
                          {/* <TableCell>
                            <TextField
                              disabled={isEdit && id === order.id ? false : true}
                              multiline
                              name='payment_status_on_date'
                              value={isEdit && id === order.id ? inputs.payment_status_on_date : ""}
                              onChange={handleChange}
                              fullWidth
                              size='small'
                              label='Payment Status on Date'
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell> */}
                          <TableCell>
                            {order.terms_code_1}
                            {/* <TextField
                              disabled={isEdit && id === order.id ? false : true}
                              multiline
                              value={isEdit && id === order.id ? inputs.payment_terms : ""}
                              name='payment_terms'
                              onChange={handleChange}
                              fullWidth
                              size='small'
                              label='Payment Terms'
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            /> */}
                          </TableCell>
                          <TableCell>
                            <TextField
                              disabled={isEdit && id === order.id ? false : true}
                              value={isEdit && id === order.id ? inputs.remarks : ""}
                              multiline
                              name='remarks'
                              onChange={handleChange}
                              fullWidth
                              size='small'
                              label='Remarks'
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Button disabled={isEdit && id === order.id ? false : true} variant="contained" onClick={() => submit(order.job_num_1)}>
                                Submit
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>}
          </Box>
        </Box>
        <Box>
          <JobscreenSingle data={multipleOrders} setSelected={setSelected} selected={selected} />
        </Box>
      </Box>
    )
  }
}

export default JobScreen