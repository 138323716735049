import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import AllBarcodes from './AllBarcodes';
import PrintIcon from '@mui/icons-material/Print';

function OverAllBarcode({ open, setOpen, data }) {
    const onPrintBarcode = () => {
        const container = document.getElementById("div-svg");
        const width = "1000px";
        const height = "1000px";
        const printWindow = window.open('', 'PrintMap',
            'width=' + width + ',height=' + height);
        printWindow.document.writeln(container.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            scroll='paper'
            open={open}
            onClose={() => setOpen(!open)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >
            <DialogContent>
                <Box sx={{ textAlign: 'center' }} id='div-svg'>
                    {data.map((element) =>
                        <Box>
                            <AllBarcodes generateBarcode={element} />
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{ textAlign: 'center', py: 1 }}>
                    <Button variant="contained" onClick={() => {
                        onPrintBarcode()
                        setOpen(!open)
                    }} startIcon={<PrintIcon />}>
                        Print
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default OverAllBarcode