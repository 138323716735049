import React from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function StoreBomExcel({tableid,filename}) {
  return (
    <ReactHTMLTableToExcel
            id="storebom-report-button"
            className="btn btn-primary fw-bold"
            table={tableid}
            filename={filename}
            sheet="Store_Bom_report"
            buttonText="Export" />
  )
}

export default StoreBomExcel