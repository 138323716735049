import axios from "axios";



// this is for post data

const submitForm = (url, method, data, setMessage, setOpen, setStatus, setColor, setOpenDialog, setrefreshpage) => {
  axios({
    method: method,
    url: url,
    data: data,
  }).then(res => {
    if (res.data.error) {
      setMessage(res.data.message)
      setOpenDialog(true)
      setStatus(false)
      setColor(false)
    } else {
      setOpen(true)
      setMessage(res.data.message)
      setStatus(true)
      setColor(true)
      setrefreshpage(true)
    }
  }).catch(err => {
    console.log(err)
  });
}

export default submitForm;