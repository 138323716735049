import React, { createContext } from 'react';
import useFetch from '../Services/useFetch';
import { useLocation } from 'react-router-dom';
import {
  Box
} from '@mui/material';
import OrderDetails from '../Comps/OrderDetails';
import Heading from './../Comps/Heading';
import { methodPost, singlemomandplanningFooter } from '../Services/Apivariables';
import Solomomandplanningtable from '../Comps/SoloMomandPlanningTable';
import Statusloader from './../Comps/Statusloader';

export const MomdataContext = createContext()

function Solemomandplanning() {
  const location = useLocation()
  const orderData = location.state;
  const jobnum = location.state.job_num;
  const jobNumber = new FormData();
  jobNumber.append("job_num", jobnum);
  const {data, isloading, emptydata,setrefreshpage} = useFetch(singlemomandplanningFooter, methodPost, jobNumber);
  if (!isloading) {
    return (
      <Statusloader />
    )
  } else {
    return (
      <MomdataContext.Provider value={data} >
        <Box sx={{ p: 3 }}>
          <Box sx={{ py: 3 }}>
            <Heading title={'single mom and planning'} />
            <Box sx={{ py: 3 }}>
              <OrderDetails data={orderData} />
            </Box>
            <Box sx={{ py: 3 }} >
              <Solomomandplanningtable emptydata={emptydata} setrefreshpage={setrefreshpage} />
            </Box>
          </Box>
        </Box>
      </MomdataContext.Provider>
    )
  }
}

export default Solemomandplanning