import React from 'react'
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { exporttoexcel, saleexport } from '../Comps/Variables/Variables';
import Heading from '../Comps/Heading';
import Nodata from '../Comps/Nodata';
import useFetch from '../Services/useFetch';
import { getallsaleorder, methodGet, } from '../Services/Apivariables';
import Statusloader from '../Comps/Statusloader';
import { ExportCSV } from '../Comps/ExportCSV';
import SaleOrderTable from './SaleOrderTable';
import { useEffect } from 'react';
import axios from 'axios';


function SaleOrder() {
    const {data, isloading, emptydata} = useFetch(getallsaleorder, methodGet);
    const exporttoExcel = saleexport(data);

    // useEffect(() => {
    //     axios({
    //       method: methodGet,
    //       url: saleorder,
    //     })
    //   }, [])
    if (!isloading) {
        return (
            <Statusloader />
        )
    } else {
        return (
            <Box variant="div" sx={{ p: 3 }}>
                <Box sx={{ py: 3 }}>
                    <Heading title={'Sale Order'} />
                </Box>

                {emptydata && data.length === 0 ? <Nodata /> : <Box>
                    <Box sx={{ py: 2 }}>
                        <ExportCSV csvData={exporttoExcel} fileName={'Sale_Order_report'} />
                    </Box>
                    <Box sx={{ py: 3 }}>
                        <SaleOrderTable data={data} subpage={`saleordersingle`} />
                    </Box>
                </Box>}
                <Outlet />
            </Box>)
    }

}

export default SaleOrder